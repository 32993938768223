import React, {useCallback, useContext, useState} from 'react'
import {STORE_UPOS_ICON} from '../../../interface/_icons'
import {BannerManagementContext} from '../../../provider/_context'
import {Text} from 'common/text'
import {Tr} from 'layouts/tableLayout/_tr'
import {Td} from 'layouts/tableLayout/_td'
import {debounce, Skeleton} from '@mui/material'
import styled from 'styled-components'
import useBannerManagementRow from '../../../hooks/useBannerManagementRow'
import {Button} from 'common/button'
import {useTranslation} from 'react-i18next'
import {GLOBAL_ICONS} from 'interface/icon'
import {formatMoney} from 'util/functionUtil'
import {DeleteConfirmModal} from '../../deleteConfirmModal'
import {fDateTimeCustom} from '../../../../../util/formatTime'
import {Input} from "../../../../../common/form/input";
import {sendRequestAuth} from "../../../../../api/api";
import config from "../../../../../config";

const Tbody = () => {
   const {t} = useTranslation()
   const {pageState} = useContext(BannerManagementContext)
   const {table} = pageState
   const displayList = table?.display?.list
   const displayListDefault = table?.display?.listDefault
   const displayLoading = table.display.loading
   const paginationAmount = table.pagination.amount

   return (
      <StyledTBody>
         {displayLoading ? (
            Array.from(Array(paginationAmount), (e, i) => (
               <BannerManagementPlaceholder key={i}/>
            ))
         ) : displayList.length > 0 ? (
            displayList.map((item, key) => <BannerManagementTr t={t} key={item.id} stt={key} data={item}/>)
         ) : (
            <BannerManagementEmpty t={t} list={displayListDefault}/>
         )}
      </StyledTBody>
   )
}

export default Tbody

const BannerManagementPlaceholder = ({...props}) => {
   return (
      <Tr {...props} className="store-upos-table__row">
         {Array.from(Array(4), (e, i) => (
            <Td key={i} className="store-upos-table__cell" data-type="td">
               <Skeleton
                  sx={{
                     width: '100%',
                     height: 33,
                     background:
                        'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
                  }}
               />
            </Td>
         ))}
      </Tr>
   )
}

const BannerManagementEmpty = ({list, t, ...props}) => {
   return (
      <StyledBannerManagementEmpty {...props}>
         <img
            className="store-upos-empty__banner"
            src="/img/order/order-empty.png"
            alt="empty"
         />
         <Text as="b" color="#7C88A6" style={{marginBottom: 16}}>
            {!!!list ? t('Bạn chưa có sản phẩm sách nào') : t('no_matching_data')}
         </Text>
         {!!!list && (
            <Button href={'/upos/shop/create'} icon={STORE_UPOS_ICON.plus}>{t('add_new_shop')}</Button>
         )}
      </StyledBannerManagementEmpty>
   )
}

const BannerManagementTr = ({data, t, ...props}) => {
   const orderRow = useBannerManagementRow(data)
   const {row} = orderRow
   const [ordering, setOrdering] = useState(data?.ordering)
   const updateOrdering = async (ordering, id) => {
      const response = await sendRequestAuth('post',
         `${config.API}/banner/update-ordering/${id}`,
         {
            'ordering': ordering
         }
      )

      if (response?.data?.success) return 1
      else return 2
   }
   const debounceUpdateOrdering = useCallback(debounce((ordering, id) => {
      const res = updateOrdering(ordering, id)
   }, 500), [data])

   const onChangeOrdering = value => {
      setOrdering(+value < 999 ? value : ordering)
      debounceUpdateOrdering(+value < 999 ? +value : ordering, data?.id)
   }
   return (
      <>
         <DeleteConfirmModal/>
         <Tr
            {...props}
            className="store-upos-table__row"
         >
            <Td className="store-upos-table__cell" data-type="td">
               {props?.stt + 1}
            </Td>
            <Td className="store-upos-table__cell" data-type="td">
               <Text as={'p'}
                     fontWeight={600}
               >{data?.title || '---'}</Text>
            </Td>
            <Td className="store-upos-table__cell" data-type="td">
               <Text as={'p'}
                     fontWeight={600}
               >{data?.position_name || '---'}</Text>
            </Td>
            <Td className="store-upos-table__cell" data-type="td">
               <img style={{maxWidth: '40%'}} src={data?.image} alt=""/>
            </Td>
            <Td className="store-upos-table__cell" data-type="td">
               <Text as={'p'}
                     fontWeight={600}
               >{!!data?.started_at ? fDateTimeCustom(data?.started_at) : '---'} {data?.ended_at && ` - ${fDateTimeCustom(data?.ended_at)}`}</Text>
            </Td>
            <Td className="store-upos-table__cell" data-type="td">
               <Text as={'p'}
                     fontWeight={600}
               >{+data?.status === 1 ? 'Hoạt động' : 'Tắt'}</Text>
            </Td>
            <Td className="store-upos-table__cell" data-type="td">
               <Text as={'p'}
                     fontWeight={600}
               >
                  <div style={{width: '60%'}}>
                     <Input type={'number'} value={ordering} onChange={e => onChangeOrdering(e.target.value)}/>
                  </div>
               </Text>
            </Td>
            <Td
               className="store-upos-table__cell"
               data-menu="true"
               data-type="td"
               onClick={e => e.stopPropagation()}
            >
               <Button
                  className="store-upos-table__edit"
                  data-active={row.shouldOpenDetail}
                  onClick={() => row?.onEditDetail(data)}
               >
                  {GLOBAL_ICONS.edit}
               </Button>
               <button
                  className="store-upos-table__delete"
                  onClick={() => row.onDisplayDeleteModal(true, data?.id)}
               >
                  {GLOBAL_ICONS.recycle}
               </button>
            </Td>
         </Tr>
      </>
   )
}

export const StyledTBody = styled.div`
  .store-upos-table {
    &__loading {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
  
      width: 100vw;
      height: 100vh;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      background: rgba(0, 0, 0, 0.25);
  
      img {
        width: 80px;
        height: 80px;
      }
    }
  
    &__row {
      &:hover {
        .store-upos-table__delete {
          display: block;
        }
        .store-upos-table__edit {
          display: block;
        }
      }
    }
  
    &__cell {
      cursor: pointer;
      margin: 0 8px;
      display: flex;
      justify-content: left;
      align-items: center;
      &[data-menu='true'] {
        position: relative;
      }
  
      &[data-type='th'] {
        &[data-selected='true'] {
          display: flex;
          flex: 1;
          align-items: center;
          height: 44px;
        }
      }
  
      &--success {
        color: #0B74E5 !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #ECF4FE;
        border-radius: 4px;
      }
      &--danger {
        color: #FF424E !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #FFEBEC;
        border-radius: 4px;
      }
       &:nth-child(1) {
          width: 2%;
        }
        &:nth-child(2) {
          flex: 1;
        }
        &:nth-child(3) {
          width: 14%;
          justify-content: center;
        }
        &:nth-child(4) {
          width: 18%;
          text-align: center;
          justify-content: center;
        }
        &:nth-child(5) {
          width: 14%;
          text-align: center;
          justify-content: center;
        }
        &:nth-child(6) {
          width: 14%;
          text-align: center;
          justify-content: center;
        }
        &:nth-child(7) {
          width: 10%;
          text-align: center;
          justify-content: center;
        }
        &:nth-child(8) {
          width: 80px;
          justify-content: end;
        }
    }
  
    &__delete {
      position: absolute;
      top: 38%;
      right: 8px;
  
      width: 20px;
      height: 20px !important;
  
      background: transparent;
      border: none;
      border-radius: 12px !important;
  
      font-size: 12px !important;
      line-height: 24px !important;
  
      cursor: pointer;
  
    }
  
    &__edit {
      position: absolute;
      top: 40%;
      right: 42px;
  
      width: 20px;
      height: 20px !important;
  
      background: transparent;
      border: none;
      border-radius: 12px !important;
  
      font-size: 12px !important;
      line-height: 24px !important;
  
      cursor: pointer;
  
      &[data-active='true'] {
        display: block !important;
      }
    }
  
    &__tooltipV2 {
      width: 100%;
      padding: 0;
      overflow: hidden;
      position: relative;
      display: inline-block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
}
`
export const StyledBannerManagementEmpty = styled.div`
  min-height: calc(100vh - 430px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;

  .store-upos-empty__banner {
    width: 133px;
    height: 133px;
    margin-bottom: 16px;

    object-fit: contain;
    object-position: center;
  }
`
