import {AlternativeAutoComplete} from 'common/form/autoComplete/_alternativeAutoComplete'
import {Option} from 'common/form/autoComplete/_option'
import useOrderFilterForm from 'Pages/orderDigital/hooks/useOrderFilterForm'

export const OrderPaymentMethod = () => {
  const {paymentMethod} = useOrderFilterForm()

  return (
    <AlternativeAutoComplete
      className="order-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: {name: 'Hình thức thanh toán', value: ''}, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 146,
        placeholder: 'Chọn hình thức thanh toán',
        readOnly: true,
        value: paymentMethod.value?.name || '',
        onIconClick: () => paymentMethod.onChange(null),
      }}
      // menu
      menuProps={{
        empty:
          paymentMethod.list.length <= 0
            ? 'Không tìm thấy hình thức thanh toán'
            : '',
      }}
      // search input in dropdown menu
      searchInputProps={{
        placeholder: 'Tìm kiếm hình thức thanh toán',
        value: paymentMethod.keyword,
        onChange: paymentMethod.onKeywordChange,
      }}
    >
      {paymentMethod.list.length > 0 &&
        paymentMethod.list.map(item => (
          <Option
            key={item.value}
            className="order-filter-form__option-text"
            data-active={item.value === paymentMethod.value?.value}
            onClick={() => paymentMethod.onChange(item)}
          >
            {item.name}
          </Option>
        ))}
    </AlternativeAutoComplete>
  )
}
