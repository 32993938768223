import React, {useEffect} from "react";
import {LicenseKeyProvider} from './provider'
import {useLicenseKey} from "./hook/useLicenseKey";
import {PageHeaderLicense} from "./component/header";
import {TableLayout} from "../../layouts/tableLayout";
import FilterForm from "./component/filter";
import TheadLicense from "./component/table/tableHeader";
import TbodyLicenseKey from "./component/table/tableBody";
export const LicenseKey = ()=>{
    const {provider, fetch, pagination} = useLicenseKey()
    const {state, dispatch} = provider
    const {table} = state
    useEffect(() => {
        fetch.origin()
    }, [])
    return(
        <LicenseKeyProvider value={{pageState: state, pageDispatch: dispatch}}>
            <PageHeaderLicense/>
            <TableLayout
                header={
                    <>
                        <FilterForm />
                    </>
                }
                table={{
                    tHead: <TheadLicense />,
                    tBody: <TbodyLicenseKey />,
                }}
                pagination={{
                    ...table.pagination,
                    onAmountChange: pagination.onAmountChange,
                    onPageChange: pagination.onPageChange,
                }}
            />
        </LicenseKeyProvider>
    )
}