import React, {useContext, useState} from 'react';
import {BookManagementContext} from "../provider/_context";
import {bookManagementActions} from "../provider/_init"
import {sendRequestAuth} from "../../../../api/api";
import config from "../../../../config";
import useBookManagementFilter from '../hooks/useBookManagementFilter'
import useAlert from '../../../../hook/useAlert'

const useBookManagementRow = data => {
  const { state, dispatch } = useContext(BookManagementContext)
  const { table } = state
  const {showAlert} = useAlert()
  // const navigate = useNavigate()

  const [shouldOpenSubmitPaymentModal, setShouldOpenSubmitPaymentModal] = useState(false)

  const detailActive = table.detail.active
  const detailActiveId = table.detail.id
  const detailList = table.detail.list

  const selectedList = table.selected.list

  const isSelected = !!selectedList.find(item => item?.id === data?.id)

  const shouldOpenDetail = data?.id && detailActiveId === data.id

  const handleToggleDetail = _ => {
    if (!data?.id) return
    if (data.id === detailActiveId) {
      dispatch({
        type: bookManagementActions.TABLE_DISPLAY_DETAIL_ID_UPDATE,
        payload: { id: null },
      })
      dispatch({
        type: bookManagementActions.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: { active: null },
      })
      return
    }

    dispatch({
      type: bookManagementActions.TABLE_DISPLAY_DETAIL_ID_UPDATE,
      payload: { id: data.id },
    })

    const findDetail = detailList.find(item => item?.id === data.id)
    if (findDetail) {
      dispatch({
        type: bookManagementActions.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: { active: findDetail },
      })
    }

    fetchRowDetail()
  }

  const fetchRowDetail = async _ => {
    const response = await sendRequestAuth('get', `${config.API}/product/book/detail/${data.id}`)

    if (response?.data?.success) {
      const newItem = response?.data?.data
      dispatch({
        type: bookManagementActions.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: { active: newItem, list: [...detailList, newItem] },
      })
    } else {
      dispatch({
        type: bookManagementActions.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: { active: [], list: [...detailList, []] },
      })
    }
  }

  const handleEditDetail = _ => {
    // navigate(`/accountant/price-adjustment/edit/${data?.id || ''}`)
  }

  const handleDisplayDeleteModal = (boo,id='') => {
    dispatch({
      type: bookManagementActions.MODAL_DELETE_PRODUCT,
      payload: {
        open: boo,
        id: id
      }
    })
  }

  const {methods} = useBookManagementFilter()
  const handleDeleteProduct = async id => {
    const response = await sendRequestAuth('delete', `${config.API}/product/book/delete/${id}`)

    if (response?.data?.success) {
      showAlert({
        type: 'success',
        content: 'Đã xóa thành công sản phẩm'
      })
      methods.applyOtherFilter()
    } else {
      showAlert({
        type: 'danger',
        content: 'Xóa sản phẩm thất bại'
      })
    }
  }
  return {
    modal: state.delete,
    detail: {
      id: detailActiveId,
      active: detailActive,
      // tabs: { payment: { formatDateTime: cellCodeOrderFormatDateTime } },
    },
    row: {
      data,
      isSelected,
      shouldOpenDetail,
      shouldOpenSubmitPaymentModal,
      onCloseSubmitPaymentModal: () => setShouldOpenSubmitPaymentModal(false),
      onToggleDetail: handleToggleDetail,
      onEditDetail: handleEditDetail,
      onDisplayDeleteModal: handleDisplayDeleteModal,
      onDeleteProduct: handleDeleteProduct,
    },
  }
}

export default useBookManagementRow