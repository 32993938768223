import React, { useState } from 'react'
import Search from './_search'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {Button} from "../../../../common/button";
import {THEME_COLORS} from  "../../../../common/theme/_colors"
import {OrderTag} from "./_tag";
import LicenseKeyStatus from "./_status";
import SearchCodeKey from "./_searchCodeKey";
import {useLicenseKeyFilter} from "../../hook/useLicenseKeyFilter";
import {Text} from "../../../../common/text";
import {FILTER_TAG} from "../../interface/_const";
const FilterForm = ({ ...props }) => {
  const { t } = useTranslation()
  const {
    canSubmitForm,
    submitBadge,
    methods,
    productName,
    status,
    keyCode
  } = useLicenseKeyFilter()
  return (
    <StyledLicenseKeyFilterForm {...props}>
      <div className="license-key-filter-form__group">
        <Search/>
        <SearchCodeKey/>
        <LicenseKeyStatus/>
        <Button
          appearance="secondary"
          disabled={!canSubmitForm}
          size="md-"
          style={{ width: 101, marginLeft: '16px' }}
          onClick={() =>
              canSubmitForm && methods.applyOtherFilter()
          }
        >
          Tìm kiếm
        </Button>
      </div>
      {
        +submitBadge.length > 0 && <div className='license-key-filter-form__tag'>
          {
            !!productName?.active && <OrderTag onDelete={()=>methods?.filterTagDelete(FILTER_TAG[0])}>
              Sản phẩm:{' '}
              {productName?.active|| '---'}
            </OrderTag>
          }
          {
            !!keyCode?.active && <OrderTag onDelete={()=>methods?.filterTagDelete(FILTER_TAG[1])}>
              Mã key:{' '}
              {keyCode?.active || '---'}
            </OrderTag>
          }
          {
            status?.active && <OrderTag onDelete={()=>methods?.filterTagDelete(FILTER_TAG[2])}>
                Trạng thái:{' '}
              {status?.active?.name || '---'}
            </OrderTag>
          }
          <Text
              fontWeight={500}
              color={THEME_COLORS.primary_300}
              style={{cursor: 'pointer'}}
              onClick={methods.filterTagDeleteAll}
          >
            Đặt lại mặc định
          </Text>
        </div>
      }
    </StyledLicenseKeyFilterForm>
  )
}

export default FilterForm

export const StyledLicenseKeyFilterForm = styled.div`

margin-bottom: -16px;
.license-key-filter-form {
    &__group {
      width: calc(100% + 180px);
      margin: 0 -6px 16px -6px;

      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & > * {
        margin: 0 6px;
      }
    }

    &__collapse {
      max-height: 0;
      margin-bottom: 0 !important;

      overflow: hidden;

      transition: all 0.25s;

      &[data-collapse='true'] {
        max-height: 50vh;
        margin-bottom: 16px;

        overflow: unset;
      }
    }

    &__input-wide {
      width: calc(25% - 12px);
      margin: 0 6px;
    }

    &__option-text {
      min-height: 36px;

      display: flex;
      align-items: center;

      color: ${THEME_COLORS.gray_900};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      cursor: pointer;

      transition: color 0.25s;

      &[data-active='true'] {
        color: ${THEME_COLORS.primary_300};
        font-weight: 600;
      }
      &:hover {
        color: ${THEME_COLORS.primary_300};
      }
    }

    &__option-container {
      min-height: 45px;
      margin-bottom: 4px;

      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__option-tabs {
      position: sticky;
      top: 0;
      z-index: 1;

      width: 100%;
      height: 28px;
      margin-bottom: 16px;

      display: flex;
      align-items: center;

      background: #fff;

      &::before {
        position: absolute;
        top: -20px;
        left: -20px;

        width: calc(100% + 40px);
        height: calc(100% + 36px);

        background: #fff;
        border-radius: 8px 8px 0 0;

        content: '';
      }
    }

    &__option-tab {
      position: relative;
      z-index: 2;

      margin-right: 16px;

      color: #808089;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      cursor: pointer;

      &[data-active='true'] {
        color: ${THEME_COLORS.primary_300};
        font-weight: 600;

        cursor: default;
      }
    }

    &__collapse {
      .store-upos-filter-form__input-wide {
        margin-bottom: 16px;
      }
    }
    &__tag{
      margin: 16px 0;
    }
  }
`
