import React, {useContext, useState} from 'react'
import {Skeleton} from '@mui/material'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {ProductGroup} from "../../provider/_context";
import {Tr} from "../../../../layouts/tableLayout/_tr";
import {Text} from "../../../../common/text";
import {Td} from "../../../../layouts/tableLayout/_td";
import {Button} from "../../../../common/button";
import {STORE_UPOS_ICON} from "../../../products/book/interface/_icons";
import {Tooltip as TooltipV2} from "../../../../common/tooltipv2";
import {Tooltip} from "../../../../common/tooltip";
import {fNumber} from "../../../../util/formatNumber";
import {GLOBAL_ICONS} from "../../../../interface/icon";
import {useProductAction} from "../../provider/_reducer";
import {useModal} from "../../hook/useModal";
import {ConfirmDeleteModal} from "../../../orderBook/components/orderTable/_confirmDeleteModal";
import {sendRequestAuth} from "../../../../api/api";
import config from "../../../../config";
import useAlert from "../../../../hook/useAlert";
import useProductGroupFilter from "../../hook/useProductGroupFilter";

const Tbody = () => {
   const {t} = useTranslation()
   const {pageState} = useContext(ProductGroup)
   const {table} = pageState
   const displayList = table?.display?.list
   const displayListDefault = table?.display?.listDefault
   const displayLoading = table.display.loading
   const paginationAmount = table.pagination.amount
   const paginationTotalItems = table.pagination.totalItems
   const paginationActive = table.pagination.active

   const [confirmDeleteModalData, setConfirmDeleteModalData] = useState(null)

   return (
      <StyledTBody>
         {displayLoading ? (
            Array.from(Array(paginationAmount), (e, i) => (
               <ProductGroupPlaceholder key={i}/>
            ))
         ) : paginationTotalItems > 0 ? (
            displayList.map((item, key) => <ProductGroupTr t={t} key={item.id}
                                                           stt={key + (+paginationAmount * +paginationActive)}
                                                           onDelete={setConfirmDeleteModalData}
                                                           data={item}/>)
         ) : (
            <ProductGroupEmpty t={t} list={displayListDefault}/>
         )}
         {!!confirmDeleteModalData && <ConfirmDeleteModal {...confirmDeleteModalData} />}
      </StyledTBody>
   )
}

export default Tbody

const ProductGroupPlaceholder = ({...props}) => {
   return (
      <Tr {...props} className="principal-table__row">
         {Array.from(Array(7), (e, i) => (
            <Td key={i} className="principal-table__cell" data-type="td">
               <Skeleton
                  sx={{
                     width: '100%',
                     height: 33,
                     background:
                        'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
                  }}
               />
            </Td>
         ))}
      </Tr>
   )
}

const ProductGroupEmpty = ({list, t, ...props}) => {
   return (
      <StyledProductGroupEmpty {...props}>
         <img
            className="principal-empty__banner"
            src="/img/order/order-empty.png"
            alt="empty"
         />
         <Text as="b" color="#7C88A6" style={{marginBottom: 16}}>
            {!!!list ? t('Bạn chưa có danh sách hiệu trưởng nào') : t('no_matching_data')}
         </Text>
         {!!!list && (
            <Button href={'/upos/shop/create'} icon={STORE_UPOS_ICON.plus}>{t('add_new_shop')}</Button>
         )}
      </StyledProductGroupEmpty>
   )
}

const ProductGroupTr = ({data, t, stt, onDelete, ...props}) => {

   const {pageDispatch} = useContext(ProductGroup)
   const {getDetailProduct} = useModal()
   const {methods} = useProductGroupFilter()
   const {showAlert} = useAlert()
   const handleModalChangeCategory = id => {
      getDetailProduct(id)
      pageDispatch({type: useProductAction.OPEN_MODAL, payload: true})
      pageDispatch({type: useProductAction.GET_ID, payload: id})
      pageDispatch({type: useProductAction.DISABLE_SELECT, payload: (+data?.total_products > 0 || data?.parent == 0)})
   }

   const deleteCategory = async (id) => {
      onDelete(null)
      await sendRequestAuth('delete', `${config.API}/product-category/delete/${id}`)
      showAlert({
         type: 'success',
         content: 'Đã xóa danh mục'
      })
      methods.applyOtherFilter()
   }
   const handleShowModal = data => {
      if (data?.total_products > 0)
         showAlert({
            type: 'danger',
            content: 'Không thể xóa danh mục đã có sản phẩm'
         })
      else {
         onDelete({
            content: (
               <>
                  <Text as={'p'}>Lưu ý: Cần đảm bảo không còn sản phẩm nào thuộc danh mục này để thực hiện yêu
                     cầu</Text>
                  <Text as={'p'}> Bạn có chắc chắn muốn xóa danh mục đã chọn?</Text>
               </>
            ),
            title: ' Xác nhận xóa danh mục ' + data.title,
            onClose: () => onDelete(null),
            onSubmit: () => deleteCategory(data?.id),
         })
      }
   }
   return (
      <>
         <Tr
            {...props}
            className="principal-table__row"
         >
            <Td className="principal-table__cell" data-type="td">
               {+stt + 1}
            </Td>
            <Td className="principal-table__cell" data-type="td">
               <TooltipV2 title={data?.title} baseOn={'height'} className='principal-table__cell-tooltip'>
                  <Text>{data?.title || '---'}</Text>
               </TooltipV2>
            </Td>
            <Td className="principal-table__cell" data-type="td">
               <TooltipV2 title={data?.parent_title} baseOn={'height'} className='principal-table__cell-tooltip'>
                  <Text>{data?.parent_title || '---'}</Text>
               </TooltipV2>
            </Td>
            <Td className="principal-table__cell" data-type="td">
               {fNumber(data?.total_products)}
            </Td>
            <Td className="principal-table__cell" data-type="td">
               {data?.category_status === "Đang hoạt động"
                  ? <Text className={'principal-table__cell--success'}>{data?.category_status}</Text>
                  : <Text className={'principal-table__cell--danger'}>{data?.category_status}</Text>
               }
            </Td>
            <Td
               className="principal-table__cell"
               data-menu="true"
               data-type="td"
               onClick={e => e.stopPropagation()}
            >
               <Button
                  className="principal-table__edit"
                  onClick={() => handleModalChangeCategory(data?.id)}
               >
                  <Tooltip title={'Chỉnh sửa'}>
                     {GLOBAL_ICONS.edit}
                  </Tooltip>
               </Button>
               <button
                  className="principal-table__delete"
                  onClick={() => handleShowModal(data)}
               >
                  <Tooltip title={'Xóa'}>
                     {GLOBAL_ICONS.recycle}
                  </Tooltip>
               </button>
            </Td>
         </Tr>
      </>
   )
}

export const StyledTBody = styled.div`
  .principal-table {
    &__loading {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
  
      width: 100vw;
      height: 100vh;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      background: rgba(0, 0, 0, 0.25);
  
      img {
        width: 80px;
        height: 80px;
      }
    }
  
    &__cell-tooltip{
      display: -webkit-box;
      height: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      word-break: break-word;
    }
    &__cell {
      cursor: pointer;
      margin: 0 8px;
      display: flex;
      justify-content: left;
      align-items: center;
      &[data-menu='true'] {
        position: relative;
      }
  
      &[data-type='th'] {
        &[data-selected='true'] {
          display: flex;
          flex: 1;
          align-items: center;
          height: 44px;
        }
      }
  
      &--success {
        color: #0B74E5 !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #ECF4FE;
        border-radius: 4px;
      }
      &--danger {
        color: #FF424E !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #FFEBEC;
        border-radius: 4px;
      }
      &:nth-child(1) {
        width: 50px;
        justify-content: center;
      }
      &:nth-child(2) {
        flex: 1;
      }
      &:nth-child(3) {
        width: 22%;
      }
  
      &:nth-child(4) {
        width: 22%;
        text-align: center;
        justify-content: center;
      }
  
      &:nth-child(5) {
        width: 16%;
        justify-content: center;
        text-align: center;
      }

      &:nth-child(6) {
        width: 100px;
        padding-left: 20px;
      }
  
    }
  
    &__delete {
      width: 20px;
      height: 20px !important;
  
      background: transparent;
      border: none;
      border-radius: 12px !important;
  
      font-size: 12px !important;
      line-height: 24px !important;
  
      cursor: pointer;
  
    }
  
    &__edit {
      width: 20px;
      height: 20px !important;
      margin-right: 16px;
      background: transparent;
      border: none;
      border-radius: 12px !important;
  
      font-size: 12px !important;
      line-height: 24px !important;
  
      cursor: pointer;
  
      &[data-active='true'] {
        display: block !important;
      }
    }
  
    &__selected-action-dropdown {
      position: relative;
  
      margin-left: 12px;
    }
  
    &__selected-action-toggle {
      width: 88px;
      padding: 0 !important;
  
      border-radius: 14px !important;
  
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  
    &__selected-action-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 11;
  
      width: 100vw;
      height: 100vh;
    }
  
    &__selected-action-menu {
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      z-index: 12;
  
      width: 150px;
      padding: 8px;
  
      background: #ffffff;
      border-radius: 6px;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    }
  
    &__selected-action-menu-item {
      padding: 8px;
  
      color: #191d32;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
  
      transition: color 0.25s;
  
      cursor: pointer;
  
      &:hover {
        color: #3264FF;
      }
    }
  
    &__tooltipV2 {
      width: 100%;
      padding: 0;
      overflow: hidden;
      position: relative;
      display: inline-block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
}

.tab-detail-order {
  &__link-hover {
    color: #1A94FF;

    &:hover {
      color: #1373DB;
    }
  }
}
`
export const StyledProductGroupEmpty = styled.div`
  min-height: calc(100vh - 430px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;

  .principal-empty__banner {
    width: 133px;
    height: 133px;
    margin-bottom: 16px;

    object-fit: contain;
    object-position: center;
  }
`
