
import { getDateFromNow } from 'util/date'
import { fDateTimeDMY } from 'util/formatTime'

const dateTimeDefaultValue = [getDateFromNow(-7), getDateFromNow(0, { type: 'end' })]
export const formatDateTimeDefaultValue = `${fDateTimeDMY(
  dateTimeDefaultValue[0],
)} - ${fDateTimeDMY(dateTimeDefaultValue[1])}`

export const useDashboardInitialState = {
  loading: false,
  loadingRevenue: false,
  loadingCommission: false,
  loadingTeacher: false,
  loadingUser: false,
  dateTime:{
    value: {value: '30days', name: '30 ngày'},
    list: [
      {value: '7days', name: '7 ngày'},
      {value: '30days', name: '30 ngày'},
      {value: '3months', name: '3 tháng'},
      {value: '6months', name: '6 tháng'},
      {value: '1year', name: '1 năm'},
    ],
    time: ''
  },
  revenue: {
    hasReferral: [],
    nonReferral: [],
    title: []
  },
  commission: {
    teacher: [],
    principal: [],
    title: []
  },
  user: {
    teacher: [],
    principal: [],
    title: []
  },
  customer: {
    hasReferral: [],
    nonReferral: [],
    title: []
  },
  panels: null,

}