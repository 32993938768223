import { PATH } from '../const/path'
import LoginForm from '../Pages/LoginForm/login'
import ForgotPasswordForm from '../Pages/ForgotPassword'
import NotFound from 'Pages/404/index.'
import NoConnection from 'Pages/noConnection'
import NotPermission from '../Pages/permision/notPermission'
import {
  accountantGroup, bannerGroup,
  categoryGroup,
  customerGroup, keyProductGroup,
  orderGroup,
  profileGroup,
  productGroup,
  statisticalGroup,
  userRoleGroup, eventGroup
} from './groupRouter'
import Dashboard from '../Pages/dashboard'

const publicRoutes = [
  { path: PATH.HOME, component: LoginForm },
  { path: PATH.LOGIN, component: LoginForm },
  { path: PATH.FORGOT_PASSWORD, component: ForgotPasswordForm },
  { path: PATH.NOT_FOUND, component: NotFound },
  { path: PATH.NO_CONNECTION, component: NoConnection },
  { path: PATH.NOT_PERMISSION, component: NotPermission },
]
const privateRoutes = [
  { path: PATH.ADMIN_DASHBOAR, component: Dashboard, prefix: 'shop', action: 'view' },
  ...profileGroup,
  ...userRoleGroup,
  ...categoryGroup,
  ...productGroup,
  ...orderGroup,
  ...customerGroup,
  ...statisticalGroup,
  ...accountantGroup,
  ...bannerGroup,
  ...keyProductGroup,
  ...eventGroup
]

export { publicRoutes, privateRoutes }
