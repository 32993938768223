import React from "react";
import {Input} from "../../../../../../Component/Input";
import {Text} from "../../../../../../common/text";
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";
import {useCreatePrincipalForm} from "../../hook/useCreatePrincipalForm";

export const SchoolName = ()=>{
    const {schoolName} = useCreatePrincipalForm()
    const {methods} = schoolName
    return (
        <Input
            label={
                <>
                    Tên Trường
                </>
            }
            value={schoolName?.value}
            onChange={e => methods.onChange(e.target?.value)}
            onBlur={e => methods.onBlur(e.target?.value)}
            validateText={schoolName?.message}
            validateType={schoolName?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập tên trường'}
            // maxLength={255}
        />
    )
}