import { Text } from 'common/text'
import { THEME_COLORS } from 'common/theme/_colors'
import { THEME_SEMANTICS } from 'common/theme/_semantics'
import { useState } from 'react'
import styled from 'styled-components'
import { Tooltip as TooltipV2 } from 'common/tooltipv2'
import { Loading } from '../../../../common/loading'
import { formatDatetimeV2 } from '../../../../common/form/datePicker/_functions'
import { fDateTimeCustom } from '../../../../util/formatTime'
import { formatMoney } from '../../../../util/functionUtil'

export const RowTabDetail = ({ data, rowData, ...props }) => {
  const { detail } = rowData
  return (
    <StyledRowTabDetail>
      {/*{showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)}/>}*/}
      <div className="row-tab-detail__content">
        <div className="row-tab-detail__content-group">
          <Text as="h4" fontSize={16} lineHeight={22}>
            Thông tin chung
          </Text>
          <div className={'row-tab-detail__content-group--items'}>
            <Text as="p" color="#7C88A6">
              Ngày tạo
            </Text>
            <Text>
              {!!detail?.active?.created_at ? fDateTimeCustom(detail?.active?.created_at, { format: 'HH:mm dd/MM/yyyy' }) : '---'}
            </Text>
          </div>

          <div className={'row-tab-detail__content-group--items'}>
            <Text as="p" color="#7C88A6">
              Người tạo
            </Text>
            <Text>
              {detail?.active?.user_name || '---'}
            </Text>
          </div>
          <div className={'row-tab-detail__content-group--items'}>
            <Text as="p" color="#7C88A6">
              Tên hiệu trưởng
            </Text>
            <Text
              color={THEME_SEMANTICS.delivering}
              style={{cursor: 'pointer'}}
              as={"a"}
              target={"_blank"}
              href={"/principal/"+detail?.active?.recipient_id}
            >
              {detail?.active?.recipient_name || '---'}
            </Text>
          </div>
        </div>
        <div className="row-tab-detail__content-group">
          <Text as="h4" fontSize={16} lineHeight={22}>
            Thông tin thanh toán
          </Text>
          <div className={'row-tab-detail__content-group--items'}>
            <Text as="p" color="#7C88A6">
              Giá trị chi
            </Text>
            <Text>
              {formatMoney(detail?.active?.payment_value)}
            </Text>
          </div>
          <div className={'row-tab-detail__content-group--items'}>
            <Text as="p" color="#7C88A6">
              Phương thức thanh toán
            </Text>
            <Text>
              {detail?.active?.payment_name}
            </Text>
          </div>
        </div>
        <div className="row-tab-detail__content-group">
          <Text as="h4" fontSize={16} lineHeight={22}>
            &nbsp;
          </Text>
          <div className={'row-tab-detail__content-group--items'}>
            <Text as="p" color="#7C88A6">
              Số tài khoản
            </Text>
            <Text>
              {detail?.active?.payment_information?.bank_number || '---'}
            </Text>
          </div>
          <div className={'row-tab-detail__content-group--items'}>
            <Text as="p" color="#7C88A6">
              Ngân hàng
            </Text>
            <Text>
              {detail?.active?.payment_information?.bank_name || '---'}
            </Text>
          </div>
          <div className={'row-tab-detail__content-group--items'}>
            <Text as="p" color="#7C88A6">
              Chủ tài khoản
            </Text>
            <TooltipV2
              title={detail?.active?.address}
              className="invoice-table__tooltipV2"
            >
              <Text>
                {detail?.active?.payment_information?.bank_owner || '---'}
              </Text>
            </TooltipV2>
          </div>
        </div>
        <div className="row-tab-detail__content-group">
          <Text as="h4" fontSize={16} lineHeight={22}>
            Thông tin khác
          </Text>
          <div className={'row-tab-detail__content-group--items'}>
            <Text as="p" color="#7C88A6">
              Mã chứng từ tham chiếu
            </Text>
            <Text>{detail?.active?.payment_reference}</Text>
          </div>
          <div className={'row-tab-detail__content-group--items'}>
            <Text as="p" color="#7C88A6">
              Ngày ghi sổ
            </Text>
            <Text> {!!detail?.active?.payment_date ? fDateTimeCustom(detail?.active?.payment_date, { format: 'HH:mm dd/MM/yyyy' }) : '---'}</Text>
          </div>
          <div className={'row-tab-detail__content-group--items'}>
            <Text as="p" color="#7C88A6">
              Lý do chi
            </Text>
            <Text>{detail?.active?.payment_reason || '---'}</Text>
          </div>
        </div>
      </div>
    </StyledRowTabDetail>
  )
}

const StyledRowTabDetail = styled.div`
  .row-tab-detail {
    &__content {
      position: relative;

      margin-bottom: 12px;

      display: flex;
      flex-wrap: wrap;
      &-note {
        display: flex;
        justify-content: right;
        margin-top: -12px;
      }
    }

    &__content-group {
      width: calc(100% / 4 - 12px);
      margin-bottom: 12px;
      margin-right: 12px;
      h4 {
        margin-bottom: 12px;
      }
      &--items {
        margin-bottom: 12px;
      }
    }

    &__info-table {
      margin-bottom: 12px;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__inventory {
      min-height: 40px;
      margin-bottom: 12px;
      padding: 10px 0;

      display: flex;
      align-items: center;

      border-bottom: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &__table {
      margin-bottom: 12px;

      overflow: hidden;

      border: 1px solid #e2eaf8;
      border-radius: 8px;
    }

    &__thead {
      .row-tab-detail__tr {
        background: #f7f9fd;
      }
    }

    &__tr {
      display: flex;
    }

    &__th {
      min-height: 44px;
      padding: 12px;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;

      &:nth-child(1) {
        width: 13%;
      }
      &:nth-child(2) {
        flex: 1;
      }
      &:nth-child(3) {
        width: 16%;
      }
      &:nth-child(4) {
        width: 7%;
      }
      &:nth-child(5) {
        width: 13%;

        text-align: right;
      }
      &:nth-child(6) {
        width: 13%;

        text-align: right;
      }
      &:nth-child(7) {
        width: 5%;

        text-align: right;
      }
    }

    &__td {
      min-height: 56px;
      padding: 18px 12px;

      border-top: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &:nth-child(1) {
        width: 13%;
      }
      &:nth-child(2) {
        flex: 1;

        color: ${THEME_SEMANTICS.delivering};
      }
      &:nth-child(3) {
        width: 16%;
      }
      &:nth-child(4) {
        width: 7%;
      }
      &:nth-child(5) {
        width: 13%;

        text-align: right;
      }
      &:nth-child(6) {
        width: 13%;

        text-align: right;
      }
      &:nth-child(7) {
        width: 5%;
        cursor: pointer;
        text-align: right;
      }
    }

    &__result-item {
      margin-bottom: 8px;

      display: flex;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      line-height: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__result-label {
      padding: 0 12px;

      flex: 1;

      text-align: right;
    }

    &__result-value {
      width: 13%;
      padding: 0 12px;

      text-align: right;
    }
  }
`
