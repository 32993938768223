import {useContext} from "react";
import {CreatePrincipalContext} from "../provider/_context";
import {useCreatePrincipalAction} from "../provider/_reducer";

export const useCreatePrincipalTab = ()=>{
    const {pageState, pageDispatch} = useContext(CreatePrincipalContext)
    const {tab} = pageState

    const handleChangeTab = (data) =>
        pageDispatch({type: useCreatePrincipalAction.FORM_CREATE_TAB_CHANGE,payload: data})

    return{
        tab:{
            list: tab?.list,
            active: tab?.activeTab,
            onChange: handleChangeTab
        }
    }

}