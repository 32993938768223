import {TableLayout} from 'layouts/tableLayout'
import {useContext, useEffect, useRef, useState} from 'react'
import {OrderFilterForm} from './components/orderFilterForm'
import {OrderTBody} from './components/orderTable/_orderTBody'
import {OrderTHead} from './components/orderTable/_orderTHead'
import {
   ORDER_BREADCRUMB,
   ORDER_PAGE_HEADER_ACTIONS,
} from './interfaces/_constants'
import {OrderProvider} from './provider'
import './components/orderTable/index.scss'
import useOrder from './hooks/useOrder'
import {OrderNotifications} from './components/orderNotifications'
import useOrderFilterForm from './hooks/useOrderFilterForm'
import {PageHeader} from './components/orderPageHeader'
import PopupNotPermission from '../permision/popupNotPermission'
import useAlert from "../../hook/useAlert";
import {ModalExport} from "../../common/modalExport";
import {GLOBAL_ICONS} from "../../interface/icon";

export const OrderDigital = () => {
   const {fetch, pagination, provider} = useOrder()

   const {state, dispatch} = provider
   const {table} = state

   useEffect(() => {
      fetch.origin()
   }, [])
   return (
      <OrderProvider value={{pageState: state, pageDispatch: dispatch}}>
         <Header/>
         <OrderNotifications/>
         <TableLayout
            header={
               <>
                  <OrderFilterForm/>
               </>
            }
            table={{
               tHead: <OrderTHead/>,
               tBody: <OrderTBody/>,
            }}
            pagination={{
               ...table.pagination,
               onAmountChange: pagination.onAmountChange,
               onPageChange: pagination.onPageChange,
            }}
         />
      </OrderProvider>
   )
}

const Header = () => {
   const [showPermission, setShowPermission] = useState(false)
   const [exportModalData, setExportModalData] = useState(null)
   const {queries, pageState} = useOrderFilterForm()
   const {showAlert} = useAlert()

   const handleExportClick = () => {
      let queryString = '?'
      let i = 0
      for (const [key, value] of Object.entries({
         ...queries,
         per_page: '',
         start: '',
      })) {
         queryString += `${i > 0 ? '&' : ''}${key}=${value}`
         i++
      }

      handleLargeExport(
         {
            ...queries,
            per_page: '',
            start: '',
         }
      )
   }

   const handleLargeExport = (q) => {
      if (pageState.table.pagination.totalItems <= 0) {
         showAlert({type: 'info', content: `Số lượng đơn hàng phải lớn hơn 0`})
         return
      }

      setExportModalData({
         data: {
            query: q,
            total: pageState.table.pagination.totalItems,
         },
         onClose: () => setExportModalData(null),
      })
   }
   return (
      <>
         {showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)}/>}
         <PageHeader
            breadcrumbLinks={ORDER_BREADCRUMB}
            breadcrumbTitle="Quản lý đơn hàng số"
            actions={[
               {
                  name: 'Xuất excel',
                  icon: GLOBAL_ICONS.export,
                  onClick: handleExportClick,
                  appearance: 'secondary',
               }
            ]}
         />

         {!!exportModalData && <ModalExport data={exportModalData} title={'đơn hàng'} api={`/order/export`}/>}
      </>
   )
}
