import { Grid } from '@mui/material'
import { Input } from '../../../../../common/form/input'
import { Text } from '../../../../../common/text'
import React, { useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useCreateInfoActive from '../../../hooks/useCreateInfoActive'
import useAlert from '../../../../../hook/useAlert'
import { productActions } from '../../../provider/~action'
import { ProductContext } from '../../../provider/~context'
import { DISPLAY_NAME_MENU } from '../../../../../const/display_name_menu'
import { StyledFacebookResponseContentScriptDetailDrawer } from '../infoProduct/_styled'
import { GLOBAL_ICONS } from '../../../../../interface/icon'
import { THEME_COLORS } from '../../../../../common/theme/_colors'
import { postData } from '../../../../../api/api'
import config from '../../../../../config'

export const InfoActive = () => {
  const { t } = useTranslation()
  const { pageState, pageDispatch } = useContext(ProductContext)
  const { value, functions, validate } = useCreateInfoActive()
  const images = pageState?.formCreate?.active?.image?.list
  const imageFiles = pageState?.formCreate?.active?.image?.imageFiles
  const { showAlert } = useAlert()

  const [file, setFile] = useState('')
  const formatFile = ['doc', 'docx', 'xls', 'xlsx', 'jpg', 'jpeg', 'png', 'pdf']

  const [validates, setValidates] = useState({
    images: { overload: false },
  })

  const handleChange = e => {
    if (e.target.files[0].size < 2000000 && !!formatFile?.find(item => item === e.target.files[0].name?.split('.')[1])) {
      const dataPost = new FormData()
      if (e.target.files[0]) dataPost.append('file', e.target.files[0])
      postData(`${config.API}/product/upload-file`, dataPost)
        .then(res => {
          if (res.data && res.data.success) {
            pageDispatch({ type: productActions.FORM_CREATE_CHANGE_INFO_ACTIVE, payload: { file: res.data } })
          }
        })
    } else {
      if (e.target.files[0].size >= 2000000) {
        showAlert({ type: 'danger', content: t(DISPLAY_NAME_MENU.FEEDBACK.FILE_MAX_2MB) })
      } else {
        showAlert({ type: 'danger', content: t(DISPLAY_NAME_MENU.FEEDBACK.INVALID_FILE) })
      }
      setFile([])
      e.target.value = ''
    }
  }

  const handleImageFileChange = async (e) => {
    const files = e.target.files
    let imageArr = []
    let count = 0
    if (
      !['image/jpg', 'image/jpeg', 'image/png'].includes(
        files[0].type.toLowerCase(),
      )
    ) {
      showAlert({
        type: 'danger',
        content: 'Chỉ hỗ trợ các định dạng: .jpg, .jpeg, .png.',
      })
      return
    }
    if (files[0]?.size <= 3000000) {
      const formData = new FormData()
      if (e.target.files[0]) formData.append('image', e.target.files[0])
      postData(`${config.API}/product/upload`, formData)
        .then(res => {
          if (res.data && res.data.success) {
            console.log(res.data)
            pageDispatch(
              {
                type: productActions.FORM_CREATE_UPDATE_QRCODE_PRODUCT,
                payload: {
                  list: [{
                    id: Math.random(),
                    name: files[0]?.name,
                    url: window.URL.createObjectURL(files[0]),
                  }],
                  imageFiles: [files[0]],
                  link: res?.data?.file_url
                }
              }
            )
          }else{
            showAlert({
              type: 'danger',
              content: res.data?.errors[0]?.message,
            })
          }
        })
    } else
      showAlert({
        type: 'danger',
        content: 'Ảnh tải lên không được vượt quá 3MB',
      })
  }

  const handleImageDelete = id => {

    const index = images.findIndex(item => +item?.id === +id)
    imageFiles.splice(index, 1)
    pageDispatch(
      {
        type: productActions.FORM_CREATE_UPDATE_QRCODE_PRODUCT,
        payload: {
          list: images.filter(item => item?.id !== id),
          imageFiles: imageFiles,
        }
      }
    )
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={12} lg={12} item>
          <div className="product-info-price__form-input">
            <Input
              label={'Link download'}
              value={value?.formInfoActive?.link_download}
              onChange={e => functions.onChangeLinkDownload(e.target?.value)}
              validateText={validate?.formInfoPriceValidate?.retail?.message}
              validateType={validate?.formInfoPriceValidate?.retail?.status ? 'danger' : 'success'}
              placeholder={t('Vui lòng nhập link download')}
              maxLength={225}
            />
          </div>
        </Grid>
        <Grid xs={3} sm={6} md={6} lg={6} item>
          <div className="product-info-price__form-input">
            <p className={'product-info-product__title'}>QR code</p>
            <Text className={'product-info-product__subtitle'} fontSize={12} color={'#808089'}>
              Trường hợp không tải ảnh, hệ thống sẽ tự động tạo QR code theo Link download</Text>
            <div className={'product-info-product__img'}>
              <StyledFacebookResponseContentScriptDetailDrawer>
                <Galleries
                  data={images}
                  validate={validates?.images}
                  onChange={handleImageFileChange}
                  onCreateClick={() =>
                    setValidates({
                      ...validates,
                      images: { ...validates.images, overload: false },
                    })
                  }
                  onImageDelete={imageId => handleImageDelete(imageId)}
                />
              </StyledFacebookResponseContentScriptDetailDrawer>
            </div>
          </div>
        </Grid>
        <Grid xs={6} sm={6} md={6} lg={6} item>
          <div className="product-info-price__form-input">
            <p className={'product-info-product__title'}>Hướng dẫn kích hoạt</p>
            <div className={'product-info-product__img'}>
              <StyledFacebookResponseContentScriptDetailDrawer>
                <GalleriesFile
                  data={value?.formInfoActive?.file}
                  validate={validates?.images}
                  onChange={handleChange}
                  onCreateClick={() =>
                    setValidates({
                      ...validates,
                      images: { ...validates.images, overload: false },
                    })
                  }
                  onImageDelete={_ => pageDispatch({
                    type: productActions.FORM_CREATE_CHANGE_INFO_ACTIVE,
                    payload: { file: null }
                  })}
                />
              </StyledFacebookResponseContentScriptDetailDrawer>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

const Galleries = ({
  data,
  validate,
  onChange,
  onCreateClick,
  onImageClick,
  onImageDelete,
  ...props
}) => {
  const inputFileRef = useRef(null)
  return (
    <div
      {...props}
      className={`facebook-response-content-script-detail-drawer__galleries ${
        props?.className || ''
      }`}
    >

      <div className="facebook-response-content-script-detail-drawer__galleries-list">
        {data?.length > 0 && data.map((item, i) => (
          <div
            key={item?.id}
            className="facebook-response-content-script-detail-drawer__galleries-item"
            onClick={() => onImageClick && onImageClick(i)}
          >
            <img
              className="facebook-response-content-script-detail-drawer__galleries-background"
              src={item?.url}
              alt={item?.name}
            />
            <div
              className="facebook-response-content-script-detail-drawer__galleries-delete"
              onClick={e => {
                e.stopPropagation()
                if (onImageDelete) onImageDelete(item?.id)
              }}
            >
              {GLOBAL_ICONS.x2}
            </div>
          </div>
        ))}
        {data?.length < 1 && (
          <>
            <div
              className="facebook-response-content-script-detail-drawer__galleries-create"
              onDrop={e => {
                e.preventDefault()
                onChange(e)
              }}
              onClick={() => {
                if (onCreateClick) onCreateClick()
                if (inputFileRef?.current) {
                  inputFileRef.current.value = ''
                  inputFileRef.current.click()
                }
              }}
            >
              {
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.5 3.74997V14.25"
                    stroke="#C8CBD4"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.25 8.99997H14.75"
                    stroke="#C8CBD4"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              <Text color={THEME_COLORS.primary_300} style={{ display: 'block' }}>
                Tải ảnh
              </Text>
            </div>
            <input
              ref={inputFileRef}
              accept="image/png, image/jpeg, image/jpg"
              type="file"
              multiple={false}
              style={{ display: 'none' }}
              onChange={onChange}
            />
          </>
        )}
      </div>
    </div>
  )
}

const GalleriesFile = ({
  data,
  validate,
  onChange,
  onCreateClick,
  onImageClick,
  onImageDelete,
  ...props
}) => {

  const inputFileRef = useRef(null)
  return (
    <div
      {...props}
      className={`facebook-response-content-script-detail-drawer__galleries ${
        props?.className || ''
      }`}
    >

      <div className="file__galleries-list" style={{ marginTop: '12px' }}>
        {!!data?.file_url ?
          <div
            className="file__galleries-item"
            style={{ display: 'flex', alignItems: 'center', }}
          >
            <div className={'file-content'}>
              <Text as={'a'} href={data.file_url} target={'_blank'}>{data?.file_name}</Text>
            </div>
            <div
              className="file__galleries-delete"
              onClick={e => {
                e.stopPropagation()
                if (onImageDelete) onImageDelete()
              }}
              style={{ marginRight: '8px' }}
            >
              {GLOBAL_ICONS.x2}
            </div>
          </div> :
          (
            <>
              <div
                className="facebook-response-content-script-detail-drawer__galleries-create"
                onDrop={e => {
                  e.preventDefault()
                  onChange(e)
                }}
                onClick={() => {
                  if (onCreateClick) onCreateClick()
                  if (inputFileRef?.current) {
                    inputFileRef.current.value = ''
                    inputFileRef.current.click()
                  }
                }}
              >
                {
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 3.74997V14.25"
                      stroke="#C8CBD4"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.25 8.99997H14.75"
                      stroke="#C8CBD4"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                <Text color={THEME_COLORS.primary_300} style={{ display: 'block' }}>
                  Tải file
                </Text>
              </div>
              <input
                ref={inputFileRef}
                accept={'.jpg, .jpeg, .png, .gif, .pdf'}
                type="file"
                multiple={false}
                style={{ display: 'none' }}
                onChange={onChange}
              />
            </>
          )}
      </div>
    </div>
  )
}