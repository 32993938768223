import React, {useContext} from 'react'
import {StyledCommissionPanels} from './_styled'
import { CommissionContext } from '../../provider/_context'
import { CommissionPanel } from './_commissionPanel'

export const CommissionPanels = ({...props}) => {
  const {pageState} = useContext(CommissionContext)
  const {panels} = pageState

  return (
    <StyledCommissionPanels {...props}>
      <CommissionPanel
        className="order-panels__item"
        currency="đơn"
        title="Tổng số lượng đơn"
        value={panels?.total_orders || 0 }
      />
      <CommissionPanel
        className="order-panels__item"
        currency="₫"
        title="Tổng doanh Thu"
        value={panels?.total_revenue || 0}
      />
      <CommissionPanel
        className="order-panels__item"
        currency="₫"
        title="Tổng hoa hồng cho hiệu trưởng"
        value={panels?.commission_principal || 0}
      />
      <CommissionPanel
        className="order-panels__item"
        currency="₫"
        title="Tổng hoa hồng cho giáo viên"
        value={panels?.commission_teacher || 0}
      />
    </StyledCommissionPanels>
  )
}
