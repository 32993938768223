import React from "react";
import {Modal} from "@mui/material";
import styled from 'styled-components'
import {Button} from "common/button";
import {Text} from "common/text";
import { PRINCIPAL_GROUP_ICON } from '../../../../principal/interface/icon'

export const ResultImport = ({open, data,title, onClose}) => {
    return (
        <Modal
            open={open}
        >
            <StyleResultImport>
                <div className={'result-import'}>
                    <Text fontSize={20} fontWeight={600}>Import {title}</Text>
                    <div className={'result-import_tabs'}>
                        <div className={'result-import_tab'}>
                            <div style={{marginRight: 16}}>
                                {PRINCIPAL_GROUP_ICON?.success}
                            </div>
                            <div>
                                <Text as={'p'} fontSize={18} fontWeight={600}>{data?.item_success} {title}</Text>
                                <Text color={'rgb(125, 154, 192)'}>Tạo thành công</Text>
                            </div>
                        </div>
                        <div className={'result-import_tab'}>
                            <div style={{marginRight: 16}}>
                                {PRINCIPAL_GROUP_ICON?.failed}
                            </div>
                            <div>
                                <Text as={'p'} fontSize={18} fontWeight={600}>{data?.item_failed} {title}</Text>
                                <Text color={'rgb(125, 154, 192)'}>Tạo không thành công</Text>
                            </div>
                        </div>
                    </div>
                    {
                        +data?.errors?.length > 0 && <div className={'result-import_errors'}>
                            <Text fontWeight={600} fontSize={15}>Danh sách các lỗi ở file tải lên</Text>
                            <div data-length={data.errors?.length >= 5} className={'result-import_errors-list common-scrollbar'}>
                                {data.errors?.map(item => (
                                    <div
                                        key={item.id}
                                        className="result-import_errors-row"
                                    >
                                        {PRINCIPAL_GROUP_ICON.danger}
                                        <Text as={'pre'}>
                                            {` `}Dòng {item.row}:{' '}
                                        </Text>
                                        <Text style={{display: 'block', flex: 1}}>
                                            {item.message}
                                        </Text>
                                    </div>
                                ))}
                            </div>

                        </div>
                    }
                    <div style={{textAlign: 'right'}}>
                        <Button
                            appearance={'ghost'}
                            size={'sm'}
                            onClick={onClose}
                        >Đóng</Button>
                    </div>
                </div>
            </StyleResultImport>
        </Modal>
    )
}
const StyleResultImport = styled.div`
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
    .result-import{
        padding: 24px;
        width: 640px;
        background: rgb(255, 255, 255);
        border-radius: 8px;
        
        &_tabs{
            display: flex ;
            align-items: center;
            margin: 24px 0 32px 0;
            gap: 16px;
        }
         &_tab{
            width: calc(50% - 16px);
            padding: 16px 12px;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            background: rgb(246, 250, 254);
            border: 1px solid rgb(240, 245, 255);
            border-radius: 8px;
        }
        &_errors{
            margin-top: 24px;
        }
        &_errors-list{
            max-height: 50vh;
            margin: 16px 0 24px 0;
            padding: 16px 10px;
            overflow: auto;
            background: rgb(243, 246, 252);
            border-radius: 6px;
            &[data-length='true']{
                height: 200px;
                overflow: auto;
            }
        }
        &_errors-row{
            display: flex;
            align-items:center;
            margin-bottom: 16px;
            &:last-child{
               margin-bottom: 0; 
            }
        }
    }
`