import React from 'react';
import {AlternativeAutoComplete} from "common/form/autoComplete/_alternativeAutoComplete";
import { Option } from 'common/form/autoComplete/_option';
import {useAddProductModal} from "../../../../../hooks/useAddProductModal";

export const ProductCategory = () => {
  const {categoryFilter} = useAddProductModal()
  return (
    <AlternativeAutoComplete
      className="store-upos-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: {name: 'Danh mục', value: ''}, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 90,
        placeholder: 'Chọn danh mục',
        readOnly: true,
        value: categoryFilter?.value?.title || '',
        onIconClick: () => categoryFilter.onChange(null),
      }}
      // menu
      menuProps={{
        empty: categoryFilter?.list?.length <= 0 
                ? 'Không tìm thấy danh mục'
                : '',
      }}
      // search input in dropdown menu
      searchInputProps={{
        placeholder: 'Tìm kiếm danh mục',
        // value: categoryFilter.keyword,
        onChange: categoryFilter?.onKeywordChange,
      }}
    >
      {categoryFilter?.list?.length > 0 &&
        categoryFilter?.list?.map(item => (
        <Option
          key={item.id}
          className="store-upos-filter-form__option-text"
          data-active={item.id === categoryFilter.value?.id}
          onClick={() => categoryFilter.onChange(item)}
        >
          {item.title}
        </Option>
      ))}
    </AlternativeAutoComplete>
  )
}
