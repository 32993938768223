import React, {useEffect} from 'react';
import {TableLayout} from "../../../layouts/tableLayout";
import useDigitalManagement from "../digital/hooks/useDigitalManagement"
import {DigitalManagementProvider} from "../digital/provider/index"
import Header from "./components/header";
import FilterForm from "./components/body/filter";
import Thead from "./components/body/table/thead";
import Tbody from "./components/body/table/tbody";

const DigitalManagement = () => {
  const {provider, pagination, fetch} = useDigitalManagement()
  const {state, dispatch} = provider
  const {table} = state
  // const location = useLocation()

  useEffect(() => {
    fetch.origin()
  }, [])

  return (
    <DigitalManagementProvider value={{state: state, dispatch: dispatch}}>
      <Header />
      <TableLayout
        header={
          <>
            <FilterForm />
            {/*<Panels />*/}
          </>
        }
        table={{
          tHead: <Thead />,
          tBody: <Tbody />,
        }}
        pagination={{
          ...table.pagination,
          onAmountChange: pagination.onAmountChange,
          onPageChange: pagination.onPageChange,
        }}
      />
   </DigitalManagementProvider>
  )
}

export default DigitalManagement