import {
  EXPORT_EXCEL_SELECTIVE_ACTIVE,
  EXPORT_REVENUE_EXCEL_SELECTIVE,
  REVENUE_FILTER_FORM_DATE_TIME_SORT_TYPES, REVENUE_FILTER_FORM_ORDER_STATUS,
  REVENUE_FILTER_FORM_PAYMENT_METHOD, REVENUE_FILTER_FORM_PAYMENT_STATUS,
  REVENUE_TABLE_THEAD_PAYMENT_FILTER_LIST
} from '../interfaces/_constants'

import { getDateFromNow } from '../utils/date'
import { fDateTimeDMY } from 'util/formatTime'

const dateTimeDefaultValue = [getDateFromNow(-7), getDateFromNow(0, { type: 'end' })]
export const formatDateTimeDefaultValue = `${fDateTimeDMY(
  dateTimeDefaultValue[0],
)} - ${fDateTimeDMY(dateTimeDefaultValue[1])}`

export const revenueInitialState = {
  filter: {
    orderId: {
      value: null,
      activeValue: null
    },
    customerInfo: {
      value: null,
      activeValue: null
    },
    dateTime: {
      activeValue: {
        end: dateTimeDefaultValue[1],
        start: dateTimeDefaultValue[0],
        type: REVENUE_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
        value: formatDateTimeDefaultValue,
      },
      end: dateTimeDefaultValue[1],
      start: dateTimeDefaultValue[0],
      type: REVENUE_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
      trigger: true,
      value: formatDateTimeDefaultValue,
    },
    payment: {
      value: REVENUE_TABLE_THEAD_PAYMENT_FILTER_LIST.map(item => item?.value),
    },
    product: {
      activeValue: [],
      keyword: '',
      list: [],
      listOrigin: [],
      loading: false,
      tab: 'all', // all | checked
      value: [],
    },
    search: {
      value: '',
    },
    paymentMethod: {
      activeValue: null,
      keyword: '',
      list: REVENUE_FILTER_FORM_PAYMENT_METHOD,
      listOrigin: REVENUE_FILTER_FORM_PAYMENT_METHOD,
      value: null,
    },
    principal: {
      activeValue: null,
      keyword: '',
      list: [],
      listOrigin: [],
      value: null,
    },
    teacher: {
      activeValue: null,
      keyword: '',
      list: [],
      listOrigin: [],
      value: null,
    },
    orderType: {
      activeValue: null,
      keyword: '',
      list: REVENUE_FILTER_FORM_PAYMENT_STATUS,
      listOrigin: REVENUE_FILTER_FORM_PAYMENT_STATUS,
      value: null,
    },
    orderStatus: {
      activeValue: [
        { id: 1, name: 'Mới', value: 'new' },
        { id: 4, name: 'Hoàn thành', value: 'completed' },
      ],
      keyword: '',
      list: REVENUE_FILTER_FORM_ORDER_STATUS,
      listOrigin: REVENUE_FILTER_FORM_ORDER_STATUS,
      value: [
        { id: 1, name: 'Mới', value: 'new' },
        { id: 4, name: 'Hoàn thành', value: 'completed' },
      ],
      loading: false,
      tab: 'all', // all | checked
    },
  },
  pageOption: {
    id: 1, name: 'Ngày đặt hàng', value: 'date'
  },
  notifications: {
    list: [],
  },
  table: {
    display: {
      list: [],
      loading: true,
    },
    detail: {
      id: null,
      active: null,
      list: [],
    },
    pagination: {
      active: 0,
      amount: 20,
      total: 0,
      totalItems: 0,
    },
    selected: {
      list: [],
    },
  },
  panels: null,
  printDetail: {},
  exportExcel: {
    list: EXPORT_REVENUE_EXCEL_SELECTIVE,
    activeList: EXPORT_REVENUE_EXCEL_SELECTIVE,
    selectedList: EXPORT_EXCEL_SELECTIVE_ACTIVE,
    keyword: '',
    hiddenList: EXPORT_REVENUE_EXCEL_SELECTIVE
  },
}