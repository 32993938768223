import styled from 'styled-components'
import {Text} from 'common/text'
import ReactApexChart from 'react-apexcharts'
import React, {useContext, useEffect} from 'react'
import {formatMoney} from 'util/functionUtil'
import {LoadingChart} from './loadingChart'
import {EventStatisticContext} from '../../provider/_context'
import {StatisticCustomerPie} from "./_chartPieStatistic";

export const StatisticCustomer = () => {
   const {pageState} = useContext(EventStatisticContext)
   const {loadingChart} = pageState
   const {chart} = pageState
   const {title} = chart
   const {hasReferral} = chart
   const {nonReferral} = chart
   const chartRevenueOption = {

      series: [{
         name: 'Khách tự do',
         data: nonReferral
      }, {
         name: 'Khách có người giới thiệu',
         data: hasReferral
      }],
      options: {
         colors: ['#3955F6', '#FF424E'],
         chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {show: false},
            zoom: {enabled: false},
         },
         grid: {
            borderColor: '#EBEEF5',
            strokeDashArray: 3,
         },
         legend: {
            show: false,
         },
         toolbar: {
            show: false,
         },
         dataLabels: {
            enabled: false
         },
         xaxis: {
            categories: title,
         },
         plotOptions: {
            bar: {
               columnWidth: '30%',
            }
         },
         stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
         },

         yaxis: {
            labels: {
               show: true,
               align: 'right',
               minWidth: 0,
               maxWidth: 200,
               style: {
                  fontSize: '12px',
                  fontWeight: 500,
                  cssClass: 'apexcharts-yaxis-label',
                  lineHeight: '140%',
                  color: '#808089',
               },
               offsetX: 0,
               offsetY: 0,
               rotate: 0,
               formatter: (value) => {
                  if (+value === 0 || +value < 100) return value
                  else if (+value < 1000) return (Math.round(value * 10 / 100) / 10) + 'k'
                  else if (+value < 1000000) return (Math.round(value * 10 / 1000) / 10) + 'k'
                  else if (+value < 1000000000) return (Math.round(value * 10 / 1000000) / 10) + 'tr'
                  else if (+value < 100000000000) return (Math.round(value * 10 / 1000000000) / 10) + 'tỷ'
                  else return 1
               },
            },
         },
         tooltip: {
            custom: function ({series, dataPointIndex, w}) {
               let html = '<div class="arrow_box">'
               if (series[1] && series[1].length > 0)
                  html += '<div class="item-tooltip"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                     '<circle cx="6" cy="6" r="4.5" stroke="#FF424E" stroke-width="3"/>\n' +
                     '</svg>\n <p >Khách có người giới thiệu: &nbsp; </p>' +
                     '<span>' + series[1][dataPointIndex] + '</span> </div>'
               if (series[0] && series[0].length > 0)
                  html += '<div class="item-tooltip"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                     '<circle cx="6" cy="6" r="4.5" stroke="#3955F6" stroke-width="3"/>\n' +
                     '</svg>\n<p >Khách tự do: &nbsp; </p>' +
                     '<span>' + series[0][dataPointIndex] + '</span> </div>'
               return html
            }
         },
      },

   }

   return (
      <StyledContent>
         <div className={'content-report'}>
            <div className={'content-report__legend'}>
               <div className={'content-report__legend-item'}>
                  <Text fontSize={22} fontWeight={600}>Thống kê khách tham gia campaign</Text>
               </div>
            </div>
            {
               !loadingChart ?
                  <div className={'content-report__wrapper'}>
                     <ReactApexChart
                        options={chartRevenueOption.options}
                        series={chartRevenueOption.series}
                        type="bar"
                        height={420}/>
                     <div className={'content-report__legend'} style={{paddingBottom: 16}}>
                        <div className={'content-report__legend-item'}>
                           <Text as={'p'} className={'content-report__legend-icon'}></Text>
                           <Text>Khách tự do</Text>
                        </div>
                        <div className={'content-report__legend-item'}>
                           <Text as={'p'} className={'content-report__legend-nicon'}></Text>
                           <Text>Khách có người giới thiệu</Text>
                        </div>
                        <div className={'content-report__legend-item'}></div>
                     </div>
                  </div>
                  : <LoadingChart/>
            }
         </div>
         <div className={'content-report'}>
            <div className={'content-report__legend'}>
               <div className={'content-report__legend-item'}>
                  <Text fontSize={22} fontWeight={600}>Tỉ lệ % khách tham gia</Text>
               </div>
            </div>
            {
               !loadingChart ?
                  <div className={'content-report__wrapper'}>
                     <StatisticCustomerPie/>
                  </div>
                  : <LoadingChart/>
            }
         </div>
      </StyledContent>
   )
}

const StyledContent = styled.div`
  width: 100%;
  background: #FFFFFF;
  .mixed-chart_series{
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    &-legend-box{
      display: flex;
      align-items: center;
      div{
        margin-right: 44px;
      }
    }
  }
  .content-report{
    min-height: 400px;
    padding-top: 32px;
    border-top: 1px solid rgb(226, 234, 248);
    margin-bottom: 32px;
    &__legend{
      display: flex;
      align-items: center;
      justify-content: center;
      &-icon{
        background: #3955F6;
        width: 40px!important;
        height: 16px!important;
        margin-right: 8px;
        border-radius: 4px;
      }
      &-nicon{
        background: #FF424E;
        width: 40px!important;
        height: 16px!important;
        margin-right: 8px;
        border-radius: 4px;
      }
      &-item{
        margin-right: 24px;
        display: flex;
        align-items: center;
      }
    }
    &__title{
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__wrapper{
      width: 100%;
      display: block;
      //margin: 0 -24px;
      &-image{
        width: 100%;
      }
    }
    
  }
  .arrow_box{
    width: 360px!important;
  }
  .arrow_box_revenue{
    width: 360px!important;
  }
  .content-report__wrap{
    img{
      width: 100%;
      height: 411px;
    }
  }
  .content-report__wrapper{
    margin-bottom: -24px;
  }
`