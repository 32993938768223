import { useContext, useState } from 'react'
import { sendRequestAuth } from 'api/api'
import config from 'config'
import { useParams } from 'react-router-dom'
import useAlert from 'hook/useAlert'
import {ProductContext} from "../provider/~context";
import {useCreateProductRelateAction} from "../provider/~action";

export const useProductRelateTable = () => {
  const { pageState, pageDispatch } = useContext(ProductContext)

  const { productId } = useParams()
  const {showAlert} = useAlert()

  const { productRelated } = pageState.formCreate

  const queries = {
    per_page: productRelated?.table?.pagination?.amount || 200,
    start: (productRelated?.table?.pagination?.active * productRelated?.table?.pagination?.amount) || 0,
  }

  const applyOtherFilter = async () => {
    const qs = {
      ...queries,
      per_page: productRelated?.table?.pagination?.amount,
      start: 0
    }
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchProductByFilter(queryString)
  }


  const handleAmountChange = async n => {
    const qs = { ...queries, per_page: n, start: 0 }
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchProductByFilter(queryString)

  }

  const handlePageChange = async page => {
    const amount = productRelated?.table?.pagination?.amount || 20
    const qs = {
      ...queries,
      per_page: productRelated?.table?.pagination?.amount,
      start: page * amount
    }
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchProductByFilter(queryString)
  }

  const fetchProductByFilter = async (qs) => {
    pageDispatch({
      type: useCreateProductRelateAction.TABLE_LOADING_DISPLAY,
      payload: true
    })
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/product/digital/related-products/${productId}${qs}`)
    ])
    if (response[0]?.data?.success) {
      pageDispatch({
        type: useCreateProductRelateAction.TABLE_LOADING_DISPLAY,
        payload: false
      })
      const rProducts = response[0]?.data
      pageDispatch({
        type: useCreateProductRelateAction.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: rProducts?.data,
          listDefault: rProducts?.data,
          loading: false
        }
      })
      pageDispatch({
        type: useCreateProductRelateAction.TABLE_UPDATE_PAGINATION,
        payload: {
          active: Math.ceil(rProducts?.meta?.start / rProducts?.meta?.per_page),
          amount: rProducts?.meta?.per_page,
          total: Math.ceil(rProducts?.meta?.total / rProducts?.meta?.per_page),
          totalItems: rProducts?.meta?.total,
        }
      })

      pageDispatch({
        type: useCreateProductRelateAction.TABLE_SELECTED_LIST_UPDATE,
        payload: {selected: {list: []}},
      })
    }
  }

  const listProduct = productRelated.table.display.list
  const removeProductFromPrincipal = async (id) => {
    const response = await sendRequestAuth('post', `${config.API}/product/digital/delete-related/${productId}`,
      {
        'product_ids': id
      }
    )
    if(!!response?.data?.success){
      showAlert({
        type: 'success',
        content: 'Xóa sản phẩm thành công'
      })
      // Xóa sản phẩm thành công
      const newProducts = listProduct.filter(item => item.id !== id)
      pageDispatch({
        type: useCreateProductRelateAction.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: newProducts,
          listDefault: newProducts,
          loading: false
        }
      })
    }else{
      showAlert({
        type: 'danger',
        content: response?.data?.message || 'Đã xóa sản phẩm thất bại'
      })
    }
  }


  // handel select product Table

  const {table} = productRelated

  const displayList = table.display.list
  const selectedList = table.selected.list

  // CHECKBOX
  const shouldActiveCheckbox = selectedList.length > 0

  const isActive =
    displayList.length <= 0
      ? false
      : selectedList.length < displayList.length
      ? false
      : !!!displayList.find(
        item => !!!selectedList.find(find => find?.id === item?.id),
      )

  const handleCheckboxChange = () => {
    let newSelectedList = []
    if (isActive) {
      newSelectedList = selectedList.filter(
        item => !!!displayList.find(find => find?.id === item?.id),
      )
    } else {
      let addingList = []
      displayList.forEach(item => {
        if (!!!selectedList.find(find => find?.id === item?.id))
          addingList = [...addingList, item]
      })
      newSelectedList = [...selectedList, ...addingList]
    }
    pageDispatch({
      type: useCreateProductRelateAction.TABLE_SELECTED_LIST_UPDATE,
      payload: {selected: {list: newSelectedList}},
    })
  }


  // SELECTED ACTION DROPDOWN
  const [shouldOpenSelectedActionMenu, setShouldOpenSelectedActionMenu] =
    useState(false)


  const handleDeleteProduct = async () => {
    //dispatch show
    const response = await sendRequestAuth('post', `${config.API}/product/digital/delete-related/${productId}`,
      {
        'product_ids': selectedList.map(item => item.id).join(',')
      }
    )
    return response
    // if(!!response?.data?.success){
    //   showAlert({
    //     type: 'success',
    //     content: 'Xóa sản phẩm thành công'
    //   })
    //   // Xóa sản phẩm thành công
    //   const newProducts = listProduct.filter(item => item.id !== id)
    //   pageDispatch({
    //     type: useCreateProductRelateAction.TABLE_UPDATE_DISPLAY_LIST,
    //     payload: {
    //       list: newProducts,
    //       listOrigin: newProducts
    //     }
    //   })
    // }else{
    //   showAlert({
    //     type: 'danger',
    //     content: response?.data?.message || 'Đã xóa sản phẩm thất bại'
    //   })
    // }
  }

  return {
    table: productRelated.table,
    queries,
    pagination: {
      onAmountChange: handleAmountChange,
      onPageChange: handlePageChange,
    },

    methods: {
      applyOtherFilter,
      removeProductFromPrincipal
    },

    checkbox: {
      checked: shouldActiveCheckbox,
      onClick: handleCheckboxChange,
    },
    selected: {
      actionMenu: {
        open: shouldOpenSelectedActionMenu,
        onToggle: setShouldOpenSelectedActionMenu,
        actions: [
          () => handleDeleteProduct(),
        ],
      },
      list: selectedList,
    },
  }
}