import { CommissionContext } from 'Pages/commission/provider/_context'
import React, { useContext, useState } from 'react'
import { TBodyByDate } from './tbody/_tBodyByDate'
import { TBodyByOrder } from './tbody/_tBodyByOrder'
import { TBodyByPrincipal } from './tbody/_tBodyByPrincipal'
import { TBodyByTeacher } from './tbody/_tBodyByTeacher'

export const CommissionTBody = () => {
  const { pageState } = useContext(CommissionContext)
  const { pageOption } = pageState
  const listBody = [
    { id: 1, value: 'date', component: <TBodyByDate/> },
    { id: 2, value: 'order', component: <TBodyByOrder/> },
    { id: 3, value: 'principal', component: <TBodyByPrincipal/> },
    { id: 5, value: 'teacher', component: <TBodyByTeacher/> },
  ]
  return (
    <>
      {listBody.find(item => item.value === pageOption?.value)?.component}
    </>
  )
}