import {AlternativeAutoComplete} from 'common/form/autoComplete/_alternativeAutoComplete'
import {Option} from 'common/form/autoComplete/_option'
import useProductGroupFilter from "../../hook/useProductGroupFilter";

export const CategoryFilter = () => {
  const {categories} = useProductGroupFilter()
  return (
    <AlternativeAutoComplete
      className="principal-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: {name: 'Danh mục cha', value: ''}, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 112,
        placeholder: 'Chọn danh mục cha',
        readOnly: true,
        value: categories.value?.title || '',
        onIconClick: () => categories.onChange(null),
      }}
      // menu
      menuProps={{
        empty:
          categories.list.length <= 0
            ? 'Không tìm thấy danh mục cha'
            : '',
      }}
      // search input in dropdown menu
      searchInputProps={{
        placeholder: 'Tìm kiếm danh mục cha',
        value: categories.keyword,
        onChange: categories.onKeywordChange,
      }}
    >
      {categories.list.length > 0 &&
        categories.list.map(item => (
          <Option
            key={item.value}
            className="principal-filter-form__option-text"
            data-active={item.id === categories.value?.id}
            onClick={() => categories.onChange(item)}
          >
            {item.title}
          </Option>
        ))}
    </AlternativeAutoComplete>
  )
}
