import { formatDatetime } from 'common/form/datePicker/_functions'
import {
  ORDER_FILTER_TAG_FIELDS
} from '../interfaces/_constants'
import { getDateFromNow } from '../utils/date'
import { ORDER_FILTER_FORM_DATE_TIME_SORT_TYPES } from '../../orderDigital/interfaces/_constants'

export const orderActions = {
  TABLE_AMOUNT_UPDATE: 'TABLE_AMOUNT_UPDATE',
  //  FILTER
  FILTER_ADVANCED_SEARCH_UPDATE: 'FILTER_ADVANCED_SEARCH_UPDATE',
  FILTER_DATE_TIME_UPDATE: 'FILTER_DATE_TIME_UPDATE',
  FILTER_ACTIVE_DATE_TIME_UPDATE: 'FILTER_ACTIVE_DATE_TIME_UPDATE',
  FILTER_DATE_TIME_TRIGGER_UPDATE: 'FILTER_DATE_TIME_TRIGGER_UPDATE',
  FILTER_DUPLICATE_UPDATE: 'FILTER_DUPLICATE_UPDATE',
  FILTER_EMPLOYEE_UPDATE: 'FILTER_EMPLOYEE_UPDATE',
  FILTER_EMPLOYEE_CATEGORY_UPDATE: 'FILTER_EMPLOYEE_CATEGORY_UPDATE',
  FILTER_EMPLOYEE_KEYWORD_UPDATE: 'FILTER_EMPLOYEE_KEYWORD_UPDATE',
  FILTER_EMPLOYEE_TAB_UPDATE: 'FILTER_EMPLOYEE_TAB_UPDATE',
  FILTER_ORIGIN_DATA_UPDATE: 'FILTER_ORIGIN_DATA_UPDATE',
  FILTER_PAYMENT_UPDATE: 'FILTER_PAYMENT_UPDATE',
  FILTER_PRODUCT_ENABLE_LOADING: 'FILTER_PRODUCT_ENABLE_LOADING',
  FILTER_PRODUCT_KEYWORD_UPDATE: 'FILTER_PRODUCT_KEYWORD_UPDATE',
  FILTER_PRODUCT_TAB_UPDATE: 'FILTER_PRODUCT_TAB_UPDATE',
  FILTER_PRODUCT_UPDATE: 'FILTER_PRODUCT_UPDATE',
  FILTER_SEARCH_UPDATE: 'FILTER_SEARCH_UPDATE',
  FILTER_PAYMENT_METHOD_KEYWORD_UPDATE:
    'FILTER_PAYMENT_METHOD_KEYWORD_UPDATE',
  FILTER_PAYMENT_METHOD_UPDATE: 'FILTER_PAYMENT_METHOD_UPDATE',
  FILTER_PRINCIPAL_KEYWORD_UPDATE: 'FILTER_PRINCIPAL_KEYWORD_UPDATE',
  FILTER_PRINCIPAL_UPDATE: 'FILTER_PRINCIPAL_UPDATE',
  FILTER_TEACHER_KEYWORD_UPDATE: 'FILTER_TEACHER_KEYWORD_UPDATE',
  FILTER_TEACHER_UPDATE: 'FILTER_TEACHER_UPDATE',
  FILTER_TEACHER_LIST_UPDATE: 'FILTER_TEACHER_LIST_UPDATE',
  FILTER_ORDER_ID_UPDATE: 'FILTER_ORDER_ID_UPDATE',
  FILTER_CUSTOMER_INFO_UPDATE: 'FILTER_CUSTOMER_INFO_UPDATE',
  FILTER_SHIPPING_STATUS_KEYWORD_UPDATE:
    'FILTER_SHIPPING_STATUS_KEYWORD_UPDATE',
  FILTER_SHIPPING_STATUS_TAB_UPDATE: 'FILTER_SHIPPING_STATUS_TAB_UPDATE',
  FILTER_SHIPPING_STATUS_UPDATE: 'FILTER_SHIPPING_STATUS_UPDATE',
  FILTER_SOURCE_KEYWORD_UPDATE: 'FILTER_SOURCE_KEYWORD_UPDATE',
  FILTER_SOURCE_UPDATE: 'FILTER_SOURCE_UPDATE',
  FILTER_ORDER_STATUS_KEYWORD_UPDATE: 'FILTER_ORDER_STATUS_KEYWORD_UPDATE',
  FILTER_ORDER_STATUS_UPDATE: 'FILTER_ORDER_STATUS_UPDATE',
  OTHER_FILTER_APPLY: 'OTHER_FILTER_APPLY',
  DELETE_TAG_UPDATE: 'DELETE_TAG_UPDATE',
  TAG_FILTER_DELETE: 'TAG_FILTER_DELETE',
  // NOTIFICATIONS
  NOTIFICATIONS_LIST_UPDATE: 'NOTIFICATIONS_LIST_UPDATE',
  // PANELS
  PANELS_UPDATE: 'PANELS_UPDATE',
  PRINT_DETAIL_UPDATE: 'PRINT_DETAIL_UPDATE',
  // TABLE
  TABLE_DISPLAY_DATA_UPDATE: 'TABLE_DISPLAY_DATA_UPDATE',
  TABLE_DISPLAY_DETAIL_UPDATE: 'TABLE_DISPLAY_DETAIL_UPDATE',
  TABLE_DISPLAY_DETAIL_ID_UPDATE: 'TABLE_DISPLAY_DETAIL_ID_UPDATE',
  TABLE_DISPLAY_LOADING_UPDATE: 'TABLE_DISPLAY_LOADING_UPDATE',
  TABLE_PAGINATION_UPDATE: 'TABLE_PAGINATION_UPDATE',
  TABLE_SELECTED_LIST_UPDATE: 'TABLE_SELECTED_LIST_UPDATE',

  //selective export excel
  UPDATE_SELECTED_LIST_ACTIVE: 'UPDATE_SELECTED_LIST_ACTIVE',
  SEARCH_SELECTED_LIST_ACTIVE: 'SEARCH_SELECTED_LIST_ACTIVE',
  UPDATE_DRAG_SELECTED_LIST: 'UPDATE_DRAG_SELECTED_LIST',

  // EDIT ORDER
  UPDATE_ORIGIN_DATA_FORM_EDIT: 'UPDATE_ORIGIN_DATA_FORM_EDIT',
  FORM_INFOMATION_UPDATE: 'FORM_INFOMATION_UPDATE',
  UPDATE_FORM_EDIT_DETAILS: 'UPDATE_FORM_EDIT_DETAILS',
  UPDATE_FORM_EDIT_DETAILS_TOTAL: 'UPDATE_FORM_EDIT_DETAILS_TOTAL',
}

export const dateTimeDefaultValue = [
  getDateFromNow(-7, { type: 'start' }),
  getDateFromNow(0, { type: 'end' }),
]
export const formatDateTimeDefaultValue = `${formatDatetime(
  dateTimeDefaultValue[0],
)} - ${formatDatetime(dateTimeDefaultValue[1])}`


export const orderReducer = (state, action) => {
  switch (action.type) {

    case orderActions.FILTER_ADVANCED_SEARCH_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          advancedSearch: {
            ...state.filter.advancedSearch,
            customer: {
              ...state.filter.advancedSearch.customer,
              keyword:
                typeof action.payload?.customer?.keyword === 'string'
                  ? action.payload.customer.keyword
                  : state.filter.advancedSearch.customer.keyword,
              value:
                typeof action.payload?.customer?.value === 'string'
                  ? action.payload.customer.value
                  : state.filter.advancedSearch.customer.value,
            },
            liveVideoId:
              typeof action.payload?.liveVideoId === 'string'
                ? action.payload.liveVideoId
                : state.filter.advancedSearch.liveVideoId,
          },
        },
      }

    case orderActions.FILTER_DATE_TIME_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          dateTime: {
            ...state.filter.dateTime,
            end: action.payload?.end,
            start: action.payload?.start,
            type: action.payload?.type,
            value: action.payload?.value,
          },
        },
      }

    case orderActions.FILTER_ACTIVE_DATE_TIME_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          dateTime: {
            ...state.filter.dateTime,
            activeValue: {
              ...state.filter.dateTime.activeValue,
              end: action.payload?.end,
              start: action.payload?.start,
              type: action.payload?.type,
              value: action.payload?.value,
            },
          },
        },
      }

    case orderActions.FILTER_DATE_TIME_TRIGGER_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          dateTime: {
            ...state.filter.dateTime,
            trigger: action.payload?.trigger,
          },
        },
      }

    case orderActions.FILTER_ORIGIN_DATA_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            list: action.payload?.product?.list || [],
            listOrigin: action.payload?.product?.list || [],
          },
          principal: {
            ...state.filter.principal,
            list: action.payload?.principal?.list || [],
            listOrigin: action.payload?.principal?.list || [],
          },
        },
      }

    case orderActions.FILTER_PAYMENT_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          payment: {
            ...state.filter.payment,
            value: action.payload?.payment?.value || [],
          },
        },
      }

    case orderActions.FILTER_PRODUCT_ENABLE_LOADING:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            loading: true,
          },
        },
      }

    case orderActions.FILTER_PRODUCT_KEYWORD_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            keyword: action.payload.keyword,
            list: action.payload.list,
            loading: false,
            tab: action.payload?.tab || state.filter.product.tab,
          },
        },
      }

    case orderActions.FILTER_PRODUCT_TAB_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            keyword: '',
            tab: action.payload.tab,
            list: action.payload.list,
          },
        },
      }

    case orderActions.FILTER_PRODUCT_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            list: action?.payload?.list,
            value: action.payload?.value || [],
          },
        },
      }
    case orderActions.FILTER_SEARCH_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          search: {
            ...state.filter.search,
            value: action.payload.value || '',
          },
        },
      }

    case orderActions.FILTER_ORDER_ID_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          orderId: {
            ...state.filter.orderId,
            ...action.payload,
          },
        },
      }

    case orderActions.FILTER_CUSTOMER_INFO_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          customerInfo: {
            ...state.filter.customerInfo,
            ...action.payload,
          },
        },
      }


    // Payment method

    case orderActions.FILTER_PAYMENT_METHOD_KEYWORD_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          paymentMethod: {
            ...state.filter.paymentMethod,
            keyword: action.payload.keyword,
            list: action.payload.list,
          },
        },
      }

    case orderActions.FILTER_PAYMENT_METHOD_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          paymentMethod: {
            ...state.filter.paymentMethod,
            value: action.payload?.value || null,
          },
        },
      }

    //  principals

    case orderActions.FILTER_PRINCIPAL_KEYWORD_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          principal: {
            ...state.filter.principal,
            keyword: action.payload.keyword,
            list: action.payload.list,
          },
        },
      }

    case orderActions.FILTER_PRINCIPAL_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          principal: {
            ...state.filter.principal,
            value: action.payload?.value || null,
          },
        },
      }
    //  teachers

    case orderActions.FILTER_TEACHER_KEYWORD_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          teacher: {
            ...state.filter.teacher,
            keyword: action.payload.keyword,
            list: action.payload.list,
          },
        },
      }

    case orderActions.FILTER_TEACHER_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          teacher: {
            ...state.filter.teacher,
            value: action.payload?.value || null,
          },
        },
      }

    case orderActions.FILTER_TEACHER_LIST_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          teacher: {
            ...state.filter.teacher,
            list: action.payload?.teacher?.list || [],
            listOrigin: action.payload?.teacher?.list || [],
          },
        },
      }


    // Payment status

    case orderActions.FILTER_SOURCE_KEYWORD_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          paymentStatus: {
            ...state.filter.paymentStatus,
            keyword: action.payload.keyword,
            list: action.payload.list,
          },
        },
      }

    case orderActions.FILTER_SOURCE_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          paymentStatus: {
            ...state.filter.paymentStatus,
            value: action.payload?.value || null,
          },
        },
      }

    // order status

    case orderActions.FILTER_ORDER_STATUS_KEYWORD_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          orderStatus: {
            ...state.filter.orderStatus,
            keyword: action.payload.keyword,
            list: action.payload.list,
          },
        },
      }

    case orderActions.FILTER_ORDER_STATUS_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          orderStatus: {
            ...state.filter.orderStatus,
            value: action.payload?.value || null,
          },
        },
      }

    case orderActions.NOTIFICATIONS_LIST_UPDATE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          list: action.payload?.notifications?.list || [],
        },
      }

    case orderActions.OTHER_FILTER_APPLY:
      return {
        ...state,
        filter: {
          ...state.filter,
          dateTime: {
            ...state.filter.dateTime,
            activeValue: {
              end: state.filter.dateTime.end,
              start: state.filter.dateTime.start,
              type: state.filter.dateTime.type,
              value: state.filter.dateTime.value,
            },
          },
          paymentMethod: {
            ...state.filter.paymentMethod,
            activeValue: state.filter.paymentMethod.value,
          },
          principal: {
            ...state.filter.principal,
            activeValue: state.filter.principal.value,
          },
          paymentStatus: {
            ...state.filter.paymentStatus,
            activeValue: state.filter.paymentStatus.value,
          },
          orderStatus: {
            ...state.filter.orderStatus,
            activeValue: state.filter.orderStatus.value,
          },
          product: {
            ...state.filter.product,
            activeValue: state.filter.product.value,
          },
          orderId: {
            ...state.filter.orderId,
            activeValue: state.filter.orderId.value,
          },
          customerInfo: {
            ...state.filter.customerInfo,
            activeValue: state.filter.customerInfo.value,
          },
          teacher: {
            ...state.filter.teacher,
            activeValue: state.filter.teacher.value,
          },
        },
        table: {
          ...state.table,
          display: {
            ...state.table.display,
            list: action.payload?.display?.list || [],
          },
          pagination: {
            ...state.table.pagination,
            active: action.payload?.pagination?.active,
            amount: action.payload?.pagination?.amount,
            total: action.payload?.pagination?.total,
            totalItems: action.payload?.pagination?.totalItems,
          },
        },
      }

    case orderActions.DELETE_TAG_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state.table.display,
            list: action.payload?.display?.list || [],
          },
          pagination: {
            ...state.table.pagination,
            active: action.payload?.pagination?.active,
            amount: action.payload?.pagination?.amount,
            total: action.payload?.pagination?.total,
            totalItems: action.payload?.pagination?.totalItems,
          },
        },
      }

    case orderActions.PANELS_UPDATE:
      return {
        ...state,
        panels: {
          ...state.panels,
          codTotal: action.payload?.panels?.codTotal,
          orderTotal: action.payload?.panels?.orderTotal,
          orderValueTotal: action.payload?.panels?.orderValueTotal,
          shippingFeeTotal: action.payload?.panels?.shippingFeeTotal,
        },
        table: {
          ...state.table,
          pagination: {
            ...state.table.pagination,
            total: Math.ceil(
              action.payload.pagination.totalItems /
              state.table.pagination.amount,
            ),
            totalItems: action.payload.pagination.totalItems,
          },
        },
      }

    case orderActions.PRINT_DETAIL_UPDATE:
      console.log(action.payload)
      return {
        ...state,
        printDetail: action.payload
      }

    case orderActions.TABLE_AMOUNT_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state.table.display,
            list: action.payload?.display?.list || [],
          },
          pagination: {
            ...state.table.pagination,
            active: action.payload?.pagination?.active,
            amount: action.payload?.pagination?.amount,
            total: action.payload?.pagination?.total,
            totalItems: action?.payload?.pagination?.totalItems,
          },
        },
      }

    case orderActions.TABLE_DISPLAY_DATA_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state.table.display,
            list: action.payload?.display?.list || [],
          },
        },
      }

    case orderActions.TABLE_DISPLAY_DETAIL_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          detail: {
            ...state.table.detail,
            active: action.payload?.active || null,
            list: action.payload?.list || [],
          },
        },
      }

    case orderActions.TABLE_DISPLAY_DETAIL_ID_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          detail: {
            ...state.table.detail,
            id: action.payload?.id || null,
          },
        },
      }

    case orderActions.TABLE_DISPLAY_LOADING_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state.table.display,
            loading: action.payload?.table?.display?.loading,
          },
        },
      }

    case orderActions.TABLE_PAGINATION_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state.table.display,
            list: action.payload?.display?.list || [],
          },
          pagination: {
            ...state.table.pagination,
            active: action.payload?.pagination?.active,
          },
        },
      }

    case orderActions.TABLE_SELECTED_LIST_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          selected: {
            ...state.table.selected,
            list: action.payload?.selected?.list || [],
          },
        },
      }

    case orderActions.TAG_FILTER_DELETE:
      switch (action.payload?.type) {
        case 'dateTime.current':
          return {
            ...state,
            filter: {
              ...state.filter,
              dateTime: {
                ...state.filter.dateTime,
                activeValue: {
                  end: null,
                  start: null,
                  type: '',
                  value: null,
                },
                end: null,
                start: null,
                type: '',
                value: null,
              },
            },
          }
        case ORDER_FILTER_TAG_FIELDS[8]:
          return {
            ...state,
            filter: {
              ...state.filter,
              dateTime: {
                ...state.filter.dateTime,
                activeValue: {
                  end: dateTimeDefaultValue[1],
                  start: dateTimeDefaultValue[0],
                  type: ORDER_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
                  value: formatDateTimeDefaultValue,
                },
                end: dateTimeDefaultValue[1],
                start: dateTimeDefaultValue[0],
                type: ORDER_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
                value: formatDateTimeDefaultValue,
              },
            },
          }

        case ORDER_FILTER_TAG_FIELDS[0]:
          return {
            ...state,
            filter: {
              ...state.filter,
              orderId: {
                ...state.filter.orderId,
                activeValue: null,
                value: null,
              },
            },
          }

        case ORDER_FILTER_TAG_FIELDS[1]:
          return {
            ...state,
            filter: {
              ...state.filter,
              customerInfo: {
                ...state.filter.customerInfo,
                activeValue: null,
                value: null,
              },
            },
          }
        case ORDER_FILTER_TAG_FIELDS[2]:
          return {
            ...state,
            filter: {
              ...state.filter,
              product: {
                ...state.filter.product,
                activeValue: [],
                keyword: '',
                loading: false,
                tab: 'all', // all | checked
                value: [],
              },
            },
          }
        case ORDER_FILTER_TAG_FIELDS[3]:
          return {
            ...state,
            filter: {
              ...state.filter,
              paymentStatus: {
                ...state.filter.paymentStatus,
                activeValue: null,
                keyword: '',
                value: null,
              },
            },
          }
        case ORDER_FILTER_TAG_FIELDS[4]:
          return {
            ...state,
            filter: {
              ...state.filter,
              principal: {
                ...state.filter.principal,
                activeValue: null,
                keyword: '',
                value: null,
              },
              teacher: {
                ...state.filter.teacher,
                activeValue: null,
                keyword: '',
                value: null,
                list: [],
                listOrigin: [],
              },
            },
          }
        case ORDER_FILTER_TAG_FIELDS[5]:
          return {
            ...state,
            filter: {
              ...state.filter,
              teacher: {
                ...state.filter.teacher,
                activeValue: null,
                keyword: '',
                value: null,
              },
            },
          }
        case ORDER_FILTER_TAG_FIELDS[6]:
          return {
            ...state,
            filter: {
              ...state.filter,
              paymentMethod: {
                ...state.filter.paymentMethod,
                activeValue: null,
                keyword: '',
                value: null,
              },
            },
          }
        case ORDER_FILTER_TAG_FIELDS[7]:
          return {
            ...state,
            filter: {
              ...state.filter,
              orderStatus: {
                ...state.filter.orderStatus,
                activeValue: null,
                keyword: '',
                value: null,
              },
            },
          }
        default:
          return { ...state }
      }
    case orderActions.UPDATE_SELECTED_LIST_ACTIVE:
      return {
        ...state,
        exportExcel: {
          ...state.exportExcel,
          activeList: action.payload.active,
          list: action.payload.list,
          selectedList: action.payload.selected,
        }
      }
    case orderActions.SEARCH_SELECTED_LIST_ACTIVE:
      return {
        ...state,
        exportExcel: {
          ...state.exportExcel,
          activeList: action.payload.activeList,
          keyword: action.payload.keyword,
        }
      }
    case orderActions.UPDATE_DRAG_SELECTED_LIST:
      return {
        ...state,
        exportExcel: {
          ...state.exportExcel,
          selectedList: action.payload,
        }
      }


    case orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT:
      return {
        ...state,
        formEdit: {
          ...state.formEdit,
          ...action.payload,
        }
      }

    case orderActions.UPDATE_FORM_EDIT_DETAILS:
      return {
        ...state,
        formEdit: {
          ...state.formEdit,
          details: action.payload,
        }
      }

    case orderActions.UPDATE_FORM_EDIT_DETAILS_TOTAL:
      return {
        ...state,
        formEdit: {
          ...state.formEdit,
          order: {
            ...state.formEdit.order,
            ...action.payload,
          }
        }
      }
    default:
      throw new Error()
  }
}
