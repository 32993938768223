import { TableLayout } from 'layouts/tableLayout'
import { useContext, useEffect, useRef, useState } from 'react'
import { RevenueFilterForm } from './components/revenueFilterForm'
import { RevenueTBody } from './components/revenueTable/_revenueTBody'
import { RevenueTHead } from './components/revenueTable/_revenueTHead'
import {
  REVENUE_BREADCRUMB,
  REVENUE_PAGE_HEADER_ACTIONS,
} from './interfaces/_constants'
import { RevenueProvider } from './provider'
import './components/revenueTable/index.scss'
import useRevenue from './hooks/useRevenue'
import { PageHeader } from './components/revenuePageHeader'
import PopupNotPermission from '../permision/popupNotPermission'
import { GLOBAL_ICONS } from '../../interface/icon'
import { PATH } from '../../const/path'
import { RevenuePanels } from './components/revenuePanels'
import { PageOption } from './components/pageOption'
import useRevenueFilterForm from './hooks/useRevenueFilterForm'
import useAlert from '../../hook/useAlert'
import { ModalExport } from '../../common/modalExport'

export const RevenueReport = () => {
  const { fetch, pagination, provider } = useRevenue()

  const { state, dispatch } = provider
  const { table } = state

  useEffect(() => {
    fetch.origin()
  }, [])
  return (
    <RevenueProvider value={{ pageState: state, pageDispatch: dispatch }}>
      <Header/>
      <TableLayout
        header={
          <>
            <RevenueFilterForm/>
            <RevenuePanels/>
            <PageOption/>
          </>
        }
        table={{
          tHead: <RevenueTHead/>,
          tBody: <RevenueTBody/>,
        }}
        pagination={{
          ...table.pagination,
          onAmountChange: pagination.onAmountChange,
          onPageChange: pagination.onPageChange,
        }}
      />
    </RevenueProvider>
  )
}

const Header = () => {
  const [showPermission, setShowPermission] = useState(false)
  const  {queries, pageState} = useRevenueFilterForm()
  const  {pageOption} = pageState
  const [exportModalData, setExportModalData] = useState(null)
  const {showAlert} = useAlert()

  const handleExportClick = () => {
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries({
      ...queries,
      per_page: '',
      start: '',
    })) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }

    handleLargeExport(
      {
        ...queries,
        per_page: '',
        start: '',
      }
    )
  }

  const handleLargeExport = (q) => {
    if (pageState.table.pagination.totalItems <= 0) {
      showAlert({ type: 'info', content: `Số lượng ${pageOption?.name} phải lớn hơn  0` })
      return
    }

    setExportModalData({
      data: {
        query: q,
        total: pageState.table.pagination.totalItems,
      },
      onClose: () => setExportModalData(null),
    })
  }

  return (
    <>
      {showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)}/>}
      <PageHeader
        breadcrumbLinks={REVENUE_BREADCRUMB}
        breadcrumbTitle="Báo cáo doanh thu"
        actions={[
          {
            name: 'Xuất excel',
            icon: GLOBAL_ICONS.export,
            onClick: handleExportClick,
            appearance: 'secondary',
          }
        ]}
      />
      {!!exportModalData && <ModalExport data={exportModalData} title={pageOption?.name} api={`/report/revenue/${pageOption.value}-export`}/>}
    </>
  )
}
