import React from 'react';
import {Button} from "../../../../common/button";
import {PATH} from "../../../../const/path";
import {useNavigate} from "react-router-dom";
import { Loading } from '../../../../common/loading'
import { useEditOrder } from '../../hooks/useEditOrder'

export const ActionFormBtnList = () => {
  const {onSubmit} = useEditOrder()
  const navigate = useNavigate()

  return (
    <div style={{textAlign: 'right', height: 48}}>
      <Button appearance="ghost"
              onClick={() => navigate(PATH.ORDER_BOOK)}
              style={{width: 74}}
      >
        Đóng
      </Button>
      <Button
        onClick={() => onSubmit()}
        style={{marginLeft: 12, padding: '0 16px', width: 108}}
        // disabled={value?.canSaveProduct}
      >
        Cập nhật
      </Button>
      {/*{!!value.loading && <Loading/>}*/}
    </div>
  )
}
