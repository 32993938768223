import React, { useContext, useState } from 'react'
import {
  STORE_UPOS_BREADCRUMB,
  STORE_UPOS_HEADER_ACTIONS
} from '../../interface/_contants'
import { PageHeader } from 'layouts/pageHeader'
import { EventStatisticContext } from '../../provider/_context'
import useEventStatisticRow from '../../hooks/useEventStatisticRow'
import { ModalExport } from '../../../../common/modalExport'
import useAlert from '../../../../hook/useAlert'
import useEventStatisticFilter from '../../hooks/useEventStatisticFilter'

const Header = () => {
  const {
    row
  } = useEventStatisticRow()
  const { pageState,pageDispatch } = useContext(EventStatisticContext)
  const {queries} = useEventStatisticFilter()
  const [exportModalData, setExportModalData] = useState(null)

  const {showAlert} = useAlert()

  const handleExportClick = () => {
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries({
      ...queries,
      per_page: '',
      start: '',
    })) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }

    handleLargeExport(
      {
        ...queries,
        per_page: '',
        start: '',
      }
    )
  }

  const handleLargeExport = (q) => {
    if (pageState.table.pagination.totalItems <= 0) {
      showAlert({type: 'info', content: `Số lượng campaign phải lớn hơn 0`})
      return
    }

    setExportModalData({
      data: {
        query: q,
        total: pageState.table.pagination.totalItems,
      },
      onClose: () => setExportModalData(null),
    })
  }

  const actions = [
    handleExportClick
  ]

  return (
    <>
      <PageHeader
        actions={STORE_UPOS_HEADER_ACTIONS.map((item, i) => ({
          ...item,
          onClick: actions[i],
        }))}
        breadcrumbLinks={STORE_UPOS_BREADCRUMB}
        breadcrumbTitle={'Thống kê sự kiện'}
      />
      {!!exportModalData && <ModalExport data={exportModalData} title={'campaign'} api={`/event/report/export`}/>}
    </>
  )
}

export default Header