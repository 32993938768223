import React from "react";
import {Input} from "../../../../common/form/input";
import {THEME_SEMANTICS} from "../../../../common/theme/_semantics";
import {Text} from "../../../../common/text";
import {useLicenseKeyCreate} from "../../hook/useLicenseKeyCreate";

export const QuotaActive = ()=>{
    const {create} = useLicenseKeyCreate()
    const {quotaActive} = create
    return(
        <Input
            label={
                <>
                    Quota active
                </>
            }
            value={quotaActive?.value}
            placeholder='Nhập quota active'
            // validateText={pageState.check_submit.name_check.status ? pageState.check_submit.name_check.message : null}
            // validateType={!pageState.check_submit.name_check.status ? 'success' : 'danger'}
            onChange={(e) => quotaActive.onChange(e.target.value)}
            // onBlur={(e) => onBlurNameSupplier(e)}
        />
    )
}