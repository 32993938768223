import React from 'react';
import {Tr} from "../../../../../../layouts/tableLayout/_tr";
import {Th} from "../../../../../../layouts/tableLayout/_th";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import { GLOBAL_ICONS } from '../../../../../../interface/icon'

const Thead = ({...props}) => {
  const {t} = useTranslation()
  return (
    <StyledThead>
      <Tr {...props} type="tHead">
        <Th className="store-upos-table__cell">STT</Th>
        <Th className="store-upos-table__cell">Tên sách</Th>
        <Th className="store-upos-table__cell">Thời hạn bản quyền</Th>
        <Th className="store-upos-table__cell">Danh mục</Th>
        <Th className="store-upos-table__cell"
             // icon={<PaymentFilterPopover />}
        >
          Giá bán
        </Th>
        <Th className="store-upos-table__cell">Hình ảnh</Th>
        <Th className="store-upos-table__cell">{t('general_status')}</Th>
        <Th className="store-upos-table__cell">Thứ tự ưu tiên</Th>
        <Th className="store-upos-table__cell" style={{display: 'flex'}}>
          {GLOBAL_ICONS.setting}
        </Th>
      </Tr>
    </StyledThead>
  )
}

export default Thead


export const StyledThead = styled.div`
  .store-upos-table {
    &__cell {
      margin: 0 8px;
    }
    &__cell:nth-child(1) {
      width: 2%;
    }
    &__cell:nth-child(2) {
      width: 15%;
    }
    &__cell:nth-child(3) {
      width: 10%;
    }
    &__cell:nth-child(4) {
      width: 17.2%;
    }
    &__cell:nth-child(5) {
        width: 12.5%;
    }
    &__cell:nth-child(6) {
      width: 8.5%;
      display: flex;
      justify-content: center;
    }
    &__cell:nth-child(7) {
      width: 12.5%;
      display: flex;
      justify-content: center;
    }
    &__cell:nth-child(8) {
      width: 9%;
      text-align: center;
      justify-content: center;
    }
    &__cell:nth-child(9) {
      flex: 1;
      justify-content: end;
    }
  }
`
