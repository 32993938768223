import { Text } from '../../../../../common/text'
import { THEME_SEMANTICS } from '../../../../../common/theme/_semantics'
import { Option } from '../../../../../common/form/autoComplete/_option'
import { AlternativeAutoComplete } from '../../../../../common/form/autoComplete/_alternativeAutoComplete'
import React, { useContext } from 'react'
import { EventManagementContext } from '../../../provider/_context'
import { useModal } from '../../../hooks/useModal'

export const POSITION_EVENT = [
  { name: 'Event chính', value: 'main' },
  { name: 'Event danh mục', value: 'category' },
]
export const PositionEvent = () => {
  const { pageState } = useContext(EventManagementContext)
  const { field_name } = useModal()
  return (
    <AlternativeAutoComplete
      className="principal-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: { name: 'Vị trí event', value: '' }, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 82,
        categoryHidden: true,
        placeholder: 'Chọn vị trí event', label: (
          <>
            Vị trí event <Text color={THEME_SEMANTICS.failed}>*</Text>
          </>
        ),
        readOnly: true,
        value: pageState?.create?.position?.name,
      }}
      hideSearchBar={true}
      validateText={pageState.create.validate?.position}
      validateType={!pageState.create.validate?.position ? 'success' : 'danger'}
    >
      {POSITION_EVENT.map(item => (
        <Option
          key={item.value}
          className="principal-filter-form__option-text"
          data-active={item.value === pageState?.create?.position?.value}
          onClick={() => field_name.onChangePosition(item)}
        >
          {item.name}
        </Option>
      ))}
    </AlternativeAutoComplete>
  )
}