import {useCallback, useContext} from 'react'
import {useSearchParams} from 'react-router-dom'
import {ProductGroup} from "../provider/_context";
import {useProductAction} from "../provider/_reducer";
import config from "../../../config";
import {sendRequestAuth} from "../../../api/api";
import {TAG_DELETE} from "../interface";
import {revenueActions} from "../../revenue/provider/_reducer";
import {removeAcent} from "../../../common/fieldText/_functions";

const useProductGroupFilter = () => {
   const [searchParams, setSearchParams] = useSearchParams()
   const {pageState, pageDispatch} = useContext(ProductGroup)
   const {filter, table} = pageState

   // ===== ===== ===== ===== =====
   // SEARCH
   // ===== ===== ===== ===== =====
   const searchValue = filter.search.value
   const searchActive = filter.search.active
   const handleSearchChange = (e) => {
      useProductAction
      if (e == ' ') e = ''
      const keyword = e.replace(/\s+/g, ' ') || ''
      pageDispatch({
         type: useProductAction.FILTER_SEARCH_UPDATE,
         payload: {value: keyword},
      })
   }

   // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
   // ===== ===== ===== ===== =====

   // ===== ===== ===== ===== =====
   // Category parent
   // ===== ===== ===== ===== =====
   const categoryActiveValue = filter.parentCategory.activeValue
   const categoryKeyword = filter.parentCategory.keyword
   const categoryList = filter.parentCategory.list
   const categoryListOrigin = filter.parentCategory.listOrigin
   const categoryValue = filter.parentCategory.value

   const handleCategoryChange = async (data) => {
      pageDispatch({
         type: useProductAction.FILTER_CATEGORY_UPDATE,
         payload: {value: data},
      })

   }

   const handleCategoryKeywordChange = data => {
      const formatDataValue = data?.value
         ? removeAcent(data?.value?.toLowerCase())
         : ''

      const categoryListData = categoryListOrigin.filter(item => {
         const formatNameItem = item?.title
            ? removeAcent(item.title.toLowerCase())
            : ''
         if (formatNameItem.includes(formatDataValue)) return true
         return false
      })

      pageDispatch({
         type: useProductAction.FILTER_CATEGORY_KEYWORD_UPDATE,
         payload: {
            keyword: data?.value || '',
            list: categoryListData,
         },
      })
   }
   // ===== ===== ===== ===== =====
   // STATUS FILTER
   // ===== ===== ===== ===== =====
   const statusValue = filter?.status?.value
   const statusActiveValue = filter?.status?.activeValue

   const handleStatusChange = value => {
      pageDispatch({
         type: useProductAction.FILTER_STATUS_VALUE_UPDATE,
         payload: value
      })
   }
   // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

   // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
   // ===== ===== ===== ===== =====
   // SUBMIT FILTER
   // ===== ===== ===== ===== =====
   // Badge
   const otherFilterBadge = [
      !!statusActiveValue,
      !!searchActive,
      !!categoryActiveValue
   ].filter(item => item === true).length

   const shouldShowResetAll = [
      !!statusActiveValue,
      !!categoryActiveValue,
      !!searchActive
   ].includes(true)

   const canSubmitOtherFilter = [
      JSON.stringify(statusValue) !== JSON.stringify(statusActiveValue),
      JSON.stringify(categoryValue) !== JSON.stringify(categoryActiveValue),
      JSON.stringify(filter?.search?.value) !== JSON.stringify(filter?.search?.active)
   ].includes(true)
   const queries = {
      type: 'digital',
      per_page: table?.pagination?.amount || 20,
      keyword: filter?.search?.value || '',
      parent_id: categoryValue?.id || '',
      status: statusValue?.value || '',
      start: 0
   }


   const handleStatusActiveValue = status => pageDispatch({
      type: useProductAction.FILTER_STATUS_ACTIVE_VALUE_UPDATE,
      payload: status
   })

   const fetchProductGroupByFilter = async (qs, opt) => {
      pageDispatch({
         type: useProductAction.TABLE_LOADING_DISPLAY,
         payload: true
      })
      let queryString = '?'
      let i = 0
      for (const [key, value] of Object.entries(qs)) {
         queryString += `${i > 0 ? '&' : ''}${key}=${value}`
         i++
      }
      const response = await Promise.all([
         sendRequestAuth('get', `${config.API}/product-category/categories${queryString}`),
      ])
      if (response[0]?.data?.success) {
         pageDispatch({
            type: useProductAction.TABLE_LOADING_DISPLAY,
            payload: false
         })
         const books = response[0]?.data
         pageDispatch({
            type: useProductAction.TABLE_UPDATE_DISPLAY_LIST,
            payload: {
               list: books?.data,
               listDefault: books?.data,
               loading: false
            }
         })
         pageDispatch({
            type: useProductAction.TABLE_UPDATE_PAGINATION,
            payload: {
               active: 0,
               amount: books?.meta?.per_page,
               total: Math.ceil(books?.meta?.total / books?.meta?.per_page),
               totalItems: books?.meta?.total,
            }
         })
      }
   }
   const applyOtherFilter = async () => {
      pageDispatch({
         type: useProductAction.TABLE_LOADING_DISPLAY,
         payload: true
      })
      fetchProductGroupByFilter(queries)
      pageDispatch({type: useProductAction.FILTER_STATUS_ACTIVE_VALUE_UPDATE, payload: statusValue})
      pageDispatch({type: useProductAction.FILTER_CATEGORY_ACTIVE_VALUE_UPDATE, payload: categoryValue})
      pageDispatch({
         type: useProductAction.FILTER_SEARCH_ACTIVE_UPDATE, payload: {
            active: filter?.search?.value
         }
      })

   }
   // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

   const filterTagDelete = (type) => {
      queries.status = ''
      pageDispatch({
         type: useProductAction.TABLE_LOADING_DISPLAY,
         payload: true
      })
      let qs = {...queries}
      switch (type) {
         case TAG_DELETE[0]:
            qs = {...qs, keyword: ''}
            pageDispatch({
               type: useProductAction.FILTER_TAG_DELETE,
               payload: type
            })
            break;
         case TAG_DELETE[1]:
            qs = {...qs, parent_id: ''}
            pageDispatch({
               type: useProductAction.FILTER_TAG_DELETE,
               payload: type
            })
            break;
         case TAG_DELETE[2]:
            qs = {...qs, status: ''}
            pageDispatch({
               type: useProductAction.FILTER_TAG_DELETE,
               payload: type
            })
            break;
         default:
            break;
      }

      fetchProductGroupByFilter(qs)
   }
   const filterTagDeleteAll = () => {
      TAG_DELETE.forEach(each => pageDispatch({
         type: useProductAction.FILTER_TAG_DELETE,
         payload: each
      }))
      fetchProductGroupByFilter({...queries, keyword: '', status: '', parent_id: ''})
   }
   return {
      queries: queries,
      search: {
         value: searchValue,
         active: filter?.search?.active,
         onChange: handleSearchChange,
      },
      statusFilter: {
         value: statusValue,
         statusValue: statusActiveValue,
         activeValue: statusActiveValue,
         onChange: handleStatusChange,
      },
      categories: {
         activeValue: categoryActiveValue,
         keyword: categoryKeyword,
         list: categoryList,
         value: categoryValue,
         onChange: handleCategoryChange,
         onKeywordChange: handleCategoryKeywordChange,
      },
      badge: {
         others: otherFilterBadge,
      },
      methods: {
         applyOtherFilter,
         filterTagDelete,
         filterTagDeleteAll
      },
      canSubmitOtherFilter,
      shouldShowResetAll,
   }
}

export default useProductGroupFilter