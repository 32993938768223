import React from 'react'
import { Input } from 'Component/Input'
import { useEditOrder } from '../../../hooks/useEditOrder'
import { THEME_SEMANTICS } from '../../../../../common/theme/_semantics'
import { Text } from '../../../../../common/text'

export const Address = () => {
  const { address } = useEditOrder()
  const { methods } = address
  return (
    <Input
      label={
        <>
          Địa chỉ <Text color={THEME_SEMANTICS.failed}>*</Text>
        </>
      }
      value={address?.value}
      onChange={e => methods.onChange(e.target?.value)}
      onBlur={e => methods.onBlur(e.target?.value)}
      validateText={address?.message}
      validateType={address?.status ? 'danger' : ''}
      placeholder={'Vui lòng nhập địa chỉ'}
      maxLength={101}
    />
  )
}