import { sendRequestAuth } from 'api/api'
import { Button } from 'common/button'
import config from 'config'
import useCommissionFilterForm from 'Pages/commission/hooks/useCommissionFilterForm'
import { CommissionContext } from 'Pages/commission/provider/_context'
import { commissionActions } from 'Pages/commission/provider/_reducer'
import { memo, useContext, useEffect, useReducer, useState } from 'react'
import { CommissionTags } from '../commissionTags'
import { CommissionDateTime } from './_dateTime'
import { CommissionSearch } from './_search'
import { StyledCommissionFilterForm } from './_styled'
import { CommissionCustomerInfo } from './_customerInfo'
import { CommissionPrincipal } from './_principal'
import { CommissionTeacher } from './_teacher'
import { GLOBAL_ICONS } from '../../../../interface/icon'
import { OrderType } from './_orderType'

export const CommissionFilterForm = memo(({ ...props }) => {
  const { canSubmitOtherFilter, functions } = useCommissionFilterForm()
  const { pageState, pageDispatch } = useContext(CommissionContext)

  useEffect(() => {
    const fetchData = async () => {
      const response = await Promise.all([
        sendRequestAuth(
          'get',
          `${config.API}/principal/principals?per_page=999&start=0`,
        ),
        sendRequestAuth(
          'get',
          `${
            config.API
          }/product/book/books?keyword=&category_id=&status=&per_page=999&start=`,
        ),
        sendRequestAuth(
          'get',
          `${
            config.API
          }/order/filter/principals?keyword=`,
        )
      ])
      pageDispatch({
        type: commissionActions.FILTER_ORIGIN_DATA_UPDATE,
        payload: {
          list: response[0].data?.data?.map(item => ({
            name: item?.name || '',
            value: item?.id || '',
          })),
          product: {
            list: response[1].data?.data.map(item => ({
              data: item || null,
              name: item?.title || '',
              value: item?.id || '',
            })),
          },
          principal: {
            list: response[2].data.map(item => ({
              data: item || null,
              name: ((!!item?.last_name ? (item?.last_name + ' ') : '' )+ item?.name) || '',
              value: item?.id || '',
            })),
          },
        },
      })
    }

    fetchData()
  }, [])

  return (
    <StyledCommissionFilterForm {...props}>
      <div
        className="commission-filter-form__group commission-filter-form__collapse"
        data-collapse={true}
      >
        <CommissionSearch/>
        <CommissionCustomerInfo/>
        <OrderType/>
        <CommissionPrincipal/>
        <CommissionTeacher/>
        <CommissionDateTime/>
        <div className="commission-filter-form__input-wide flex">
          <Button
            appearance="secondary"
            disabled={!canSubmitOtherFilter}
            size="md-"
            onClick={() =>
              canSubmitOtherFilter && functions.applyCommissionOtherFilter()
            }
          >
            Tìm kiếm
          </Button>
          <Button
            appearance="secondary"
            size="md-"
            onClick={() => functions.refresh(pageState?.pageOption?.value)}
            className={'commission-filter-form__btn-refresh'}
          >
            {GLOBAL_ICONS.repeat}
          </Button>
        </div>
      </div>
      <div className="commission-filter-form__group" style={{ marginBottom: 4 }}>
        <CommissionTags/>
      </div>
    </StyledCommissionFilterForm>
  )
})
