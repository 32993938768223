import {DISPLAY_NAME_MENU} from 'const/display_name_menu'
import {PATH} from 'const/path'
import {ROLE} from 'const/role'
import {SIDEBAR_MENU_ICONS} from './_icons'

export const SIDEBAR_MENU_ITEMS = [
   {
      id: 1,
      label: "Dashboard",
      path: PATH.ADMIN_DASHBOAR,
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.overview,
      list: [],
   },
   {
      id: 2,
      label: 'Danh mục',
      path: PATH.CATEGORY,
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.categories,
      list: [],
   },
   {
      id: 3,
      label: 'Sản phẩm',
      path: PATH.DIGITAL_PRODUCT,
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.product,

   },
   {
      id: 4,
      label: 'Đơn hàng',
      path: PATH.ORDER_DIGITAL,
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.order,
   },
   {
      id: 5,
      label: 'Khách hàng',
      path: null,
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.customer,
      list: [
         {
            id: 1,
            label: 'Hiệu trưởng/GV',
            path: PATH.PRINCIPAL,
         },
         {
            id: 2,
            label: 'Khách hàng',
            path: PATH.CUSTOMER,
         },
      ],
   },
   {
      id: 6,
      label: 'Doanh thu & hoa hồng',
      path: null,
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.revenue,
      list: [
         {
            id: 1,
            label: 'Doanh thu',
            path: PATH.REVENUE,
         },
         {
            id: 2,
            label: 'Hoa hồng',
            path: PATH.COMMISSION,
         },
      ],
   },
   {
      id: 7,
      label: 'Kế toán',
      path: null,
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.accountant,
      list: [
         {
            id: 1,
            label: 'Phiếu chi hoa hồng',
            path: PATH.COMMISSION_VOUCHER,
         },
      ],
   },
   {
      id: 8,
      label: 'Banner',
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.banner,
      path: PATH.BANNER,
   },
   {
      id: 12,
      label: 'Sự kiện',
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.event,
      path: null,
      list: [
         {
            id: 1,
            label: 'Quản lý sự kiện',
            path: PATH.EVENT,
         },
         {
            id: 2,
            label: 'Thống kê sự kiện',
            path: PATH.EVENT_REVENUE,
         },
      ],
   },
   {
      id: 9,
      label: 'Quản lý Key SP số',
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.keyTProduct,
      path: PATH.KEY_PRODUCT,
   },

   {
      id: 10,
      label: 'Cấu hình và cài đặt',
      path: null,
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.setting,
      list: [
         {
            id: 1,
            label: 'Quản lý người dùng',
            path: '/users',
         },
         // {
         //   id: 2,
         //   label: 'Phân quyền',
         //   path: '/user/groups',
         // },
         // {
         //   id: 3,
         //   label: 'Cấu hình thanh toán',
         //   path: PATH.CONFIG_PAYMENT,
         // },
         // {
         //   id: 4,
         //   label: 'Cấu hình thông báo',
         //   path: PATH.CONFIG_NOTIFICATION,
         // },
      ],
   },
]


export const SIDEBAR_MENU_STAFF_ITEMS = [
   {
      id: 1,
      label: "Dashboard",
      path: PATH.ADMIN_DASHBOAR,
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.overview,
      list: [],
   },
   {
      id: 2,
      label: 'Danh mục',
      path: PATH.CATEGORY,
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.categories,
      list: [],
   },
   {
      id: 3,
      label: 'Sản phẩm',
      path: PATH.DIGITAL_PRODUCT,
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.product,
   },
   {
      id: 4,
      label: 'Đơn hàng',
      path: PATH.ORDER_DIGITAL,
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.order,
   },
   {
      id: 5,
      label: 'Khách hàng',
      path: null,
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.customer,
      list: [
         {
            id: 1,
            label: 'Hiệu trưởng/GV',
            path: PATH.PRINCIPAL,
         },
         {
            id: 2,
            label: 'Khách hàng',
            path: PATH.CUSTOMER,
         },
      ],
   },
   {
      id: 6,
      label: 'Doanh thu & hoa hồng',
      path: null,
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.revenue,
      list: [
         {
            id: 1,
            label: 'Doanh thu',
            path: PATH.REVENUE,
         },
         {
            id: 2,
            label: 'Hoa hồng',
            path: PATH.COMMISSION,
         },
      ],
   },
   {
      id: 7,
      label: 'Kế toán',
      path: null,
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.accountant,
      list: [
         {
            id: 1,
            label: 'Phiếu chi hoa hồng',
            path: PATH.COMMISSION_VOUCHER,
         },
      ],
   },
   {
      id: 8,
      label: 'Banner',
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.banner,
      path: PATH.BANNER,
   },
   {
      id: 9,
      label: 'Quản lý Key SP số',
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.keyTProduct,
      path: PATH.KEY_PRODUCT,
   },
]

