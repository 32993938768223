import React from "react";
import {Tr} from "../../../../../../layouts/tableLayout/_tr";
import {TEACHER_TABLE_HEADER} from "../../interfaces/constant";
import {Td} from "../../../../../../layouts/tableLayout/_td";
import {Text} from "../../../../../../common/text";

export const TableTeacherHeader= ()=>{
    return(
        <Tr type={'tHead'} className='create-teacher-table'>
            <Td className='create-teacher-table_cell'>
                <Text>STT</Text>
            </Td>
            {
                TEACHER_TABLE_HEADER?.map(map =>{
                    return <Td key={map.id} className='create-teacher-table_cell'>
                        <Text>{map.name}</Text>
                    </Td>
                })
            }
            <Td className='create-teacher-table_cell'/>
        </Tr>
    )
}