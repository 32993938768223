import React from 'react';
import {STORE_UPOS_ICON} from "../../../products/book/interface/_icons";
import {CategoryInput} from "../../../../common/form/input/_categoryInput";
import {useLicenseKeyFilter} from "../../hook/useLicenseKeyFilter";

const Search = () => {
  const {productName} = useLicenseKeyFilter()
  return (
    <CategoryInput
        categoryValue={{name:'Sản phẩm', value:''}}
        categoryWidth={110}
        className="license-key-filter-form__input-wide"
        placeholder={"Tìm kiếm theo tên sản phẩm"}
        value={productName.value}
        onChange={productName.onChangeValue}
    />
  )
}

export default Search