import React from 'react'
import { Input } from 'Component/Input'
import { useEditOrder } from '../../../hooks/useEditOrder'
import { THEME_SEMANTICS } from '../../../../../common/theme/_semantics'
import { Text } from '../../../../../common/text'
import { Box } from '@mui/material'
import { formatMoney } from '../../../../../util/functionUtil'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../../../common/theme/_colors'
import { GLOBAL_ICONS } from '../../../../../interface/icon'
import { Tooltip } from '../../../../../common/tooltip'
import { CurrencyInput } from '../../../../../common/form/input/_currencyInput'
import useGlobalContext from '../../../../../containerContext/storeContext'

export const ProductDetail = () => {
  const { details, customerInfo, product } = useEditOrder()
  const data = customerInfo?.order
  const handleInputChange = (e, data) => {
    let currentValue = e.target.value.toString().replace(/[^0-9]/g, '')
    if (currentValue.startsWith('0')) currentValue = currentValue.substring(1)
    product.onProductSelect(data, {
      type: 'amount',
      amount: currentValue ? Number(currentValue) : '',
    })
  }

  const handlePriceChange = (val, item) => {
    let currentValue = val.toString().replace(/[^0-9]/g, '')
    if (currentValue.startsWith('0')) currentValue = currentValue.substring(1)

    product.onProductSelect(item, {
      type: 'price',
      value: currentValue ? Number(currentValue) : '',
    })
  }
  return (
    <StyledRowTabDetail>
      <Text fontWeight={300}>Thông tin sản phẩm <Text fontWeight={500}>(Tổng số lượng: {details?.length})</Text></Text>
      <div className="row-tab-detail__table">
        <div className="row-tab-detail__thead">
          <div className="row-tab-detail__tr">
            <div className="row-tab-detail__th">Mã sản phẩm/SKU</div>
            <div className="row-tab-detail__th">Tên sản phẩm</div>
            <div className="row-tab-detail__th">Số lượng</div>
            <div className="row-tab-detail__th">Đơn giá</div>
            <div className="row-tab-detail__th">Giá bán</div>
            <div className="row-tab-detail__th">Giảm giá</div>
            <div className="row-tab-detail__th">Thành tiền</div>
            <div className="row-tab-detail__th">Action</div>
          </div>
        </div>
        <div className="row-tab-detail__tbody">
          {details.map(item => (
            <div key={item.product_id} className="row-tab-detail__tr">
              <div
                className="row-tab-detail__td"
                title={item?.product_sku}
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {item?.product_sku || '---'}
              </div>
              <div className="row-tab-detail__td">
                <Text
                  color={THEME_SEMANTICS.delivering}
                  style={{ cursor: 'pointer' }}
                >
                  {item?.product_name || '---'}
                </Text>
                <span style={{ cursor: 'pointer' }}></span>
              </div>
              <div className="row-tab-detail__td">
                {/*{item?.quantity*/}
                {/*  ? Number(item.quantity) < 10*/}
                {/*    ? `0${item.quantity}`*/}
                {/*    : item.quantity*/}
                {/*  : '---'}*/}
                <Tooltip title={+item.quantity === 0 ? 'Số lượng sản phẩm cần ≥ 1' : ''} className={`--danger`}
                         placement={'bottom'}>
                  <Input
                    disabled={data?.payment_status === 'paid'}
                    icon={
                      <div className="order-single-product-table__number-arrow">
                        <i
                          data-disabled={+item.quantity > 9998}
                          onClick={() =>
                            +item.quantity < 9999 &&
                            product.onProductSelect(item, { type: 'increase' })
                          }
                        >
                          {GLOBAL_ICONS.caretUp}
                        </i>
                        <i
                          data-disabled={+item.quantity <= 1}
                          onClick={() =>
                            +item.quantity > 1 &&
                            product.onProductSelect(item, { type: 'decrease' })
                          }
                        >
                          {GLOBAL_ICONS.caretUp}
                        </i>
                      </div>
                    }
                    // min={0}
                    type="number"

                    validateText={+item.quantity == 0 ? true : ''}
                    validateType={'danger'}
                    value={item.quantity}
                    onChange={e => handleInputChange(e, item)}
                    onIconClick={() => {
                    }}
                  />
                </Tooltip>
              </div>
              <div className="row-tab-detail__td">
                {formatMoney(item?.price)}
              </div>
              <div className="row-tab-detail__td">
                {/*{formatMoney(item?.price_sale)}*/}
                <CurrencyInput
                  disabled={data?.payment_status === 'paid'}
                  defaultValue={item?.price_sale || 0}
                  triggerDefault={item?.trigger}
                  onChange={e => handlePriceChange(e, item)}
                  style={{ textAlign: 'right' }}
                />
              </div>
              <div className="row-tab-detail__td">
                {formatMoney((!!item?.price_sale && !!item?.price_sale < item?.price) ?
                  (+item?.price * +item?.quantity) - +(item?.price_sale * +item?.quantity) : 0)}
              </div>
              <div className="row-tab-detail__td">
                {formatMoney(Number(item?.quantity) == 0 ? 0 :
                  Number(item?.price || 0) * Number(item?.quantity || 0) -
                  (Number((!!item?.price_sale && !!item?.price_sale) < item?.price ? +item?.price - +item?.price_sale : 0) * Number(item?.quantity || 0))
                )}
              </div>
              <div className="row-tab-detail__td"
                   style={{ cursor: (details.length > 1 && data?.payment_status !== "paid") ? 'pointer' : 'not-allowed' }}
                   onClick={() => (details.length > 1 && data?.payment_status !== "paid") ? product.onProductSelect(item, {
                     type: 'amount',
                     delete: true
                   }) : ''}
              >
                {GLOBAL_ICONS.recycle}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="row-tab-detail__result">
        <div className="row-tab-detail__result-item">
          <div className="row-tab-detail__result-label">
            <b>Tổng đơn giá</b>
          </div>
          <div className="row-tab-detail__result-value">
            <b>{formatMoney(+data?.total_amount)}</b>
          </div>
        </div>
        <div className="row-tab-detail__result-item">
          <div className="row-tab-detail__result-label">
            <b>Tổng giảm giá</b>
          </div>
          <div className="row-tab-detail__result-value">
            <b>{formatMoney(+data?.discount || 0)}</b>
          </div>
        </div>
        <div className="row-tab-detail__result-item">
          <div className="row-tab-detail__result-label">
            <b>Thành tiền</b>
          </div>
          <div className="row-tab-detail__result-value">
            <b>{formatMoney(+data?.sub_total)}</b>
          </div>
        </div>
        <div className="row-tab-detail__result-item">
          <div className="row-tab-detail__result-label">
            <b>Phí vận chuyển</b>
          </div>
          <div className="row-tab-detail__result-value">
            <b>{formatMoney(+data?.shipping_fee || 0)}</b>
          </div>
        </div>
        <div className="row-tab-detail__result-item">
          <div className="row-tab-detail__result-label">
            <b>Tổng tiền</b>
          </div>
          <div className="row-tab-detail__result-value">
            <b>{formatMoney(+data?.grand_total)}</b>
          </div>
        </div>
      </div>
    </StyledRowTabDetail>
  )
}

const StyledRowTabDetail = styled.div`
  margin-top: 34px;
  .row-tab-detail {
    &__content {
      position: relative;

      margin-bottom: 12px;

      display: flex;
      flex-wrap: wrap;
      &-note {
        display: flex;
        justify-content: right;
        margin-top: -12px;
      }
    }

    &__content-group {
      width: calc(100% / 3 - 12px);
      margin-bottom: 12px;
      margin-right: 12px;
      h4 {
        margin-bottom: 12px;
      }
      &--items {
        margin-bottom: 12px;
      }
    }

    &__info-table {
      margin-bottom: 12px;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__inventory {
      min-height: 40px;
      margin-bottom: 12px;
      padding: 10px 0;

      display: flex;
      align-items: center;

      border-bottom: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &__table {
      margin-top: 16px;
      margin-bottom: 12px;

      overflow: hidden;

      border: 1px solid #e2eaf8;
      border-radius: 8px;
    }

    &__thead {
      .row-tab-detail__tr {
        background: #f7f9fd;
      }
    }

    &__tr {
      display: flex;
    }

    &__th {
      min-height: 44px;
      padding: 12px;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      
      &:nth-child(1) {
        width: 13%;
      }
      &:nth-child(2) {
        flex: 1;
      }
      &:nth-child(3) {
        width: 7%;
      }
      &:nth-child(4) {
        width: 13%;
      }
      &:nth-child(5) {
        width: 13%;
      }
      &:nth-child(6) {
        width: 13%;
      }
      &:nth-child(7) {
        width: 13%;
      }
      &:nth-child(8) {
        width: 5%;
      }
    }

    &__td {
      min-height: 56px;
      padding: 18px 12px;

      border-top: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      display: flex;
      align-items: center;

      &:nth-child(1) {
        width: 13%;
      }
      &:nth-child(2) {
        flex: 1;

        color: ${THEME_SEMANTICS.delivering};
      }
      &:nth-child(3) {
        width: 7%;
      }
      &:nth-child(4) {
        width: 13%;
      }
      &:nth-child(5) {
        width: 13%;
      }
      &:nth-child(6) {
        width: 13%;
      }
      &:nth-child(7) {
        width: 13%;
      }
      &:nth-child(8) {
        width: 5%;
      }
    }

    &__result-item {
      margin-bottom: 8px;

      display: flex;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      line-height: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__result-label {
      padding: 0 12px;

      flex: 1;

      text-align: right;
    }

    &__result-value {
      width: 13%;
      padding: 0 12px;

      text-align: right;
    }
  }
  .order-single-product-table {
    &__table {
      position: relative;
      z-index: 0;

      max-height: 400px;

      overflow: auto;

      border: 1px solid #e2eaf8;
      border-radius: 8px;

      .order-single-product-table__th,
      .order-single-product-table__td {
        &:nth-child(2) {
          width: 124px;

          text-align: right;
        }
        &:nth-child(3) {
          width: 144px;
          padding-right: 12px;

          text-align: right;
        }
        &:nth-child(4) {
          width: 144px;

          justify-content: flex-end;

          text-align: right;
        }
        &:nth-child(5) {
          width: 48px;
          padding-right: 16px;
        }
      }
    }

    &__thead {
      position: sticky;
      top: 0;
      z-index: 1;

      background: #f7f9fd;
    }

    &__tbody {
      display: flex;
      flex-direction: column-reverse;
      position: relative;
    }

    &__tr {
      display: flex;
    }

    &__th {
      min-height: 44px;
      padding: 12px;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;

      &:nth-child(1) {
        padding-left: 16px;

        flex: 1;
      }

      &:nth-child(2) {
        width: 124px;

        text-align: right;
      }

      &:nth-child(3) {
        width: 124px;
        padding-right: 16px;
      }
    }

    &__td {
      min-height: 56px;
      padding: 18px 12px;

      display: flex;
      align-items: center;

      border-top: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      line-height: 20px;

      &:nth-child(1) {
        padding-left: 16px;

        display: flex;
        flex: 1;
        align-items: center;
      }

      &:nth-child(2) {
        width: 124px;

        justify-content: flex-end;

        text-align: right;
      }

      &:nth-child(3) {
        width: 124px;
        padding-right: 16px;
      }

      .--ellipsis {
        max-width: 100%;
        max-height: 40px;

        display: -webkit-box;
        overflow: hidden;

        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }

    &__thumbnail {
      width: 48px;
      height: 48px;
      margin-right: 13px;

      object-fit: cover;
      object-position: center;
      overflow: hidden;

      border-radius: 4px;
    }

    &__number-arrow {
      position: relative;

      i {
        position: absolute;
        right: 0;

        &:nth-child(1) {
          top: calc(50% - 6px);
        }
        &:nth-child(2) {
          top: calc(50% + 6px);

          transform: rotate(180deg);
        }

        &[data-disabled='true'] {
          cursor: default;

          svg {
            color: #ebeef5;

            path {
              stroke: #ebeef5;
            }
          }
        }

        svg {
          width: 10px;
          height: 10px;
        }
      }
    }

    &__discount-type-dropdown-toggle {
      width: 32px;
      height: 20px;

      text-align: center;

      i {
        display: inline-block;

        transform: rotate(180deg);
        transform-origin: center;
        transition: transform 0.25s;

        &[data-active='true'] {
          transform: rotate(0);
        }
      }

      svg {
        width: 8px;
        height: 8px;

        path {
          stroke: ${THEME_COLORS.primary_300};
        }
      }
    }

    &__discount-type-dropdown-menu {
      width: 32px;
    }

    &__discount-type-dropdown-menu-item {
      margin-bottom: 8px;

      font-weight: 600;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__checkout {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    &__checkout-group {
      margin-bottom: 8px;

      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__checkout-name {
      flex: 1;

      text-align: right;
    }

    &__checkout-value {
      width: 120px;
      margin-left: 73px;

      text-align: right;
    }

    &__empty {
      min-height: 300px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`