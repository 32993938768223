import {useEffect, useState} from 'react'
import {useContext} from 'react'
import {EventStatisticContext} from '../provider/_context'
import {eventStatisticActions, eventStatisticInitialState} from '../provider/_init'
import {getData, postData, sendRequestAuth} from 'api/api'
import config from '../../../config'
import useAlert from '../../../hook/useAlert'
import useEventStatistic from './useEventStatistic'
import {convertDateTimeToApiFormat, formatDatetime} from '../../../common/form/datePicker/_functions'
import {useProductAction} from '../../customer/provider/_reducer'
import {removeAcent} from '../../../common/fieldText/_functions'
import {orderActions} from '../../orderDigital/provider/_reducer'

export const useModal = () => {
   const {pageState, pageDispatch} = useContext(EventStatisticContext)
   const [animate, setAnimate] = useState(false)
   const {showAlert} = useAlert()
   const {create} = pageState

   const handleClose = () => {
      if (!pageState?.change_modal) {
         pageDispatch({
            type: eventStatisticActions.UPDATE_FORM_CREATE, payload: eventStatisticInitialState.create
         })
         pageDispatch({type: eventStatisticActions.SET_ID, payload: null})
         setAnimate(true)
         setTimeout(() => {
            pageDispatch({type: eventStatisticActions.OPEN_MODAL, payload: false})
            setAnimate(false)
         }, 300)
      } else {
         pageDispatch({type: eventStatisticActions.MODAL_CONFIRM, payload: true})
      }
   }
   const checkBeforeSubmit = [
      pageState?.valid?.filde_name?.status,
      pageState?.valid?.filde_parent?.status,
   ].includes(true)
   const handleUploadImage = async () => {
      const formData = new FormData()
      formData.append('image', pageState?.create?.image?.file)
      const response = await postData(`${config.API}/event/upload`, formData)
      return response
   }
   const {title} = pageState.create
   const {image} = pageState.create

   const fetchListBanner = async () => {
      pageDispatch({type: eventStatisticActions.TABLE_LOADING_DISPLAY, payload: true})
      const response = await sendRequestAuth('get', `${config.API}/event?keyword=&category_id=&status=&per_page=20&start=`)
      if (response?.data?.success) {
         const event = response?.data
         pageDispatch({
            type: eventStatisticActions.TABLE_UPDATE_DISPLAY_LIST,
            payload: {
               list: event?.data,
               listDefault: event?.data,
               loading: false
            }
         })
         pageDispatch({
            type: eventStatisticActions.TABLE_UPDATE_PAGINATION,
            payload: {
               active: 0,
               amount: event?.meta?.per_page,
               total: Math.ceil(event?.meta?.total / event?.meta?.per_page),
               totalItems: event?.meta?.total,
            }
         })
      }

      pageDispatch({
         type: eventStatisticActions.SET_LOADING,
         payload: false
      })
   }

   const createBanner = async (event) => {
      const res = await postData(`${config.API}/event/create`, event)
      if (res.data.success) {
         setAnimate(true)
         setTimeout(() => {
            pageDispatch({type: eventStatisticActions.OPEN_MODAL, payload: false})
            setAnimate(false)
         }, 300)

         showAlert({
            type: 'success',
            content: 'Thêm mới event thành công'
         })
         pageDispatch({
            type: eventStatisticActions.UPDATE_FORM_CREATE, payload: {
               title: {
                  value: null,
                  validate: null
               },
               image: {
                  link: null,
                  file: null
               }
            }
         })
         pageDispatch({type: eventStatisticActions.SET_ID, payload: null})
         await fetchListBanner()
      } else {
         errorResponse(res?.data?.errors)

         pageDispatch({
            type: eventStatisticActions.SET_LOADING,
            payload: false
         })
         showAlert({
            type: 'success',
            content: res?.data?.message || 'Thêm mới event thất bại'
         })
      }
   }

   const editBanner = async (event) => {
      const res = await postData(`${config.API}/event/update/${pageState?.idBanner}`, event)
      if (res.data.success) {
         setAnimate(true)
         setTimeout(() => {
            pageDispatch({type: eventStatisticActions.OPEN_MODAL, payload: false})
            setAnimate(false)
         }, 300)
         showAlert({
            type: 'success',
            content: 'Chỉnh sửa event thành công'
         })
         pageDispatch({
            type: eventStatisticActions.UPDATE_FORM_CREATE, payload: eventStatisticInitialState?.create
         })
         pageDispatch({type: eventStatisticActions.SET_ID, payload: null})
         await fetchListBanner()
      } else {
         errorResponse(res?.data?.errors)
         showAlert({
            type: 'danger',
            content: res?.data?.message || 'Chỉnh sửa event thất bại'
         })

         pageDispatch({
            type: eventStatisticActions.SET_LOADING,
            payload: false
         })
      }
   }

   const validateForm = () => {

      let isValid = true

      if (!title?.value) {
         pageDispatch({
            type: eventStatisticActions.CREATE_EVENT_VALIDATE_UPDATE,
            payload: {
               title: 'Tiêu đề không được để trống'
            }
         })
         isValid = false
      }

      if (pageState.create?.linkTo?.value === 'category' && !pageState.create.category?.value?.id) {
         pageDispatch({
            type: eventStatisticActions.CREATE_EVENT_VALIDATE_UPDATE,
            payload: {
               category: 'Danh mục không được để trống'
            }
         })
         isValid = false
      }

      if (pageState.create?.linkTo?.value === 'product' && !pageState.create.product?.value?.id) {
         pageDispatch({
            type: eventStatisticActions.CREATE_EVENT_VALIDATE_UPDATE,
            payload: {
               product: 'Sản phẩm không được để trống'
            }
         })
         isValid = false
      }

      return isValid
   }

   const handleAccept = async () => {
      // validate
      if (!validateForm()) return
      if (image?.file) {
         pageDispatch({
            type: eventStatisticActions.SET_LOADING,
            payload: true
         })
         const uploadResponse = handleUploadImage()

         uploadResponse.then(res => {
            const event = {
               'title': title?.value,
               'status': pageState.create.status,
               'link_type': pageState.create?.linkTo?.value || 'none',
               'link_id': pageState.create?.linkTo?.value === 'category' ? pageState.create.category.value?.id : pageState.create?.linkTo?.value === 'product' ? pageState.create.product.value?.id : '',
               'started_at': pageState.create.startedDate.formatValue,
               'ended_at': pageState.create.endedDate.formatValue,
            }
            try {
               if (!!pageState?.idBanner) {
                  editBanner(event)
               } else {
                  createBanner(event)
               }
            } catch (e) {
               console.log(e)
            }
         })
      } else {

         pageDispatch({
            type: eventStatisticActions.SET_LOADING,
            payload: true
         })
         const event = {
            'title': title?.value,
            'image': image?.link,
            'status': pageState.create.status,
            'ordering': pageState.create.ordering,
            'position': pageState.create.position?.value || 'main',
            'link_type': pageState.create?.linkTo?.value || 'none',
            'link_id': pageState.create?.linkTo?.value === 'category' ? pageState.create.category.value?.id : pageState.create?.linkTo?.value === 'product' ? pageState.create.product.value?.id : '',
            'description': pageState.create?.description || '',
            'color': pageState.create?.color || '',
            'started_at': pageState.create.startedDate.formatValue,
            'ended_at': pageState.create.endedDate.formatValue,
         }
         await editBanner(event)
      }

   }
   const errorResponse = response => {
      response?.map(res => {
         switch (res.field) {
            case 'title':
               pageDispatch({
                  type: eventStatisticActions.VALID_NAME_PRODUCT,
                  payload: {status: true, message: res.message}
               })
               break
            case 'parent':
               pageDispatch({
                  type: eventStatisticActions.VALID_PARENT_CATEGORY,
                  payload: {status: true, message: res.message}
               })
               break
            default:
               break
         }
      })
   }
   const handleCancelConfirm = () => {
      pageDispatch({type: eventStatisticActions.MODAL_CONFIRM, payload: false})
   }
   const handleAcceptConfirm = () => {
      pageDispatch({type: eventStatisticActions.MODAL_CONFIRM, payload: false})
      pageDispatch({type: eventStatisticActions.CHANGE_MODAL, payload: false})
      pageDispatch({
         type: eventStatisticActions.UPDATE_FORM_CREATE, payload: {
            title: {
               value: null,
               validate: null
            },
            image: {
               link: null,
               file: null
            }
         }
      })
      pageDispatch({type: eventStatisticActions.SET_ID, payload: null})
      setAnimate(true)
      setTimeout(() => {
         pageDispatch({type: eventStatisticActions.OPEN_MODAL, payload: false})
         setAnimate(false)
      }, 300)
   }

   const onChangeNameBanner = (e) => {
      let {value} = e.target
      pageDispatch({type: eventStatisticActions.CHANGE_MODAL, payload: true})
      pageDispatch({
         type: eventStatisticActions.UPDATE_FORM_CREATE, payload: {
            title: {
               value: value,
               validate: pageState?.create?.title?.validate
            }
         }
      })
   }

   const onChangeOrderingBanner = (e) => {
      let {value} = e.target
      pageDispatch({type: eventStatisticActions.CHANGE_MODAL, payload: true})
      pageDispatch({
         type: eventStatisticActions.UPDATE_FORM_CREATE, payload: {
            ordering: value
         }
      })
   }

   const onBlurNameBanner = (e) => {
      const {value} = e.target
      pageDispatch({
         type: eventStatisticActions.CREATE_EVENT_VALIDATE_UPDATE,
         payload: {
            title: value === '' ? 'Tiêu đề không được để trống' : ''
         }
      })
   }

   const onChangeStartedDate = value => {
      pageDispatch({
         type: eventStatisticActions.UPDATE_FORM_CREATE, payload: {
            startedDate: {
               ...pageState.create.startedDate,
               value: value.value,
               formatValue: convertDateTimeToApiFormat(value.formatValue),
            }
         }
      })
   }

   const onChangeEndedDate = value => {
      pageDispatch({
         type: eventStatisticActions.UPDATE_FORM_CREATE, payload: {
            endedDate: {
               ...pageState.create.endedDate,
               value: value.value,
               formatValue: convertDateTimeToApiFormat(value.formatValue),
            }
         }
      })
   }

   const onChangeStatusBanner = (boo) => {
      pageDispatch({type: eventStatisticActions.CHANGE_MODAL, payload: true})
      pageDispatch({
         type: eventStatisticActions.UPDATE_FORM_CREATE, payload: {
            status: boo
         }
      })
   }

   const onChangeDescription = data => {
      pageDispatch({type: eventStatisticActions.CREATE_EVENT_DESCRIPTION_UPDATE, payload: data.target?.value || ''})
   }

   const onChangeColor = data => {
      pageDispatch({type: eventStatisticActions.CREATE_EVENT_COLOR_UPDATE, payload: data.target?.value || ''})
   }

   // POSITION

   const onChangePosition = data => {
      pageDispatch({type: eventStatisticActions.CREATE_EVENT_POSITION_UPDATE, payload: data})

      if (!!data?.name)
         pageDispatch({
            type: eventStatisticActions.CREATE_EVENT_VALIDATE_UPDATE,
            payload: {
               position: ''
            }
         })
   }
   const onBlurPosition = data => {
      pageDispatch({
         type: eventStatisticActions.UPDATE_FORM_CREATE,
         payload: {
            position: data?.value ? '' : 'Vị trí event không được để trống'
         }
      })
   }

   // LINK TO

   const onChangeLinkTo = data => {

      pageDispatch({
         type: eventStatisticActions.CREATE_EVENT_CATEGORY_UPDATE,
         payload: {
            value: null
         }
      })

      pageDispatch({
         type: eventStatisticActions.CREATE_EVENT_PRODUCT_UPDATE,
         payload: {
            value: null
         }
      })

      pageDispatch({
         type: eventStatisticActions.CREATE_EVENT_VALIDATE_UPDATE,
         payload: {
            product: '',
            category: ''
         }
      })
      pageDispatch({type: eventStatisticActions.CREATE_EVENT_LINK_TO_UPDATE, payload: data})
   }

   // CATEGORY
   const categoryList = create?.category?.list
   const categoryListOrigin = create?.category?.listOrigin
   const categoryValue = create?.category?.value

   const handleCategoryChange = value => {
      pageDispatch({
         type: eventStatisticActions.CREATE_EVENT_CATEGORY_UPDATE,
         payload: {
            value: value
         }
      })
      pageDispatch({
         type: eventStatisticActions.CREATE_EVENT_VALIDATE_UPDATE,
         payload: {
            category: ''
         }
      })
   }

   const handleCategoryKeywordChange = data => {
      const formatDataValue = data?.value
         ? removeAcent(data?.value?.toLowerCase())
         : ''

      const categoryListData = categoryListOrigin.filter(item => {
         const formatNameItem = item?.title
            ? removeAcent(item?.title.toLowerCase())
            : ''
         if (formatNameItem.includes(formatDataValue?.trim())) return true
         return false
      })
      pageDispatch({
         type: eventStatisticActions.CREATE_EVENT_CATEGORY_UPDATE,
         payload: {
            list: categoryListData
         }
      })
   }

   // PRODUCT

   const productList = create?.product?.list
   const productListOrigin = create?.product?.listOrigin
   const productValue = create?.product?.value
   const pageValue = create?.page?.value

   const handleProductChange = value => {
      pageDispatch({
         type: eventStatisticActions.CREATE_EVENT_PRODUCT_UPDATE,
         payload: {
            value: value
         }
      })

      pageDispatch({
         type: eventStatisticActions.CREATE_EVENT_VALIDATE_UPDATE,
         payload: {
            product: ''
         }
      })
   }

   let productKeywordTimeout
   const fetchProduct = async (keyword) => {
      const res = await sendRequestAuth(
         'get',
         `${
            config.API
         }/order/filter/digitals?keyword=${keyword}&category_id=&status=&per_page=999&start=`,
      )
      return res
   }
   const handleProductKeywordChange = async data => {
      clearTimeout(productKeywordTimeout)

      productKeywordTimeout = setTimeout(() => {
         // pageDispatch({ type: orderActions.FILTER_PRODUCT_ENABLE_LOADING })
         const keyword = data?.value || ''
         const response = fetchProduct(data?.value)
         response.then(res => {
            if (res?.status === 200) {
               const productListData = res?.data || []

               pageDispatch({
                  type: eventStatisticActions.CREATE_EVENT_PRODUCT_UPDATE,
                  payload: {
                     isFetchNew: true,
                     keyword: keyword,
                     list: productListData,
                  },
               })
            }
         })
      }, 500)

   }

   return {
      modal: {
         handleAccept,
         handleClose,
         checkBeforeSubmit,
      },
      confirm: {
         handleCancelConfirm,
         handleAcceptConfirm,
      },
      animate,
      field_name: {
         onChangeNameBanner,
         onChangeOrderingBanner,
         onBlurNameBanner,
         onChangeStartedDate,
         onChangeEndedDate,
         onChangeStatusBanner,
         onChangeLinkTo,
         onChangePosition,
         onChangeDescription,
         onChangeColor
      },
      category: {
         value: categoryValue,
         list: categoryList,
         onChange: handleCategoryChange,
         onKeywordChange: handleCategoryKeywordChange
      },
      product: {
         value: productValue,
         list: productList,
         onChange: handleProductChange,
         onKeywordChange: handleProductKeywordChange
      },
      page: {
         value: pageValue,
      },
   }
}