import React, {useEffect} from 'react';
import {TableLayout} from "../../layouts/tableLayout";
import useEventStatistic from "./hooks/useEventStatistic"
import {EventStatisticProvider} from "./provider/index"
import Header from "./components/header";
import Thead from "./components/body/table/thead";
import Tbody from "./components/body/table/tbody";
import { ModalCreateBanner } from './components/modal'
import FilterForm from "./components/filter";
import {Loading} from "../../common/loading";
import {PageOption} from "./components/pageOption";

const EventStatistic = () => {
  const {provider, pagination, fetch} = useEventStatistic()
  const {state, dispatch} = provider
  const {table} = state
  const {pageOption} = state
  useEffect(() => {
    fetch.origin()
  }, [])

  return (
    <EventStatisticProvider value={{pageState: state, pageDispatch: dispatch}}>
      <Header />
      <TableLayout
         header={
            <>
            <FilterForm/>
            <PageOption/>
            </>
         }
        table={{
          tHead: <Thead />,
          tBody: <Tbody />,
        }}
        pagination={pageOption.id === 1 ? {
          ...table.pagination,
          onAmountChange: pagination.onAmountChange,
          onPageChange: pagination.onPageChange,
        } : null}
      />
      <ModalCreateBanner/>
       {!!state?.loading && <Loading/>}
   </EventStatisticProvider>
  )
}

export default EventStatistic