import React, {useReducer} from 'react'
import {bannerManagementReducer} from "../provider/_reducer"
import {bannerManagementInitialState, bannerManagementActions} from  "../provider/_init"
import {sendRequestAuth} from "../../../api/api";
import config from "../../../config";

const useBannerManagement = () => {
  const [state, dispatch] = useReducer(bannerManagementReducer, bannerManagementInitialState)
  const handleOriginFetch = async () => {
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/banner?keyword=&category_id=&status=&per_page=20&start=`),
    ])
    if(response[0]?.data?.success) {
      const banners = response[0]?.data
      dispatch({
        type: bannerManagementActions.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: banners?.data,
          listDefault: banners?.data,
          loading: false
        }
      })
      dispatch({
        type: bannerManagementActions.TABLE_UPDATE_PAGINATION,
        payload: {
          active: 0,
          amount: banners?.meta?.per_page,
          total: Math.ceil(banners?.meta?.total / banners?.meta?.per_page),
          totalItems: banners?.meta?.total,
        }
      })
    }
  }


  const querySearch = {
    per_page: state?.table?.pagination?.amount || 20,
    page: (state?.table?.pagination?.active * state?.table?.pagination?.amount) || 0,
  }

  const handleAmountChange = async n => {
    const qs = {...querySearch, per_page: n, page: 1}
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchBannerByFilter(queryString)

    dispatch({
      type: bannerManagementActions.TABLE_SELECTED_LIST_UPDATE,
      payload: {selected: {list: []}},
    })
  }

  const handlePageChange = async page => {
    const qs = {
      ...querySearch,
      per_page: state?.table?.pagination?.amount,
      page: page + 1
    }
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchBannerByFilter(queryString)
  }

  const fetchBannerByFilter = async (qs) => {
    dispatch({
      type: bannerManagementActions.TABLE_LOADING_DISPLAY,
      payload: true
    })
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/product/banner/banners${qs}`)
    ])
    if (response[0]?.data?.success) {
      dispatch({
        type: bannerManagementActions.TABLE_LOADING_DISPLAY,
        payload: false
      })
      const banners = response[0]?.data
      dispatch({
        type: bannerManagementActions.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: banners?.data,
          listDefault: banners?.data,
          loading: false
        }
      })
      dispatch({
        type: bannerManagementActions.TABLE_UPDATE_PAGINATION,
        payload: {
          active: 0,
          amount: banners?.meta?.per_page,
          total: Math.ceil(banners?.meta?.total / banners?.meta?.per_page),
          totalItems: banners?.meta?.total,
        }
      })
    }
  }
  return {
    fetch: {
      origin: handleOriginFetch
    },
    provider: {
      state,
      dispatch
    },
    pagination: {
      onAmountChange: handleAmountChange,
      onPageChange: handlePageChange,
    }
  }
}

export default useBannerManagement