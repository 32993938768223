import React from 'react';
import {Tr} from "layouts/tableLayout/_tr";
import {Th} from "layouts/tableLayout/_th";
import styled from "styled-components";
import {STORE_UPOS_ICON} from "../../../interface/_icons";
import {useTranslation} from "react-i18next";
import { GLOBAL_ICONS } from 'interface/icon'

const Thead = ({...props}) => {
  const {t} = useTranslation()
  return (
    <StyledThead>
      <Tr {...props} type="tHead" style={{ height: '44px' }}>
        <Th className="store-upos-table__cell">STT</Th>
        <Th className="store-upos-table__cell">Tên banner</Th>
        <Th className="store-upos-table__cell">Vị trí banner</Th>
        <Th className="store-upos-table__cell">Hình ảnh</Th>
        <Th className="store-upos-table__cell">Thời gian hiển thị</Th>
        <Th className="store-upos-table__cell">Trạng thái</Th>
        <Th className="store-upos-table__cell">Thứ tự ưu tiên</Th>
        <Th className="store-upos-table__cell" style={{display: 'flex'}}>
          {GLOBAL_ICONS.setting}
        </Th>
      </Tr>
    </StyledThead>
  )
}

export default Thead


export const StyledThead = styled.div`
  .store-upos-table {
    &__cell {
      margin: 0 8px;
    }
    &__cell:nth-child(1) {
      width: 2%;
    }
    &__cell:nth-child(2) {
      flex: 1;
    }
    &__cell:nth-child(3) {
      width: 14%;
      text-align: center;
    }
    &__cell:nth-child(4) {
      width: 18%;
      text-align: center;
    }
    &__cell:nth-child(5) {
      width: 14%;
      text-align: center;
    }
    &__cell:nth-child(6) {
      width: 14%;
      text-align: center;
    }
    &__cell:nth-child(7) {
      width: 10%;
    }
    &__cell:nth-child(8) {
      width: 80px;
      justify-content: end;
    }
  }
`
