import React from 'react';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button} from "../../../../../common/button";
import {PATH} from "../../../../../const/path";
import {useCreatePrincipalForm} from "../hook/useCreatePrincipalForm";

const ActionFormBrnList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
    const {canConfirmForm, actionList} = useCreatePrincipalForm()
  return (
    <div style={{textAlign: 'right', height: 60}}>
      <Button appearance="ghost"
              onClick={() => navigate(PATH.PRINCIPAL)}
              style={{width: 74}}
      >
          Hủy
      </Button>
      <Button
        onClick={() => actionList.onSave()}
        style={{marginLeft: 12, padding: '0 16px', width: 108}}
        disabled={canConfirmForm.length >= 1}
      >
          Lưu
      </Button>
    </div>
  )
}

export default ActionFormBrnList;