import React, {useContext} from 'react'
import {Skeleton} from '@mui/material'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {PrincipalContext} from "../../provider/_context";
import {Tr} from "../../../../layouts/tableLayout/_tr";
import {Text} from "../../../../common/text";
import {Td} from "../../../../layouts/tableLayout/_td";
import {Button} from "../../../../common/button";
import {STORE_UPOS_ICON} from "../../../products/book/interface/_icons";
import {formatDatetime} from "../../../../common/form/datePicker/_functions";
import {PRINCIPAL_GROUP_ICON} from "../../interface/icon";
import {usePrincipalTable} from "../../hook/usePrincipalTable";
import {Tooltip as TooltipV2} from "../../../../common/tooltipv2";
import {Tooltip} from "../../../../common/tooltip";
import ReactImageFallback from "react-image-fallback";

const Tbody = () => {
   const {t} = useTranslation()
   const {pageState} = useContext(PrincipalContext)
   const {table} = pageState

   const displayList = table?.display?.list
   const displayListDefault = table?.display?.listDefault
   const displayLoading = table.display.loading
   const paginationAmount = table.pagination.amount
   const paginationTotalItems = table.pagination.totalItems
   const paginationActive = table.pagination.active
   return (
      <StyledTBody>
         {displayLoading ? (
            Array.from(Array(paginationAmount), (e, i) => (
               <PrincipalPlaceholder key={i}/>
            ))
         ) : paginationTotalItems > 0 ? (
            displayList.map((item, key) => <PrincipalTr t={t} key={item.id}
                                                        stt={key + (+paginationAmount * +paginationActive)}
                                                        data={item}/>)
         ) : (
            <PrincipalEmpty t={t} list={displayListDefault}/>
         )}
      </StyledTBody>
   )
}

export default Tbody

const PrincipalPlaceholder = ({...props}) => {
   return (
      <Tr {...props} className="principal-table__row">
         {Array.from(Array(7), (e, i) => (
            <Td key={i} className="principal-table__cell" data-type="td">
               <Skeleton
                  sx={{
                     width: '100%',
                     height: 33,
                     background:
                        'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
                  }}
               />
            </Td>
         ))}
      </Tr>
   )
}

const PrincipalEmpty = ({list, t, ...props}) => {
   return (
      <StyledPrincipalEmpty {...props}>
         <img
            className="principal-empty__banner"
            src="/img/order/order-empty.png"
            alt="empty"
         />
         <Text as="b" color="#7C88A6" style={{marginBottom: 16}}>
            {!!!list ? t('Bạn chưa có danh sách hiệu trưởng nào') : t('no_matching_data')}
         </Text>
         {!!!list && (
            <Button href={'/upos/shop/create'} icon={STORE_UPOS_ICON.plus}>{t('add_new_shop')}</Button>
         )}
      </StyledPrincipalEmpty>
   )
}

const PrincipalTr = ({data, t, stt, ...props}) => {
   const fullName = `${data?.last_name || ''} ${data?.name || ''}`
   const {methods} = usePrincipalTable()
   return (
      <>
         <Tr
            {...props}
            className="principal-table__row"
         >
            <Td className="principal-table__cell" data-type="td">
               {+stt + 1}
            </Td>
            <Td className="principal-table__cell" data-type="td">
               <ReactImageFallback
                  src={data?.avatar || '/img/useravatar.jpg'}
                  // alt={data?.shopname}
                  fallbackImage='/img/useravatar.jpg'
                  style={{width: 36, height: 36, borderRadius: 50, marginRight: 8}}
               />
               <div>
                  <TooltipV2 title={data?.username} baseOn={'height'} className='principal-table__cell-tooltip'>
                     <Text>{data?.username || '---'}</Text>
                  </TooltipV2>
                  <TooltipV2 title={fullName} baseOn={'height'} className='principal-table__cell-tooltip'>
                     <Text>{fullName || '---'}</Text>
                  </TooltipV2>
               </div>
            </Td>
            <Td className="principal-table__cell" data-type="td">
               <TooltipV2 title={data?.email} baseOn={'height'} className='principal-table__cell-tooltip'>
                  <Text>{data?.email || '---'}</Text>
               </TooltipV2>
            </Td>
            <Td className="principal-table__cell" data-type="td">
               <Text>{data?.commission_rate || '--'}</Text>
            </Td>
            <Td className="principal-table__cell" data-type="td">
               <Text
                  href={`/principal/${data?.id}?tab=teachers`}
                  as={'a'}
                  color={'#0F62F9'}
               >
                  {data?.teachers_count || '---'}
               </Text>
            </Td>
            <Td className="principal-table__cell" data-type="td">
               <Text
                  href={`/principal/${data?.id}?tab=products`}
                  as={'a'}
                  color={'#0F62F9'}
               >
                  {data?.total_products || 0}
               </Text>
            </Td>
            <Td className="principal-table__cell" data-type="td">
               {formatDatetime(data?.created_at)}
            </Td>
            <Td className="principal-table__cell" data-type="td">
               {data?.status !== 'inactive'
                  ? <Text className={'principal-table__cell--success'}>{data?.status_name}</Text>
                  : <Text className={'principal-table__cell--danger'}>{data?.status_name}</Text>
               }
            </Td>
            <Td
               className="principal-table__cell"
               data-menu="true"
               data-type="td"
               onClick={e => e.stopPropagation()}
            >
               <Tooltip title={'Chỉnh sửa'}>
                  <Text
                     className="principal-table__edit"
                     // data-active={row.shouldOpenDetail}
                     href={`/principal/${data?.id}`}
                     as={'a'}
                  >
                     {PRINCIPAL_GROUP_ICON.edit}
                  </Text>
               </Tooltip>
               <Tooltip title={data?.status !== 'inactive' ? 'Khóa hiệu trưởng' : ' Mở khóa hiệu trưởng'}>
                  <Text
                     className="principal-table__delete"
                     onClick={() => methods.onChangeStatus(data?.id, data?.status)}
                  >
                     {data?.status !== 'inactive'
                        ? PRINCIPAL_GROUP_ICON.lock
                        : PRINCIPAL_GROUP_ICON.unlock
                     }
                  </Text>
               </Tooltip>
            </Td>
         </Tr>
      </>
   )
}

export const StyledTBody = styled.div`
  .principal-table {
    &__loading {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
  
      width: 100vw;
      height: 100vh;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      background: rgba(0, 0, 0, 0.25);
  
      img {
        width: 80px;
        height: 80px;
      }
    }
  
    &__row {
      &:hover {
        .principal-table__delete {
          display: block;
        }
        .principal-table__edit {
          display: block;
        }
      }
    }
    &__cell-tooltip{
      display: -webkit-box;
      height: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      word-break: break-word;
    }
    &__cell {
      cursor: pointer;
      margin: 0 8px;
      display: flex;
      justify-content: left;
      align-items: center;
      &[data-menu='true'] {
        position: relative;
      }
  
      &[data-type='th'] {
        &[data-selected='true'] {
          display: flex;
          flex: 1;
          align-items: center;
          height: 44px;
        }
      }
  
      &--success {
        color: #0B74E5 !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #ECF4FE;
        border-radius: 4px;
      }
      &--danger {
        color: #FF424E !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #FFEBEC;
        border-radius: 4px;
      }
      &:nth-child(1) {
        width: 50px;
        justify-content: center;
      }
      &:nth-child(2) {
        width: 23%;
      }
      &:nth-child(3) {
        width: 18%;
      }
  
      &:nth-child(4) {
        width: 15%;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
  
      &:nth-child(5) {
        width: 10%;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
  
      &:nth-child(6) {
        width: 10%;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
      &:nth-child(7) {
        width: 10%;
      }
      &:nth-child(8) {
        width: 15%;
        justify-content: center;
      }
      &:nth-child(9) {
        width: 100px;
        padding-left: 20px;
      }
  
    }
  
    &__delete {
      width: 20px;
      height: 20px !important;
      background: transparent;
      border: none;
      border-radius: 12px !important;
  
      font-size: 12px !important;
      line-height: 24px !important;
  
      cursor: pointer;
  
    }
  
    &__edit {
      width: 20px;
      height: 20px !important;
      margin-right: 16px;
      background: transparent;
      border: none;
      border-radius: 12px !important;
  
      font-size: 12px !important;
      line-height: 24px !important;
  
      cursor: pointer;
  
      &[data-active='true'] {
        display: block !important;
      }
    }
  
    &__selected-action-dropdown {
      position: relative;
  
      margin-left: 12px;
    }
  
    &__selected-action-toggle {
      width: 88px;
      padding: 0 !important;
  
      border-radius: 14px !important;
  
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  
    &__selected-action-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 11;
  
      width: 100vw;
      height: 100vh;
    }
  
    &__selected-action-menu {
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      z-index: 12;
  
      width: 150px;
      padding: 8px;
  
      background: #ffffff;
      border-radius: 6px;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    }
  
    &__selected-action-menu-item {
      padding: 8px;
  
      color: #191d32;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
  
      transition: color 0.25s;
  
      cursor: pointer;
  
      &:hover {
        color: #3264FF;
      }
    }
  
    &__tooltipV2 {
      width: 100%;
      padding: 0;
      overflow: hidden;
      position: relative;
      display: inline-block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
}

.tab-detail-order {
  &__link-hover {
    color: #1A94FF;

    &:hover {
      color: #1373DB;
    }
  }
}
`
export const StyledPrincipalEmpty = styled.div`
  min-height: calc(100vh - 430px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;

  .principal-empty__banner {
    width: 133px;
    height: 133px;
    margin-bottom: 16px;

    object-fit: contain;
    object-position: center;
  }
`
