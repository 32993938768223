import React, { memo, useEffect } from 'react'
import Header from './components/header'
import useUserRole from './hooks/useUserRole'
import { UserRoleProvider } from './provider/index'
import TableHeader from './components/table/tableHeader'
import { TableLayout } from '../../layouts/tableLayout'
import TableBody from './components/table/tableBody'
import styled from 'styled-components'
import { ConfirmModal } from '../../layouts/rightSightPopup/confirm'
import { useTranslation } from 'react-i18next'
import useGlobalContext from '../../containerContext/storeContext'
import NotPermission from '../permision/notPermission'
import { UserManagementProvider } from '../userManagement/provider'

export const UserRole = memo(() => {
  const { provider, fetch, handlePaginationAmountChange, handlePaginationPageChange, functions } = useUserRole()
  const { state, dispatch } = provider
  const { t } = useTranslation()
  useEffect(() => {
    fetch.userRoleList()
  }, [])

  const [globalState, globalDispatch] = useGlobalContext()
  const { user } = globalState
  const userType = user?.group_ids

  return (
    <UserRoleProvider value={{ pageState: state, pageDispatch: dispatch }}>
      {
        +userType !== 1
          ? <NotPermission/>
          :
          <StyledUserRole>
            <Header/>
            <TableLayout
              table={{
                tHead: <TableHeader/>,
                tBody: <TableBody/>,
              }}
            />
            {state.openModalConfirmDelete && (
              <>
                <ConfirmModal
                  openModal={true}
                  header={<><p style={{
                    fontWeight: '600',
                    fontSize: '20px',
                    marginBottom: '24px'
                  }}>{t('role_page_remove')}</p></>}
                  body={<><p style={{
                    fontWeight: '400',
                    fontSize: '14px',
                    marginBottom: '24px'
                  }}>{t('role_page_remove_content')}</p></>}
                  footer={
                    {
                      cancel: {
                        title: t('general_cancel')
                      },
                      acceptance: {
                        title: t('delete')
                      },
                    }
                  }
                  footerProps={{
                    className: 'create-user-role__modal-confirm-delete-list-btn'
                  }}
                  // stylePopup={'create-user-role__modal-confirm-delete'}
                  closeModal={() => functions.closeModalRoleConfirmDelete()}
                  acceptance={() => functions.acceptanceModalRoleConfirmDelete()}
                />
              </>
            )}
          </StyledUserRole>
      }
    </UserRoleProvider>
  )
})

const StyledUserRole = styled.div`
`