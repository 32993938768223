import useGlobalContext from "../../containerContext/storeContext";
import {publicRoutes} from "../../routes";
import {PATH} from "../../const/path";

export const LinkPage = (route, state) => {
  const notPermission = publicRoutes.find(item => item.path === PATH.NOT_PERMISSION).component
  const { user } = state
  const { permission } = user
  const accountPermission = permission?.account
    if(+user?.is_manage === 1) return route.component
    const subBranch = accountPermission?.filter(item => item?.feature_code === route?.prefix)
    if(subBranch?.length > 0) {
      return !!subBranch?.find(it => +it.actions[route?.action] === 1) ? route.component : notPermission
    }
    // return route?.prefix === 'publish' ? route.component : notPermission
    return route?.prefix === 'publish' ? route.component : route.component
}

export const checkPermission = () => {
  const [state, ] = useGlobalContext()
  const {  user } = state
  const { permission } = user

  if(+user?.is_manage === 1) return true

  //tiếp tục check permission

  return true
}