import React, {useCallback, useContext, useState} from 'react'
import {STORE_UPOS_ICON} from '../../../interface/_icons'
import {EventStatisticContext} from '../../../provider/_context'
import {Text} from 'common/text'
import {Tr} from 'layouts/tableLayout/_tr'
import {Td} from 'layouts/tableLayout/_td'
import {debounce, Skeleton} from '@mui/material'
import styled from 'styled-components'
import {Button} from 'common/button'
import {useTranslation} from 'react-i18next'
import {DeleteConfirmModal} from '../../deleteConfirmModal'
import {fDateTimeCustom, fDateTimeDMY} from '../../../../../util/formatTime'
import {StatisticCustomer} from "../../chart/statisticCustomer";

const Tbody = () => {
   const {t} = useTranslation()
   const {pageState} = useContext(EventStatisticContext)
   const {table} = pageState
   const {pageOption} = pageState
   const displayList = table?.display?.list
   const displayListDefault = table?.display?.listDefault
   const displayLoading = table.display.loading
   const paginationAmount = table.pagination.amount

   return (
      pageOption.id === 1 ?
         <StyledTBody>
            {displayLoading ? (
               Array.from(Array(paginationAmount), (e, i) => (
                  <EventStatisticPlaceholder key={i}/>
               ))
            ) : displayList.length > 0 ? (
               displayList.map((item, key) => <EventStatisticTr t={t} key={item.id} stt={key} data={item}/>)
            ) : (
               <EventStatisticEmpty t={t} list={displayListDefault}/>
            )}
         </StyledTBody>
         : <StatisticCustomer/>
   )
}

export default Tbody

const EventStatisticPlaceholder = ({...props}) => {
   return (
      <Tr {...props} className="store-upos-table__row">
         {Array.from(Array(4), (e, i) => (
            <Td key={i} className="store-upos-table__cell" data-type="td">
               <Skeleton
                  sx={{
                     width: '100%',
                     height: 33,
                     background:
                        'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
                  }}
               />
            </Td>
         ))}
      </Tr>
   )
}

const EventStatisticEmpty = ({list, t, ...props}) => {
   return (
      <StyledEventStatisticEmpty {...props}>
         <img
            className="store-upos-empty__event"
            src="/img/order/order-empty.png"
            alt="empty"
         />
         <Text as="b" color="#7C88A6" style={{marginBottom: 16}}>
            {!!!list ? t('Bạn chưa có sản phẩm sách nào') : t('no_matching_data')}
         </Text>
         {!!!list && (
            <Button href={'/upos/shop/create'} icon={STORE_UPOS_ICON.plus}>{t('add_new_shop')}</Button>
         )}
      </StyledEventStatisticEmpty>
   )
}

const EventStatisticTr = ({data, t, ...props}) => {
   return (
      <>
         <DeleteConfirmModal/>
         <Tr
            {...props}
            className="store-upos-table__row"
         >
            <Td className="store-upos-table__cell" style={{display: 'block'}} data-type="td">
               <Text as={'p'}
                     fontWeight={600}
               >{data?.title || '---'}</Text>
               <Text as={'p'}
                     fontWeight={400}
               >{fDateTimeDMY(data?.started_at)} - {fDateTimeDMY(data?.ended_at)}</Text>
            </Td>
            <Td className="store-upos-table__cell" data-type="td">
               <Text as={'a'}>{data?.name}</Text>
            </Td>
            <Td className="store-upos-table__cell" data-type="td">
               {!!data?.phone ? data.phone : '---'}
            </Td>
            <Td className="store-upos-table__cell" data-type="td">
               {!!data?.email ? data.email : '---'}
            </Td>
            <Td className="store-upos-table__cell" data-type="td" style={{display: 'block'}}>
               <Text as={'p'}>HT: {!!data?.principal_name ? <Text as={'a'} target={'_blank'} color={'#3264FF'}
                                                                  href={`/principal/${data?.principal_id}`}>{data.principal_name}</Text> : '---'}</Text>
               <Text as={'p'}>GV: {!!data?.teacher_name ? <Text as={'a'} target={'_blank'} color={'#3264FF'}
                                                                href={`/principal/${data?.principal_id}?teacherId=${data?.teacher_id}`}>{data.teacher_name}</Text> : '---'}</Text>
            </Td>
            <Td
               className="store-upos-table__cell"
               style={{display: 'block'}}
            >
               <Text as={'p'} style={{width: '100%'}}>{fDateTimeCustom(data?.created_at, {format: 'HH:mm:ss'})}</Text>
               <Text as={'p'} style={{width: '100%'}}>{fDateTimeDMY(data?.created_at)}</Text>
            </Td>
         </Tr>
      </>
   )
}

export const StyledTBody = styled.div`
  .store-upos-table {
    &__loading {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
  
      width: 100vw;
      height: 100vh;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      background: rgba(0, 0, 0, 0.25);
  
      img {
        width: 80px;
        height: 80px;
      }
    }
  
    &__row {
      &:hover {
        .store-upos-table__delete {
          display: block;
        }
        .store-upos-table__edit {
          display: block;
        }
      }
    }
  
    &__cell {
      cursor: pointer;
      margin: 0 8px;
      display: flex;
      justify-content: left;
      align-items: center;
      &[data-menu='true'] {
        position: relative;
      }
  
      &[data-type='th'] {
        &[data-selected='true'] {
          display: flex;
          flex: 1;
          align-items: center;
          height: 44px;
        }
      }
  
      &--success {
        color: #0B74E5 !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #ECF4FE;
        border-radius: 4px;
      }
      &--danger {
        color: #FF424E !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #FFEBEC;
        border-radius: 4px;
      }
        &:nth-child(1) {
          flex: 1;
        }
        &:nth-child(2) {
          width: 14%;
        }
        &:nth-child(3) {
          width: 14%;
        }
        &:nth-child(4) {
          width: 18%;
        }
        &:nth-child(5) {
          width: 14%;
        }
        &:nth-child(6) {
          width: 13%;
          justify-content: end;
          text-align: right;
        }
    }
  
    &__delete {
      position: absolute;
      top: 38%;
      right: 8px;
  
      width: 20px;
      height: 20px !important;
  
      background: transparent;
      border: none;
      border-radius: 12px !important;
  
      font-size: 12px !important;
      line-height: 24px !important;
  
      cursor: pointer;
  
    }
  
    &__edit {
      position: absolute;
      top: 40%;
      right: 42px;
  
      width: 20px;
      height: 20px !important;
  
      background: transparent;
      border: none;
      border-radius: 12px !important;
  
      font-size: 12px !important;
      line-height: 24px !important;
  
      cursor: pointer;
  
      &[data-active='true'] {
        display: block !important;
      }
    }
  
    &__tooltipV2 {
      width: 100%;
      padding: 0;
      overflow: hidden;
      position: relative;
      display: inline-block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
}
`
export const StyledEventStatisticEmpty = styled.div`
  min-height: calc(100vh - 430px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;

  .store-upos-empty__event {
    width: 133px;
    height: 133px;
    margin-bottom: 16px;

    object-fit: contain;
    object-position: center;
  }
`
