import React from 'react'
import useBookManagementFilter from '../../../hooks/useBookManagementFilter'
import { CategoryInput } from '../../../../../../common/form/input/_categoryInput'

const Search = () => {
  const { search } = useBookManagementFilter()
  return (
    <CategoryInput
      className={'invoice-filter-form__input-wide'}
      categoryList={[]}
      categoryValue={{ name: 'Sản phẩm', value: '' }}
      categoryWidth={92}
      placeholder='Tìm kiếm theo tên sách'
      value={search?.value || ''}
      onChange={search.onChange}
      style={{ position: 'relative' }}
    />
  )
}

export default Search