import React, { useContext } from 'react'
import { ProductContext } from '../provider/~context'
import { productActions } from '../provider/~action'
import { fNumber } from '../../../util/formatNumber'
import { useTranslation } from 'react-i18next'
import { DISPLAY_NAME_MENU } from '../../../const/display_name_menu'

const useCreateInfoPrice = () => {
  const { t } = useTranslation()
  const { pageState, pageDispatch } = useContext(ProductContext)
  const formInfoPrice = pageState?.formCreate?.price
  const formInfoInventory = pageState?.formCreate?.inventory
  const formInfoPriceValidate = formInfoPrice?.validate
  const formCreate = pageState?.formCreate

  const handleChangeRetail = value => {
    const currentValue = fNumber(value.toString().replace(/[^0-9]/g, ''))
    pageDispatch({ type: productActions.FORM_CREATE_INIT_PRICE_RETAIL, payload: currentValue })
    if (!!currentValue) pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_RETAIL,
      payload: { status: false, message: '' }
    })
  }

  const handleChangeDiscount = value => {
    const currentValue = fNumber(value.toString().replace(/[^0-9]/g, ''))
    pageDispatch({ type: productActions.FORM_CREATE_INIT_PRICE_DISCOUNT, payload: currentValue })
    if (!!currentValue) pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_DISCOUNT,
      payload: { status: false, message: '' }
    })

  }

  const handleChangeCost = value => {
    const currentValue = fNumber(value.toString().replace(/[^0-9]/g, ''))
    pageDispatch({ type: productActions.FORM_CREATE_INIT_PRICE_COST, payload: currentValue })
    pageState?.formCreate?.version?.valueVersion?.map(item => {
      item.cost_price = currentValue
      return item
    })
    if (!!currentValue) pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_COST,
      payload: { status: false, message: '' }
    })
  }

  const handleBlurRetail = _ => {
    pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_RETAIL,
      payload: {
        status: +formInfoPrice?.retail === 0,
        message: +formInfoPrice?.retail === 0 ? t(DISPLAY_NAME_MENU.VALIDATE.INVALID.RETAIL_PRICE) : ''
      }
    })
  }
  const onBlurDiscount = _ => {
    pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_DISCOUNT,
      payload: {
        status: (formInfoPrice?.discount == 0 || !formInfoPrice?.discount) && !!dateTimeValue,
        message: (formInfoPrice?.discount == 0 || !formInfoPrice?.discount) && !!dateTimeValue ? 'Hãy nhập giá khuyến mãi' : ''
      }
    })
    pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_DISCOUNT_DATETIME,
      payload: {
        status: formInfoPrice?.discount != 0 && !dateTimeValue && !!formInfoPrice?.discount,
        message: (formInfoPrice?.discount != 0 && !dateTimeValue && !!formInfoPrice?.discount )? 'Hãy chọn thời gian khuyễn mãi' : ''
      }
    })
  }

  const handleValidateDiscount = _ => {
    pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_DISCOUNT,
      payload: {
        status: +formInfoPrice?.discount > +formInfoPrice?.retail,
        message: +formInfoPrice?.discount > +formInfoPrice?.retail ? 'Giá khuyễn mãi không được lớn hơn giá bán' : ''
      }
    })
  }

  const handleBlurLastEntry = _ => {
    pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_LAST_ENTRY,
      payload: {
        status: +formInfoPrice?.lastEntry === 0,
        message: +formInfoPrice?.lastEntry === 0 ? t(DISPLAY_NAME_MENU.VALIDATE.INVALID.LAST_ENTRY_PRICE) : ''
      }
    })
  }

  const handleBlurCost = _ => {
    pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_COST,
      payload: {
        status: +formInfoPrice?.cost === 0,
        message: +formInfoPrice?.cost === 0 ? t(DISPLAY_NAME_MENU.VALIDATE.INVALID.COST_PRICE) : ''
      }
    })
  }
  const dateTimeActiveValue = formInfoPrice?.dateTime?.activeValue
  const dateTimeValue = formInfoPrice?.dateTime?.value
  const dateTimeTrigger = formInfoPrice?.dateTime?.trigger

  const handleDateTimeChange = data => {
    pageDispatch({
      type: productActions.FORM_CREATE_DATE_TIME_UPDATE,
      payload: {
        end: data.value[1],
        start: data.value[0],
        type: data.category,
        value: data.formatValue,
      },
    })
    pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_DISCOUNT,
      payload: {
        status: (+formInfoPrice?.discount === 0 || !formInfoPrice?.discount) && !!data.formatValue,
        message: (+formInfoPrice?.discount === 0 || !formInfoPrice?.discount) && !!data.formatValue ? 'Hãy nhập giá khuyến mãi' : ''
      }
    })

    pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_DISCOUNT_DATETIME,
      payload: {
        status: !data.formatValue && formInfoPrice?.discount != 0 && !!formInfoPrice?.discount,
        message: (!data.formatValue && formInfoPrice?.discount != 0  && !!formInfoPrice?.discount) ? 'Hãy chọn thời gian khuyễn mãi' : ''
      }
    })
  }

  return {
    value: {
      formInfoInventory,
      formInfoPrice,
      formCreate,
    },
    dateTime: {
      activeValue: dateTimeActiveValue,
      triggerDefault: dateTimeTrigger,
      value: dateTimeValue,
      start: formInfoPrice?.dateTime?.start,
      end: formInfoPrice?.dateTime?.end,
      onChange: handleDateTimeChange,
    },
    functions: {
      onChangeRetail: handleChangeRetail,
      onChangeDiscount: handleChangeDiscount,
      onChangeCost: handleChangeCost,
    },
    validate: {
      formInfoPriceValidate,
      onBlurRetail: handleBlurRetail,
      onBlurDiscount: onBlurDiscount,
      onBlurLastEntry: handleBlurLastEntry,
      onBlurCost: handleBlurCost,
    }
  }
}

export default useCreateInfoPrice