import { useCallback, useContext } from 'react'
import { DateRangePicker } from 'rsuite'
import { useSearchParams } from 'react-router-dom'
import { DigitalManagementContext } from '../provider/_context'
import { digitalManagementActions } from '../provider/_init'
import { getDateFromNow } from '../interface/_util'
import { removeAcent } from '../../../../common/fieldText/_functions'
import { sendRequestAuth } from '../../../../api/api'
import config from '../../../../config'
import { STORE_UPOS_FILTER_FORM_DATE_TIME_SORT_TYPES } from '../interface/_contants'

const useDigitalManagementFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { state, dispatch } = useContext(DigitalManagementContext)
  const { filter } = state

  // ===== ===== ===== ===== =====
  // SEARCH
  // ===== ===== ===== ===== =====
  const searchActiveValue = filter.search.activeValue
  const searchValue = filter.search.value
  const querySearch = searchParams.get('search') || ''

  const handleSearchChange = value => {
    dispatch({
      type: digitalManagementActions.FILTER_SEARCH_UPDATE,
      payload: value,
    })
  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // DATE TIME
  // ===== ===== ===== ===== =====
  const { afterToday } = DateRangePicker
  const dateTimeActiveValue = filter.dateTime.activeValue
  const dateTimeDefaultValue = [
    querySearch ? '' : getDateFromNow(-7, { type: 'start' }),
    querySearch ? '' : getDateFromNow(0, { type: 'end' }),
  ]
  const dateTimeType = filter.dateTime.type
  const dateTimeValue = filter.dateTime.value
  const dateTimeTrigger = filter.dateTime.trigger

  const handleDateTimeChange = data =>
    dispatch({
      type: digitalManagementActions.FILTER_DATE_TIME_UPDATE,
      payload: {
        end: data.value[1],
        start: data.value[0],
        type: data.category,
        value: data.formatValue,
      },
    })

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // CATEGORY FILTER
  // ===== ===== ===== ===== =====
  const categoryList = filter?.category?.list
  const categoryListOrigin = filter?.category?.listOrigin
  const categoryValue = filter?.category?.value
  const categoryActiveValue = filter?.category?.activeValue

  const handleCategoryChange = value => {
    dispatch({
      type: digitalManagementActions.FILTER_CATEGORY_VALUE_UPDATE,
      payload: value
    })
  }

  const handleCategoryKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const categoryListData = categoryListOrigin.filter(item => {
      const formatNameItem = item?.title
        ? removeAcent(item?.title.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue?.trim())) return true
      return false
    })
    dispatch({
      type: digitalManagementActions.FILTER_CATEGORY_LIST_UPDATE,
      payload: categoryListData
    })
  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // SOURCE FILTER
  // ===== ===== ===== ===== =====
  const sourceTab = filter?.source?.tab
  const sourceList = filter?.source?.list
  const sourceListOrigin = filter?.source?.listOrigin
  const sourceValue = filter?.source?.value
  const sourceActiveValue = filter?.source?.activeValue

  const handleSourceChange = value => {
    const srcValue = value
      ? !!sourceValue?.find(item => item.id === value.id)
        ? sourceValue.filter(item => item.id !== value.id)
        : [...sourceValue, value]
      : []
    dispatch({
      type: digitalManagementActions.FILTER_SOURCE_VALUE_UPDATE,
      payload: srcValue
    })
  }

  const handleSourceKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const sourceListData = sourceListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item?.name?.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue?.trim())) return true
      return false
    })
    dispatch({
      type: digitalManagementActions.FILTER_SOURCE_LIST_UPDATE,
      payload: sourceListData
    })
  }

  const handleSourceTabChange = tab => {
    dispatch({
      type: digitalManagementActions.FILTER_SOURCE_TAB_UPDATE,
      payload: {
        list: tab === 'checked' ? sourceValue : sourceListOrigin,
        tab,
      },
    })
  }

  const handleSourceInputReset = _ => {
    dispatch({ type: digitalManagementActions.FILTER_SOURCE_VALUE_UPDATE, payload: [] })
  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== =====
  // STATUS FILTER
  // ===== ===== ===== ===== =====
  const statusValue = filter?.status?.value
  const statusActiveValue = filter?.status?.activeValue

  const handleStatusChange = value => {
    dispatch({
      type: digitalManagementActions.FILTER_STATUS_VALUE_UPDATE,
      payload: value
    })
  }

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // SUBMIT FILTER
  // ===== ===== ===== ===== =====
  // Badge
  const otherFilterBadge = [
    dateTimeActiveValue?.value?.length !== 0,
    !!categoryActiveValue,
    sourceActiveValue?.length !== 0,
    !!statusActiveValue,
  ].filter(item => item === true).length

  const shouldShowResetAll = [
    dateTimeActiveValue?.value?.length !== 0,
    !!categoryActiveValue,
    sourceActiveValue?.length !== 0,
    !!statusActiveValue,
    !!searchActiveValue
  ].includes(true)

  const canSubmitOtherFilter = [
    dateTimeActiveValue.value !== dateTimeValue || JSON.stringify(dateTimeActiveValue.type) !== JSON.stringify(dateTimeType),
    JSON.stringify(categoryValue) !== JSON.stringify(categoryActiveValue),
    JSON.stringify(sourceValue) !== JSON.stringify(sourceActiveValue),
    JSON.stringify(searchValue) !== JSON.stringify(searchActiveValue),
    JSON.stringify(statusValue) !== JSON.stringify(statusActiveValue),
  ].includes(true)
  const queries = {
    category_id: categoryValue?.id || '',
    status: statusValue?.value || '',
    keyword: searchValue || '',
    page: 1
  }

  const handleActiveValueDatetime = date => dispatch({
    type: digitalManagementActions.FILTER_DATE_TIME_ACTIVE_UPDATE,
    payload: date
  })

  const handleCategoryActiveValue = category => dispatch({
    type: digitalManagementActions.FILTER_CATEGORY_ACTIVE_VALUE_UPDATE,
    payload: category
  })

  const handleStatusActiveValue = status => dispatch({
    type: digitalManagementActions.FILTER_STATUS_ACTIVE_VALUE_UPDATE,
    payload: status
  })

  const handleSearchActiveValue = search => dispatch({
    type: digitalManagementActions.FILTER_SEARCH_ACTIVE_VALUE_UPDATE,
    payload: search
  })
  const handleActiveValueAll = _ => {
    handleCategoryActiveValue(!!queries?.category_id ? categoryValue : '')
    handleStatusActiveValue(!!queries?.status ? statusValue : '')
    handleSearchActiveValue(!!queries?.keyword ? searchValue : '')
  }

  const fetchBookByFilter = async (qs, opt) => {
    dispatch({
      type: digitalManagementActions.TABLE_LOADING_DISPLAY,
      payload: true
    })
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/product/digital/digitals${queryString}`),
    ])
    if (response[0]?.data?.success) {
      dispatch({
        type: digitalManagementActions.TABLE_LOADING_DISPLAY,
        payload: false
      })
      const digitals = response[0]?.data
      dispatch({
        type: digitalManagementActions.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: digitals?.data,
          listDefault: digitals?.data,
          loading: false
        }
      })
      dispatch({
        type: digitalManagementActions.TABLE_UPDATE_PAGINATION,
        payload: {
          active: 0,
          amount: digitals?.meta?.per_page,
          total: Math.ceil(digitals?.meta?.total / digitals?.meta?.per_page),
          totalItems: digitals?.meta?.total,
        }
      })
    }
  }
  const applyOtherFilter = async () => {
    handleActiveValueAll()
    dispatch({
      type: digitalManagementActions.TABLE_LOADING_DISPLAY,
      payload: true
    })
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(queries)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/product/digital/digitals${queryString}`),
    ])
    if (response[0]?.data?.success) {
      dispatch({
        type: digitalManagementActions.TABLE_LOADING_DISPLAY,
        payload: false
      })
      const digitals = response[0]?.data
      dispatch({
        type: digitalManagementActions.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: digitals?.data,
          listDefault: digitals?.data,
          loading: false
        }
      })
      dispatch({
        type: digitalManagementActions.TABLE_UPDATE_PAGINATION,
        payload: {
          active: 0,
          amount: digitals?.meta?.per_page,
          total: Math.ceil(digitals?.meta?.total / digitals?.meta?.per_page),
          totalItems: digitals?.meta?.total,
        }
      })
    }
  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  const filterTagDelete = async type => {
    switch (type) {
      case 'dateTime':
        queries.start_date = ''
        queries.end_date = ''
        dispatch({
          type: digitalManagementActions.FILTER_DATE_TIME_ACTIVE_UPDATE,
          payload: {
            end: '',
            start: '',
            type: STORE_UPOS_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
            value: '',
          }
        })
        handleActiveValueDatetime({
          end: '',
          start: '',
          type: STORE_UPOS_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
          value: '',
        })
        break
      case 'category':
        queries.category_id = ''
        dispatch({ type: digitalManagementActions.FILTER_CATEGORY_VALUE_UPDATE, payload: '' })
        handleCategoryActiveValue('')
        break
      case 'status':
        queries.status = ''
        dispatch({ type: digitalManagementActions.FILTER_STATUS_VALUE_UPDATE, payload: '' })
        handleStatusActiveValue('')
        break
      case 'search':
        queries.keyword = ''
        dispatch({ type: digitalManagementActions.FILTER_SEARCH_UPDATE, payload: '' })
        handleSearchActiveValue('')
        break
      case 'all':
        queries.category_id = ''
        queries.status = ''
        queries.keyword = ''
        dispatch({ type: digitalManagementActions.FILTER_CATEGORY_VALUE_UPDATE, payload: '' })
        handleCategoryActiveValue('')
        dispatch({ type: digitalManagementActions.FILTER_STATUS_VALUE_UPDATE, payload: '' })
        handleStatusActiveValue('')
        dispatch({ type: digitalManagementActions.FILTER_SEARCH_UPDATE, payload: '' })
        handleSearchActiveValue('')
        break
      default:
        return type
    }

    dispatch({
      type: digitalManagementActions.TABLE_LOADING_DISPLAY,
      payload: true
    })
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(queries)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/product/digital/digitals${queryString}`),
    ])
    if (response[0]?.data?.success) {
      dispatch({
        type: digitalManagementActions.TABLE_LOADING_DISPLAY,
        payload: false
      })
      const digitals = response[0]?.data
      dispatch({
        type: digitalManagementActions.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: digitals?.data,
          listDefault: digitals?.data,
          loading: false
        }
      })
      dispatch({
        type: digitalManagementActions.TABLE_UPDATE_PAGINATION,
        payload: {
          active: 0,
          amount: digitals?.meta?.per_page,
          total: Math.ceil(digitals?.meta?.total / digitals?.meta?.per_page),
          totalItems: digitals?.meta?.total,
        }
      })
    }
  }

  return {
    pageState: state,
    queries,
    search: {
      value: searchValue,
      activeValue: searchActiveValue,
      onChange: handleSearchChange,
    },
    dateTime: {
      activeValue: dateTimeActiveValue,
      defaultValue: dateTimeDefaultValue,
      disabledDate: afterToday(),
      triggerDefault: dateTimeTrigger,
      value: dateTimeValue,
      onChange: handleDateTimeChange,
    },
    categoryFilter: {
      value: categoryValue,
      activeValue: categoryActiveValue,
      list: categoryList,
      onChange: handleCategoryChange,
      onKeywordChange: handleCategoryKeywordChange
    },
    sourceFilter: {
      tab: sourceTab,
      value: sourceValue,
      activeValue: sourceActiveValue,
      list: sourceList,
      onChange: handleSourceChange,
      onKeywordChange: handleSourceKeywordChange,
      onTabChange: handleSourceTabChange,
      onInputReset: handleSourceInputReset
    },
    statusFilter: {
      value: statusValue,
      statusValue: statusActiveValue,
      activeValue: statusActiveValue,
      onChange: handleStatusChange,
    },
    badge: {
      others: otherFilterBadge,
    },
    methods: {
      applyOtherFilter,
      filterTagDelete
    },
    canSubmitOtherFilter,
    shouldShowResetAll,
  }
}

export default useDigitalManagementFilter