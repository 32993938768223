import React, {useContext} from 'react';
import styled from "styled-components";
import ReactApexChart from "react-apexcharts";
import merge from "lodash/merge";
import {BaseOptionChart} from "../../../../Component/chart";
import {pieDonutOption} from "./_chartOptions";
import {useTranslation} from "react-i18next";
import {EventStatisticContext} from "../../provider/_context";

export const StatisticCustomerPie = () => {
   const {t} = useTranslation()
   const {pageState} = useContext(EventStatisticContext)
   const {chart} = pageState
   const {title} = chart
   const {hasReferral} = chart
   const {nonReferral} = chart
   // const orderTotal = pageState.listControl.data.map((i) => i.total_cod)
   const chartSeries = hasReferral.map((item, index) => (+item + +nonReferral[index]))

   const chartOption = {

      colors: [
         "#168AFF","#00AB56","#FFC116","#5245E5","#c819ba","#70da18","#da9118","#da1870","#1820da","#149dbd","#47bd14","#bd1414"
      ],
      labels: title,
      // orderTotal: orderTotal,
      ...pieDonutOption
      // stroke: { colors: [theme.palette.background.paper] },
   }
   return (
      <StyledChartPieStatistic>
         <ReactApexChart type="pie" series={chartSeries} options={chartOption} height={400}/>
      </StyledChartPieStatistic>

   )
}

const StyledChartPieStatistic = styled.div`
 
  height: 500px;
  
  padding-top: 40px;
  .apexcharts-legend-series{
    margin: 4px 12px !important;
  }

`