import {
  EXPORT_EXCEL_SELECTIVE_ACTIVE,
  EXPORT_ORDER_EXCEL_SELECTIVE,
  ORDER_FILTER_FORM_DATE_TIME_SORT_TYPES, ORDER_FILTER_FORM_ORDER_STATUS,
  ORDER_FILTER_FORM_PAYMENT_METHOD, ORDER_FILTER_FORM_PAYMENT_STATUS, ORDER_FILTER_FORM_TYPE,
  ORDER_TABLE_THEAD_PAYMENT_FILTER_LIST
} from '../interfaces/_constants'

import { getDateFromNow } from '../utils/date'
import { fDateTimeDMY } from 'util/formatTime'

const dateTimeDefaultValue = [getDateFromNow(-7), getDateFromNow(0, { type: 'end' })]
export const formatDateTimeDefaultValue = `${fDateTimeDMY(
  dateTimeDefaultValue[0],
)} - ${fDateTimeDMY(dateTimeDefaultValue[1])}`

export const orderInitialState = {
  filter: {
    orderId: {
      value: null,
      activeValue: null
    },
    customerInfo: {
      value: null,
      activeValue: null
    },
    dateTime: {
      activeValue: {
        end: dateTimeDefaultValue[1],
        start: dateTimeDefaultValue[0],
        type: ORDER_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
        value: formatDateTimeDefaultValue,
      },
      end: dateTimeDefaultValue[1],
      start: dateTimeDefaultValue[0],
      type: ORDER_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
      trigger: true,
      value: formatDateTimeDefaultValue,
    },
    payment: {
      value: ORDER_TABLE_THEAD_PAYMENT_FILTER_LIST.map(item => item?.value),
    },
    product: {
      activeValue: [],
      keyword: '',
      list: [],
      listOrigin: [],
      loading: false,
      tab: 'all', // all | checked
      value: [],
    },
    search: {
      value: '',
    },
    paymentMethod: {
      activeValue: null,
      keyword: '',
      list: ORDER_FILTER_FORM_PAYMENT_METHOD,
      listOrigin: ORDER_FILTER_FORM_PAYMENT_METHOD,
      value: null,
    },
    orderType: {
      activeValue: null,
      list: ORDER_FILTER_FORM_TYPE,
      value: null,
    },
    principal: {
      activeValue: null,
      keyword: '',
      list: [],
      listOrigin: [],
      value: null,
    },
    teacher: {
      activeValue: null,
      keyword: '',
      list: [],
      listOrigin: [],
      value: null,
    },
    paymentStatus: {
      activeValue: null,
      keyword: '',
      list: ORDER_FILTER_FORM_PAYMENT_STATUS,
      listOrigin: ORDER_FILTER_FORM_PAYMENT_STATUS,
      value: null,
    },
    orderStatus: {
      activeValue: null,
      keyword: '',
      list: ORDER_FILTER_FORM_ORDER_STATUS,
      listOrigin: ORDER_FILTER_FORM_ORDER_STATUS,
      value: null,
    },
  },
  notifications: {
    list: [],
  },
  table: {
    display: {
      list: [],
      loading: true,
    },
    detail: {
      id: null,
      active: null,
      list: [],
    },
    pagination: {
      active: 0,
      amount: 20,
      total: 0,
      totalItems: 0,
    },
    selected: {
      list: [],
    },
  },
  printDetail: {},
  exportExcel: {
    list: EXPORT_ORDER_EXCEL_SELECTIVE,
    activeList: EXPORT_ORDER_EXCEL_SELECTIVE,
    selectedList: EXPORT_EXCEL_SELECTIVE_ACTIVE,
    keyword: '',
    hiddenList: EXPORT_ORDER_EXCEL_SELECTIVE
  },
  formEdit: {
    feeShip: {
      value: "",
      status: true,
      message: ''
    },
    customerName: {
      value: "",
      status: true,
      message: ''
    },
    customerPhone: {
      value: "",
      status: true,
      message: ''
    },
    customerEmail: {
      value: "",
      status: true,
      message: ''
    },
    customerAddress: {
      value: "",
      status: true,
      message: ''
    },
    orderNotes : {
      value: "",
      status: true,
      message: ''
    },
    details : [],
    order: {}
  }
}