import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, } from 'react-router-dom'

import { postData } from '../../api/api'
import { getUrlLogin } from '../../api/url'
import './LoginForm.css'
import LoginWrapper from './LoginWrapper.js'
import useGlobalContext from '../../containerContext/storeContext'
import { Button } from '../../common/button'
import {SnowLake} from "./snow";
import Dashboard from '../dashboard'
// import ForgotPasswordForm from '../ForgotPassword/'

function loginFromKeyboard () {
  try {
    const buttonConfirm = document.getElementsByClassName('button-confirm')
    buttonConfirm[0].click()
  } catch (error) {
    console.error(
      `================ error at loginFromKeyboard==================${error}`,
    )
  }
}

const listener = event => {
  if (event.code === 'Enter' || event.code === 'NumpadEnter') {
    loginFromKeyboard()
  }
}

export default function Login () {
  const [state, dispatch] = useGlobalContext()
  // const [passwordNote, setPasswordNote] = useState(false)
  const [isVI, setIsVI] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [isUsernameEmpty, setIsUsernameEmpty] = useState(false)
  const [errorPass, setErrorPass] = useState()
  const nav = useNavigate()
  const checkPasswordVN = value => {
    const regex =
      /[ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]/
    const checkVN = value.match(regex)
    setIsVI(checkVN)
  }

  //***************************
  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [firstTime, setFirstTime] = useState(false)
  const [isSavePass, setIsSavePass] = useState(false)
  const [isShowPass, setShowHidePass] = useState(false)
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false)
  const [loading, changeLoading] = useState(false)

  // action here
  const handleSubmit = () => {
    if (errorPass) return

    const usrname = username?.trim()
    const pass = password?.trim()

    if (!usrname && !pass) {
      setError(true)
      setIsUsernameEmpty(false)
      setIsEmpty(false)
    } else {
      if (!usrname) setIsUsernameEmpty(true)
      if (!pass) setIsEmpty(true)
      if (!usrname && !pass) {
        setError(false)
      }
    }

    setFirstTime(true)
    if (!usrname || !pass) return
    const url = getUrlLogin()
    const data = {
      client_id: 'dtp-education',
      username: usrname,
      password: pass,
    }

    changeLoading(true)
    postData(url, data)
      .then(res => {
        changeLoading(false)
        if (res.data && res.data.success) {
          // clear data
          setUserName('')
          setPassword('')
          setError(false)
          setMessage('')
          setFirstTime(false)

          const { access_token } = res.data.data
          window.localStorage.setItem('token', access_token)
          dispatch({ type: 'SET_USER', payload: res.data })
          // dispatch({type: 'SET_LOGIN'})
          window.location.reload()
        }
        if (res.data && res.data.message) {
          if (!!res.data?.otp_required)
          {
            dispatch({
              type: 'UPDATE_USER',
              payload: {
                otp: res.data
              }
            })
            nav('/account-verify')
          }
          else
            setMessage(res.data.message)
        }
      })
      .catch(() => {
        changeLoading(false)
        dispatch({ type: 'SET_USER', payload: {} })
        dispatch({ type: 'SET_LOGOUT' })
      })
  }

  const onChangeUserName = data => {
    setUserName(
      data && data.target && data.target.value ? data.target.value : null,
    )
  }
  const onChangePassword = data => {
    setPassword(
      data && data.target && data.target.value ? data.target.value : null,
    )
  }
  const onChangeSavePassword = () => {
    setIsSavePass(!isSavePass)
  }

  const showHidePassword = () => {
    setShowHidePass(!isShowPass)
  }

  const { t } = useTranslation()
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize () {
      setSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  const [width, height] = size

  useEffect(() => {
    document.addEventListener('keydown', listener)
    return () => document.removeEventListener('keydown', listener)
  }, [])
  if (state && state.isLogin) {
    return <Dashboard/>
  }

  return (
    <LoginWrapper>
      <img className={'background-right'} src={'/img/no-connection/analytics-pana.png'} alt={'background'}/>
      <img className={'background-left'} src={'/img/no-connection/analytics.png'} alt={'background'}/>
      <div
        // className={width < 1200 ? 'login-form mini-login-form' : 'login-form'}
        className={'login-form'}
      >
        <SnowLake/>
        <div className={'group-login-form'}>
          <img id={'logo-loginform'} src={'/img/header-logo.svg'} alt={'logo'}/>
          <div className={'title-login-form'}>
            <p>Đăng nhập</p>
            <span>Chào mừng bạn đến với trang quản trị hệ thống ZALO MINI APP</span>
          </div>

          {message ? (
            <div className={'error-all-form'}>
              <img
                id={'logo-error-all-form'}
                src={'/svg/error.svg'}
                alt={'icon-error'}
              />
              <p>{message}</p>
              {/* <p>Thông tin đăng nhập chưa đúng, vui lòng kiểm tra lại</p> */}
            </div>
          ) : null}

          {error ? (
            <div className={'error-all-form'}>
              <img
                id={'logo-error-all-form'}
                src={'/svg/error.svg'}
                alt={'icon-error'}
              />
              <p>Vui lòng nhập đầy đủ thông tin</p>
            </div>
          ) : null}

          <div className="user-name-field">
            <label for="username">Tên đăng nhập/Email</label>
            <input
              tabIndex="1"
              className={
                (!username && firstTime) || message || error || isUsernameEmpty
                  ? 'border-red-input upos-text'
                  : 'upos-text'
              }
              onChange={e => {
                //reset summary error
                setMessage('')
                setError(false)
                setFirstTime(false)

                const value = e.target.value
                onChangeUserName(e)
                if (value?.trim()) setIsUsernameEmpty(false)
                else setIsUsernameEmpty(true)
              }}
              type={'text'}
              // placeholder={t('Username')}
              required={''}
              id={'username'}
              autoComplete={'on'}
              onBlur={e => {
                const value = e.target.value
                //check empty
                if (!value?.trim()) setIsUsernameEmpty(true)
              }}
              onFocus={e => {
                setIsUsernameEmpty(false)
              }}
            />
            {isUsernameEmpty && !error && (
              <div className={'password-message'}>
                Tên đăng nhập không được để trống.
              </div>
            )}
          </div>

          <div className={'password-field'}>
            <div className={'password-forgot'}>
              <label for={'password'}>Mật khẩu</label>
              {/*<div*/}
              {/*  className={'forgot-password-button cursor-pointer upos-text'}*/}
              {/*>*/}
              {/*  <Link*/}
              {/*    to={'/forgot-password'}*/}
              {/*    tabIndex="3"*/}
              {/*    // onClick={() => {*/}
              {/*    //   setShowForgotPasswordForm(true)*/}
              {/*    //   setShowLoginForm(false)*/}
              {/*    // }}*/}
              {/*  >*/}
              {/*    {t('forgot_password')}*/}
              {/*  </Link>*/}
              {/*</div>*/}
            </div>
            <div className="group-inpput-login-form">
              <input
                tabIndex="2"
                className={
                  (!password && firstTime) || message || error || isEmpty
                    ? 'border-red-input upos-text'
                    : 'upos-text'
                }
                type={!isShowPass ? 'password' : 'text'}
                // placeholder={t('Password')}
                required={''}
                id={'password'}
                autoComplete={'on'}
                onChange={e => {
                  //reset summary error
                  setMessage('')
                  setError(false)
                  setFirstTime(false)

                  const value = e.target.value
                  if (value?.trim()) setIsEmpty(false)
                  else setIsEmpty(true)
                  checkPasswordVN(e.target.value)
                  onChangePassword(e)
                  // setErrorPass(false)
                  // checkLength(e.target.value)
                  // if (e.target.value.length >= 6) setPasswordNote(false)
                }}
                onBlur={e => {
                  !e.target.value.trim() && setIsEmpty(true)
                  // setPasswordNote(false)
                }}
                onFocus={e => {
                  // if (e.target.value.length < 6) setPasswordNote(true)
                  !e.target.value && setIsEmpty(false)
                }}
              />
              <img
                className={!isShowPass ? 'icon-hide-pass' : 'icon-show-pass'}
                src={!isShowPass ? '/svg/close-eye.svg' : '/svg/open-eye.svg'}
                onClick={() => showHidePassword(isShowPass)}
                alt={'icon'}
              />
            </div>
            {/* {errorPass && (
              <div className={'password-message'}>
                Mật khẩu cần có ít nhất 6 ký tự.
              </div>
            )} */}
            {/* {passwordNote && !errorPass && (
                  <div className="password-note">
                    Mật khẩu cần có ít nhất 6 ký tự.
                  </div>
                )} */}
            {isEmpty && !error && (
              <div className={'password-message'}>
                Mật khẩu không được để trống.
              </div>
            )}
            {/* {isVI && (
              <div className={'password-message'}>
                Mật khẩu đang chứa ký tự có dấu, vui lòng kiểm tra lại.
              </div>
            )} */}
          </div>
          {/* <div className={'save-password-field'}></div> */}
          <Button
            className={
              username && password
                ? // ? 'button-confirm button-confirm-active cursor-pointer'
                'button-confirm cursor-pointer'
                : 'button-confirm cursor-pointer'
            }
            onClick={() => handleSubmit(setIsVI, setIsEmpty)} //login(username, password, isSavePass)}
            type="submit"
          >
            {loading ? (
              <img src={'/svg/loading.svg'} alt={'loading'}/>
            ) : (
              t('Login')
            )}
          </Button>
        </div>
      </div>
    </LoginWrapper>
  )
}
