import { Th } from 'layouts/tableLayout/_th'
import { Tr } from 'layouts/tableLayout/_tr'
import useOrderTHead from 'Pages/orderDigital/hooks/useOrderTHead'

export const OrderTHead = ({ ...props }) => {

  const { globalLoading } = useOrderTHead()

  return (
    <>
      <Tr {...props} type="tHead" style={{ height: '44px' }}>
        <Th className="order-table__cell">
        </Th>
        <Th className="order-table__cell">Mã đơn hàng</Th>
        <Th className="order-table__cell">Tên khách hàng</Th>
        <Th className="order-table__cell">HT giới thiệu</Th>
        <Th className="order-table__cell">
          GV giới thiệu
        </Th>
        <Th className="order-table__cell">Tổng tiền</Th>
        <Th className="order-table__cell">Thanh toán</Th>
        <Th className="order-table__cell">Trạng thái</Th>
        <Th
          className="order-table__cell"
          style={{ display: 'flex' }}
        />
      </Tr>
      {globalLoading.value && (
        <div className="order-table__loading">
          <img src="/img/loading.gif"/>
        </div>
      )}
    </>
  )
}
