import React from 'react'
import { useTranslation } from 'react-i18next'
import {AlternativeAutoComplete} from "../../../../common/form/autoComplete/_alternativeAutoComplete";
import {Option} from "../../../../common/form/autoComplete/_option";
import useEventStatisticFilter from "../../hooks/useEventStatisticFilter";

const EVENT_STATUS=[
   {name: 'Có GV giới thiệu', value: 'linked'},
   {name: 'Không có GV giới thiệu', value: 'unlink'},
]

const BannerStatus = () => {
  const { t } = useTranslation()
  const { customerTypeFilter } = useEventStatisticFilter()
  return (
    <AlternativeAutoComplete
      className="principal-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: { name: t('Loại khách hàng'), value: '' }, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 120,
        placeholder: t('Chọn loại khách hàng'),
        readOnly: true,
        value: t(customerTypeFilter?.value?.name) || '',
        onIconClick: () => customerTypeFilter.onChange(null),
      }}
      hideSearchBar={true}

    >
      {EVENT_STATUS?.map(item => (
        <Option
          key={item.value}
          className="principal-filter-form__option-text"
          data-active={item.value === customerTypeFilter?.value?.value}
          onClick={() => customerTypeFilter.onChange(item)}
        >
          {t(item.name)}
        </Option>
      ))}
    </AlternativeAutoComplete>
  )
}

export default BannerStatus
