import React from 'react';
import {STORE_UPOS_ICON} from "../../../products/book/interface/_icons";
import {Input} from "../../../../common/form/input";
import {CategoryInput} from "../../../../common/form/input/_categoryInput";
import {useLicenseKeyFilter} from "../../hook/useLicenseKeyFilter";

const SearchCodeKey = () => {
    const {keyCode} = useLicenseKeyFilter()
    return (
        <CategoryInput
            categoryValue={{name:'Mã key', value:''}}
            categoryWidth={90}
            className="license-key-filter-form__input-wide"
            placeholder={"Tìm kiếm theo mã key"}
            value={keyCode.value}
            onChange={keyCode.onChangeValue}
        />

    )
}

export default SearchCodeKey