import { Input } from "../../../../common/form/input";
import React from "react";
import { Text } from "../../../../common/text";
import { THEME_SEMANTICS } from "../../../../common/theme/_semantics";
import { USER_ICON } from "../../icon/icon";
import { Tooltip } from "../../../../common/tooltip";
import { USER_PROFILE } from "../../../../Component/Icons";
import {ConfirmModalV2} from "../../../../Component/ConfirmModal";
import useCreateUserManagement from "../../hooks/useCreateUserManagement";
import {useUpdatePassword} from "../../hooks/useUpdatePassword";
import { useTranslation } from "react-i18next";
import { DISPLAY_NAME_MENU } from "../../../../const/display_name_menu";
import { useParams } from "react-router-dom";

const UserAccount = ({ ...props }) => {
    const { t } = useTranslation()
    const { userId } = useParams()
    const {modalPassword} = useUpdatePassword()
    const { functions, valueForm, typeInput, validate } = useCreateUserManagement()
    return (
        <>
            <div className="create-user-info__form-group">
                <Input
                    {...props}
                    label={
                        <>
                            {t(DISPLAY_NAME_MENU.USER_PAGE.USERNAME)} {!!!userId && <Text color={THEME_SEMANTICS.failed}>*</Text>}
                        </>
                    }
                    placeholder={t(DISPLAY_NAME_MENU.GENERAL.ENTER_USERNAME)}
                    value={valueForm?.username}
                    maxLength={200}
                    validateText={validate?.errorUserName?.status ? validate?.errorUserName?.message : null}
                    validateType={!validate?.errorUserName?.status ? 'success' : 'danger'}
                    onChange={e => functions.onChangeUserName(e.target.value)}
                    onBlur={e => validate?.onBlurUserName(e.target.value)}
                    autoComplete={'false'}
                    disabled={!!userId && true}
                />
            </div>
            {
                !!!userId ?
                <>
                    <div className="create-user-info__form-group">
                        <Input
                            {...props}
                            label={
                                <>
                                    <span>{t(DISPLAY_NAME_MENU.GENERAL.USER_PROFILE_TAB_PASS)}</span>
                                    <Text color={THEME_SEMANTICS.failed}> *</Text>
                                    <Tooltip placement="bottom-start"
                                        title={'Mật khẩu có độ dài 8 - 32 ký tự. Hãy đặt mật khẩu mạnh để bảo vệ tài khoản của mình.'}
                                        className={'user-management-tooltip__user-password'}
                                    >
                                        <span
                                            style={{
                                                marginLeft: 6,
                                                display: 'inline-block',
                                                transform: 'translateY(2px)',
                                            }}
                                        >
                                        </span>
                                        <span className={"create-user-info__form-group-account--tooltip"}>{USER_ICON.question}</span>
                                    </Tooltip>
                                </>
                            }
                            placeholder={t(DISPLAY_NAME_MENU.USER_PAGE.ENTER_CURRENT_PASS)}
                            value={valueForm?.password}
                            maxLength={32}
                            validateText={validate?.errorPassword?.status ? validate?.errorPassword?.message : null}
                            validateType={!validate?.errorPassword?.status ? 'success' : 'danger'}
                            type={typeInput.password}
                            icon={typeInput.password === 'password' ? <i>{USER_PROFILE.not_eye}</i> : <i>{USER_PROFILE.eye}</i>}
                            onChange={e => functions.onChangePassword(e.target.value)}
                            onBlur={e => validate?.onBlurPassword(e.target.value)}
                            onIconClick={() => functions.onChangeTypePassword()}
                            autoComplete={'new-password'}
                        />
                    </div>
                    <div className="create-user-info__form-group">
                        <Input
                            {...props}
                            label={
                                <>
                                    <span>{t(DISPLAY_NAME_MENU.GENERAL.ENTER_REPEAT_PASS)}</span>
                                    <Text color={THEME_SEMANTICS.failed}> *</Text>
                                </>
                            }
                            placeholder={t(DISPLAY_NAME_MENU.GENERAL.ENTER_REPEAT_PASS)}
                            value={valueForm?.confirmPassword}
                            maxLength={32}
                            validateText={validate?.errorConfirmPassword?.status ? validate?.errorConfirmPassword?.message : null}
                            validateType={!validate?.errorConfirmPassword?.status ? 'success' : 'danger'}
                            type={typeInput.confirmPassword}
                            icon={typeInput.confirmPassword === 'password' ? <i>{USER_PROFILE.not_eye}</i> : <i>{USER_PROFILE.eye}</i>}
                            onChange={e => functions.onChangeConfirmPassword(e.target.value)}
                            onBlur={e => validate?.onBlurConfirmPassword(e.target.value)}
                            onIconClick={() => functions.onChangeTypeConfirmPassword()}
                            autoComplete={'new-password'}
                        />
                    </div>
                </> :
                <>
                    <div
                        style={{marginTop: 20, width: 'fit-content', cursor:'pointer'}}
                        onClick={()=>modalPassword.methods?.onOpen(true)}
                    >
                        <Text color={'#2150B7'}>Đặt lại mật khẩu</Text>
                    </div>
                    {
                        !!modalPassword?.open && <ConfirmModalV2
                            open={modalPassword?.open}
                            width={480}
                            body={
                                <BodyConfirm  {...props} lastName={valueForm?.last_name} name={valueForm?.name} userName={valueForm?.username}/>
                            }
                            footerButton={{
                                cancel:{
                                    title: 'Hủy',
                                    action: () => modalPassword.methods?.onOpen(false)
                                },
                                confirm:{
                                    title: 'Lưu',
                                    action: () => modalPassword.methods?.onSave(),
                                    disabled: modalPassword?.status
                                }
                            }}
                        />
                    }
                </>
            }
        </>
    )
}
export default UserAccount;

const BodyConfirm = ({name, lastName, userName})=>{
    const {modalPassword} = useUpdatePassword()
    const {methods} = modalPassword
    return(
        <div className='create-principal_confirm-body'>
            <Text fontSize={20} fontWeight={600}>Đặt lại mật khẩu {name} {lastName}</Text>
            <div style={{margin: '24px 0'}}>
                <Text>Mật khẩu người dùng <b>{userName}</b> sẽ được thay đổi ngay sau khi bạn đặt lại mật khẩu.</Text>
            </div>
            <Input
                label={
                    <>
                        Mật khẩu mới <Text color={THEME_SEMANTICS.failed}>*</Text>
                    </>
                }
                value={modalPassword?.value}
                onChange={e => methods.onChange(e.target?.value)}
                onBlur={e => methods.onBlur(e.target?.value)}
                validateText={modalPassword?.message}
                validateType={modalPassword?.status ? 'danger' : ''}
                placeholder={'Vui lòng nhập mật khẩu'}
                maxLength={32}
            />

        </div>
    )
}