import { AlternativeAutoComplete } from 'common/form/autoComplete/_alternativeAutoComplete'
import { Option } from 'common/form/autoComplete/_option'
import useRevenueFilterForm from 'Pages/revenue/hooks/useRevenueFilterForm'
import { Text } from '../../../../common/text'
import { THEME_COLORS } from '../../../../common/theme/_colors'

export const RevenueStatus = () => {
  const { orderStatus } = useRevenueFilterForm()

  return (
    <AlternativeAutoComplete
      className="revenue-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: { name: 'Trạng thái đơn hàng', value: '' }, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 142,
        placeholder: 'Chọn trạng thái đơn hàng',
        readOnly: true,
        value: orderStatus.value.length > 0 ? `Đã chọn ${orderStatus.value.length}` : '',
        // onIconClick: () => orderStatus.onChange(null),
      }}
      // menu
      hideSearchInput={true}
      menuProps={{
        empty:
          orderStatus.list.length <= 0
            ? orderStatus.tab === 'all'
            ? 'Không tìm thấy trạng thái sản phẩm'
            : 'Bạn chưa chọn trạng thái nào'
            : '',
        multipleChoices: true,
        onReset: orderStatus.onInputReset, // only use this prop for multiple choice
      }}
      // tab list <only use this prop for multiple choices>
      tabProps={{
        active: orderStatus.tab,
        checkedNumber: orderStatus.value.length,
        onChange: orderStatus.onTabChange,
      }}

    >
      {orderStatus.list.length > 0 &&
      orderStatus.list.map(item => (
        <Option
          key={item.value}
          className="revenue-filter-form__option-text"
          checked={!!orderStatus.value.find(find => find.value === item.value)}
          multipleChoices={true}
          style={{ marginBottom: 16 }}
          onClick={() => orderStatus.onChange(item)}
        >
          <Text color={THEME_COLORS.gray_900} style={{ display: 'block' }}>
            {item.name}
          </Text>
        </Option>
      ))}
    </AlternativeAutoComplete>
  )
}
