import {AlternativeAutoComplete} from 'common/form/autoComplete/_alternativeAutoComplete'
import {Option} from 'common/form/autoComplete/_option'
import useRevenueFilterForm from 'Pages/revenue/hooks/useRevenueFilterForm'

export const RevenuePrincipal = () => {
  const {principal} = useRevenueFilterForm()

  return (
    <AlternativeAutoComplete
      className="revenue-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: {name: 'Hiệu trưởng', value: ''}, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 94,
        placeholder: 'Chọn hiệu trưởng',
        readOnly: true,
        value: principal.value?.name || '',
        onIconClick: () => principal.onChange(null),
      }}
      // menu
      menuProps={{
        empty:
          principal.list.length <= 0
            ? 'Không tìm thấy hiệu trưởng'
            : '',
      }}
      // search input in dropdown menu
      searchInputProps={{
        placeholder: 'Tìm kiếm hiệu trưởng',
        value: principal.keyword,
        onChange: principal.onKeywordChange,
      }}
    >
      {principal.list.length > 0 &&
        principal.list.map(item => (
          <Option
            key={item.value}
            className="revenue-filter-form__option-text"
            data-active={item.value === principal.value?.value}
            onClick={() => principal.onChange(item)}
          >
            {item.name}
          </Option>
        ))}
    </AlternativeAutoComplete>
  )
}
