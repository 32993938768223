import { THEME_COLORS } from 'common/theme/_colors'
import styled from 'styled-components'
import {formatDatetime} from "../../../../common/form/datePicker/_functions";
import {Text} from "../../../../common/text";
import {Button} from "../../../../common/button";
import {useLicenseKeyCreate} from "../../hook/useLicenseKeyCreate";
export const LicenseRowExtra = ({ id, active, data, rowData, status, ...props }) => {
    const DETAIL_ROW_LICENSE_KEY=[
        {name:'Mã Key', value:data?.license_key || '---'},
        {name:'App name', value:data?.app_name || '---'},
        {name:'appid', value:data?.app_id || '---'},
        {name:'level', value:data?.level || '---'},
        {name:'Pack name', value: data?.pack_name},
        {name:'Quota active', value:data?.quota_active || '---'},
    ]
    const {functions} = useLicenseKeyCreate()
    return (
        <StyledLicenseRowExtra {...props} data-active={active}>
            {active && (
                <div className="license-row-extra__container">
                    <div className="license-row-extra__tabs">
                        <div
                            className="license-row-extra__tab"
                            data-active={true}
                        >
                            Chi tiết mã key sản phẩm
                        </div>
                    </div>
                    <div className="license-row-extra__content">
                        <div className={'license-row-extra__items'} >
                            <Text fontWeight={600} fontSize={16}>Thông tin KEY</Text>
                            <div className={'license-row-extra__grid'}>
                                {DETAIL_ROW_LICENSE_KEY?.map((map,i)=>{
                                    return <div className={'license-row-extra__grid-item'}>
                                        <Text as={'p'} color={'#7c88a6'} >{map?.name}:</Text>
                                        <Text>{map?.value}</Text>
                                    </div>
                                })}
                            </div>
                        </div>
                        <div className={'license-row-extra__items'} >
                            {
                                status === 'new' &&  <div className={'license-row-extra__grid_info'}>
                                    <Button
                                        onClick={()=> functions?.onEditKey(id)}
                                    >Sửa</Button>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            )}
        </StyledLicenseRowExtra>
    )
}

const StyledLicenseRowExtra = styled.div`
  max-height: 0;

  overflow: hidden;

  transition: all 0.25s;

  &[data-active='true'] {
    max-height: 75vh;
    padding: 4px 0 7px 0;
  }

  .license-row-extra {
    &__container {
      overflow: hidden;

      border-left: 4px solid #3264FF;
      border-radius: 0px 8px 8px 8px;
    }

    &__tabs {
      height: 36px;

      display: flex;
    }

    &__tab {
      margin-right: 4px;
      padding: 0 32px;

      display: flex;
      align-items: center;
      justify-content: center;

      background: #e2eaf8;
      border-radius: 8px 8px 0px 0px;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;

      transition: all 0.25s;

      cursor: pointer;

      &:first-child {
        border-radius: 0 8px 0px 0px;
      }

      &[data-active='true'] {
        background: #fff;
      }
    }

    &__content {
      max-height: 60vh;
      padding: 24px 36px 32px 36px;

      overflow: auto;

      background: #fff;
      border-radius: 0 8px 0 0;
      
      display: flex;
      justify-content: space-between;
    }
    &__items{
      height: 100%;
      &:nth-child(1){
         width: calc(100% - 20%);
      } 
      &:nth-child(2){
         width: calc(100% - 80%);
      }
    }
    &__grid{
      align-items: center;
      flex-wrap: wrap;
      display: flex;
    }
    &__grid-item{
      width: calc(50% - 8px);
      margin: 12px 0;
    }
    &__loading {
      width: 100%;
      height: 200px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
