import { Text } from 'common/text'
import { THEME_SEMANTICS } from 'common/theme/_semantics'
import { StyledOrderTags } from './_styled'
import { OrderTag } from './_tag'
import useEventStatisticFilter from "../../../hooks/useEventStatisticFilter";
import {TAG_DELETE} from "../../../interface/_contants";

export const EventTags = ({ ...props }) => {
  const {
    dateTime,
    teacher,
    customerTypeFilter,
    campaign,
    principal,
    methods,
  } = useEventStatisticFilter()

  const shouldShowResetAll = methods.hasFilter()

  const handleDeleteAll = () => methods.filterTagDeleteAll()

  return (
    <StyledOrderTags {...props}>

      {campaign.activeValue.length > 0 && (
         <OrderTag
            onDelete={() => methods.filterTagDelete(TAG_DELETE[0])}
         >
           Tên sự kiện: {campaign.activeValue
           .map(item => item?.data?.title || '')
           .join(', ')}
         </OrderTag>
      )}
      {principal?.activeValue?.name && (
        <OrderTag
          onDelete={() => methods.filterTagDelete(TAG_DELETE[1])}
        >
          Hiệu trưởng: {principal.activeValue.name}
        </OrderTag>
      )}
      {teacher?.activeValue?.name && (
        <OrderTag
          onDelete={() => methods.filterTagDelete(TAG_DELETE[2])}
        >
          Giáo viên: {teacher.activeValue.name}
        </OrderTag>
      )}
      {customerTypeFilter.activeValue && (
         <OrderTag
            onDelete={() => methods.filterTagDelete(TAG_DELETE[3])}
         >
           Loại khách hàng: {customerTypeFilter.activeValue.name}
         </OrderTag>
      )}

      {dateTime?.activeValue?.value && dateTime?.activeValue?.type?.name && (
        <OrderTag
          onDelete={() => methods.filterTagDelete('dateTime.current')}
        >
          {dateTime.activeValue.type.name}: {dateTime.activeValue.value}
        </OrderTag>
      )}

      {shouldShowResetAll && (
        <Text
          as="b"
          color={THEME_SEMANTICS.delivering}
          lineHeight={28}
          style={{ marginBottom: 12, cursor: 'pointer' }}
          onClick={handleDeleteAll}
        >
          Đặt lại mặc định
        </Text>
      )}
    </StyledOrderTags>
  )
}
