import React, { memo, useReducer } from 'react'
import { DashboardProvider } from './provider'
import PageHeader from './component/header/index'
import { GridLayout } from '../../layouts/gridLayout'
import { DashboardPanels } from './component/dashboardPanels'
import { StatisticRevenue } from './component/chart/statisticRevenue'
import { StatisticTeacher } from './component/chart/statisticTeacher'
import { StatisticCustomer } from './component/chart/statisticCustomer'
import { Text } from '../../common/text'
import { ReportTitle } from './component/title/report'
import { StatisticCommission } from './component/chart/statisticCommission'
import { useDashboardReducer } from './provider/_reducer'
import { useDashboardInitialState } from './provider/_initState'

const Index = memo(() => {
  const [state, dispatch] = useReducer(useDashboardReducer, useDashboardInitialState)
  return (
    <DashboardProvider value={{ pageState: state, pageDispatch: dispatch }}>
      <GridLayout
        header={<PageHeader/>}
      />
      {/*<Text fontWeight={600} fontSize={22}>Tổng quan</Text>*/}
      <DashboardPanels/>
      <ReportTitle/>

      {/*Tối ưu cho page, tối ưu tốc đó -> dùng useEffect cho từng component, không dùng customer hook*/}
      <GridLayout
        grid={[
          {
            width: 100,
            sections: [
              {
                title: 'Doanh thu',
                props: {
                  style: {
                    position: 'relative',
                    padding: '24px'
                  },
                  children: <StatisticRevenue/>,
                },
              },
              {
                title: 'Hoa hồng tổng thể',
                props: {
                  style: { position: 'relative', padding: '24px' },
                  children: <StatisticCommission/>,
                },
              },
              {
                title: 'Thống kê hiệu trưởng/giáo viên',
                props: {
                  style: { position: 'relative', padding: '24px' },
                  children: <StatisticTeacher/>,
                },
              },
              {
                title: 'Thống kê khách hàng',
                props: {
                  style: { position: 'relative', marginBottom: 0, padding: '24px' },
                  children: <StatisticCustomer/>,
                },
              },
            ],
            props: { style: { position: 'relative' } },
          }
        ]}
      />
    </DashboardProvider>

  )
})
export default Index