import {eventStatisticActions} from './_init'
import {TAG_DELETE} from "../interface/_contants";
import { orderActions } from '../../orderBook/provider/_reducer'

export const eventStatisticReducer = (state, action) => {
   switch (action.type) {
      case eventStatisticActions.PANEL_STORE_TOTAL:
         return {
            ...state,
            panels: {
               ...state.panels,
               storeTotal: action.payload,
            }
         }
      case eventStatisticActions.SET_LOADING:
         return {
            ...state,
            loading: action.payload,
         }
      case eventStatisticActions.SET_LOADING_CHART:
         return {
            ...state,
            loadingChart: action.payload,
         }
      case eventStatisticActions.UPDATE_PAGE_OPTION_VALUE:
         return {
            ...state,
            // loading: true,
            pageOption: action.payload,
         }
      case eventStatisticActions.PANEL_ORDER_TOTAL:
         return {
            ...state,
            panels: {
               ...state.panels,
               orderTotal: action.payload,
            }
         }

      case eventStatisticActions.TABLE_LOADING_DISPLAY:
         return {
            ...state,
            table: {
               ...state.table,
               display: {
                  ...state?.table?.display,
                  loading: action?.payload
               }
            },
         }
      case eventStatisticActions.TABLE_UPDATE_DISPLAY_LIST:
         return {
            ...state,
            table: {
               ...state.table,
               display: {
                  ...state?.table?.display,
                  list: action?.payload?.list || [],
                  listDefault: action?.payload?.listDefault || [],
                  loading: action?.payload?.loading
               }
            },
         }
      case eventStatisticActions.TABLE_UPDATE_DISPLAY_CHART:
         return {
            ...state,
            chart: {
               ...state.chart,
               ...action.payload
            },
         }
      case eventStatisticActions.TABLE_UPDATE_PAGINATION:
         return {
            ...state,
            table: {
               ...state.table,
               pagination: {
                  ...state.table.pagination,
                  active: action.payload?.active || 0,
                  amount: action.payload?.amount || 20,
                  total: action.payload?.total || 0,
                  totalItems: action.payload?.totalItems || 0,
               },
            },
         }
      case eventStatisticActions.TABLE_DISPLAY_DETAIL_ID_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               detail: {
                  ...state.table.detail,
                  id: action.payload?.id || null,
               },
            },
         }
      case eventStatisticActions.TABLE_DISPLAY_DETAIL_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               detail: {
                  ...state.table.detail,
                  active: action.payload?.active || null,
                  list: action.payload?.list || [],
               },
            },
         }
      case eventStatisticActions.TABLE_SELECTED_LIST_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               selected: {
                  ...state.table.selected,
                  list: action.payload?.selected?.list || [],
               },
            },
         }
      case eventStatisticActions.MODAL_DELETE_PRODUCT:
         return {
            ...state,
            delete: {
               ...state.delete,
               ...action.payload,
            },
         }
      case eventStatisticActions.OPEN_MODAL:
         return {
            ...state,
            open_modal: action.payload,
         }
      case eventStatisticActions.MODAL_CONFIRM:
         return {
            ...state,
            modal_confirm: action.payload,
         }
      case eventStatisticActions.CHANGE_MODAL:
         return {
            ...state,
            change_modal: action.payload,
         }
      case eventStatisticActions.UPDATE_FORM_CREATE:
         return {
            ...state,
            create: {
               ...state.create,
               ...action.payload
            },
         }

      case eventStatisticActions.CREATE_EVENT_CATEGORY_UPDATE:
         return {
            ...state,
            create: {
               ...state.create,
               category: {
                  ...state.create.category,
                  ...action.payload
               }
            },
         }

      case eventStatisticActions.CREATE_EVENT_PRODUCT_UPDATE:
         return {
            ...state,
            create: {
               ...state.create,
               product: {
                  ...state.create.product,
                  ...action.payload
               }
            },
         }
      case eventStatisticActions.CREATE_EVENT_LINK_TO_UPDATE:
         return {
            ...state,
            create: {
               ...state.create,
               linkTo: action.payload
            },
         }
      case eventStatisticActions.CREATE_EVENT_COLOR_UPDATE:
         return {
            ...state,
            create: {
               ...state.create,
               color: action.payload
            },
         }
      case eventStatisticActions.CREATE_EVENT_DESCRIPTION_UPDATE:
         return {
            ...state,
            create: {
               ...state.create,
               description: action.payload
            },
         }
      case eventStatisticActions.CREATE_EVENT_POSITION_UPDATE:
         return {
            ...state,
            create: {
               ...state.create,
               position: action.payload
            },
         }

      case eventStatisticActions.CREATE_EVENT_VALIDATE_UPDATE:
         return {
            ...state,
            create: {
               ...state.create,
               validate: {
                  ...state.create.validate,
                  ...action.payload
               }
            },
         }

      case eventStatisticActions.SET_ID:
         return {
            ...state,
            idBanner: action.payload
         }

      case eventStatisticActions.FILTER_ORIGIN_DATA_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               principal: {
                  ...state.filter.principal,
                  list: action.payload?.principal?.list || [],
                  listOrigin: action.payload?.principal?.list || [],
               },
               campaign: {
                  ...state.filter.campaign,
                  list: action.payload?.campaign?.list || [],
                  listOrigin: action.payload?.campaign?.list || [],
               },
            },
         }

      //  campaign

      case eventStatisticActions.FILTER_CAMPAIGN_KEYWORD_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               campaign: {
                  ...state.filter.campaign,
                  keyword: action.payload.keyword,
                  list: action.payload.list,
                  tab: action.payload?.tab || state.filter.campaign.tab,
                  loading: false,
               },
            },
         }

      case eventStatisticActions.FILTER_CAMPAIGN_ENABLE_LOADING:
         return {
            ...state,
            filter: {
               ...state.filter,
               campaign: {
                  ...state.filter.campaign,
                  loading: true,
               },
            },
         }

      case eventStatisticActions.FILTER_CAMPAIGN_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               campaign: {
                  ...state.filter.campaign,
                  value: action.payload?.value || [],
               },
            },
         }

      case eventStatisticActions.FILTER_CAMPAIGN_TAB_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               campaign: {
                  ...state.filter.campaign,
                  keyword: '',
                  tab: action.payload.tab,
                  list: action.payload.list,
               },
            },
         }

      //  principals

      case eventStatisticActions.FILTER_PRINCIPAL_KEYWORD_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               principal: {
                  ...state.filter.principal,
                  keyword: action.payload.keyword,
                  list: action.payload.list,
               },
            },
         }

      case eventStatisticActions.FILTER_PRINCIPAL_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               principal: {
                  ...state.filter.principal,
                  value: action.payload?.value || null,
               },
            },
         }
      //  teachers

      case eventStatisticActions.FILTER_TEACHER_KEYWORD_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               teacher: {
                  ...state.filter.teacher,
                  keyword: action.payload.keyword,
                  list: action.payload.list,
               },
            },
         }

      case eventStatisticActions.FILTER_TEACHER_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               teacher: {
                  ...state.filter.teacher,
                  value: action.payload?.value || null,
               },
            },
         }

      case eventStatisticActions.FILTER_TEACHER_LIST_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               teacher: {
                  ...state.filter.teacher,
                  list: action.payload?.teacher?.list || [],
                  listOrigin: action.payload?.teacher?.list || [],
               },
            },
         }

      case eventStatisticActions.FILTER_SEARCH_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               search: {
                  ...state.filter.search,
                  ...action.payload,
               }
            },
         }
      case eventStatisticActions.FILTER_CATEGORY:
         return {
            ...state,
            filter: {
               ...state.filter,
               parentCategory: {
                  ...state.filter.parentCategory,
                  list: action.payload,
                  listOrigin: action.payload,
               }
            },
         }
      case eventStatisticActions.FILTER_CATEGORY_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               parentCategory: {
                  ...state.filter.parentCategory,
                  value: action.payload?.value || null,
               }
            },
         }
      case
      eventStatisticActions.FILTER_CATEGORY_KEYWORD_UPDATE
      :
         return {
            ...state,
            filter: {
               ...state.filter,
               parentCategory: {
                  ...state.filter.parentCategory,
                  keyword: action.payload.keyword,
                  list: action.payload.list,
               },
            },
         }
      case eventStatisticActions.FILTER_SEARCH_ACTIVE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               search: {
                  ...state.filter.search,
                  ...action.payload,
               }
            },
         }

      case eventStatisticActions.FILTER_STATUS_VALUE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               customerType: {
                  ...state.filter.customerType,
                  value: action.payload,
               },
            },
         }

      case eventStatisticActions.FILTER_POSITION_VALUE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               position: {
                  ...state.filter.position,
                  value: action.payload,
               },
            },
         }
      case eventStatisticActions.FILTER_STATUS_ACTIVE_VALUE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               customerType: {
                  ...state.filter.customerType,
                  activeValue: action.payload,
               },
            },
         }
      case eventStatisticActions.FILTER_ACTIVE_VALUE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               customerType: {
                  ...state.filter.customerType,
                  activeValue: state.filter.customerType.value,
               },
               campaign: {
                  ...state.filter.campaign,
                  activeValue: state.filter.campaign.value,
               },
               principal: {
                  ...state.filter.principal,
                  activeValue: state.filter.principal.value,
               },
               teacher: {
                  ...state.filter.teacher,
                  activeValue: state.filter.teacher.value,
               },
               dateTime: {
                  ...state.filter.dateTime,
                  activeValue: {
                     end: state.filter.dateTime?.end,
                     start: state.filter.dateTime?.start,
                     type: state.filter.dateTime?.type,
                     value: state.filter.dateTime.value,
                  },
               },
            },
         }
      case eventStatisticActions.FILTER_POSITION_ACTIVE_VALUE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               position: {
                  ...state.filter.position,
                  activeValue: action.payload,
               },
            },
         }
      case eventStatisticActions.FILTER_CATEGORY_ACTIVE_VALUE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               parentCategory: {
                  ...state.filter.parentCategory,
                  activeValue: action.payload,
               },
            },
         }

      // Date time

      case eventStatisticActions.FILTER_DATE_TIME_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               dateTime: {
                  ...state.filter.dateTime,
                  end: action.payload?.end,
                  start: action.payload?.start,
                  type: action.payload?.type,
                  value: action.payload?.value,
               },
            },
         }

      case eventStatisticActions.FILTER_ACTIVE_DATE_TIME_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               dateTime: {
                  ...state.filter.dateTime,
                  activeValue: {
                     ...state.filter.dateTime.activeValue,
                     end: action.payload?.end,
                     start: action.payload?.start,
                     type: action.payload?.type,
                     value: action.payload?.value,
                  },
               },
            },
         }

      case eventStatisticActions.FILTER_DATE_TIME_TRIGGER_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               dateTime: {
                  ...state.filter.dateTime,
                  trigger: action.payload?.trigger,
               },
            },
         }
      case eventStatisticActions.FILTER_TAG_DELETE:
         switch (action.payload) {
            case 'dateTime.current':
               return {
                  ...state,
                  filter: {
                     ...state.filter,
                     dateTime: {
                        ...state.filter.dateTime,
                        activeValue: {
                           end: null,
                           start: null,
                           type: '',
                           value: null,
                        },
                        end: null,
                        start: null,
                        type: '',
                        value: null,
                     },
                  },
               }
            case 'dateTime':
               return {
                  ...state,
                  filter: {
                     ...state.filter,
                     dateTime: {
                        ...state.filter.dateTime,
                        activeValue: {
                           end: null,
                           start: null,
                           type: '',
                           value: null,
                        },
                        end: null,
                        start: null,
                        type: '',
                        value: null,
                     },
                  },
               }
            case TAG_DELETE[0]:
               return {
                  ...state,
                  filter: {
                     ...state.filter,
                     campaign: {
                        ...state.filter.campaign,
                        activeValue: [],
                        value: []
                     },
                  },
               }
            case TAG_DELETE[1]:
               return {
                  ...state,
                  filter: {
                     ...state.filter,
                     principal: {
                        ...state.filter.principal,
                        activeValue: null,
                        value: null
                     },
                  },
               }
            case TAG_DELETE[2]:
               return {
                  ...state,
                  filter: {
                     ...state.filter,
                     teacher: {
                        ...state.filter.teacher,
                        activeValue: null,
                        value: null
                     },
                  },
               }
            case TAG_DELETE[3]:
               return {
                  ...state,
                  filter: {
                     ...state.filter,
                     customerType: {
                        ...state.filter.customerType,
                        activeValue: null,
                        value: null
                     },
                  },
               }
            default:
               return {...state};
         }
      default:
         break
   }
}