import {useContext} from "react";
import {LicenseKey} from "../provider/_context";
import {useLicenseKeyAction} from "../provider/_reducer";
import {sendRequestAuth} from "../../../api/api";
import config from "../../../config";
import {FILTER_TAG} from "../interface/_const";

export const useLicenseKeyFilter = ()=>{
    const {pageState, pageDispatch} = useContext(LicenseKey)
    const { filter, table } = pageState
    const {pagination} = table
    //productName
    const valueProductName = filter?.productName?.value
    const activeProductName = filter?.productName?.active

    const handleChangeProductName = data => {
        pageDispatch({
            type:useLicenseKeyAction.FILTER_PRODUCT_NAME_UPDATE,
            payload:{
                value: data
            }
        })
    }

    // keycode
    const valueKeyCode = filter?.keyCode?.value
    const activeKeyCode = filter?.keyCode?.active

    const handleChangeKeyCode = data => {
        pageDispatch({
            type:useLicenseKeyAction.FILTER_KEYCODE_ACTIVE_VALUE_UPDATE,
            payload: data
        })
    }

    //status
    const valueStatus = filter?.status?.value
    const activeStatus = filter?.status?.active

    const handleChangeStatus = data => {
        pageDispatch({
            type:useLicenseKeyAction.FILTER_STATUS_VALUE_UPDATE,
            payload: data
        })
    }

    const canSubmitForm = [
        JSON.stringify(valueProductName)?.toLowerCase() !== JSON.stringify(activeProductName)?.toLowerCase(),
        JSON.stringify(valueKeyCode)?.toLowerCase() !== JSON.stringify(activeKeyCode)?.toLowerCase(),
        JSON.stringify(valueStatus)?.toLowerCase() !== JSON.stringify(activeStatus)?.toLowerCase(),
    ].includes(true)
    const submitBadge = [
        !!activeStatus,
        !!activeKeyCode,
        !!activeProductName
    ].filter(filter => filter === true)


    const querySearch = {
        per_page: pageState?.table?.pagination?.amount || 20,
        start: (pageState?.table?.pagination?.active * pageState?.table?.pagination?.amount) || 0,
        keyword: valueKeyCode || '',
        status: valueStatus?.value || '',
        product_name: valueProductName|| ''
    }
    const fetchApiLicense = async (qs)=>{
        pageDispatch({
            type: useLicenseKeyAction.TABLE_LOADING_DISPLAY,
            payload: true
        })
        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(qs)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        const res = await sendRequestAuth('get', `${config.API}/product/license/filter${queryString}`)
        if(res?.data?.success){
            pageDispatch({
                type: useLicenseKeyAction.TABLE_LOADING_DISPLAY,
                payload: false
            })
            const books = res?.data
            pageDispatch({
                type: useLicenseKeyAction.TABLE_UPDATE_DISPLAY_LIST,
                payload: {
                    list: books?.data,
                    listDefault: books?.data,
                    loading: false
                }
            })
            pageDispatch({
                type: useLicenseKeyAction.TABLE_UPDATE_PAGINATION,
                payload: {
                    active:Math.ceil(books?.meta?.start / books?.meta?.per_page),
                    amount: books?.meta?.per_page,
                    total: Math.ceil(books?.meta?.total / books?.meta?.per_page),
                    totalItems: books?.meta?.total,
                }
            })
            pageDispatch({type:useLicenseKeyAction.FILTER_ACTIVE_FORM})
        }
    }
    const handleSubmitFilter = ()=>{
        fetchApiLicense(querySearch)
    }
    const handleTagDelete = (tag)=>{
        let qs = {}
        switch (tag) {
            case FILTER_TAG[0]:
                qs={...querySearch, product_name:''}
                break;
            case FILTER_TAG[1]:
                qs={...querySearch, keyword:''}
                break;
            case FILTER_TAG[2]:
                qs={...querySearch, status:''}
                break;
            default:
                break
        }
        pageDispatch({
            type:useLicenseKeyAction.FILTER_FORM_TAG_DELETE,
            payload: tag
        })
        fetchApiLicense(qs)
    }

    const handleTagDeleteAll = ()=>{
        fetchApiLicense({...querySearch,
            product_name:'',
            keyword:'',
            status:''
        })
        FILTER_TAG?.forEach(each =>  pageDispatch({
            type:useLicenseKeyAction.FILTER_FORM_TAG_DELETE,
            payload: each
        }))

    }
    return{
        canSubmitForm,
        submitBadge,
        querySearch,
        methods:{
            applyOtherFilter: handleSubmitFilter,
            filterTagDelete: handleTagDelete,
            filterTagDeleteAll: handleTagDeleteAll,
            fetchApiLicense
        },
        productName:{
            value: valueProductName,
            active:activeProductName,
            onChangeValue: handleChangeProductName
        },
        keyCode:{
            value: valueKeyCode,
            active:activeKeyCode,
            onChangeValue: handleChangeKeyCode
        },
        status:{
            value: valueStatus,
            active:activeStatus,
            onChangeValue: handleChangeStatus
        }
    }
}