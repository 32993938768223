import useProfileUpdate from '../../hooks/useProfileUpdate'
import { Text } from '../../../../common/text'
import { Grid } from '@mui/material'
import { Input } from '../../../../common/form/input'
import { THEME_SEMANTICS } from '../../../../common/theme/_semantics'
import styled from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { THEME_COLORS } from 'common/theme/_colors'

const InfoBasic = () => {
  const { t } = useTranslation()
  const { value, functions } = useProfileUpdate()
  const data = value?.userData;
  
  return (
    <StyledInfoBasic>
      <Grid container spacing={2}>
        <Grid xs={6} sm={6} md={6} lg={6} item>
          <div className="product-info-basic__form-input ">
            <Input
              label={'Email'}
              disabled = {true}
              value={data?.email}
            />
          </div>
        </Grid>
        <Grid xs={6} sm={6} md={6} lg={6} item>
          <div className="product-info-basic__form-input ">
            <Input
              label={
                <>
                  {t('Username')}
                </>
              }
              disabled = {true}
              value={data?.username}
            />
          </div>
        </Grid>
        <Grid xs={6} sm={6} md={6} lg={6} item>
          <div className="product-info-basic__form-input-1">
            <Input
              label={
                <>
                  {t('Họ')} <Text color={THEME_SEMANTICS.failed}>*</Text>
                </>
              }
              value={data?.last_name}
              onChange={e => functions.onChangeLastName(e.target?.value)}
              onBlur={e => functions.onBlurLastName(e.target?.value)}
              validateText={value?.formValidate?.last_name?.message}
              validateType={value?.formValidate?.last_name?.status ? 'danger' : 'success'}
              placeholder={t('Vui lòng nhập họ')}
              maxLength={200}
            />
          </div>
        </Grid>
        <Grid xs={6} sm={6} md={6} lg={6} item>
          <div className="product-info-basic__form-input-1">
            <Input
              label={
                <>
                  {t('Tên')} <Text color={THEME_SEMANTICS.failed}>*</Text>
                </>
              }
              value={data?.name}
              onChange={e => functions.onChangeName(e.target?.value)}
              onBlur={e => functions.onBlurName(e.target?.value)}
              validateText={value?.formValidate?.name?.message}
              validateType={value?.formValidate?.name?.status ? 'danger' : 'success'}
              placeholder={t('Vui lòng nhập tên')}
              maxLength={200}
            />
          </div>
        </Grid>
        <Grid xs={6} sm={6} md={6} lg={6} item>
          <div className="product-info-basic__form-input-1">
            <Input
              label={
                <>
                  {t('phone')}
                </>
              }
              value={data?.phone}
              onChange={e => functions.onChangePhone(e.target?.value)}
              onBlur={e => functions.onBlurPhone(e.target?.value)}
              validateText={value?.formValidate?.phone?.message}
              validateType={value?.formValidate?.phone?.status ? 'danger' : 'success'}
              placeholder={t('Vui lòng nhập số điện thoại')}
              maxLength={50}
            />
          </div>
        </Grid>
      </Grid>
    </StyledInfoBasic>
  )
}

export default InfoBasic

export const StyledInfoBasic = styled.div`
  .product-info-basic {
    &__toggle-item {
      position: absolute;
      top: 27px;
      right: 24px;
    }
    &__form-input-1 {
      margin-top: 24px;
    }
    &__form-input-code {
      margin-right: 8px;
    }
    &__form-input-barcode {
      margin-left: 8px;
    }
    &__form-input-group-product {
      position: relative;
      &--add {
        position: absolute;
        bottom: 72px;
        right: 24px;
        font-size: 14px;
        z-index: 2;
        
        svg {
          margin-left: 4px;
        }
        
        div {
          position: absolute;
          left: -24px;
          top: -2px;
          cursor: pointer;
        }
        span {
          color: #1A94FF;
          cursor: pointer;
        }
      }
    }
    
    &__list-parent {
      width: 50%;
      height: 260px;
      overflow: auto;
      &--wrapper {
        //height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 8px 0;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        
        & span:hover {
          color: rgb(229, 16, 29) !important;
        }
        &[data-type='true'] span{
          color: rgb(229, 16, 29) !important;
        }
      }
      &--tooltip {
        max-width: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 8px;
      }
    }
    &__list-child {
      border-left: 1px solid #EBEEF5;
      padding-left: 20px;
      width: 48%;
      height: 260px;
      overflow: auto;
      &--wrapper {
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 16px 0;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
      }
      &--tooltip {
        display: -webkit-box;
        height: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  
.store-upos-filter-form {
    &__group {
      width: calc(100% + 12px);
      margin: 0 -6px 16px -6px;

      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & > * {
        margin: 0 6px;
      }
    }

    &__collapse {
      max-height: 0;
      margin-bottom: 0 !important;

      overflow: hidden;

      transition: all 0.25s;

      &[data-collapse='true'] {
        max-height: 50vh;
        margin-bottom: 16px;

        overflow: unset;
      }
    }

    &__input-wide {
      width: calc(25% - 12px);
      margin: 0 6px;

      @media screen and (max-width: 1599px) {
        width: calc((100% / 3) - 12px);
      }
    }

    &__option-text {
      min-height: 36px;

      display: flex;
      align-items: center;

      color: ${THEME_COLORS.gray_900};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      cursor: pointer;

      transition: color 0.25s;

      &[data-active='true'] {
        color: ${THEME_COLORS.primary_300};
        font-weight: 600;
      }
      &:hover {
        color: ${THEME_COLORS.primary_300};
      }
    }

    &__option-container {
      min-height: 45px;
      margin-bottom: 4px;

      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__option-tabs {
      position: sticky;
      top: 0;
      z-index: 1;

      width: 100%;
      height: 28px;
      margin-bottom: 16px;

      display: flex;
      align-items: center;

      background: #fff;

      &::before {
        position: absolute;
        top: -20px;
        left: -20px;

        width: calc(100% + 40px);
        height: calc(100% + 36px);

        background: #fff;
        border-radius: 8px 8px 0 0;

        content: '';
      }
    }

    &__option-tab {
      position: relative;
      z-index: 2;

      margin-right: 16px;

      color: #808089;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      cursor: pointer;

      &[data-active='true'] {
        color: ${THEME_COLORS.primary_300};
        font-weight: 600;

        cursor: default;
      }
    }

    &__collapse {
      .store-upos-filter-form__input-wide {
        margin-bottom: 16px;
      }
    }
  }
`
