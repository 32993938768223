import { GLOBAL_ICONS } from '../../../interface/icon'

export const INVOICE_BREADCRUMB = [
  {id: 1, name: 'Trang chủ', url: '/'},
  {id: 2, name: 'Kế toán', url: '/'},
  {id: 3, name: 'Phiếu chi hoa hồng', url: '/invoice'},
]

export const INVOICE_STATUS=[
  {name: 'Mới', value: 'new'},
  {name: 'Hoàn thành', value: 'completed'},
  {name: 'Hủy', value: 'canceled'},
]
export const PAYMENT_TYPE=[
  {name: 'Tiền mặt', value: 'CASH'},
  {name: 'Chuyển khoản', value: 'BANK_TRANSFER'},
]
export const INVOICE_FILTER_FORM_DATE_TIME_SORT_TYPES = [
  {id: 1, name: 'Ngày tạo', value: 'created'},
]

export const INVOICE_FILTER_TAG_FIELDS = [
  'invoiceInfo',
  'dateTime',
  'principal',
  'employee',
  'month_time',
  'payment_status',
  'payment_method',
]

export const INVOICE_LIMIT_EXPORT = 5000

export const INVOICE_TABLE_CELL_PAYMENT_TYPES = {
  danger: 'Thanh toán thất bại',
  success: 'Đã thanh toán',
  warning: 'Đã thanh toán một phần',
  waiting: 'Chưa thanh toán',
}

export const INVOICE_TABLE_CELL_SHIPPING_STATUSES = {
  new: {background: '#EBF5FF', color: '#1A94FF'},
  delivery: {background: '#EFFBF4', color: '#33CC70'},
  processing: {background: '#ECF4FE', color: '#0B74E5'},
  completed: {background: '#EBFFF5', color: '#00AB56'},
  canceled: {background: '#FFEBF2', color: '#FC5994'},
  _6: {background: '#EBFFF9', color: '#007B56'},
  _7: {background: '#FFEBEB', color: '#FF7471'},
  _8: {background: '#EBFAFF', color: '#038DB2'},
  _15: {background: '#EFF3FB', color: '#7C88A6'},
  _17: {background: '#FFF0EB', color: '#FC4C0A'},
  _19: {background: '#EBFFF4', color: '#007D3A'},
  _20: {background: '#FFF5EB', color: '#FC820A'},
  _21: {background: '#EFF3FB', color: '#7C88A6'},
  _22: {background: '#EBF8FE', color: '#1BACF9'},
  _23: {background: '#FFEBEC', color: '#FF424E'},
}

export const INVOICE_TABLE_ROW_MENU_POPOVER =
  {
    new: [
      {
        id: 1,
        name: 'Chỉnh sửa',
        icon: GLOBAL_ICONS.edit,
        action: 'edit',
      },
      {
        id: 2,
        name: 'In phiếu chi',
        icon: GLOBAL_ICONS.printer,
        action: 'print',
      },
      {
        id: 3,
        name: 'Hoàn thành phiếu chi',
        icon: GLOBAL_ICONS.confirmOrder,
        action: 'completed',
      },
      {
        id: 6,
        name: 'Hủy phiếu chi',
        icon: GLOBAL_ICONS.packageX,
        action: 'cancel-invoice',
      }
    ],
    completed: [
      {
        id: 2,
        name: 'In phiếu chi',
        icon: GLOBAL_ICONS.printer,
        action: 'print',
      },
    ],
    canceled: [
      {
        id: 2,
        name: 'In phiếu chi',
        icon: GLOBAL_ICONS.printer,
        action: 'print',
      },
    ]

  }

export const INVOICE_TABLE_ROW_EXTRA_TABS = [
  {id: 1, name: 'Chi tiết phiếu chi', value: 'detail'},
  {id: 3, name: 'Chi tiết Kỳ thanh toán', value: 'payment'},
]


export const INVOICE_TABLE_THEAD_PAYMENT_FILTER_LIST = [
  {id: 1, name: 'Đã thanh toán', value: 'paid'},
  {id: 2, name: 'Chưa thanh toán', value: 'unpaid'},
  {id: 3, name: 'Thanh toán 1 phần', value: 'partial'},
]

export const EXPORT_INVOICE_EXCEL_SELECTIVE=[
  {id:'stt', name:'STT', position: 0, default:1, status: 1},
  {id:'invoice_id', name:'Mã khách hàng', position: 1, default:1, status: 1},
  {id:'sku', name:'Mã SKU sản phẩm', position: 2, default:1, status: 1},
  {id:'invoice_name', name:'Tên khách hàng', position: 3, default:1, status: 1},
  {id:'dt_created', name:'Ngày mua hàng', position: '', default:0, status: 0},
  {id:'total_amount', name:' Giá trị khách hàng ', position: '', default:0, status: 0},
  {id:'shipping_status_name', name:'Trạng thái khách hàng', position: '', default:0, status: 0},
  {id:'invoiceStatus_name', name:'Kho xuất hàng', position: '', default:0, status: 0},
  {id:'origin_name', name:'Nguồn bán hàng', position: '', default:0, status: 0},
  {id:'invoice_note', name:'Ghi chú khách hàng', position: '', default:0, status: 0},
  {id:'product_name', name:'Tên sản phẩm', position: '', default:0, status: 0},
  {id:'quantity', name:'Số lượng', position: '', default:0, status: 0},
  {id:'price', name:'Giá bán', position: '', default:0, status: 0},
  {id:'discount', name:'Giảm giá SP', position: '', default:0, status: 0},
  {id:'invoice_discount', name:'Giảm giá ĐH', position: '', default:0, status: 0},
  {id:'total', name:' Thành tiền ', position: '', default:0, status: 0},
  {id:'invoice_phone', name:'Số điện thoại', position: '', default:0, status: 0},
  {id:'billcode', name:'Mã vận khách', position: '', default:0, status: 0},
  {id:'dt_send_shipping', name:'Ngày gửi giao hàng', position: '', default:0, status: 0},
  {id:'shipping_name', name:'Đối tác vận chuyển', position: '', default:0, status: 0},
  {id:'address', name:'Địa chỉ', position: '', default:0, status: 0},
  {id:'city_name', name:'Tỉnh/Thành', position: '', default:0, status: 0},
  {id:'district_name', name:'Quận/Huyện', position: '', default:0, status: 0},
  {id:'ward_name', name:'Phường/Xã', position: '', default:0, status: 0},
  {id:'cod', name:'Tiền thu hộ', position: '', default:0, status: 0},
  {id:'payment_method', name:'Phương thức thanh toán', position: '', default:0, status: 0},
  {id:'note', name:'Ghi chú giao hàng', position: '', default:0, status: 0},
  {id:'weight', name:'Trọng lượng (Kg)', position: '', default:0, status: 0},
  {id:'length', name:'Dài (cm)', position: '', default:0, status: 0},
  {id:'width', name:'Rộng (cm)', position: '', default:0, status: 0},
  {id:'height', name:'Cao (cm)', position: '', default:0, status: 0},
  {id:'employee_name', name:'Tên nhân viên', position: '', default:0, status: 0},
  {id:'employee_phone', name:'Số điện thoại', position: '', default:0, status: 0},
];
export const EXPORT_EXCEL_SELECTIVE_ACTIVE=[
  {id:'stt', name:'STT', position: 0, default:1, status: 1},
  {id:'invoice_id', name:'Mã khách hàng', position: 1, default:1, status: 1},
  {id:'sku', name:'Mã SKU sản phẩm', position: 2, default:1, status: 1},
  {id:'invoice_name', name:'Tên khách hàng', position: 3, default:1, status: 1},
]
