import React, {useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {
  DIGITAL_COLUMN_NAMES,
  STORE_UPOS_BREADCRUMB,
  STORE_UPOS_HEADER_ACTIONS
} from '../../interface/_contants'
import {PageHeader} from "../../../../../layouts/pageHeader";
import useDigitalManagementFilter from '../../hooks/useDigitalManagementFilter'
import { DigitalManagementContext } from '../../provider/_context'
import { digitalManagementActions } from '../../provider/_init'
import { ImportFileModal } from '../../../../../Component/importComponent'
import { ResultImport } from '../../../book/components/modal/_resultImport'
import useAlert from "../../../../../hook/useAlert";
import {ModalExport} from "../../../../../common/modalExport";

const Header = () => {
  const {methods,queries, pageState} = useDigitalManagementFilter()
   const [exportModalData, setExportModalData] = useState(null)
   const {showAlert} = useAlert()

   const handleExportClick = () => {
      let queryString = '?'
      let i = 0
      for (const [key, value] of Object.entries({
         ...queries,
         per_page: '',
         start: '',
      })) {
         queryString += `${i > 0 ? '&' : ''}${key}=${value}`
         i++
      }

      handleLargeExport(
         {
            ...queries,
            per_page: '',
            start: '',
         }
      )
   }

   const handleLargeExport = (q) => {
      if (pageState.table.pagination.totalItems <= 0) {
         showAlert({type: 'info', content: `Số lượng sản phẩm phải lớn hơn 0`})
         return
      }

      setExportModalData({
         data: {
            query: q,
            total: pageState.table.pagination.totalItems,
         },
         onClose: () => setExportModalData(null),
      })
   }
  const navigate = useNavigate()

  const { state, dispatch } = useContext(DigitalManagementContext)

  const onCloseImport = () => {
    dispatch({ type: digitalManagementActions.MODAL_IMPORT_PRINCIPAL, payload: false })
  }
  const handleGetResult = res => {
    if (res) {
      dispatch({
        type: digitalManagementActions.MODAL_IMPORT_RESULT_OPEN,
        payload: {
          open: true,
          data: res
        }
      })
      methods?.applyOtherFilter()
    }
  }
  const handleCloseResult = _ => {
    dispatch({
      type: digitalManagementActions.MODAL_IMPORT_RESULT_OPEN,
      payload: {
        open: false,
        data: null
      }
    })
  }

  const handleCollectionData = (data) => {
    let sliceData = data?.slice(
      DIGITAL_COLUMN_NAMES?.startRowNumber - 1
    )
    if(sliceData.length > 1000) sliceData = sliceData.slice(0, 1000)
    let qs = {
      items: sliceData?.map((map, i) => (
        {
          'row': +DIGITAL_COLUMN_NAMES?.startRowNumber + i,
          'ordering': map[0],
          'title': map[1],
          'product_sku': map[2],
          'category_name': map[3],
          "option_platform": map[4],
          "option_number_devices": map[5],
          "status" : map[6],
          "option_language": map[7],
          "option_license_term": map[8],
          "price": map[9],
          "price_discount": map[10],
          "discount_started_at": map[11],
          "discount_ended_at": map[12],
          "short_description" : map[13],
          "description" : map[14],
        }
      ))
    }
    return qs
  }

  const handleCollectionCsv = data =>{
    const sliceData = data?.slice(4)
    const keys = sliceData?.map(map=>Object.keys(map))[0]
    let qs={
      items: sliceData?.map((map, i)=>(
        {
          'row': +DIGITAL_COLUMN_NAMES?.startRowNumber + i,
          'ordering': map[keys[0]],
          'title':map[keys[1]],
          'product_sku': map[keys[2]],
          'category_name': map[keys[3]],
          "option_platform": map[keys[4]],
          "option_number_devices": map[keys[5]],
          "status" : map[keys[6]],
          "option_language": map[keys[7]],
          "option_license_term": map[keys[8]],
          "price": map[keys[9]],
          "price_discount": map[keys[10]],
          "discount_started_at": map[keys[11]],
          "discount_ended_at": map[keys[12]],
          "short_description" : map[keys[13]],
          "description" : map[keys[14]],
        }
      ))
    }
    return qs
  }

  const actions = [
    methods.applyOtherFilter,
    () => dispatch({ type: digitalManagementActions.MODAL_IMPORT_PRINCIPAL, payload: true }),
     handleExportClick,
    () => navigate('/digital-product/create')
  ]

  return (
    <>
      <PageHeader
        actions={STORE_UPOS_HEADER_ACTIONS.map((item, i) => ({
          ...item,
          onClick: actions[i],
        }))}
        breadcrumbLinks={STORE_UPOS_BREADCRUMB}
        breadcrumbTitle={'Quản lý sản phẩm số'}
      />

       {!!exportModalData && <ModalExport data={exportModalData} title={'sản phẩm'} api={`/product/digital/export`}/>}
      {
        state?.importExcel?.open && <ImportFileModal
          onClose={onCloseImport}
          columnNames={DIGITAL_COLUMN_NAMES?.columnNames}
          startRowNumber={DIGITAL_COLUMN_NAMES?.startRowNumber}
          collectedData={handleCollectionData}
          url={'product/digital/import'}
          title={'Tạo sản phẩm số bằng Excel'}
          importFile={'/files/product_digital_template.xlsx'}
          positionStart={2}
          result={handleGetResult}
          positionCsvStart={3}
          collectedDataCsv={handleCollectionCsv}
        />
      }
      {
        state?.importExcel?.result?.open && <ResultImport
          open={state?.importExcel?.result?.open}
          data={state?.importExcel?.result?.data}
          title={'Sản phẩm số'}
          onClose={handleCloseResult}
        />
      }
    </>
  )
}

export default Header;