
export const profileActions = {
    //======== INIT ==========
    CHANGE_LOADING : 'CHANGE_LOADING',
    HOVER_AVATAR : 'HOVER_AVATAR',
    FORM_INIT_USER_DATA : 'FORM_INIT_USER_DATA',
    UPDATE_USER_INFORMATION : 'UPDATE_USER_INFORMATION',
    FORM_UPLOAD_AVATAR : 'FORM_UPLOAD_AVATAR',

    //========= FORM ===========
    FORM_CHANGE_NAME: 'FORM_CHANGE_NAME',
    FORM_CHANGE_LAST_NAME: 'FORM_CHANGE_LAST_NAME',
    FORM_CHANGE_PHONE: 'FORM_CHANGE_PHONE',

    //========= VALIDATE FORM ===========
    VALIDATE_FORM_CHANGE_NAME: 'VALIDATE_FORM_CHANGE_NAME',
    VALIDATE_FORM_CHANGE_LAST_NAME: 'VALIDATE_FORM_CHANGE_LAST_NAME',
    VALIDATE_FORM_CHANGE_PHONE: 'VALIDATE_FORM_CHANGE_PHONE',
}