import { useEffect, useReducer } from 'react'
import { sendRequestAuth } from 'api/api'
import {
  useCreatePrincipalAction,
  useCreatePrincipalReducer,
} from '../provider/_reducer'
import config from '../../../../../config'
import { CREATE_PRINCIPAL_GENDER, STATUS_PRINCIPAL, TAB_CREATE_PRINCIPAL_EDIT } from '../interfaces/constant'
import { usePrincipalAction } from '../../../provider/_reducer'
import { useParams, useSearchParams } from 'react-router-dom'
import { useCreatePrincipalInitialState } from '../provider/_initState'
import { useCreateProductAction, useModalProductAction } from '../provider/_action'

const useCreatePrincipal = () => {
  const [state, dispatch] = useReducer(useCreatePrincipalReducer, useCreatePrincipalInitialState)
  const [searchParams] = useSearchParams()
  const { principalId } = useParams()
  const detailList = state.table.detail.list
  useEffect(() => {
    if (searchParams.get('tab') === 'teachers' || !!searchParams.get('teacherId'))
      dispatch({ type: useCreatePrincipalAction.FORM_CREATE_TAB_CHANGE, payload: TAB_CREATE_PRINCIPAL_EDIT[1] })
    if (searchParams.get('tab') === 'customers')
      dispatch({ type: useCreatePrincipalAction.FORM_CREATE_TAB_CHANGE, payload: TAB_CREATE_PRINCIPAL_EDIT[2] })
    if (searchParams.get('tab') === 'products')
      dispatch({ type: useCreatePrincipalAction.FORM_CREATE_TAB_CHANGE, payload: TAB_CREATE_PRINCIPAL_EDIT[3] })
    if (!!searchParams.get('teacherId')) {
      dispatch({ type: useCreatePrincipalAction.FORM_CREATE_TAB_CHANGE, payload: TAB_CREATE_PRINCIPAL_EDIT[1] })
      fetchOrderDetail(searchParams.get('teacherId'))
    }
  }, [searchParams])

  const handleOriginFetch = async (id) => {
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/principal/detail/${id}`),
      sendRequestAuth('get', `${config.API}/principal/teachers/${id}?start=0&per_page=20`),
    ])
    if (response[0]?.data?.success && response[1]?.data?.success) {
      const detail = response[0]?.data?.data
      const res = response[1]?.data
      dispatch({
        type: useCreatePrincipalAction.FORM_CREATE_GET_DETAIL,
        payload: {
          info: {
            userName: {
              value: detail?.username || '',
              status: false,
              message: ''
            },
            schoolName: {
              value: detail?.school_name || '',
              status: false,
              message: ''
            },
            phone: {
              value: detail?.phone || '',
              status: false,
              message: ''
            },
            email: {
              value: detail?.email || '',
              status: false,
              message: ''
            },
            name: {
              value: detail?.name || '',
              status: false,
              message: ''
            },
            lastName: {
              value: detail?.last_name || '',
              status: false,
              message: ''
            },
            birthday: {
              value: detail?.birthday ? new Date(detail?.birthday) : '',
              status: false,
              message: '',
              trigger: !state.infomation?.birthdate?.trigger
            },
            identify: {
              value: detail?.identity_number || '',
              status: false,
              message: ''
            },
            gender: {
              value: CREATE_PRINCIPAL_GENDER?.find(find => find.value === detail?.gender),
              status: false,
              message: ''
            },
            address: {
              value: detail?.address,
              status: false,
              message: ''
            },
            password: {
              value: '',
              status: false,
              message: ''
            },
            commission: {
              value: detail?.commission_rate,
              status: false,
              message: ''
            },
            statusPerson: {
              value: STATUS_PRINCIPAL?.find(find => find.value === detail?.status),
              status: false,
              message: ''
            },
            accountNumber: {
              value: detail?.bank_number,
              status: false,
              message: ''
            },
            accountOwner: {
              value: detail?.bank_owner,
              status: false,
              message: ''
            },
            bankName: {
              value: detail?.bank_name,
              status: false,
              message: ''
            },
            avatar: {
              value: detail?.avatar || ''
            },
            detail: detail
          }
        }
      })
      dispatch({
        type: useCreatePrincipalAction.TABLE_UPDATE_PAGINATION,
        payload: {
          active: 0,
          amount: res?.meta?.per_page,
          total: Math.ceil(res?.meta?.total / res?.meta?.per_page),
          totalItems: res?.meta?.total,
        }
      })
      dispatch({
        type: usePrincipalAction.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: res?.data,
          listDefault: res?.data,
          loading: false
        }
      })
    }

    const res = await Promise.all([
      sendRequestAuth('get', `${config.API}/principal/products/${principalId}?keyword&category_id&status&start=0&per_page=20`),
      sendRequestAuth('get', `${config.API}/product-category/group-categories?type=digital&is_parent=0`),
    ])
    if (res[0]?.data?.success) {
      dispatch({
        type: useCreateProductAction.TABLE_LOADING_DISPLAY,
        payload: false
      })
      const products = res[0]?.data
      dispatch({
        type: useCreateProductAction.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: products?.data,
          listDefault: products?.data,
          loading: false
        }
      })
      dispatch({
        type: useCreateProductAction.TABLE_UPDATE_PAGINATION,
        payload: {
          active: Math.ceil(products?.meta?.start / products?.meta?.per_page),
          amount: products?.meta?.per_page,
          total: Math.ceil(products?.meta?.total / products?.meta?.per_page),
          totalItems: products?.meta?.total,
        }
      })
    }
      if(res[1]?.data?.length > 0) {
          dispatch({
              type: useCreateProductAction.FILTER_CATEGORY_LIST_ORIGIN_UPDATE,
              payload: {
                  list: res[1]?.data,
                  listOrigin: res[1]?.data
              }
          })
          dispatch({
              type: useModalProductAction.FILTER_CATEGORY_LIST_ORIGIN_UPDATE,
              payload: {
                  list: res[1]?.data,
                  listOrigin: res[1]?.data
              }
          })
      }
  }
  const fetchOrderDetail = async id => {
    const response = await sendRequestAuth(
      'get',
      `${config.API}/principal/${principalId}/teacher-detail/${id}`,
    )
    if (!!response?.data?.success) {
      const newItem = response?.data?.data
      dispatch({
        type: useCreatePrincipalAction.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: { active: newItem, list: [...detailList, newItem] },
      })
    }
  }

  return {
    fetch: {
      origin: handleOriginFetch
    },
    provider: {
      state,
      dispatch
    },

  }
}
export default useCreatePrincipal