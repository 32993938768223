import React, { useEffect, useState, useContext } from 'react';
import { userManagementActions } from "../provider/_reducer";
import { getData, sendRequestAuth } from "../../../api/api";
import config from "../../../config";
import { UserManagementContext } from "../provider/_context";
import toast from "../../../Component/Toast";
import { SCRIPT } from "../interfaces/~script";
import { getUrlDetailUserManagement } from "../../../api/url";
import { useNavigate } from "react-router-dom";

const useFilterUserManagement = () => {
    const { pageState, pageDispatch } = useContext(UserManagementContext)
    const stateGroupEmployee = {
        keyword: pageState?.filter?.groupEmployee?.keyword || '',
        value: pageState?.filter?.groupEmployee?.value || [],
        activeValue: pageState?.filter?.groupEmployee?.activeValue || [],
        list: pageState?.filter?.groupEmployee?.list || [],
        listOrigin: pageState?.filter?.groupEmployee?.listOrigin || [],
    }
    const stateGroupStatus = {
        value: pageState?.filter?.groupStatus?.value || [],
        activeValue: pageState?.filter?.groupStatus?.activeValue || [],
        list: pageState?.filter?.groupStatus?.list || [],
        listOrigin: pageState?.filter?.groupStatus?.listOrigin || [],
    }
    // const search = pageState?.search
    const searchFilter = pageState?.filter?.search
    // console.log(searchFilter)

    const [isInit, setIsInit] = useState(true)
    // const [valueSearch, setValueSearch] = useState(search)
    const navigate = useNavigate()
    // useEffect(() => {
    //     if (isInit) return setIsInit(false)

    //     const timeOutId = setTimeout(() => {
    //         const fetchData = async () => {
    //             const response = await Promise.all([
    //                 sendRequestAuth('get', `${config.API}/user/users?keyword=${search}&group&status&per_page=20&start=0`),
    //             ])
    //             if (response[0]?.data?.success) {
    //                 let meta = response[0]?.data.meta
    //                 const perPage = meta?.per_page || 0
    //                 const total = meta?.total || 0
    //                 pageDispatch({ type: userManagementActions.LIST_USER, payload: response[0]?.data?.data })
    //                 pageDispatch({
    //                     type: userManagementActions.GET_PAGINATE, payload: {
    //                         active: meta.current_page - 1,
    //                         amount: perPage,
    //                         total: Math.ceil(meta?.total / meta?.per_page),
    //                         totalItems: total,
    //                     }
    //                 })
    //             }
    //         }
    //         fetchData()
    //     }, 500)
    //     return () => clearTimeout(timeOutId)
    // }, [valueSearch]);

    const handleSearchChange = e => {
        if (e == ' ') e = ''
        const keyword = e.replace(/\s+/g, ' ') || ''
        pageDispatch({
            type: userManagementActions.FILTER_SEARCH_UPDATE,
            payload: {value: keyword},
        })
        // setValueSearch(data)
        // pageDispatch({
        //     type: userManagementActions.SET_SEARCH,
        //     payload: data.target.value.trim(),
        // })
    }

    // =============== GROUP STATUS ==================
    const handleSelectedGroupStatus = data => {
        pageDispatch({
            type: userManagementActions.FILTER_STATUS,
            payload: data
        })
    }
    const handleBlurGroupStatus = () => {
        if (stateGroupStatus?.value?.length === 0) {
            pageDispatch({
                type: userManagementActions.FILTER,
                payload: {
                    search: {...searchFilter},
                    groupStatus: { ...stateGroupStatus, value: '' },
                    groupEmployee: { ...stateGroupEmployee }
                }
            })
        }
    }
    const handleChangeGroupStatus = data => {
        const listByKey = stateGroupStatus.listOrigin.filter((item) => {
            const name = item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace('đ', 'd').toLowerCase()
            const key = data.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace('đ', 'd').toLowerCase().trim()
            return name.includes(key)
        })
        if (!!listByKey) {
            pageDispatch({
                type: userManagementActions.FILTER,
                payload: {
                    search: {...searchFilter},
                    groupStatus: { ...stateGroupStatus, list: listByKey },
                    groupEmployee: { ...stateGroupEmployee},
                }
            })
        }
    }

    // =============== GROUP EMPLOYEE ==================
    const handleSelectedGroupEmployee = data => {
        pageDispatch({
            type: userManagementActions.FILTER_EMPLOYEE_UPDATE,
            payload: { value: data },
        })
    }
    const handleChangeGroupEmployee = data => {
        const listByKey = stateGroupEmployee.listOrigin.filter((item) => {
            const name = item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace('đ', 'd').toLowerCase()
            const key = data.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace('đ', 'd').toLowerCase().trim()
            return name.includes(key)
        })
        if (!!listByKey) {
            pageDispatch({
                type: userManagementActions.FILTER,
                payload: {
                    search: {...searchFilter},
                    groupStatus: { ...stateGroupStatus },
                    groupEmployee: { ...stateGroupEmployee, list: listByKey },
                }
            })
        }
    }
    const handleFocusGroupEmployee = () => {
        pageDispatch({
            type: userManagementActions.FILTER,
            payload: {
                search: {...searchFilter},
                groupStatus: { ...stateGroupStatus },
                groupEmployee: { ...stateGroupEmployee, list: stateGroupEmployee.listOrigin },
            }
        })
    }


    // APPLY
    const queries = {
        keyword: searchFilter?.value || '',
        // keyword: searchFilter?.value.trim() || '',
        group: stateGroupEmployee?.value || [],
        status: stateGroupStatus?.value || [],
        per_page: 20,
        start: 0,
    }
    const fetchUserByFilter = async (qs, opt) => {
        // if (Number.isNaN(opt?.activePage) || opt?.forceLoading)
        //   pageDispatch({
        //     type: orderActions.TABLE_DISPLAY_LOADING_UPDATE,
        //     payload: {table: {display: {loading: true}}},
        //   })

        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(qs)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        const response = await Promise.all([
            sendRequestAuth('get', `${config.API}/user/users${queryString}`),
        ])
        if (response[0]?.data?.success && !!response[0]?.data?.data) {
            pageDispatch({ type: userManagementActions.LIST_USER, payload: response[0]?.data?.data })
            pageDispatch({
                type: userManagementActions.FILTER,
                payload: {
                    search: {...searchFilter, active : searchFilter.value},
                    groupStatus: { ...stateGroupStatus, activeValue: stateGroupStatus.value },
                    groupEmployee: { ...stateGroupEmployee, activeValue: stateGroupEmployee.value },
                }
            })
            let meta = response[0]?.data.meta
            const perPage = meta?.per_page || 0
            const total = meta?.total || 0
            pageDispatch({
                type: userManagementActions.GET_PAGINATE, payload: {
                    active: Math.ceil(meta?.start / meta?.per_page),
                    amount: perPage,
                    total: Math.ceil(meta?.total / meta?.per_page),
                    totalItems: total,
                }
            })
        } else {
            toast.error(SCRIPT.ERROR.SEARCH)
        }
    }
    const applyUserOtherFilter = async () => {
        const collection = {
            ...queries,
            keyword: (searchFilter?.value !== '' && searchFilter?.value !== undefined) ? searchFilter?.value : '',
            group: stateGroupEmployee?.value?.id || [],
            status: stateGroupStatus?.value?.id || [],
        }
        fetchUserByFilter(collection, { forceLoading: true })
    }

    // Badge
    const otherFilterBadge = [
        searchFilter?.value,
        stateGroupEmployee?.activeValue.length !== 0,
        stateGroupStatus?.activeValue.length !== 0
    ].filter(item => item === true).length

    const canSubmitOtherFilter = [
        JSON.stringify(searchFilter?.active) !== JSON.stringify(searchFilter?.value),
        JSON.stringify(stateGroupEmployee?.activeValue) !== JSON.stringify(stateGroupEmployee?.value),
        JSON.stringify(stateGroupStatus?.activeValue) !== JSON.stringify(stateGroupStatus?.value),
    ].includes(true)

    const filterTagDelete = async val => {
        let tmpCollection = {
            keyword: searchFilter?.value || '',
            status: stateGroupStatus?.activeValue?.id || '',
            group: stateGroupEmployee?.activeValue?.id || '',
        }
        switch (val) {
            case 'keyword' :
                pageDispatch({
                    type: userManagementActions.FILTER,
                    payload: {
                        search : {value : '', active: ''},
                        groupStatus: { ...stateGroupStatus },
                        groupEmployee: { ...stateGroupEmployee },
                    }
                })
                tmpCollection = {
                    ...tmpCollection,
                    keyword: ''
                }
                break
            case 'status':
                pageDispatch({
                    type: userManagementActions.FILTER,
                    payload: {
                        search : {...searchFilter},
                        groupStatus: { ...stateGroupStatus, activeValue: [], value: [] },
                        groupEmployee: { ...stateGroupEmployee },
                    }
                })
                tmpCollection = {
                    ...tmpCollection,
                    status: ''
                }
                break
            case 'employee':
                pageDispatch({
                    type: userManagementActions.FILTER,
                    payload: {
                        search : {...searchFilter},
                        groupStatus: { ...stateGroupStatus },
                        groupEmployee: { ...stateGroupEmployee, activeValue: [], value: [] },
                    }
                })
                tmpCollection = {
                    ...tmpCollection,
                    group: ''
                }
                break
            default:
                throw new Error()
        }

        const collection = { ...queries, ...tmpCollection }
        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(collection)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        const response = await Promise.all([
            sendRequestAuth('get', `${config.API}/user/users${queryString}`),
        ])
        if (response[0]?.data?.success && !!response[0]?.data?.data) {
            pageDispatch({type: userManagementActions.LIST_USER, payload: response[0]?.data?.data})
            let meta = response[0]?.data.meta
            const perPage = meta?.per_page || 0
            const total = meta?.total || 0
            pageDispatch({
                type: userManagementActions.GET_PAGINATE, payload: {
                    active: Math.ceil(meta?.start / meta?.per_page),
                    amount: perPage,
                    total: Math.ceil(meta?.total / meta?.per_page),
                    totalItems: total,
                }

            })
        } else {
            toast.error(SCRIPT.ERROR.SEARCH)
        }
    }

    const handleDeleteAll = async () => {
        let tmpCollection = {
            keyword: '',
            status: '',
            group: '',
        }
        const collection = { ...queries, ...tmpCollection }
        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(collection)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        const response = await Promise.all([
            sendRequestAuth('get', `${config.API}/user/users${queryString}`),
        ])
        if (response[0]?.data?.success && !!response[0]?.data?.data) {
            pageDispatch({
                type: userManagementActions.FILTER,
                payload: {
                    search: {...searchFilter, value: '', active: ''},
                    groupStatus: { ...stateGroupStatus, activeValue: [], value: [] },
                    groupEmployee: { ...stateGroupEmployee, activeValue: [], value: [] },
                }
            })
            pageDispatch({type: userManagementActions.LIST_USER, payload: response[0]?.data?.data})
            let meta = response[0]?.data.meta
            const perPage = meta?.per_page || 0
            const total = meta?.total || 0
            pageDispatch({
                type: userManagementActions.GET_PAGINATE, payload: {
                    active: Math.ceil(meta?.start / meta?.per_page),
                    amount: perPage,
                    total: Math.ceil(meta?.total / meta?.per_page),
                    totalItems: total,
                }

            })
        } else {
            toast.error(SCRIPT.ERROR.SEARCH)
        }
    }

    const [debounceRefresh, setDebounceRefresh] = useState(true)
    const refresh = async () => {
        if (debounceRefresh) {
            pageDispatch({ type: userManagementActions.LOADING, payload: false })
            setDebounceRefresh(false)

            let tmpCollection = {
                keyword: searchFilter?.value || '',
                status: stateGroupStatus?.activeValue?.id || '',
                group: stateGroupEmployee?.activeValue?.id || '',
                start: pageState?.paginate?.amount * pageState?.paginate?.active || '0',
                per_page: pageState?.paginate?.amount || '20',
            }
            const collection = { ...queries, ...tmpCollection }
            let queryString = '?'
            let i = 0
            for (const [key, value] of Object.entries(collection)) {
                queryString += `${i > 0 ? '&' : ''}${key}=${value}`
                i++
            }
            const response = await Promise.all([
                sendRequestAuth('get', `${config.API}/user/users${queryString}`),
            ])
            if (response[0]?.data?.success && !!response[0]?.data?.data) {
                pageDispatch({
                    type: userManagementActions.FILTER,
                    payload: {
                        groupStatus: { ...stateGroupStatus },
                        groupEmployee: { ...stateGroupEmployee },
                    }
                })
                pageDispatch({type: userManagementActions.LIST_USER, payload: response[0]?.data?.data})

                // FETCH DETAIL
                // if (!!pageState?.detailActive) {
                //     const fetchRowDetail = async (id) => {
                //         const response = await getData(getUrlDetailUserManagement(id))
                //         if (!!response?.data?.success) {
                //             const newItem = response?.data?.data
                //             pageDispatch({
                //                 type: userManagementActions.DETAIL_ACTIVE,
                //                 payload: newItem,
                //             })
                //             pageDispatch({
                //                 type: userManagementActions.DETAIL_LIST,
                //                 payload: [...pageState.detailList, newItem],
                //             })
                //             pageDispatch({
                //                 type: userManagementActions.LOADING_DETAIL,
                //                 payload: true,
                //             })
                //         }
                //     }
                //     fetchRowDetail(pageState?.detailActive?.user_id)
                // }

                setTimeout(() => {
                    setDebounceRefresh(true)
                }, 1000)
            } else { toast.error(SCRIPT.ERROR.SEARCH) }
            pageDispatch({ type: userManagementActions.LOADING, payload: true })
        }
    }

    const [debounceCheckPermission, setDebounceCheckPermission] = useState(true)
    const checkPermission = async () => {
        // if(debounceCheckPermission) {
        //   setDebounceCheckPermission(false)
        //   const response = await Promise.all([
        //     sendRequestAuth('get', `${config.API}/admin/user/users/check`)
        //   ])
        //   setTimeout(() => {setDebounceCheckPermission(true)}, 3000)
        //
        //   if(!response[0]?.data?.success) {
        //     toast.error(response[0]?.data?.message)
        //   } else {
        navigate('/users/create')
        //   }
        // }
    }

    return {
        provider: { pageState, pageDispatch },
        // search: {
        //     value: searchFilter.value,
        //     onChange: handleSearchChange
        // },
        searchFilter: {
            value: searchFilter.value,
            active: searchFilter.active,
            onChange: handleSearchChange
        },
        groupStatus: {
            value: stateGroupStatus.value,
            activeValue: stateGroupStatus.activeValue,
            list: stateGroupStatus.list,
            onSelected: handleSelectedGroupStatus,
            onKeywordChange: handleChangeGroupStatus,
            onBlur: handleBlurGroupStatus,
        },
        groupEmployee: {
            value: stateGroupEmployee.value,
            activeValue: stateGroupEmployee.activeValue,
            list: stateGroupEmployee.list,
            onSelected: handleSelectedGroupEmployee,
            onKeywordChange: handleChangeGroupEmployee,
            onFocus: handleFocusGroupEmployee
        },
        badge: {
            others: otherFilterBadge,
        },
        functions: {
            applyUserOtherFilter,
            canSubmitOtherFilter,
            filterTagDelete,
            handleDeleteAll,
            refresh,
            checkPermission
        }
    }
};

export default useFilterUserManagement