import {useReducer} from 'react'
import {productInitialState} from '../provider/~initState'
import {productReducer} from '../provider/~reducer'
import {sendRequestAuth} from '../../../api/api'
import config from '../../../config'
import {productActions, useCreateProductRelateAction, useModalProductRelateAction} from '../provider/~action'
import {useLocation, useSearchParams} from 'react-router-dom'
import {STATUS_PRODUCT} from '../../products/book/interface/_contants'
import {BACKGOUND_PRODUCT, OPTION_MINIAPP_ACTIVE, OPTION_MINIAPP_SEND_KEY} from '../interfaces/~constants'
import {formatDatetime} from '../../../common/form/datePicker/_functions'
import {fNumber} from '../../../util/formatNumber'

const useProduct = () => {
   const [state, dispatch] = useReducer(productReducer, productInitialState)
   const location = useLocation()?.pathname?.split('/')
   let [searchParams] = useSearchParams()
   const handleOriginFetch = async () => {
      const response = await Promise.all([
         sendRequestAuth('get', `${config.API}/product-category/group-categories?type=digital&is_parent=0`),
      ])
      if (response[0]?.data?.length > 0) {
         const listCategories = response[0]?.data
         dispatch({
            type: productActions.FORM_CREATE_ADD_LIST_ORIGIN, payload: {
               list: listCategories,
               listOrigin: listCategories,
            }
         })
         dispatch({
            type: useModalProductRelateAction.FILTER_CATEGORY_LIST_ORIGIN_UPDATE,
            payload: {
               list: listCategories,
               listOrigin: listCategories
            }
         })
      }

      if (location[2] === 'edit') {
         const listCategories = response[0]?.data
         const url = `${config.API}/product/digital/detail/${location[3]}`
         const res = await sendRequestAuth('get', url)
         if (res?.data?.success) {
            const data = res?.data?.data
            const endDate = new Date(data?.discount_ended_at)
            const startDate = new Date(data?.discount_started_at)
            // Basic
            const arrImage = !!data?.images ? JSON.parse(data?.images) : []
            const formatDateTimeDefaultValue = `${formatDatetime(data?.discount_started_at)} - ${formatDatetime(data?.discount_ended_at)}`
            const defaultState = arrImage.length > 0
               ? arrImage.map((item, i) => ({
                  id: i + 100,
                  name: `image-${i}`,
                  url: item,
               }))
               : []
            const defaultQR = !!data?.qrcode ? [{
               id: 100,
               name: `image-${100}`,
               url: data.qrcode,
            }] : []
            const categoryIds = data?.category_id.split(',');
            dispatch(
               {
                  type: productActions.FORM_CREATE_CHANGE,
                  payload: {
                     basic: {
                        status: STATUS_PRODUCT.find(item => item.value === data?.status),
                        name: data?.title,
                        productSku: data?.product_sku,
                        copyrightTerm: data?.option_license_term,
                        publisher: data?.option_publisher,
                        platform: data?.option_platform,
                        language: data?.option_language,
                        devices: data?.option_number_devices,
                        optionMiniappActive: OPTION_MINIAPP_ACTIVE.find(item => +item.value === (!!data?.option_miniapp_active ? +data.option_miniapp_active : 0)) || {},
                        optionMiniappSendKey: OPTION_MINIAPP_SEND_KEY.find(item => +item.value === (!!data?.option_send_license ? +data.option_send_license : 0)) || {},
                        groupProduct: {
                           id: '',
                           value: listCategories?.filter(item => categoryIds.includes(item.id)),
                           list: listCategories,
                           listOrigin: listCategories,
                           keyword: '',
                           tab: 'all'
                        },
                     },
                     active: {
                        image: {
                           id: '',
                           name: '',
                           link: data?.qrcode || '',
                           list: defaultQR,
                           listOrigin: defaultQR.map(item => item?.id),
                           imageFiles: []
                        },
                        link_download: data?.link_download || null,
                        file: {
                           file_name: data?.use_guide || null,
                           file_url: data?.use_guide || null,
                        }
                     },
                     product: {
                        image: {
                           id: '',
                           name: '',
                           link: !!data?.images ? JSON.parse(data?.images) : [],
                           list: defaultState,
                           listOrigin: defaultState.map(item => item.id),
                           imageFiles: []
                        },
                        size: data?.option_size,
                        cover: BACKGOUND_PRODUCT.find(item => item.value == data?.option_book_cover) || {},
                        description: data?.description,
                        shortDescription: data?.short_description,
                        note: {
                           open: false,
                           content: '',
                        },
                        validate: {
                           image: {
                              status: false,
                              message: ''
                           },
                        },
                     },
                     price: {
                        retail: fNumber(data?.price),
                        discount: fNumber(data?.price_discount),
                        dateTime: {
                           end: !!data?.discount_ended_at ? endDate : null,
                           start: !!data?.discount_started_at ? startDate : null,
                           trigger: true,
                           value: (!!data?.discount_ended_at && !!data?.discount_started_at) ? formatDateTimeDefaultValue : null,
                        },
                        validate: {
                           retail: {
                              status: false,
                              message: '',
                           },
                           discount: {
                              status: false,
                              message: '',
                           },
                        }
                     },
                  }
               })
            dispatch({
               type: productActions.CHANGE_FORM_STATUS,
               payload: 'edit'
            })
         }

         const resProduct = await sendRequestAuth('get', `${config.API}/product/digital/related-products/${location[3]}?start=0&per_page=200`)
         if (resProduct.data?.success) {

            dispatch({
               type: useCreateProductRelateAction.TABLE_LOADING_DISPLAY,
               payload: false
            })
            const products = resProduct?.data
            dispatch({
               type: useCreateProductRelateAction.TABLE_UPDATE_DISPLAY_LIST,
               payload: {
                  list: products?.data,
                  listDefault: products?.data,
                  loading: false
               }
            })
         }
      }
   }

   return {
      fetch: {
         origin: handleOriginFetch
      },
      provider: {
         state,
         dispatch
      }
   }
}
export default useProduct