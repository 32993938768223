import React, { useContext, useState } from 'react'
import { Tr } from 'layouts/tableLayout/_tr'
import { Td } from 'layouts/tableLayout/_td'
import { Text } from 'common/text'
import { Checkbox } from 'common/form/checkbox'
import { GLOBAL_ICONS } from 'interface/icon'
import { Button } from 'common/button'
import { ConfirmDeleteModal } from './_confirmDeleteModal'
import useAlert from 'hook/useAlert'
import {useCreateProductRelateAction} from "../../../provider/~action";
import {ProductContext} from "../../../provider/~context";
import {useProductRelateTable} from "../../../hooks/useProductRelateTable";


export const PRODUCT_TABLE_HEADER =[
  {name: 'Tên sản phẩm', id: 1},
  {name: 'Giá bán', id: 2},
  {name: 'Giá khuyến mãi', id: 3},
  {name: 'Thời hạn bản quyền', id: 4},
]

export const TableTeacherHeader = () => {
  const { checkbox, selected, methods } = useProductRelateTable()
  const { pageState, pageDispatch } = useContext(ProductContext)
  const displayList = pageState.formCreate.productRelated.table.display.list
  const [confirmDeleteModalData, setConfirmDeleteModalData] = useState(false)
  const [canDelete, setCanDelete] = useState(true)
  const [submit, setSubmit] = useState(() => {})
  const {showAlert} = useAlert()

  const checkFullPageChecked = () => {
    let checkFullPage = true
    displayList.forEach(item => {
      const findItem = selected.list.find(
        find => find.order_id === item.order_id,
      )
      if (!!!findItem) checkFullPage = false
    })
    return checkFullPage
  }

  const handeSubmitDelete = () => {
    setCanDelete(false)
    setConfirmDeleteModalData(null)
    if (!canDelete) return
    const response = submit()
    response.then(() => {
      setCanDelete(true)
      methods.applyOtherFilter()
      selected.actionMenu.onToggle(false)
      pageDispatch({
        type: useCreateProductRelateAction.TABLE_SELECTED_LIST_UPDATE,
        payload: {selected: {list: []}},
      })
      showAlert({
        type: 'success',
        content: "Đã xóa sản phẩm thành công"
      })
    })
  }

  return (
    <Tr type={'tHead'} className='create-teacher-table'>
      <Td className='create-teacher-table_cell'>
        <Checkbox
          className={"create-teacher-table_cell-checkbox"}
          checked={checkbox.checked}
          indeterminate={!checkFullPageChecked()}
          onClick={e => {
            e.stopPropagation()
            checkbox.onClick()
          }}
        />
        <Text>STT</Text>
      </Td>
      {
        checkbox.checked ? (
            <Td className="create-teacher-table_cell" data-selected="true" data-type="th">
              <Text as="b">
                {selected.list.length > 9
                  ? selected.list.length
                  : `0${selected.list.length}`}{' '}
                sản phẩm được chọn
              </Text>
              <div className="create-teacher-table__selected-action-dropdown">
                <Button
                  className="create-teacher-table__selected-action-toggle"
                  size="xs"
                  onClick={() => selected.actionMenu.onToggle(true)}
                >
                  Thao tác {GLOBAL_ICONS.caretRight}
                </Button>
                {selected.actionMenu.open && (
                  <>
                    <div
                      className="create-teacher-table__selected-action-backdrop"
                      onClick={() => selected.actionMenu.onToggle(false)}
                    ></div>
                    <ul className="create-teacher-table__selected-action-menu common-popover">
                      <li
                        className="create-teacher-table__selected-action-menu-item"
                        onClick={() => {
                          setConfirmDeleteModalData({
                            title: 'Xác nhận xóa sản phẩm',
                            description: (
                              <>
                                <Text as={'p'}> Bạn có chắc chắn muốn xóa sản phẩm liên quan này?</Text>
                              </>
                            ),
                          })
                          setSubmit(() => selected.actionMenu.actions[0])
                        }}
                      >
                        Xóa sản phẩm
                      </li>
                    </ul>
                  </>
                )}
              </div>
            </Td>
          )
          :
          PRODUCT_TABLE_HEADER?.map(map => {
            return <Td key={map.id} className='create-teacher-table_cell'>
              <Text>{map.name}</Text>
            </Td>
          })
      }
      <Td className='create-teacher-table_cell'/>
      {!!confirmDeleteModalData && (
        <ConfirmDeleteModal
          content={confirmDeleteModalData?.description}
          title={confirmDeleteModalData?.title}
          onClose={() => setConfirmDeleteModalData(null)}
          onSubmit={handeSubmitDelete}
        />
      )}
    </Tr>
  )
}