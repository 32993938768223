import React from "react";
import {Input} from "Component/Input";
import { useEditOrder } from '../../../hooks/useEditOrder'
import { THEME_SEMANTICS } from '../../../../../common/theme/_semantics'
import { Text } from '../../../../../common/text'

export const Phone = ()=>{
    const {phone} = useEditOrder()
    const {methods} = phone
    return (
        <Input
            label={
                <>
                    Số điện thoại <Text color={THEME_SEMANTICS.failed}>*</Text>
                </>
            }
            value={phone?.value}
            onChange={e => methods.onChange(e.target?.value)}
            onBlur={e => methods.onBlur(e.target?.value)}
            validateText={phone?.message}
            validateType={phone?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập số điện thoại'}
            maxLength={12}
        />
    )
}