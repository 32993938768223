import React, {useContext} from 'react';
import {AlternativeAutoComplete} from "common/form/autoComplete/_alternativeAutoComplete";
import { Option } from 'common/form/autoComplete/_option';
import { useModal } from '../../../hooks/useModal'
import { Text } from '../../../../../common/text'
import { THEME_SEMANTICS } from '../../../../../common/theme/_semantics'
import {EventStatisticContext} from "../../../provider/_context";

export const ProductCategory = () => {
   const { pageState } = useContext(EventStatisticContext)
  const {category} = useModal()
  return (
    <AlternativeAutoComplete
      className="principal-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: {name: 'Danh mục', value: ''}, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 90,
        categoryHidden: true,
        placeholder: 'Chọn danh mục',
        readOnly: true,
        label: (
          <>
            Danh mục <Text color={THEME_SEMANTICS.failed}>*</Text>
          </>
        ),
        value: category?.value?.title || '',
        // onIconClick: () => category.onChange(null),
      }}
      // menu
      menuProps={{
        empty: category?.list?.length <= 0 
                ? 'Không tìm thấy danh mục'
                : '',
      }}
      // search input in dropdown menu
      searchInputProps={{
        placeholder: 'Tìm kiếm danh mục',
        // value: category.keyword,
        onChange: category?.onKeywordChange,
      }}

      validateText={pageState.create.validate?.category}
      validateType={!pageState.create.validate?.category ? 'success' : 'danger'}
    >
      {category?.list?.length > 0 &&
        category?.list?.map(item => (
        <Option
          key={item.id}
          className="principal-filter-form__option-text"
          data-active={item.id === category.value?.id}
          onClick={() => category.onChange(item)}
        >
          {item.title}
        </Option>
      ))}
    </AlternativeAutoComplete>
  )
}
