import React, {useContext} from 'react'
import {Skeleton} from '@mui/material'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {LicenseKey} from "../../provider/_context";
import {Tr} from "../../../../layouts/tableLayout/_tr";
import {Text} from "../../../../common/text";
import {Td} from "../../../../layouts/tableLayout/_td";
import {Button} from "../../../../common/button";
import {formatDatetime} from "../../../../common/form/datePicker/_functions";
import {Tooltip as TooltipV2} from "../../../../common/tooltipv2";
import {GLOBAL_ICONS} from "../../../../interface/icon";
import CheckBoxConsignment from "../../../../Component/CheckBoxConsignment/CheckBoxConsignment";
import {LicenseRowExtra} from "./_licenseRowExtra";
import {useLicenseKeyRow} from "../../hook/useLicenseKeyRow";

const TbodyLicenseKey = () => {
    const {t} = useTranslation()
    const {pageState} = useContext(LicenseKey)
    const {table} = pageState

    const displayList = table?.display?.list
    const displayListDefault = table?.display?.listDefault
    const displayLoading = table.display.loading
    const paginationAmount = table.pagination.amount
    const paginationActive = table.pagination.active
    const paginationTotalItems = table.pagination.totalItems
    return (
        <StyledTBody>
            {displayLoading ? (
                Array.from(Array(paginationAmount), (e, i) => (
                    <LicenseKeyPlaceholder key={i}/>
                ))
            ) : paginationTotalItems > 0 ? (
                displayList.map((item, key) => <LicenseKeyTr t={t} key={item.id} stt={key + ( +paginationAmount * +paginationActive)} data={item}/>)
            ) : (
                <LicenseEmpty t={t} list={displayListDefault}/>
            )}
        </StyledTBody>
    )
}

export default TbodyLicenseKey

const LicenseKeyPlaceholder = ({...props}) => {
    return (
        <Tr {...props} className="license-key-table__row">
            {Array.from(Array(7), (e, i) => (
                <Td key={i} className="license-key-table__cell" data-type="td">
                    <Skeleton
                        sx={{
                            width: '100%',
                            height: 33,
                            background:
                                'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
                        }}
                    />
                </Td>
            ))}
        </Tr>
    )
}

const LicenseEmpty = ({list, t, ...props}) => {
    return (
        <StyledLicenseKeyEmpty {...props}>
            <img
                className="license-key-empty__banner"
                src="/img/order/order-empty.png"
                alt="empty"
            />
            <Text as="b" color="#7C88A6" style={{marginBottom: 16}}>
                {!!!list ? t('Bạn chưa có danh sách key sản phẩm nào') : t('no_matching_data')}
            </Text>
            {!!!list && (
                <Button icon={GLOBAL_ICONS.plus}>{t('Thêm mới key sản phẩm')}</Button>
            )}
        </StyledLicenseKeyEmpty>
    )
}

const LicenseKeyTr = ({data, t, stt, ...props}) => {
    const orderRow = useLicenseKeyRow(data)
    const { detail, row} = orderRow
    return (
        <>
            <Tr
                {...props}
                className="license-key-table__row"
                extra={
                    <LicenseRowExtra
                        id={data?.id}
                        active={row.shouldOpenDetail}
                        data={detail?.active}
                        rowData={orderRow}
                        status={data?.status}
                    />
                }
                data-active={row.shouldOpenDetail}
                onClick={row.onToggleDetail}
            >
                <Td className="license-key-table__cell" data-type="td">
                    {/*<CheckBoxConsignment/>*/}
                    {stt + 1}
                </Td>
                <Td className="license-key-table__cell" data-type="td">
                    <TooltipV2 title={data?.license_key} baseOn={'height'} className='license-key-table__cell-tooltip'>
                        <Text>{data?.license_key || '---'}</Text>
                    </TooltipV2>
                </Td>
                <Td className="license-key-table__cell" data-type="td">
                    <TooltipV2 title={data?.product_name} baseOn={'height'} className='license-key-table__cell-tooltip'>
                        <Text>{data?.product_name || '---'}</Text>
                    </TooltipV2>
                </Td>
                <Td className="license-key-table__cell" data-type="td">
                    <div style={{display:'flex', alignItems:'center'}}>
                        {GLOBAL_ICONS.clock}
                        <Text style={{marginLeft: 4}} color={'#7C88A6'}>{formatDatetime(data?.updated_at || data?.created_at)}</Text>
                    </div>

                </Td>
                <Td className="license-key-table__cell" data-type="td">
                    {
                        !!data?.sold_at ?  <div style={{display:'flex', alignItems:'center'}}>
                            {GLOBAL_ICONS.clock}{' '}
                            <Text style={{marginLeft: 4}} color={'#7C88A6'}>{formatDatetime(data?.sold_at)}</Text>
                        </div>: <Text color={'#7C88A6'}>
                            ---
                        </Text>
                    }

                </Td>
                <Td className="license-key-table__cell" data-type="td">
                    <Text>
                        {+data?.order_id !== 0 ? `#${data?.order_id}` : '---' }
                    </Text>
                </Td>
                <Td className="license-key-table__cell" data-type="td">
                    {data?.status !== 'sold'
                        ? <Text className={'license-key-table__cell--success'}>{data?.status_name}</Text>
                        : <Text className={'license-key-table__cell--danger'}>{data?.status_name}</Text>
                    }
                </Td>
                <Td
                    className="license-key-table__cell"
                    data-active={row.shouldOpenDetail}
                    onClick={e => e.stopPropagation()}
                >
                    <div
                        onClick={row.onToggleDetail}
                        className="license-key-table__cell_arrow">
                        {GLOBAL_ICONS.up}
                    </div>
                </Td>
            </Tr>
        </>
    )
}

export const StyledTBody = styled.div`
  .license-key-table {
    &__loading {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
  
      width: 100vw;
      height: 100vh;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      background: rgba(0, 0, 0, 0.25);
  
      img {
        width: 80px;
        height: 80px;
      }
    }
  
    &__row {
      &:hover{
        .license-key-table__cell {
            .license-key-table__cell_arrow{
                top: 22px;
                opacity: 1;
           
            }
        }
      }
    }
    &__cell-tooltip{
      display: -webkit-box;
      height: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      word-break: break-word;
    }
    &__cell {
      cursor: pointer;
      margin: 0 8px;
      display: flex;
      justify-content: left;
      align-items: center;
      &[data-menu='true'] {
        position: relative;
      }
  
      &[data-type='th'] {
        &[data-selected='true'] {
          display: flex;
          flex: 1;
          align-items: center;
          height: 44px;
        }
      }
  
      &--success {
        color: #0B74E5 !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #ECF4FE;
        border-radius: 4px;
      }
      &--danger {
        color: #ffffff !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #FF9F41;
        border-radius: 4px;
      }
      &:nth-child(1) {
        width: 50px;
        justify-content: center;
      }
      &:nth-child(2) {
        width: 20%;
      }
      &:nth-child(3) {
        width: 30%;
      }
  
      &:nth-child(4) {
        width: 17%;
      }
  
      &:nth-child(5) {
        width: 17%;
      }
  
      &:nth-child(6) {
        width: 10%;
         text-align: center;
        justify-content: center;
        align-items: center;
      }
  
      &:nth-child(7) {
        width: 10%;
        justify-content: center;
      }
      &:nth-child(8) {
        width: 100px;
        padding-left: 20px;
        position:relative; 
        .license-key-table__cell_arrow{
            position: absolute;
            top: 19px;
            left: 35px;
            transition: all .25s;
            transform: rotate(180deg);
            opacity: 0;
        }
         &[data-active='true']{
          .license-key-table__cell_arrow{
                opacity: 1;
                transform: rotate(0);
           
            }
          }
      }
  
    }
  
  
    &__selected-action-dropdown {
      position: relative;
  
      margin-left: 12px;
    }
  
    &__selected-action-toggle {
      width: 88px;
      padding: 0 !important;
  
      border-radius: 14px !important;
  
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  
    &__selected-action-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 11;
  
      width: 100vw;
      height: 100vh;
    }
  
    &__selected-action-menu {
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      z-index: 12;
  
      width: 150px;
      padding: 8px;
  
      background: #ffffff;
      border-radius: 6px;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    }
  
    &__selected-action-menu-item {
      padding: 8px;
  
      color: #191d32;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
  
      transition: color 0.25s;
  
      cursor: pointer;
  
      &:hover {
        color: #3264FF;
      }
    }
  
    &__tooltipV2 {
      width: 100%;
      padding: 0;
      overflow: hidden;
      position: relative;
      display: inline-block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
}

.tab-detail-order {
  &__link-hover {
    color: #1A94FF;

    &:hover {
      color: #1373DB;
    }
  }
}
`
export const StyledLicenseKeyEmpty = styled.div`
  min-height: calc(100vh - 430px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;

  .license-key-empty__banner {
    width: 133px;
    height: 133px;
    margin-bottom: 16px;

    object-fit: contain;
    object-position: center;
  }
`
