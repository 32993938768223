import React, { useContext, useState } from 'react'
import { Tr } from 'layouts/tableLayout/_tr'
import { Td } from 'layouts/tableLayout/_td'
import { Text } from 'common/text'
import { Checkbox } from '../../../../../../../../common/form/checkbox'
import { CreatePrincipalContext } from '../../../../provider/_context'
import useAlert from '../../../../../../../../hook/useAlert'
import { useCreateProductAction, useModalProductAction } from '../../../../provider/_action'
import { useAddProductModal } from '../../../../hook/useAddProductModal'
import { Button } from '../../../../../../../../common/button'
import { GLOBAL_ICONS } from '../../../../../../../../interface/icon'
import { ConfirmDeleteModal } from '../../_confirmDeleteModal'

export const TableProductHeader = () => {
  const { showAlert } = useAlert()
  const { pageDispatch } = useContext(CreatePrincipalContext)
  const [confirmDeleteModalData, setConfirmDeleteModalData] = useState(false)
  const [canDelete, setCanDelete] = useState(true)
  const [submit, setSubmit] = useState(() => {})
  const { checkbox, selected, methods, table } = useAddProductModal()
  const displayList = table.display.list

  const checkFullPageChecked = () => {
    let checkFullPage = true
    displayList.forEach(item => {
      const findItem = selected.list.find(
        find => find.order_id === item.order_id,
      )
      if (!!!findItem) checkFullPage = false
    })
    return checkFullPage
  }

  const handeSubmitDelete = () => {
    setCanDelete(false)
    setConfirmDeleteModalData(null)
    if (!canDelete) return
    const response = submit()
    response.then(() => {
      setCanDelete(true)
      methods.applyOtherFilter()
      selected.actionMenu.onToggle(false)
      pageDispatch({
        type: useModalProductAction.TABLE_SELECTED_LIST_UPDATE,
        payload: { selected: { list: [] } },
      })
      showAlert({
        type: 'success',
        content: 'Đã xóa sản phẩm thành công'
      })
    })
  }
  const addProduct = () => {
    setCanDelete(false)
    if (!canDelete) return
    const response = selected.actionMenu.actions[1]()
    response.then(() => {
      setCanDelete(true)
      methods.applyOtherFilter()
      selected.actionMenu.onToggle(false)
      pageDispatch({
        type: useModalProductAction.TABLE_SELECTED_LIST_UPDATE,
        payload: { selected: { list: [] } },
      })
      showAlert({
        type: 'success',
        content: 'Đã thêm sản phẩm thành công'
      })
    })
  }
  return (
    <Tr type={'tHead'} className='create-teacher-table'>
      <Td className='create-teacher-table_cell'>
        <Checkbox
          className={'create-teacher-table_cell-checkbox'}
          checked={checkbox.checked}
          indeterminate={!checkFullPageChecked()}
          onClick={e => {
            e.stopPropagation()
            checkbox.onClick()
          }}
        />
        <Text>STT</Text>
      </Td>
      {
        checkbox.checked ? (
            <Td className="create-teacher-table_cell" data-selected="true" data-type="th">
              <Text as="b">
                {selected.list.length > 9
                  ? selected.list.length
                  : `0${selected.list.length}`}{' '}
                sản phẩm được chọn
              </Text>
              <div className="create-teacher-table__selected-action-dropdown">
                <Button
                  className="create-teacher-table__selected-action-toggle"
                  size="xs"
                  onClick={() => selected.actionMenu.onToggle(true)}
                >
                  Thao tác {GLOBAL_ICONS.caretRight}
                </Button>
                {selected.actionMenu.open && (
                  <>
                    <div
                      className="create-teacher-table__selected-action-backdrop"
                      onClick={() => selected.actionMenu.onToggle(false)}
                    ></div>
                    <ul className="create-teacher-table__selected-action-menu common-popover">
                      <li
                        className="create-teacher-table__selected-action-menu-item"
                        onClick={addProduct}
                      >
                        Thêm sản phẩm
                      </li>
                      <li
                        className="create-teacher-table__selected-action-menu-item"
                        onClick={() => {
                          setConfirmDeleteModalData({
                            title: 'Xác nhận xóa sản phẩm',
                            description: (
                              <>
                                <Text as={'p'}> Bạn có chắc chắn muốn xóa sản phẩm này ra khỏi danh sách sản phẩm có thể
                                  bán của hiệu trưởng không?</Text>
                                <Text as={'p'} fontWeight={600}>Lưu ý:</Text>
                                <ul>
                                  <li>
                                    <Text as={'p'}> &bull; Khi xóa sản phẩm, sẽ loại bỏ sản phẩm khỏi danh sách các sản
                                      phảm mà hiệu trưởng có thể bán.</Text>
                                  </li>
                                  <li>
                                    <Text as={'p'}> &bull; Học sinh của giáo viên trực thuộc hiệu trưởng sẽ không thấy /
                                      tìm kiếm được các sản phẩm này trên Zalo Mini App</Text>
                                  </li>
                                </ul>
                              </>
                            ),
                          })
                          setSubmit(() => selected.actionMenu.actions[0])
                        }}
                      >
                        Xóa sản phẩm
                      </li>
                    </ul>
                  </>
                )}
              </div>
            </Td>
          )
          :
          <>
            <Td className='create-teacher-table_cell'>
              <Text>Sản phẩm</Text>
            </Td>
            <Td className='create-teacher-table_cell'/>
          </>
      }
      {!!confirmDeleteModalData && (
        <ConfirmDeleteModal
          content={confirmDeleteModalData?.description}
          title={confirmDeleteModalData?.title}
          onClose={() => setConfirmDeleteModalData(null)}
          onSubmit={handeSubmitDelete}
        />
      )}
    </Tr>
  )
}