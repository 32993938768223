import {STORE_UPOS_ICON} from "./_icons";

export const STORE_UPOS_BREADCRUMB = [
  {id: 1, name: 'Trang chủ', url: '/'},
  {id: 2, name: 'Quản lý banner', url: '/banners'},
]

export const STORE_UPOS_HEADER_ACTIONS = [
  {
    id: 2,
    name: 'Thêm mới banner',
    appearance: 'primary',
    icon: STORE_UPOS_ICON.plus,
  },
]

export const TAG_DELETE=[
  'search',
  'position',
  'status'
]