import {PATH} from "const/path";
import {usePrincipalAction} from "../provider/_reducer";
import { GLOBAL_ICONS } from '../../../interface/icon'
import {PRINCIPAL_GROUP_ICON} from "./icon";
import { useNavigate} from 'react-router-dom'
export const PRINCIPAL_BREADCUM = [
    {id: 1, name: 'Home', url: PATH.SETTING},
    {id: 2, name: 'Khách hàng', url: '#'},
    {id: 2, name: 'Quản lý hiệu trưởng ', url: '#'}
]
export const PRINCIPAL_BUTTON_ACTION = (pageState, pageDispatch,t, action) => {
    const navigate = useNavigate()
    const PRINCIPAL_ACTION_BUTTON = [
        {
            id: 1,
            name: null,
            appearance: 'secondary',
            icon: PRINCIPAL_GROUP_ICON.repeat,
            onClick: () => action.refresh(),
        },
        {
            id: 2,
            name: 'Import excel',
            appearance: 'secondary',
            icon: PRINCIPAL_GROUP_ICON.import,
            onClick: () => pageDispatch({type:usePrincipalAction.MODAL_IMPORT_PRINCIPAL,payload: true}),
        },
        {
            id: 3,
            name: 'Export excel',
            appearance: 'secondary',
            icon: GLOBAL_ICONS.export,
            onClick: () => action.export(),
        },
        {
            id: 4,
            name: 'more_principal',
            appearance: 'primary',
            icon: GLOBAL_ICONS.plus,
            onClick: () => navigate(PATH.CREATE_PRINCIPAL),
        },
    ]
    return {
        PRINCIPAL_ACTION_BUTTON,
    }
}
export const PRINCIPAL_STATUS=[
    {name: 'Đang hoạt động', value: 'active'},
    {name: 'Đang  khóa', value: 'inactive'},
]
export const PRINCIPAL_TABLE_HEADER=[

]
export const INVENTORY_COLUMN_NAMES = {
        columnNames: [
            'STT',
            'Tên đăng nhập (*)',
            'Mật khẩu (*)',
            'Tên (*)',
            'Họ',
            'Trạng thái (*)',
            'Tên trường',
            'Số điện thoại',
            'Email',
            'Ngày sinh\n(dd/mm/yyyy)',
            'Giới tính',
            'Địa chỉ',
            'Số CMND/CCCD',
            'Số tài khoản',
            'Chủ tài khoản',
            'Ngân hàng'
        ],
        startRowNumber: 5,
};
export const TAG_DELETE=[
    'search',
    'status'
]
