import { sendRequestAuth } from 'api/api'
import { Button } from 'common/button'
import config from 'config'
import useRevenueFilterForm from 'Pages/revenue/hooks/useRevenueFilterForm'
import { RevenueContext } from 'Pages/revenue/provider/_context'
import { revenueActions } from 'Pages/revenue/provider/_reducer'
import { memo, useContext, useEffect, useReducer, useState } from 'react'
import { RevenueTags } from '../revenueTags'
import { RevenueDateTime } from './_dateTime'
import { RevenueSearch } from './_search'
import { RevenuePaymentMethod } from './_paymentMethod'
import { OrderType } from './_orderType'
import { RevenueStatus } from './_status'
import { StyledRevenueFilterForm } from './_styled'
import { RevenueCustomerInfo } from './_customerInfo'
import { RevenuePrincipal } from './_principal'
import { RevenueTeacher } from './_teacher'
import { GLOBAL_ICONS } from '../../../../interface/icon'

export const RevenueFilterForm = memo(({ ...props }) => {
  const { canSubmitOtherFilter, functions } = useRevenueFilterForm()
  const { pageState, pageDispatch } = useContext(RevenueContext)

  useEffect(() => {
    const fetchData = async () => {
      const response = await Promise.all([
        sendRequestAuth(
          'get',
          `${config.API}/principal/principals?per_page=999&start=0`,
        ),
        sendRequestAuth(
          'get',
          `${
            config.API
          }/product/book/books?keyword=&category_id=&status=&per_page=999&start=`,
        ),
        sendRequestAuth(
          'get',
          `${
            config.API
          }/order/filter/principals?keyword=`,
        )
      ])
      pageDispatch({
        type: revenueActions.FILTER_ORIGIN_DATA_UPDATE,
        payload: {
          list: response[0].data?.data?.map(item => ({
            name: item?.name || '',
            value: item?.id || '',
          })),
          product: {
            list: response[1].data?.data.map(item => ({
              data: item || null,
              name: item?.title || '',
              value: item?.id || '',
            })),
          },
          principal: {
            list: response[2].data.map(item => ({
              data: item || null,
              name: ((!!item?.last_name ? (item?.last_name + ' ') : '' )+ item?.name) || '',
              value: item?.id || '',
            })),
          },
        },
      })
    }

    fetchData()
  }, [])

  return (
    <StyledRevenueFilterForm {...props}>
      <div
        className="revenue-filter-form__group revenue-filter-form__collapse"
        data-collapse={true}
      >
        <RevenueSearch/>
        <RevenueCustomerInfo/>
        <OrderType/>
        <RevenuePrincipal/>
        <RevenueTeacher/>
        <RevenuePaymentMethod/>
        <RevenueStatus/>
        <RevenueDateTime/>
        <div className="revenue-filter-form__input-wide flex">
          <Button
            appearance="secondary"
            disabled={!canSubmitOtherFilter}
            size="md-"
            onClick={() =>
              canSubmitOtherFilter && functions.applyRevenueOtherFilter()
            }
          >
            Tìm kiếm
          </Button>
          <Button
            appearance="secondary"
            size="md-"
            onClick={() => functions.refresh(pageState?.pageOption?.value)}
            className={'revenue-filter-form__btn-refresh'}
          >
            {GLOBAL_ICONS.repeat}
          </Button>
        </div>
      </div>
      <div className="revenue-filter-form__group" style={{ marginBottom: 4 }}>
        <RevenueTags/>
      </div>
    </StyledRevenueFilterForm>
  )
})
