import useCreateInfoBasic from '../../../hooks/useCreateInfoBasic'
import { Text } from '../../../../../common/text'
import { Grid } from '@mui/material'
import { Input } from '../../../../../common/form/input'
import { THEME_SEMANTICS } from '../../../../../common/theme/_semantics'
import { Tooltip } from '../../../../../common/tooltip'
import { PRODUCT_ICONS } from '../../../interfaces/~icon'
import { AlternativeAutoComplete } from './~alternativeAutoComplete'
import styled from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DISPLAY_NAME_MENU } from '../../../../../const/display_name_menu'
import { Option } from '../../../../../common/form/autoComplete/_option'
import { THEME_COLORS } from 'common/theme/_colors'
import { STATUS_PRODUCT } from '../../../../products/book/interface/_contants'

const InfoBasic = () => {
  const { t } = useTranslation()
  const { value, functions, validate } = useCreateInfoBasic()
  const groupProduct = value?.formInfoBasic?.groupProduct
  const status = value?.formInfoBasic?.status
  return (
    <StyledInfoBasic>
      <Grid container spacing={2}>
        <Grid xs={6} sm={6} md={6} lg={6} item>
          <div className="product-info-basic__form-input ">
            <Input
              label={
                <>
                  {t(DISPLAY_NAME_MENU.PRODUCT_PAGE.PRODUCT_NAME)} <Text color={THEME_SEMANTICS.failed}>*</Text>
                </>
              }
              value={value?.formInfoBasic?.name}
              onChange={e => functions.onChangeName(e.target?.value)}
              onBlur={_ => validate.onBlurName()}
              validateText={validate?.formInfoBasicValidate?.name?.message}
              validateType={validate?.formInfoBasicValidate?.name?.status ? 'danger' : ''}
              placeholder={t(DISPLAY_NAME_MENU.PRODUCT_PAGE.ENTER_PRODUCT_NAME)}
              maxLength={200}
            />
          </div>
        </Grid>
        <Grid xs={6} sm={6} md={6} lg={6} item>

          <div className="product-info-basic__form-input">
            <AlternativeAutoComplete
              // main input
              inputProps={{
                categoryList: [], // menu list in category dropdown
                categoryHidden: true,
                readOnly: true,
                categoryValue: { name: t('Vui lòng chọn trạng thái'), value: '' },
                label: <>
                  <div> Trạng thái <Text color={THEME_SEMANTICS.failed}>*</Text>
                  </div>
                </>,
                placeholder: t('Vui lòng chọn trạng thái'),
                value: value?.formInfoBasic?.status?.title,
              }}
              hideSearchBar={true}
              // search input in dropdown menu
              menuProps={{
                empty: '',
              }}
              // search input in dropdown menu
              validateText={validate?.formInfoBasicValidate?.status?.message}
              validateType={validate?.formInfoBasicValidate?.status?.status ? 'danger' : 'success'}
            >
              {
                STATUS_PRODUCT?.map((item, index) => (
                  <Option
                    key={index}
                    className="store-upos-filter-form__option-text"
                    data-active={+status?.id === +item.id}
                    onClick={() => functions.onSelectStatus(item)}
                  >
                    {item.title}
                  </Option>
                ))}
            </AlternativeAutoComplete>
          </div>
        </Grid>
        <Grid xs={6} sm={6} md={6} lg={6} item>
          <div className="product-info-basic__form-input-1">
            <AlternativeAutoComplete
              // main input
              inputProps={{
                categoryList: [], // menu list in category dropdown
                categoryHidden: true,
                readOnly: true,
                categoryValue: { name: t(DISPLAY_NAME_MENU.PRODUCT_PAGE.GROUP_PRODUCT), value: '' },
                label: <>
                  <div>{t('Danh mục')} <Text color={THEME_SEMANTICS.failed}>*</Text>
                  </div>
                </>,
                placeholder: t('Vui lòng chọn danh mục'),
                value: groupProduct?.value?.title,
              }}
              // search input in dropdown menu
              menuProps={{
                empty: groupProduct?.list?.length <= 0 ? t('Không tìm thấy danh mục') : '',
              }}
              // search input in dropdown menu
              searchInputProps={{
                placeholder: t('Tìm theo tên danh mục'),
                value: groupProduct?.keyword || '',
                onChange: functions.onKeywordChange,
              }}
              validateText={validate?.formInfoBasicValidate?.group?.message}
              validateType={validate?.formInfoBasicValidate?.group?.status ? 'danger' : 'success'}
            >
              {groupProduct?.list?.length > 0 &&
              groupProduct?.list?.map((item, index) => (
                <Option
                  key={index}
                  className="store-upos-filter-form__option-text"
                  data-active={+groupProduct?.value?.id === +item.id}
                  onClick={() => functions.onSelectGroupProduct(item)}
                >
                  {item.title}
                </Option>
              ))}
            </AlternativeAutoComplete>
          </div>
        </Grid>
        <Grid xs={6} sm={6} md={6} lg={6} item>
          <div className="product-info-basic__form-input-1">
            <Input
              label={
                <>
                  {t('ISBN')} <Text color={THEME_SEMANTICS.failed}>*</Text>
                </>
              }
              value={value?.formInfoBasic?.barCode}
              onChange={e => functions.onChangeBarCode(e.target?.value)}
              onBlur={() => validate.onBlurBarCode()}
              validateText={validate?.formInfoBasicValidate?.barCode?.message}
              validateType={validate?.formInfoBasicValidate?.barCode?.status ? 'danger' : 'success'}
              placeholder={t('Vui lòng nhập ISBN')}
              maxLength={14}
              icon={value?.formCreate?.statusForm === 'editSingleVersion'
                ? (<Tooltip placement="bottom" title={t(DISPLAY_NAME_MENU.PRODUCT_PAGE.PRINT_BARCODE)}>
                  <i> {PRODUCT_ICONS?.printSingleProduct} </i>
                </Tooltip>) : ''}
              onIconClick={() => functions?.onPrintBarcode()}
            />
          </div>
        </Grid>
        <Grid xs={6} sm={6} md={6} lg={6} item>
          <div className="product-info-basic__form-input-1">
            <Input
              label={
                <>
                  {t('Năm  xuất bản')}
                </>
              }
              value={value?.formInfoBasic?.publicationYear}
              onChange={e => functions.onChangePublicationYear(e.target?.value)}
              placeholder={t('Vui lòng nhập năm xuất bản')}
              maxLength={4}
            />
          </div>
        </Grid>
        <Grid xs={6} sm={6} md={6} lg={6} item>
          <div className="product-info-basic__form-input-1">
            <Input
              label={
                <>
                  {t('Nhà xuất bản')}
                </>
              }
              value={value?.formInfoBasic?.publisher}
              onChange={e => functions.onChangePublisher(e.target?.value)}
              placeholder={t('Vui lòng nhập nhà xuất bản')}
              maxLength={200}
            />
          </div>
        </Grid>
        <Grid xs={6} sm={6} md={6} lg={6} item>
          <div className="product-info-basic__form-input-1">
            <Input
              label={
                <>
                  {t('Tác giả')} <Text color={THEME_SEMANTICS.failed}>*</Text>
                </>
              }
              value={value?.formInfoBasic?.author}
              onChange={e => functions.onChangeAuthor(e.target?.value)}
              onBlur={() => validate.onBlurAuthor()}
              validateText={validate?.formInfoBasicValidate?.author?.message}
              validateType={validate?.formInfoBasicValidate?.author?.status ? 'danger' : 'success'}
              placeholder={t('Vui lòng nhập tác giả')}
              maxLength={200}
            />
          </div>
        </Grid>
        <Grid xs={6} sm={6} md={6} lg={6} item>
          <div className="product-info-basic__form-input-1">
            <Input
              label={
                <>
                  {t('Ngôn ngữ')}
                </>
              }
              value={value?.formInfoBasic?.language}
              onChange={e => functions.onChangeLanguage(e.target?.value)}
              placeholder={t('Vui lòng nhập ngôn ngữ')}
              maxLength={200}
            />
          </div>
        </Grid>
      </Grid>
    </StyledInfoBasic>
  )
}

export default InfoBasic

export const StyledInfoBasic = styled.div`
  .product-info-basic {
    //&__list-parent--tooltip {
    //  display: -webkit-box;
    //  height: 100%;
    //  -webkit-line-clamp: 1;
    //  -webkit-box-orient: vertical;
    //  overflow: hidden;
    //  text-overflow: ellipsis;
    //}
    &__toggle-item {
      position: absolute;
      top: 27px;
      right: 24px;
    }
    &__form-input-1 {
      margin-top: 24px;
    }
    &__form-input-code {
      margin-right: 8px;
    }
    &__form-input-barcode {
      margin-left: 8px;
    }
    &__form-input-group-product {
      position: relative;
      &--add {
        position: absolute;
        bottom: 72px;
        right: 24px;
        font-size: 14px;
        z-index: 2;
        
        svg {
          margin-left: 4px;
        }
        
        div {
          position: absolute;
          left: -24px;
          top: -2px;
          cursor: pointer;
        }
        span {
          color: #1A94FF;
          cursor: pointer;
        }
      }
    }
    
    &__list-parent {
      width: 50%;
      height: 260px;
      overflow: auto;
      &--wrapper {
        //height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 8px 0;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        
        & span:hover {
          color: rgb(229, 16, 29) !important;
        }
        &[data-type='true'] span{
          color: rgb(229, 16, 29) !important;
        }
      }
      &--tooltip {
        max-width: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 8px;
      }
    }
    &__list-child {
      border-left: 1px solid #EBEEF5;
      padding-left: 20px;
      width: 48%;
      height: 260px;
      overflow: auto;
      &--wrapper {
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 16px 0;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
      }
      &--tooltip {
        display: -webkit-box;
        height: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  
.store-upos-filter-form {
    &__group {
      width: calc(100% + 12px);
      margin: 0 -6px 16px -6px;

      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & > * {
        margin: 0 6px;
      }
    }

    &__collapse {
      max-height: 0;
      margin-bottom: 0 !important;

      overflow: hidden;

      transition: all 0.25s;

      &[data-collapse='true'] {
        max-height: 50vh;
        margin-bottom: 16px;

        overflow: unset;
      }
    }

    &__input-wide {
      width: calc(25% - 12px);
      margin: 0 6px;

      @media screen and (max-width: 1599px) {
        width: calc((100% / 3) - 12px);
      }
    }

    &__option-text {
      min-height: 36px;

      display: flex;
      align-items: center;

      color: ${THEME_COLORS.gray_900};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      cursor: pointer;

      transition: color 0.25s;

      &[data-active='true'] {
        color: ${THEME_COLORS.primary_300};
        font-weight: 600;
      }
      &:hover {
        color: ${THEME_COLORS.primary_300};
      }
    }

    &__option-container {
      min-height: 45px;
      margin-bottom: 4px;

      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__option-tabs {
      position: sticky;
      top: 0;
      z-index: 1;

      width: 100%;
      height: 28px;
      margin-bottom: 16px;

      display: flex;
      align-items: center;

      background: #fff;

      &::before {
        position: absolute;
        top: -20px;
        left: -20px;

        width: calc(100% + 40px);
        height: calc(100% + 36px);

        background: #fff;
        border-radius: 8px 8px 0 0;

        content: '';
      }
    }

    &__option-tab {
      position: relative;
      z-index: 2;

      margin-right: 16px;

      color: #808089;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      cursor: pointer;

      &[data-active='true'] {
        color: ${THEME_COLORS.primary_300};
        font-weight: 600;

        cursor: default;
      }
    }

    &__collapse {
      .store-upos-filter-form__input-wide {
        margin-bottom: 16px;
      }
    }
  }
`
