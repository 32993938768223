import { useCreateProductAction, useModalProductAction } from './_action'

export const useCreatePrincipalAction = {
  FORM_INFOMATION_UPDATE: 'FORM_INFOMATION_UPDATE',
  FORM_CREATE_TAB_CHANGE: 'FORM_CREATE_TAB_CHANGE',
  FORM_CREATE_GET_DETAIL: 'FORM_CREATE_GET_DETAIL',
  TABLE_UPDATE_PAGINATION: 'TABLE_UPDATE_PAGINATION',
  TABLE_LOADING_DISPLAY: 'TABLE_LOADING_DISPLAY',
  TABLE_UPDATE_DISPLAY_LIST: 'TABLE_UPDATE_DISPLAY_LIST',
  TABLE_DISPLAY_DETAIL_UPDATE: 'TABLE_DISPLAY_DETAIL_UPDATE',
  TABLE_DISPLAY_DETAIL_ID_UPDATE: 'TABLE_DISPLAY_DETAIL_ID_UPDATE',
  TABLE_DETAIL_LOADING_UPDATE: 'TABLE_DETAIL_LOADING_UPDATE',
  FORM_UPLOAD_AVATAR: 'FORM_UPLOAD_AVATAR',
  FORM_DELETE_UPDATE: 'FORM_DELETE_UPDATE',
  FORM_DELETE_OPEN_CONFIRM: 'FORM_DELETE_OPEN_CONFIRM',
}

export const useCreatePrincipalReducer = (state, action) => {
  switch (action.type) {
    case useCreatePrincipalAction.FORM_INFOMATION_UPDATE:

      return {
        ...state,
        infomation: {
          ...state.infomation,
          ...action.payload,
        },
      }
    case useCreatePrincipalAction.FORM_CREATE_TAB_CHANGE:
      return {
        ...state,
        tab: {
          ...state.tab,
          activeTab: action.payload,
        },
      }
    case useCreatePrincipalAction.FORM_CREATE_GET_DETAIL:
      return {
        ...state,
        infomation: {
          ...state.infomation,
          ...action.payload?.info,
        },
      }
    case useCreatePrincipalAction.TABLE_UPDATE_PAGINATION:
      return {
        ...state,
        table: {
          ...state.table,
          pagination: {
            ...state.table.pagination,
            active: action.payload?.active || 0,
            amount: action.payload?.amount || 20,
            total: action.payload?.total || 0,
            totalItems: action.payload?.totalItems || 0,
          },
        },
      }
    case useCreatePrincipalAction.TABLE_LOADING_DISPLAY:
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state?.table?.display,
            loading: action?.payload
          }
        },
      }
    case useCreatePrincipalAction.TABLE_UPDATE_DISPLAY_LIST:
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state?.table?.display,
            list: action?.payload?.list || [],
            listDefault: action?.payload?.listDefault || [],
            loading: action?.payload?.loading
          }
        },
      }
    case useCreatePrincipalAction.TABLE_DISPLAY_DETAIL_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          detail: {
            ...state.table.detail,
            active: action.payload?.active || null,
            list: action.payload?.list || state.table.detail?.list,
          },
        },
      }
    case useCreatePrincipalAction.TABLE_DISPLAY_DETAIL_ID_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          detail: {
            ...state.table.detail,
            id: action.payload?.id || null,
          },
        },
      }
    case useCreatePrincipalAction.TABLE_DETAIL_LOADING_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          detail: {
            ...state.table.detail,
            loading: action.payload,
          },
        },
      }
    case useCreatePrincipalAction.FORM_UPLOAD_AVATAR:
      return {
        ...state,
        infomation: {
          ...state.infomation,
          avatar: {
            ...state.infomation.avatar,
            value: action.payload
          },
        },
      }
    case useCreatePrincipalAction.FORM_DELETE_UPDATE:
      return {
        ...state,
        deleteConfirm: {
          ...state.deleteConfirm,
          ...action.payload,
        },
      }
    case useCreatePrincipalAction.FORM_DELETE_OPEN_CONFIRM:
      return {
        ...state,
        deleteConfirm: {
          ...state.deleteConfirm,
          open: action.payload,
          value: '',
          status: false,
          message: ''
        },
      }


    case useCreateProductAction.TABLE_LOADING_DISPLAY:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          table: {
            ...state.productTab.table,
            display: {
              ...state?.productTab?.table?.display,
              loading: action?.payload
            }
          },
        }
      }

    case useCreateProductAction.TABLE_UPDATE_DISPLAY_LIST:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          table: {
            ...state.productTab.table,
            display: {
              ...state?.productTab?.table?.display,
              list: action?.payload?.list || [],
              listDefault: action?.payload?.listDefault || [],
              loading: action?.payload?.loading
            }
          },
        }
      }
    case useCreateProductAction.TABLE_UPDATE_PAGINATION:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          table: {
            ...state.productTab.table,
            pagination: {
              ...state.productTab.table.pagination,
              active: action.payload?.active || 0,
              amount: action.payload?.amount || 20,
              total: action.payload?.total || 0,
              totalItems: action.payload?.totalItems || 0,
            },
          },
        }
      }
    case useCreateProductAction.TABLE_SELECTED_LIST_UPDATE:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          table: {
            ...state.productTab.table,
            selected: {
              ...state.productTab.table.selected,
              list: action.payload?.selected?.list || [],
            },
          },
        }
      }
    case useCreateProductAction.FILTER_SEARCH_UPDATE:
      return {
        ...state, productTab: {
          ...state.productTab,
          filter: {
            ...state.productTab.filter,
            search: {
              ...state.productTab.filter.search,
              value: action.payload
            },
          },
        }
      }
    case useCreateProductAction.FILTER_STATUS_VALUE_UPDATE:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          filter: {
            ...state.productTab.filter,
            status: {
              ...state.productTab.filter.status,
              value: action.payload,
            },
          },
        }
      }
    case useCreateProductAction.FILTER_CATEGORY_LIST_ORIGIN_UPDATE:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          filter: {
            ...state.productTab.filter,
            category: {
              ...state.productTab.filter.category,
              list: action.payload?.list,
              listOrigin: action.payload?.listOrigin,
            },
          },
        }
      }
    case useCreateProductAction.FILTER_CATEGORY_LIST_UPDATE:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          filter: {
            ...state.productTab.filter,
            category: {
              ...state.productTab.filter.category,
              list: action.payload,
            },
          },
        }
      }
    case useCreateProductAction.FILTER_CATEGORY_VALUE_UPDATE:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          filter: {
            ...state.productTab.filter,
            category: {
              ...state.productTab.filter.category,
              value: action.payload,
            },
          },
        }
      }
    case useCreateProductAction.FILTER_CATEGORY_ACTIVE_VALUE_UPDATE:
      return {
        ...state, productTab: {
          ...state.productTab,
          filter: {
            ...state.productTab.filter,
            category: {
              ...state.productTab.filter.category,
              activeValue: action.payload,
            },
          },
        }
      }

      // product modal


    case useModalProductAction.TABLE_LOADING_DISPLAY:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          modalProduct : {
            ...state.productTab.modalProduct,
            table: {
              ...state.productTab.modalProduct.table,
              display: {
                ...state?.productTab?.modalProduct?.table?.display,
                loading: action?.payload
              }
            },
          }
        }
      }

    case useModalProductAction.TABLE_UPDATE_DISPLAY_LIST:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          modalProduct : {
            ...state.productTab.modalProduct,
            table: {
              ...state.productTab.modalProduct.table,
              display: {
                ...state?.productTab?.modalProduct?.table?.display,
                list: action?.payload?.list || [],
                listDefault: action?.payload?.listDefault || [],
                loading: action?.payload?.loading
              }
            },
          }
        }
      }
    case useModalProductAction.TABLE_UPDATE_PAGINATION:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          modalProduct : {
            ...state.productTab.modalProduct,
            table: {
              ...state.productTab.modalProduct.table,
              pagination: {
                ...state.productTab.modalProduct.table.pagination,
                active: action.payload?.active || 0,
                amount: action.payload?.amount || 20,
                total: action.payload?.total || 0,
                totalItems: action.payload?.totalItems || 0,
              },
            },
          }
        }
      }
    case useModalProductAction.FILTER_SEARCH_UPDATE:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          modalProduct : {
            ...state.productTab.modalProduct,
            filter: {
              ...state.productTab.modalProduct.filter,
              search: {
                ...state.productTab.modalProduct.filter.search,
                value: action.payload
              },
            },
          }
        }
      }
    case useModalProductAction.FILTER_CATEGORY_LIST_ORIGIN_UPDATE:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          modalProduct : {
            ...state.productTab.modalProduct,
            filter: {
              ...state.productTab.modalProduct.filter,
              category: {
                ...state.productTab.modalProduct.filter.category,
                list: action.payload?.list,
                listOrigin: action.payload?.listOrigin,
              },
            },
          }
        }
      }
    case useModalProductAction.FILTER_CATEGORY_LIST_UPDATE:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          modalProduct : {
            ...state.productTab.modalProduct,
            filter: {
              ...state.productTab.modalProduct.filter,
              category: {
                ...state.productTab.modalProduct.filter.category,
                list: action.payload,
              },
            },
          }
        }
      }
    case useModalProductAction.FILTER_CATEGORY_VALUE_UPDATE:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          modalProduct : {
            ...state.productTab.modalProduct,
            filter: {
              ...state.productTab.modalProduct.filter,
              category: {
                ...state.productTab.modalProduct.filter.category,
                value: action.payload,
              },
            },
          }
        }
      }
    case useModalProductAction.RESET_MODAL_FILTER:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          modalProduct : {
            ...state.productTab.modalProduct,
            filter: {
              ...state.productTab.modalProduct.filter,
              category: {
                ...state.productTab.modalProduct.filter.category,
                value: null,
                search: {
                  ...state.productTab.modalProduct.filter.search,
                  value: null
                },
              },
            },
          }
        }
      }
    case useModalProductAction.FILTER_CATEGORY_ACTIVE_VALUE_UPDATE:
      return {
        ...state, productTab: {
          ...state.productTab,
          modalProduct : {
            ...state.productTab.modalProduct,
            filter: {
              ...state.productTab.modalProduct.filter,
              category: {
                ...state.productTab.modalProduct.filter.category,
                activeValue: action.payload,
              },
            },
          }
        }
      }
    case useModalProductAction.DISPLAY_MODAL_PRODUCT:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          modalProduct : {
            ...state.productTab.modalProduct,
            show: action.payload
          }
        }
      }
    case useModalProductAction.TABLE_SELECTED_LIST_UPDATE:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          modalProduct : {
            ...state.productTab.modalProduct,
            table: {
              ...state.productTab.modalProduct.table,
              selected: {
                ...state.productTab.modalProduct.table.selected,
                list: action.payload?.selected?.list || [],
              },
            },
          }
        }
      }
    default:
      break
  }
}
