import React, {useContext, useState} from "react";
import {PageHeader} from "../../../../layouts/pageHeader";
import {ACTION_HEADER_LICENSE, LICENSE_BREADCUM, LICENSE_COLUMN_NAMES} from "../../interface/_const";
import {useLicenseKeyFilter} from "../../hook/useLicenseKeyFilter";
import {useLicenseKeyCreate} from "../../hook/useLicenseKeyCreate";
import {CreateLicenseKey} from "../createLicense";
import {LicenseKey} from "../../provider/_context";
import {useLicenseKeyAction} from "../../provider/_reducer";
import {ImportFileModal} from "../../../../Component/importComponent";
import {ResultImport} from "../modal/_resultImport";
import useDigitalManagementFilter from "../../../products/digital/hooks/useDigitalManagementFilter";
import useAlert from "../../../../hook/useAlert";
import {ModalExport} from "../../../../common/modalExport";

export const PageHeaderLicense = ()=>{

    const{querySearch, methods} = useLicenseKeyFilter()
    const {functions, create} = useLicenseKeyCreate()

    const {pageState, pageDispatch} = useContext(LicenseKey)
   const [exportModalData, setExportModalData] = useState(null)
   const {showAlert} = useAlert()

   const handleExportClick = () => {
      let queryString = '?'
      let i = 0
      for (const [key, value] of Object.entries({
         ...querySearch,
         per_page: '',
         start: '',
      })) {
         queryString += `${i > 0 ? '&' : ''}${key}=${value}`
         i++
      }

      handleLargeExport(
         {
            ...querySearch,
            per_page: '',
            start: '',
         }
      )
   }

   const handleLargeExport = (q) => {
      if (pageState.table.pagination.totalItems <= 0) {
         showAlert({type: 'info', content: `Số lượng key sản phẩm phải lớn hơn 0`})
         return
      }

      setExportModalData({
         data: {
            query: q,
            total: pageState.table.pagination.totalItems,
         },
         onClose: () => setExportModalData(null),
      })
   }
   
    const onCloseImport = ()=>{
        pageDispatch({type:useLicenseKeyAction.MODAL_IMPORT_LICENSEL,payload: false})
    }
    const handleCollectionData = (data)=>{
        const sliceData = data?.slice(
            LICENSE_COLUMN_NAMES?.startRowNumber
        )

        let qs={
            items: sliceData?.map((map, i)=>(
                {
                    "row": +LICENSE_COLUMN_NAMES?.startRowNumber + i + 1,
                    "ordering" :map[0],
                    "product_sku" : map[1],
                    "product_name": map[2],
                    "license_key": map[3],
                    "app_id": map[4],
                    "pack_name": map[5],
                    "app_name": map[6],
                    "level": map[7],
                    "quota_active": map[8],
                }
            ))
        }
        return qs
    }
    const handleCollectionCsv = data =>{
        const sliceData = data?.slice(4)
        const keys = sliceData?.map(map=>Object.keys(map))[0]
        let qs={
            items: sliceData?.map((map, i)=>(
                {
                    "row": 5 + i + 1,
                    "ordering" :map[keys[0]],
                    "product_sku" : map[keys[1]],
                    "product_name": map[keys[2]],
                    "license_key": map[keys[3]],
                    "app_id": map[keys[4]],
                    "pack_name": map[keys[5]],
                    "app_name": map[keys[6]],
                    "level": map[keys[7]],
                    "quota_active": map[keys[8]],
                }
            ))
        }
        return qs
    }
    const handleGetResult = res =>{
        if(res) pageDispatch({
            type:useLicenseKeyAction.MODAL_IMPORT_RESULT_OPEN,
            payload:{
                open:true,
                data: res
            }
        })
        if(+res?.item_success > 0) methods.fetchApiLicense(querySearch)
    }
    const handleCloseResult = _=>{
        pageDispatch({
            type:useLicenseKeyAction.MODAL_IMPORT_RESULT_OPEN,
            payload:{
                open:false,
                data: null
            }
        })
    }

    const actions = [
        ()=>methods.fetchApiLicense(querySearch),
        ()=> pageDispatch({type:useLicenseKeyAction.MODAL_IMPORT_LICENSEL,payload: true}),
       handleExportClick,
        ()=>functions?.onOpen(true)
    ]
    const actionList = ACTION_HEADER_LICENSE?.map((map,i)=>({
        ...map,
        onClick: actions[i]
    }))
    return(
        <>
            <PageHeader
                actions={actionList}
                breadcrumbLinks={LICENSE_BREADCUM}
                breadcrumbTitle={'Quản lý Key sản phẩm số'}
            />
           {!!exportModalData && <ModalExport data={exportModalData} title={'key sản phẩm'} api={`/product/license/export`}/>}
            {
                create?.open && <CreateLicenseKey open={create?.open}/>
            }

            {
                pageState?.importExcel?.open && <ImportFileModal
                    onClose={onCloseImport}
                    columnNames={LICENSE_COLUMN_NAMES?.columnNames}
                    startRowNumber={LICENSE_COLUMN_NAMES?.startRowNumber}
                    collectedData={handleCollectionData}
                    url={'product/license/import'}
                    title={'Tạo mã Key bằng Excel'}
                    importFile={'/files/product_license_keys_template.xlsx'}
                    positionStart={2}
                    positionCsvStart={2}
                    result={handleGetResult}
                    collectedDataCsv={handleCollectionCsv}
                />
            }
            {
                pageState?.importExcel?.result?.open && <ResultImport
                    open={pageState?.importExcel?.result?.open}
                    data={pageState?.importExcel?.result?.data}
                    onClose={handleCloseResult}
                />
            }
        </>

    )
}