import { useContext, useState } from 'react'
import { OrderContext } from '../provider/_context'
import { orderActions } from '../provider/_reducer'
import useAlert from '../../../hook/useAlert'
import config from '../../../config'
import { useNavigate, useParams } from 'react-router-dom'
import { sendRequestAuth } from '../../../api/api'
import useGlobalContext from '../../../containerContext/storeContext'

export const useEditOrder = () => {
  const { pageState, pageDispatch } = useContext(OrderContext)
  const [globalState] = useGlobalContext()
  const setting = globalState?.user?.setting
  const freePrice = setting?.free_ship || 0
  const shippingFee = setting?.shipping_fee || 0
  const { formEdit } = pageState
  const { details } = formEdit
  const {showAlert} = useAlert()
  const { orderId } = useParams()

  //=======Phone========
  const valuePhone = formEdit.customerPhone?.value
  const statusPhone = formEdit.customerPhone?.status
  const messagePhone = formEdit.customerPhone?.message

  const handleChangePhone = data => {
    const regexNumb = /^(|[0-9]\d*)$/
    if (regexNumb.test(data) || data === '') pageDispatch({
      type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
        customerPhone: {
          value: data,
          status: false,
          message: ''
        }
      }
    })
  }
  const handleBlurPhone = data => {
    if (data?.length > 11 || data?.length < 10 && data?.length > 0) pageDispatch({
      type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
        customerPhone: {
          value: data,
          status: true,
          message: 'Số điện thoại chỉ được phép nhập 10, 11 ký tự.'
        }
      }
    })
    else if (!!!data)
      pageDispatch({
        type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
          customerPhone: {
            value: data,
            status: true,
            message: 'Số điện thoại không được để trống.'
          }
        }
      })
    else
      pageDispatch({
        type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
          customerPhone: {
            value: data,
            status: false,
            message: ''
          }
        }
      })
  }

  //=======Email========
  const valueEmail = formEdit.customerEmail?.value
  const statusEmail = formEdit.customerEmail?.status
  const messageEmail = formEdit.customerEmail?.message

  const handleChangeEmail = data => pageDispatch({
    type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
      customerEmail: {
        value: data,
        status: false,
        message: ''
      }
    }
  })
  const handleBlurEmail = data => {
    let regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!regex.test(data) && data !== '') pageDispatch({
      type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
        customerEmail: {
          value: data,
          status: true,
          message: 'Email phải nhập theo example@gmail.com'
        }
      }
    })
    else if (data?.length >= 201) pageDispatch({
      type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
        customerEmail: {
          value: data,
          status: true,
          message: 'Email được phép nhập 200 ký tự.'
        }
      }
    })
    else if (!!!data) pageDispatch({
      type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
        customerEmail: {
          value: data,
          status: true,
          message: 'Email không được để trống'
        }
      }
    })
    else pageDispatch({
        type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
          customerEmail: {
            value: data,
            status: false,
            message: ''
          }
        }
      })
  }

  //=======NAME========
  const valueName = formEdit.customerName?.value
  const statusName = formEdit.customerName?.status
  const messageName = formEdit.customerName?.message

  const handleChangeName = data => pageDispatch({
    type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
      customerName: {
        value: data,
        status: false,
        message: ''
      }
    }
  })
  const handleBlurName = data => {
    if (!!!data) pageDispatch({
      type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
        customerName: {
          value: data,
          status: true,
          message: 'Tên khách hàng không được để trống'
        }
      }
    })
    else if (data?.length >= 201) pageDispatch({
      type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
        customerName: {
          value: data,
          status: true,
          message: 'Tên khách hàng không được phép vượt quá 200 ký tự'
        }
      }
    })
    else pageDispatch({
        type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
          customerName: {
            value: data,
            status: false,
            message: ''
          }
        }
      })
  }

  //=======Address========
  const valueAddress = formEdit.customerAddress?.value
  const statusAddress = formEdit.customerAddress?.status
  const messageAddress = formEdit.customerAddress?.message

  const handleChangeAddress = data => pageDispatch({
    type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
      customerAddress: {
        value: data,
        status: false,
        message: ''
      }
    }
  })
  const handleBlurAddress = data => {
    if (data?.length >= 101) pageDispatch({
      type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
        customerAddress: {
          value: data,
          status: true,
          message: 'Địa chỉ không được phép vượt quá 200 ký tự'
        }
      }
    })
    else if(data.trim() === '' || !data){
      pageDispatch({
        type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
          customerAddress: {
            value: data,
            status: true,
            message: 'Địa chỉ không được bỏ trống'
          }
        }
      })
    }else pageDispatch({
      type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
        customerAddress: {
          value: data,
          status: false,
          message: ''
        }
      }
    })
  }
  //=======FeeShip========
  const valueFeeShip = formEdit.order?.shipping_fee
  const statusFeeShip = formEdit.feeShip?.status
  const messageFeeShip = formEdit.feeShip?.message

  const handleChangeFeeShip = data => {
    let currentValue = data.toString().replace(/[^0-9]/g, '')
    if (currentValue.startsWith('0')) currentValue = currentValue.substring(1)
    pageDispatch({
      type: orderActions.UPDATE_FORM_EDIT_DETAILS_TOTAL, payload: {
        shipping_fee: +currentValue || 0,
        grand_total: formEdit?.order?.sub_total - +data,
      }
    })
  }
  const handleBlurFeeShip = data => {
    if (data?.length === 0) pageDispatch({
      type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
        feeShip: {
          status: true,
          message: 'Phí vận chuyển không được để trống'
        }
      }
    })
    else pageDispatch({
      type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
        feeShip: {
          status: false,
          message: ''
        }
      }
    })
  }
  //=======NOTE========
  const valueOrderNotes = formEdit.orderNotes?.value
  const statusOrderNotes = formEdit.orderNotes?.status
  const messageOrderNotes = formEdit.orderNotes?.message

  const handleChangeOrderNotes = data => pageDispatch({
    type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
      orderNotes: {
        value: data,
        status: false,
        message: ''
      }
    }
  })
  const handleBlurOrderNotes = data => {
    if (data?.length >= 101) pageDispatch({
      type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
        orderNotes: {
          value: data,
          status: true,
          message: 'Ghi chú không được phép vượt quá 200 ký tự'
        }
      }
    })
    else pageDispatch({
      type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT, payload: {
        orderNotes: {
          value: data,
          status: false,
          message: ''
        }
      }
    })
  }

  // update product detail

  const handleProductSelect = (data, opt) => {
    let amount = +opt?.amount
    if (!['increase', 'decrease', 'amount', 'price'].includes(opt?.type) || !data) return
    amount = Math.ceil(amount)
    let newSelectArr = details
    const findIndexData = newSelectArr.findIndex(
      item => item?.id === data?.id,
    )

    switch (opt.type) {
      case 'amount':
        if ((!!amount && amount <= 9999) || !amount) {
          if (findIndexData !== -1) {
            if (!!opt?.delete) {
              newSelectArr = newSelectArr.filter(
                (item, i) => i !== findIndexData,
              )
            } else
              newSelectArr[findIndexData].quantity = amount || 0
          } else newSelectArr = [...newSelectArr, { data, quantity: 1 }]
        }
        break

      case 'decrease':
        if (findIndexData !== -1) {
          if (newSelectArr[findIndexData].quantity > 1)
            newSelectArr[findIndexData].quantity = +newSelectArr[findIndexData].quantity - 1
        }
        break

      case 'increase':
        if ((!!amount && amount <= 9999) || !amount) {
          if (findIndexData !== -1) {
            newSelectArr[findIndexData].quantity = +newSelectArr[findIndexData].quantity + 1
            opt?.price ? newSelectArr[findIndexData].price = Number(opt?.price) : ''
          } else {
            newSelectArr = [...newSelectArr, { data, quantity: 1, price: Number(opt?.price || 0) }]
          }
        }
        break
      case 'price':
        if (findIndexData !== -1) {
          let price = Number(opt?.value || newSelectArr[findIndexData].price_sale)
          if (price > newSelectArr[findIndexData].price) price = newSelectArr[findIndexData].price
          if (price <= 99999999) {
            newSelectArr[findIndexData].price_sale = price
          }
          newSelectArr[findIndexData].trigger = !newSelectArr[findIndexData]?.trigger
        }
        break
      default:
        break
    }

    //calculate subtotal

    const totalPrice = newSelectArr.reduce((total, item) => {
      total += (+item.price * +item.quantity)
      return total
    }, 0)

    //calculate totalDiscount

    const totalDiscount = newSelectArr.reduce((total, item) => {
      total += ((+item.price - +item.price_sale) * +item.quantity)
      return total
    }, 0)
    //
    // pageDispatch({
    //   type: 'FORM_PAYMENT_MONEY_UPDATE',
    //   payload: { value: totalPayment > 0 ? totalPayment : 0 },
    // })
    pageDispatch({ type: orderActions.UPDATE_FORM_EDIT_DETAILS, payload: newSelectArr })
    pageDispatch({
      type: orderActions.UPDATE_FORM_EDIT_DETAILS_TOTAL, payload: {
        total_amount: totalPrice,
        discount: totalDiscount,
        sub_total: totalPrice - totalDiscount,
        shipping_fee: freePrice > (totalPrice - totalDiscount) ? +shippingFee : 0,
        grand_total: totalPrice - totalDiscount + (freePrice > (totalPrice - totalDiscount) ? +shippingFee : 0),
      }
    })
  }

  const validateFormBeforeSubmit = _ => {
    let success = true
    if(!!!valueName) {
      handleBlurName(valueName)
      success = false
    }
    if(!!!valuePhone) {
      handleBlurPhone(valuePhone)
      success = false
    }
    if(!!!valueEmail) {
      handleBlurEmail(valueEmail)
      success = false
    }
    if(!!!valueAddress) {
      handleBlurAddress(valueAddress)
      success = false
    }
    pageState.formEdit.details.map(product => {
      if(product.quantity == 0 ) success = false
    })
    return success
  }

  const [debounceSubmitCreate, setDebounceSubmitCreate] = useState(true)
  const nav = useNavigate()
  const handleSubmit = async () => {
    if(debounceSubmitCreate && validateFormBeforeSubmit()) {
      // pageDispatch({
      //   type: orderActions.CHANGE_LOADING,
      //   payload: true
      // })
      setDebounceSubmitCreate(false)
      setTimeout(() => setDebounceSubmitCreate(true), 2000)

      // call api update

      const data = {
        "customer_name" : valueName,
        "customer_phone" : valuePhone,
        "customer_email" : valueEmail,
        "customer_address" : valueAddress,
        "order_notes" : valueOrderNotes,
        "details" : pageState.formEdit.details
      }
      const response = await sendRequestAuth('post',
        `${config.API}/order/update/${orderId}`,
        data
      )
      if (response.data.success) {
        showAlert({
          type: 'success',
          content: 'Cập nhật đơn hàng thành công.'
        })
        nav('/order/books')
      } else {
        showAlert({
          type: 'danger',
          content: !!response.data?.message ? response.data?.message : 'Cập nhật đơn hàng thất bại!'
        })
      }
    }
  }

  return {
    customerInfo: pageState.formEdit,
    details: pageState.formEdit.details,
    product: {
      onProductSelect: handleProductSelect
    },
    phone: {
      value: valuePhone,
      status: statusPhone,
      message: messagePhone,
      methods: {
        onChange: handleChangePhone,
        onBlur: handleBlurPhone
      }
    },
    email: {
      value: valueEmail,
      status: statusEmail,
      message: messageEmail,
      methods: {
        onChange: handleChangeEmail,
        onBlur: handleBlurEmail
      }
    },
    name: {
      value: valueName,
      status: statusName,
      message: messageName,
      methods: {
        onChange: handleChangeName,
        onBlur: handleBlurName
      }
    },
    address: {
      value: valueAddress,
      status: statusAddress,
      message: messageAddress,
      methods: {
        onChange: handleChangeAddress,
        onBlur: handleBlurAddress
      }
    },
    feeShip: {
      value: valueFeeShip,
      status: statusFeeShip,
      message: messageFeeShip,
      methods: {
        onChange: handleChangeFeeShip,
        onBlur: handleBlurFeeShip
      }
    },
    orderNotes: {
      value: valueOrderNotes,
      status: statusOrderNotes,
      message: messageOrderNotes,
      methods: {
        onChange: handleChangeOrderNotes,
        onBlur: handleBlurOrderNotes
      }
    },
    onSubmit: handleSubmit
  }
}