import {STORE_UPOS_ICON} from "./_icons";

export const STORE_UPOS_BREADCRUMB = [
  {id: 1, name: 'Trang chủ', url: '/'},
  {id: 2, name: 'Sự kiện', url: '/events'},
  {id: 3, name: 'Quản lý sự kiện', url: '/events'},
]

export const STORE_UPOS_HEADER_ACTIONS = [
  {
    id: 2,
    name: 'Thêm mới sự kiện',
    appearance: 'primary',
    icon: STORE_UPOS_ICON.plus,
  },
]

export const TAG_DELETE=[
  'search',
  'position',
  'status'
]

export const EVENT_FILTER_FORM_DATE_TIME_SORT_TYPES = [
  {id: 1, name: 'Thời gian campaign', value: 'created'},
]

export const EVENT_STATUSES = {
  ongoing: {background: '#EFFBF4', color: '#33CC70'},
  finished: {background: '#ECF4FE', color: '#0B74E5'},
  upcoming: {background: '#FFF5EB', color: '#FC820A'},
  canceled: {background: '#FFEBEC', color: '#FF424E'},
  _6: {background: '#EBFFF9', color: '#007B56'},
  _8: {background: '#EBFAFF', color: '#038DB2'},
  _15: {background: '#EFF3FB', color: '#7C88A6'},
  _17: {background: '#FFF0EB', color: '#FC4C0A'},
  _19: {background: '#EBFFF4', color: '#007D3A'},
  _21: {background: '#EFF3FB', color: '#7C88A6'},
  _22: {background: '#EBF8FE', color: '#1BACF9'},
}