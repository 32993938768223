import { Th } from 'layouts/tableLayout/_th'
import { Tr } from 'layouts/tableLayout/_tr'
import useCustomerTHead from 'Pages/customer/hooks/useCustomerTHead'

export const CustomerTHead = ({ ...props }) => {

  const { globalLoading } = useCustomerTHead()

  return (
    <>
      <Tr {...props} type="tHead" style={{ height: '44px' }}>
        <Th className="customer-table__cell">Khách hàng</Th>
        <Th className="customer-table__cell">Số điện thoại</Th>
        <Th className="customer-table__cell">HT giới thiệu</Th>
        <Th className="customer-table__cell">
          GV giới thiệu
        </Th>
        <Th className="customer-table__cell">Ngày đăng ký</Th>
        <Th className="customer-table__cell">Trạng thái</Th>
        <Th
          className="customer-table__cell"
          style={{ display: 'flex' }}
        />
      </Tr>
      {globalLoading.value && (
        <div className="customer-table__loading">
          <img src="/img/loading.gif"/>
        </div>
      )}
    </>
  )
}
