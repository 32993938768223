import {formatDatetime} from 'common/form/datePicker/_functions'
import {getDateFromNow} from 'util/date'
import {OPTION_MINIAPP_ACTIVE, OPTION_MINIAPP_SEND_KEY} from '../interfaces/~constants'

export const dateTimeDefaultValue = [
   getDateFromNow(0, {type: 'start'}),
   getDateFromNow(7, {type: 'end'}),
]
export const formatDateTimeDefaultValue = `${formatDatetime(
   dateTimeDefaultValue[0],
)} - ${formatDatetime(dateTimeDefaultValue[1])}`

export const productInitialState = {
   formCreate: {
      basic: {
         active: true,
         name: '',
         optionMiniappActive: OPTION_MINIAPP_ACTIVE[0],
         optionMiniappSendKey: OPTION_MINIAPP_SEND_KEY[0],
         code: '',
         productSku: '',
         copyrightTerm: '',
         publisher: '',
         platform: '',
         language: '',
         devices: '',
         status: {},
         groupProduct: {
            id: '',
            value: [],
            list: [],
            listOrigin: [],
            keyword: '',
            loading: false,
            tab: 'all', // all | checked
         },
         validate: {
            name: {
               status: false,
               message: ''
            },
            code: {
               status: false,
               message: ''
            },
            productSku: {
               status: false,
               message: ''
            },
            devices: {
               status: false,
               message: ''
            },
            group: {
               status: false,
               message: '',
            },
         },
         modal: {
            open: false,
            form: {
               code: '',
               name: '',
               group: [],
               note: '',
            },
            validate: {
               code: {
                  status: false,
                  message: '',
               },
               name: {
                  status: false,
                  message: '',
               }
            }
         }
      },
      product: {
         image: {
            id: '',
            name: '',
            link: '',
            list: [],
            listOrigin: [],
            imageFiles: []
         },
         size: '',
         cover: {},
         description: '',
         shortDescription: '',
         note: {
            open: true,
            content: '',
         },
         validate: {
            image: {
               status: false,
               message: ''
            },
         },
         modal: {
            open: false,
            form: {
               unit: '',
               symbol: '',
            },
            validate: {
               unit: {
                  status: false,
                  message: '',
               },
               symbol: {
                  status: false,
                  message: '',
               },
            }
         },
      },
      price: {
         retail: 0,
         discount: '',
         dateTime: {
            end: null,
            start: null,
            trigger: true,
            value: '',
         },
         validate: {
            retail: {
               status: false,
               message: '',
            },
            discount: {
               status: false,
               message: '',
            },
         }
      },
      active: {
         link_download: '',
         file: null,
         image: {
            id: '',
            name: '',
            link: '',
            list: [],
            listOrigin: [],
            imageFiles: []
         },
         validate: {
            link_download: {
               status: false,
               message: '',
            },
            image: {
               status: false,
               message: ''
            },
         }
      },
      zoomIn: {
         open: false,
         linkActive: '',
      },
      statusForm: 'create',

      productRelated: {
         table: {
            display: {
               list: [],
               listDefault: [],
               loading: true,
            },
            selected: {
               list: [],
            },
            pagination: {
               active: 0,
               amount: 200,
               total: 0,
               totalItems: 0,
            },
         },
         modalProduct: {
            show: false,
            filter: {
               search: {
                  value: null,
                  active: null
               },
               category: {
                  list: [],
                  listOrigin: [],
                  activeValue: null,
                  value: null,
                  keyword: '',
               },
            },
            table: {
               display: {
                  list: [],
                  listDefault: [],
                  loading: true,
               },
               detail: {
                  id: null,
                  active: null,
                  list: [],
               },
               selected: {
                  list: [],
               },
               pagination: {
                  active: 0,
                  amount: 200,
                  total: 0,
                  totalItems: 0,
               },
            },
         }
      },
   },
   modal: {
      confirmProductStatus: false,
      dataChangeProductStatus: {},
      confirmRemoveProduct: false,
      dataRemoveProduct: {},
      confirmProductDetailStatus: false,
      confirmProductGroupStatus: false,
      dataChangeProductDetailStatus: {},
      confirmPopup1: false,
      confirmPopup2: false,
      confirmPopup3: false,
      statusConfirmEdit: {
         warehouse_quantity: 0,
         order: 0
      }

   },
   export_file: '#',
   import_file: null,
   tab: {
      tab_id: [],
      list_id: []
   },
   notifications: {
      list: [],
      total: ''
   },
   loading: false
}