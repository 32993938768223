import React, { useContext, useState } from 'react'
import { BannerManagementContext } from '../provider/_context'
import { bannerManagementActions } from '../provider/_init'
import { sendRequestAuth } from '../../../api/api'
import config from '../../../config'
import useAlert from 'hook/useAlert'
import { convertDateTimeToApiFormat, formatDatetime } from '../../../common/form/datePicker/_functions'
import { LINK_BANNER } from '../components/modal/component/linkTo'
import { POSITION_BANNER } from '../components/modal/component/positionBanner'
import {STATUS_BANNER} from "../components/modal/component/statusBanner";

const useBannerManagementRow = data => {
  const { pageState, pageDispatch } = useContext(BannerManagementContext)
  const { table } = pageState
  const { showAlert } = useAlert()
  // const navigate = useNavigate()

  const [shouldOpenSubmitPaymentModal, setShouldOpenSubmitPaymentModal] = useState(false)

  const detailActive = table.detail.active
  const detailActiveId = table.detail.id
  const detailList = table.detail.list

  const selectedList = table.selected.list

  const isSelected = !!selectedList.find(item => item?.id === data?.id)

  const shouldOpenDetail = data?.id && detailActiveId === data.id

  const handleToggleDetail = _ => {
    if (!data?.id) return
    if (data.id === detailActiveId) {
      pageDispatch({
        type: bannerManagementActions.TABLE_DISPLAY_DETAIL_ID_UPDATE,
        payload: { id: null },
      })
      pageDispatch({
        type: bannerManagementActions.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: { active: null },
      })
      return
    }

    pageDispatch({
      type: bannerManagementActions.TABLE_DISPLAY_DETAIL_ID_UPDATE,
      payload: { id: data.id },
    })

    const findDetail = detailList.find(item => item?.id === data.id)
    if (findDetail) {
      pageDispatch({
        type: bannerManagementActions.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: { active: findDetail },
      })
    }

    fetchRowDetail()
  }

  const fetchRowDetail = async _ => {
    const response = await sendRequestAuth('get', `${config.API}/banner/detail/${data.id}`)

    if (response?.data?.success) {
      const newItem = response?.data?.data
      pageDispatch({
        type: bannerManagementActions.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: { active: newItem, list: [...detailList, newItem] },
      })
    } else {
      pageDispatch({
        type: bannerManagementActions.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: { active: [], list: [...detailList, []] },
      })
    }
  }

  const handleEditDetail = item => {
    pageDispatch({ type: bannerManagementActions.SET_ID, payload: item?.id })
    pageDispatch({
      type: bannerManagementActions.UPDATE_FORM_CREATE, payload: {
        title: {
          value: item?.title,
          validate: null
        },
        image: {
          link: item?.image,
          file: null
        },
        startedDate: {
          value: item?.started_at ? new Date(item?.started_at) : new Date(),
          originValue: item?.started_at ? new Date(item?.started_at) : new Date(),
          formatValue:  convertDateTimeToApiFormat(formatDatetime(item?.started_at ? new Date(item?.started_at) : new Date())),
          originFormatValue:  convertDateTimeToApiFormat(formatDatetime(item?.started_at ? new Date(item?.started_at) : new Date()))
        },
        endedDate: {
          value: item?.ended_at ? new Date(item?.ended_at) : null,
          originValue: item?.ended_at ? new Date(item?.ended_at) : null,
          formatValue:  item?.ended_at ? convertDateTimeToApiFormat(formatDatetime(new Date(item?.ended_at))) : null,
          originFormatValue:  item?.ended_at ? convertDateTimeToApiFormat(formatDatetime(new Date(item?.ended_at))) : null
        },
        status:  +item?.status,
        ordering: item?.ordering,

        position: POSITION_BANNER.find(po => po.value === item?.position),
        description: item?.description,
        color: item?.color,
        linkTo: !!item?.link_type ? LINK_BANNER.find(link => link.value === item?.link_type) : null,
        category: {
          ...pageState.create.category,
          value: (item?.link_type === 'category') ? pageState.create.category.list.find(cate => +cate.id === +item?.link_id) : null,
        },
        product: {
          ...pageState.create.product,
          value: (item?.link_type === 'product') ? pageState.create.product.list.find(product => product.id === item?.link_id) : null,
        },
      }
    })

    setTimeout(() => {
      pageDispatch({ type: bannerManagementActions.OPEN_MODAL, payload: true })
    }, 300)

  }

  const handleDisplayDeleteModal = (boo, id = '') => {
    pageDispatch({
      type: bannerManagementActions.MODAL_DELETE_PRODUCT,
      payload: {
        open: boo,
        id: id
      }
    })
  }

  const fetchListBanner = async () => {
    pageDispatch({ type: bannerManagementActions.TABLE_LOADING_DISPLAY, payload: true })
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/banner?keyword=&category_id=&status=&per_page=20&start=`),
    ])
    if (response[0]?.data?.success) {
      const banners = response[0]?.data
      pageDispatch({
        type: bannerManagementActions.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: banners?.data,
          listDefault: banners?.data,
          loading: false
        }
      })
      pageDispatch({
        type: bannerManagementActions.TABLE_UPDATE_PAGINATION,
        payload: {
          active: 0,
          amount: banners?.meta?.per_page,
          total: Math.ceil(banners?.meta?.total / banners?.meta?.per_page),
          totalItems: banners?.meta?.total,
        }
      })
    }
  }
  const handleDeleteBanner = async id => {
    const response = await sendRequestAuth('delete', `${config.API}/banner/delete/${id}`)

    if (response?.data?.success) {
      showAlert({
        type: 'success',
        content: 'Đã xóa thành công banner'
      })
      await fetchListBanner()
    } else {
      showAlert({
        type: 'danger',
        content: 'Xóa banner thất bại'
      })
    }
  }
  return {
    modal: pageState.delete,
    detail: {
      id: detailActiveId,
      active: detailActive,
      // tabs: { payment: { formatDateTime: cellCodeOrderFormatDateTime } },
    },
    row: {
      data,
      isSelected,
      shouldOpenDetail,
      shouldOpenSubmitPaymentModal,
      onCloseSubmitPaymentModal: () => setShouldOpenSubmitPaymentModal(false),
      onToggleDetail: handleToggleDetail,
      onEditDetail: handleEditDetail,
      onDisplayDeleteModal: handleDisplayDeleteModal,
      onDeleteBanner: handleDeleteBanner,
      fetchListBanner
    },
  }
}

export default useBannerManagementRow