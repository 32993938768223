import useRevenueFilterForm from 'Pages/revenue/hooks/useRevenueFilterForm'
import { CategoryInput } from '../../../../common/form/input/_categoryInput'

export const RevenueCustomerInfo = () => {
  const { customerInfo } = useRevenueFilterForm()
  return (
    <CategoryInput
      className={'revenue-filter-form__input-wide'}
      categoryList={[]}
      categoryValue={{ name: 'Tên khách hàng/Số điện thoại', value: '' }}
      categoryWidth={200}
      placeholder='Nhập Tên khách hàng/Số điện thoại'
      value={customerInfo?.value || ''}
      onChange={customerInfo.onChange}
      style={{ position: 'relative' }}
    />
  )
}
