import React, { useState } from 'react'
import { Box, Modal } from '@mui/material'
import { Button } from 'common/button/index'
import { Text } from 'common/text'
import useBookManagementRow from '../../hooks/useBookManagementRow'
import './index.scss'

export const DeleteConfirmModal = _ => {
  const { modal, row } = useBookManagementRow()

  return (
    <>
      <Modal
        open={modal.open}
        onClose={() => {row.onDisplayDeleteModal(false)}}
        className={'modal-confirm'}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={'modal-confirm__box'}>
          <div>
            <Text>Bạn có chắc chắn muốn xoá sản phẩm đã chọn?</Text>
            <div className={'modal-confirm__group-btn'}>

              <Button size='sm' className={'modal-confirm__dismiss'} appearance={'ghost'}
                      onClick={() => {
                        row.onDisplayDeleteModal(false)
                      }}
              >Hủy</Button>
              {/* <button className={'modal-confirm__dismiss'}
                      onClick={() => {
                        props.handleConfirm(false)
                        setConfirm(false)
                      }}
              >Hủy</button> */}
              <Button size='sm'
                      appearance={'danger'}
                      onClick={() => {
                        row.onDeleteProduct(modal.id)
                        row.onDisplayDeleteModal(false)
                      }}
              >Xóa</Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}