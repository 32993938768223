import {useContext} from 'react'
import {EventStatisticContext} from '../../provider/_context'
import {Text} from 'common/text'
import {AlternativeAutoComplete} from 'common/form/autoComplete/_alternativeAutoComplete'
import {AutoCompleteSingleOption} from 'common/form/autocompleteSingleOption'
import styled from 'styled-components'
import {eventStatisticActions} from "../../provider/_init";
import useEventStatisticFilter from "../../hooks/useEventStatisticFilter";

const PAGE_OPTION = [
   {id: 1, name: 'Danh sách khách hàng', value: 'customer'},
   {id: 2, name: 'Biểu đồ', value: 'chart'},
]
export const PageOption = () => {
   const {pageState, pageDispatch} = useContext(EventStatisticContext)
   const {pageOption} = pageState
   const {methods} = useEventStatisticFilter()
   const handlePageOptionChange = value => {
      pageDispatch({
         type: eventStatisticActions.UPDATE_PAGE_OPTION_VALUE,
         payload: value
      })
      methods.applyOtherFilter(value)
   }

   return (
      <PageOptionStyled>
         <Text fontWeight={600}>Xem theo: </Text>
         <AlternativeAutoComplete
            className="page-option__select"
            // main input
            inputProps={{
               categoryList: [], // menu list in category dropdown
               categoryValue: {name: '', value: ''}, // if not exist this value -> default category: categoryList[0]
               categoryHidden: true,
               placeholder: '',
               readOnly: true,
               value: pageOption?.name || '',
            }}
            // menu
            hideSearchBar={true}
         >
            {PAGE_OPTION.length > 0 &&
            PAGE_OPTION.map(item => (
               <AutoCompleteSingleOption
                  key={item.value}
                  className="revenue-filter-form__option-text"
                  data-active={item.value === pageOption?.value}
                  onClick={() => item.value !== pageOption?.value ? handlePageOptionChange(item) : ''}
               >
                  {item.name}
               </AutoCompleteSingleOption>
            ))}
         </AlternativeAutoComplete>
      </PageOptionStyled>
   )
}

const PageOptionStyled = styled.div`
  display: flex;
  align-items: center;
  padding-left: 6px;
  margin-top: 20px;
  .page-option{
    &__select{
      width: 20%;
      margin-left: 8px;
    }
  }
`