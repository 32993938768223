import React from 'react';
import useEventManagementFilter from "../../hooks/useEventManagementFilter";
import {CategoryInput} from "../../../../common/form/input/_categoryInput";

const Search = () => {
  const {search} = useEventManagementFilter()
  return (
    <CategoryInput
      categoryValue={{name:'Tên campaign', value:''}}
      categoryWidth={108}
      className="principal-filter-form__input-wide"
      placeholder={"Tìm kiếm theo tên campaign"}
      value={search.value}
      onChange={e => search.onChange(e)}
    />
  )
}

export default Search