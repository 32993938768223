import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ProfileContext } from '../provider/~context'
import { profileActions } from '../provider/~action'
import { postData, sendRequestAuth } from '../../../api/api'
import config from '../../../config'
import { removeVietnameseTones } from '../../../util/checkPasswordVN'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useAlert from "../../../hook/useAlert";
import { DISPLAY_NAME_MENU } from '../../../const/display_name_menu'
import { removeAcent } from '../../../common/fieldText/_functions'
import useGlobalContext from 'containerContext/storeContext'

const useProfileUpdate = () => {
    const { t } = useTranslation()
    const { showAlert } = useAlert()
    const [debounceSubmitCreate, setDebounceSubmitCreate, state, setState] = useState(true)
    const { pageState, pageDispatch } = useContext(ProfileContext)
    const [globalState, globalDispath] = useGlobalContext()

    const userData = pageState?.userData
    const formValidate = pageState?.formValidate
    const submitQueries = {
        "name": userData?.name || '',
        "last_name": userData?.last_name || '',
        "phone": userData?.phone || '',
        "avatar": userData?.avatar || '',
    }

    const handleChangeName = value => {
        pageDispatch({ type: profileActions.FORM_CHANGE_NAME, payload: value })
    }
    const handleBlurName = value => {
        if (!!!value) pageDispatch({
            type: profileActions.VALIDATE_FORM_CHANGE_NAME, payload: {
                status: true,
                message: 'Tên không được để trống'
            }
        })
        else if (value?.length >= 201) pageDispatch({
            type: profileActions.VALIDATE_FORM_CHANGE_NAME, payload: {
                status: true,
                message: 'Tên không được phép vượt quá 200 ký tự'
            }
        })
        else pageDispatch({
            type: profileActions.VALIDATE_FORM_CHANGE_NAME, payload: {
                status: false,
                message: ''
            }
        })
    }
    const handleChangeLastName = value => {
        pageDispatch({ type: profileActions.FORM_CHANGE_LAST_NAME, payload: value })
    }
    const handleBlurLastName = value => {
        if (!!!value) pageDispatch({
            type: profileActions.VALIDATE_FORM_CHANGE_LAST_NAME, payload: {
                status: true,
                message: 'Họ không được để trống'
            }
        })
        else if (value?.length >= 201) pageDispatch({
            type: profileActions.VALIDATE_FORM_CHANGE_LAST_NAME, payload: {
                status: true,
                message: 'Họ không được phép vượt quá 200 ký tự'
            }
        })
        else pageDispatch({
            type: profileActions.VALIDATE_FORM_CHANGE_LAST_NAME, payload: {
                status: false,
                message: ''
            }
        })
    }
    const handleChangePhone = value => {
        const regexNumb = /^(|[0-9]\d*)$/
        if (regexNumb.test(value) || value === '') {
            pageDispatch({ type: profileActions.FORM_CHANGE_PHONE, payload: value })
        }
    }
    const handleBlurPhone = data => {
        if ((data?.length > 11 || data?.length < 10) && data?.length > 0) {
            pageDispatch({
                type: profileActions.VALIDATE_FORM_CHANGE_PHONE,
                payload: {
                    status: true,
                    message: 'Số điện thoại không hợp lệ'
                }
            })
        } else {
            pageDispatch({
                type: profileActions.VALIDATE_FORM_CHANGE_PHONE,
                payload: {
                    status: false,
                    message: ''
                }
            })
        }
    }
    const handleHover = hover => {
        pageDispatch({ type: profileActions.HOVER_AVATAR, payload: hover })
    }

    const handleUploadAvatar = async (val) => {
        let image = val.target.files[0]
        const formData = new FormData()
        formData.append('image', image)
        formData.get('image')
        try {
            const response = await sendRequestAuth('post', `${config.API}/account/upload`, formData)
            if (response?.data?.success) {
                showAlert({ type: 'success', content: response?.data?.message })
                pageDispatch({ type: profileActions.FORM_UPLOAD_AVATAR, payload: response?.data?.file_url })
            }
            else showAlert({ type: 'danger', content: response?.data?.errors[0]?.message })
        } catch (e) {
            console.log(e)
        }
    }
    const handleSubmit = async () => {
        if (debounceSubmitCreate) {
            pageDispatch({
                type: profileActions.CHANGE_LOADING,
                payload: true
            })
            setDebounceSubmitCreate(false)
            setTimeout(() => setDebounceSubmitCreate(true), 2000)
        }

        const response = await sendRequestAuth(
            'post',
            `${config.API}/account/update-profile`, JSON.stringify({ ...submitQueries}),
        ).catch(() => showAlert({ type: 'danger', content: t(DISPLAY_NAME_MENU.GENERAL.API_ERROR) }))

        if (response?.data?.success) {
            globalDispath({
                type: 'UPDATE_USER',
                payload: {
                    name : userData?.name,
                    last_name : userData?.last_name,
                    phone : userData?.phone,
                    avatar : userData?.avatar,
                }
            })
            showAlert({ type: 'success', content: response?.data?.message })
        } else {
            errorResponse(response?.data?.errors)
            setTimeout(() => {
                showAlert({ type: 'danger', content: t(DISPLAY_NAME_MENU.GENERAL.CHECK_INFO_BEFORE_SUBMIT) })
            }, 100)
        }
        pageDispatch({
            type: profileActions.CHANGE_LOADING,
            payload: false
        })
    }
    const errorResponse = response => {
        response?.map(res => {
            switch (res.field) {
                case 'name':
                    pageDispatch({
                        type: profileActions.VALIDATE_FORM_CHANGE_NAME, payload: {
                            status: true, message: res.message
                        }
                    })
                    break
                case 'last_name':
                    pageDispatch({
                        type: profileActions.VALIDATE_FORM_CHANGE_LAST_NAME, payload: {
                            status: true, message: res.message
                        }
                    })
                    break
                case 'phone':
                    pageDispatch({
                        type: profileActions.VALIDATE_FORM_CHANGE_PHONE, payload: {
                            status: true, message: res.message
                        }
                    })
                    break
                default:
                    break
            }
        })
    }

    const canSubmit = [
        formValidate?.name?.status,
        formValidate?.last_name?.status,
        formValidate?.phone?.status,
    ].includes(true)

    return {
        value: {
            userData,
            formValidate,
            canSubmit,
            loading: pageState.loading,
            avatarHover : pageState.avatarHover,
        },
        functions: {
            onChangeName: handleChangeName,
            onBlurName: handleBlurName,
            onChangeLastName: handleChangeLastName,
            onBlurLastName: handleBlurLastName,
            onChangePhone: handleChangePhone,
            onBlurPhone: handleBlurPhone,
            onUploadAvatar: handleUploadAvatar,
            submit: handleSubmit,
            setHover: handleHover,
        }
    }
}

export default useProfileUpdate