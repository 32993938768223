import React, {useContext} from 'react';
import {AlternativeAutoComplete} from "common/form/autoComplete/_alternativeAutoComplete";
import { Option } from 'common/form/autoComplete/_option';
import { useModal } from '../../../hooks/useModal'
import { Text } from '../../../../../common/text'
import { THEME_SEMANTICS } from '../../../../../common/theme/_semantics'
import {EventStatisticContext} from "../../../provider/_context";

export const PageEvent = () => {
   const { pageState } = useContext(EventStatisticContext)
  const {page} = useModal()
  return (
    <AlternativeAutoComplete
      className="principal-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: {name: 'Trang', value: ''}, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 90,
        categoryHidden: true,
        placeholder: 'Chọn trang',
        readOnly: true,
        label: (
          <>
            Trang <Text color={THEME_SEMANTICS.failed}>*</Text>
          </>
        ),
        value: page?.value?.title || '',
        disabled:true
        // onIconClick: () => page.onChange(null),
      }}
      // search input in dropdown menu
      searchInputProps={{
        placeholder: 'Tìm kiếm trang',
        // value: category.keyword,
        onChange: page?.onKeywordChange,
      }}
      validateText={pageState.create.validate?.page}
      validateType={!pageState.create.validate?.page ? 'success' : 'danger'}
    >
      {[{title: 'Trang chủ', id: 'home'}].map(item => (
        <Option
          key={item.id}
          className="principal-filter-form__option-text"
          data-active={item.id === page.value?.id}
          onClick={() => page.onChange(item)}
        >
          {item?.title}
        </Option>
      ))}
    </AlternativeAutoComplete>
  )
}
