import {useContext} from "react";
import {CreatePrincipalContext} from "../provider/_context";
import {useCreatePrincipalAction} from "../provider/_reducer";
import {sendRequestAuth} from "../../../../../api/api";
import config from "../../../../../config";
import {useParams} from "react-router-dom";

 export const useCreatePrincipalTable = () =>{
    const {pageState, pageDispatch} = useContext(CreatePrincipalContext)

    const {principalId} = useParams()

    const querySearch = {
        per_page: pageState?.table?.pagination?.amount || 20,
        start: (pageState?.table?.pagination?.active * pageState?.table?.pagination?.amount) || 0,
    }

    const handleAmountChange = async n => {
        const qs = {...querySearch, per_page: n, start: 0}
        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(qs)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        fetchPrincipalByFilter(queryString)

    }

    const handlePageChange = async page => {
        const amount = pageState?.table?.pagination?.amount || 20
        const qs = {
            ...querySearch,
            per_page: pageState?.table?.pagination?.amount,
            start: page * amount
        }
        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(qs)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        fetchPrincipalByFilter(queryString)
    }

    const fetchPrincipalByFilter = async (qs) => {
        pageDispatch({
            type: useCreatePrincipalAction.TABLE_LOADING_DISPLAY,
            payload: true
        })
        const response = await Promise.all([
            sendRequestAuth('get', `${config.API}/principal/teachers/${principalId}${qs}`)
        ])
        if (response[0]?.data?.success) {
            pageDispatch({
                type: useCreatePrincipalAction.TABLE_LOADING_DISPLAY,
                payload: false
            })
            const tearchers = response[0]?.data
            pageDispatch({
                type: useCreatePrincipalAction.TABLE_UPDATE_DISPLAY_LIST,
                payload: {
                    list: tearchers?.data,
                    listDefault: tearchers?.data,
                    loading: false
                }
            })
            pageDispatch({
                type: useCreatePrincipalAction.TABLE_UPDATE_PAGINATION,
                payload: {
                    active: Math.ceil(tearchers?.meta?.start / tearchers?.meta?.per_page),
                    amount: tearchers?.meta?.per_page,
                    total: Math.ceil(tearchers?.meta?.total / tearchers?.meta?.per_page),
                    totalItems: tearchers?.meta?.total,
                }
            })
        }
    }
    return{
        table:pageState.table,
        pagination: {
            onAmountChange: handleAmountChange,
            onPageChange: handlePageChange,
        }
    }
}