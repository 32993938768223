import {useContext, useState} from "react";
import {LicenseKey} from "../provider/_context";
import {useLicenseKeyAction} from "../provider/_reducer";
import StringUtils from "../../../Component/Account/utils/string";
import {sendRequestAuth} from "../../../api/api";
import config from "../../../config";
import useAlert from "../../../hook/useAlert";
import {removeVietnameseTones} from "../../../util/checkPasswordVN";

export const useLicenseKeyCreate = ()=>{
    const {pageState, pageDispatch} = useContext(LicenseKey)
    const {formCreate, filter} = pageState
    const {form, open, openConfirm} = formCreate

    const [animationClose, setAnimationClose] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)

    const {isConfirm} = formCreate
    const {showAlert} = useAlert()

    const handleOpenRightSide = boo =>{
        pageDispatch({
            type: useLicenseKeyAction.FORM_CREATE_MODAL_OPEN,
            payload: boo
        })

    }
    const handleCloseOpen = ()=>{
        if(isConfirm) pageDispatch({
            type: useLicenseKeyAction.FORM_CREATE_MODAL_OPEN_CONFIRM,
            payload: true
        })
        else{
            setAnimationClose(true)
            setTimeout(()=>{
                setAnimationClose(false)
                pageDispatch({
                    type: useLicenseKeyAction.FORM_CREATE_MODAL_OPEN,
                    payload: false
                })
                // setConfirmModal(false)
            },300)
        }
    }
    const handleChangeProduct = data =>{
        pageDispatch({
            type:useLicenseKeyAction.FORM_CREATE_MODAL_PRODUCT_VALIDATE,
            payload: {
                status: false,
                message:  ''
            }
        })
        pageDispatch({
            type: useLicenseKeyAction.FORM_CREATE_MODAL_PRODUCT_ACTIVE,
            payload: data
        })
    }
    const handleSearchProduct = data =>{
        const listOrigin = form?.product?.listOrigin
        const formatDataValue = data?.value
            ? StringUtils.removeAcent(data?.value?.toLowerCase())
            : ''

        const listData = listOrigin.filter(item => {
            const formatNameItem = item?.title
                ? StringUtils.removeAcent(item.title.toLowerCase())
                : ''
            if (formatNameItem.includes(formatDataValue.trim())) return true
            return false
        })

        pageDispatch({
            type: useLicenseKeyAction.FORM_CREATE_MODAL_PRODUCT_SEARCH,
            payload: {list: listData, keyword: data?.value || ''},
        })
    }

    const handleChangeKey = val =>{
        // let checkValue = val?.replace(/-/g, "");
        // if(+checkValue?.length < 17){
            pageDispatch({
                type:useLicenseKeyAction.FORM_CREATE_MODAL_KEY_VALUE,
                payload: removeVietnameseTones(val?.trim())
            })
            pageDispatch({
                type:useLicenseKeyAction.FORM_CREATE_MODAL_KEY_VALIDATE,
                payload: {
                    status: false,
                    message: ''
                }
            })

        // }
    }

    const handleBlurKey = val =>{
        if(val === '')  pageDispatch({
            type:useLicenseKeyAction.FORM_CREATE_MODAL_KEY_VALIDATE,
            payload: {
                status: true,
                message: 'Mã key không được để trống'
            }
        })
        else pageDispatch({
            type:useLicenseKeyAction.FORM_CREATE_MODAL_KEY_VALIDATE,
            payload: {
                status: false,
                message: ''
            }
        })
    }


    const handleChangeAppID = val =>{
        if(val.length < 225)
            pageDispatch({type:useLicenseKeyAction.FORM_CREATE_MODAL_APPID_VALUE, payload:val.trim()})
    }

    const handleChangeLevel = val =>{
        const regexNumb = /^(|[0-9]\d*)$/
        if(regexNumb.test(val) || val === ''){
            pageDispatch({type:useLicenseKeyAction.FORM_CREATE_MODAL_LEVEL_VALUE, payload:val})
        }
    }

    const handleChangeQuota = val =>{
        const regexNumb = /^(|[0-9]\d*)$/
        if(regexNumb.test(val) || val === ''){
            pageDispatch({type:useLicenseKeyAction.FORM_CREATE_MODAL_QUOTA_VALUE, payload:val})
        }
    }

    const handleChangePackName = val =>{
        pageDispatch({type:useLicenseKeyAction.FORM_CREATE_MODAL_PACKNAME_VALUE, payload:val})
    }
    const handleChangeAppName = val =>{
        pageDispatch({type:useLicenseKeyAction.FORM_CREATE_MODAL_APPNAME_VALUE, payload:val})
    }


    const handleValidateBeforeSubmit = _ =>{
        const valueKey = form?.key?.value
        const valueProduct = form?.product?.active?.title
        if(!valueKey || !valueProduct){
            pageDispatch({
                type:useLicenseKeyAction.FORM_CREATE_MODAL_KEY_VALIDATE,
                payload: {
                    status:!valueKey ? true : false,
                    message: !valueKey ? 'Mã key không được để trống' : ''
                }
            })
            pageDispatch({
                type:useLicenseKeyAction.FORM_CREATE_MODAL_PRODUCT_VALIDATE,
                payload: {
                    status:!valueProduct ? true : false,
                    message: !valueProduct ? 'Sản phẩm không được để trống' : ''
                }
            })
            return true
        }
        return false
    }
    const valueKeyCode = filter?.keyCode?.value
    const valueStatus = filter?.status?.value
    const valueProductName = filter?.productName?.value

    const queries={
        per_page: pageState?.table?.pagination?.amount || 20,
        start: (pageState?.table?.pagination?.active * pageState?.table?.pagination?.amount) || 0,
        keyword: valueKeyCode || '',
        status: valueStatus?.value || '',
        product_name: valueProductName|| ''
    }
    const fetchApi = async (qs)=>{
        pageDispatch({
            type: useLicenseKeyAction.TABLE_LOADING_DISPLAY,
            payload: true
        })
        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(qs)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        const res = await sendRequestAuth('get', `${config.API}/product/license/filter${queryString}`)
        if(res?.data?.success){
            pageDispatch({
                type: useLicenseKeyAction.TABLE_LOADING_DISPLAY,
                payload: false
            })
            const books = res?.data
            pageDispatch({
                type: useLicenseKeyAction.TABLE_UPDATE_DISPLAY_LIST,
                payload: {
                    list: books?.data,
                    listDefault: books?.data,
                    loading: false
                }
            })
            pageDispatch({
                type: useLicenseKeyAction.TABLE_UPDATE_PAGINATION,
                payload: {
                    active:Math.ceil(books?.meta?.start / books?.meta?.per_page),
                    amount: books?.meta?.per_page,
                    total: Math.ceil(books?.meta?.total / books?.meta?.per_page),
                    totalItems: books?.meta?.total,
                }
            })
        }
    }

    const fetchRowDetail = async (id) => {
        const response = await sendRequestAuth('get', `${config.API}/product/license/detail/${id}`)

        if (response?.data?.success) {
            const newItem = response?.data?.data
            pageDispatch({
                type: useLicenseKeyAction.TABLE_DISPLAY_DETAIL_UPDATE,
                payload: { active: newItem },
            })
        } else {
            pageDispatch({
                type: useLicenseKeyAction.TABLE_DISPLAY_DETAIL_UPDATE,
                payload: { active: null },
            })
        }
    }

    const handleSubmit = async ()=>{
        const validate = handleValidateBeforeSubmit()
        if(validate) return
        const url = formCreate?.type === 'edit' ? `product/license/update/${formCreate?.editId}` : 'product/license/create'
        const data = {
            "license_key" : form?.key?.value,
            "product_id": form?.product?.active?.id,
            "app_id" : form?.appId?.value || 0,
            "app_name": form?.appName?.value?.trim() || '',
            "pack_name": form?.packName?.value?.trim() || '',
            "level": form?.level?.value || 0,
            "quota_active": form?.quotaActive?.value || 0
        }
        const res = await sendRequestAuth('post',`${config.API}/${url}`, data)

        if(res?.data?.success){
            showAlert({type:'success', content: res?.data?.message})
            fetchApi(queries)
            fetchRowDetail(formCreate?.editId)
            handleOpenRightSide(false)
        }else{
            // showAlert({type:'danger', content: res?.data?.errors[0]?.message})
            pageDispatch({
                type:useLicenseKeyAction.FORM_CREATE_MODAL_KEY_VALIDATE,
                payload: {
                    status:true,
                    message: res?.data?.errors[0]?.message
                }
            })
        }


    }

    const handleGetEditKey= async(id)=>{
        const response = await sendRequestAuth('get', `${config.API}/product/license/detail/${id}`)

        if (response?.data?.success) {
            const detail = response?.data?.data
            pageDispatch({
                type:useLicenseKeyAction.FORM_CREATE_MODAL_EDIT_DETAIL,
                payload:{
                    open: true,
                    id: id,
                    form:{
                        key:{
                            value: detail?.license_key,
                            status: false,
                            message:''
                        },
                        product:{
                            list:form?.product?.list,
                            listOrigin:form?.product?.list,
                            active:form?.product?.list?.find(find =>+find.id === +detail?.product_id ),
                            status: false,
                            message:''
                        },
                        appId: {
                            value: detail?.app_id || 0,
                            status: false,
                            message: ''
                        },
                        packName:{
                            value: detail?.pack_name || '',
                            status: false,
                            message: ''
                        },
                        appName:{
                            value: detail?.app_name || '',
                            status: false,
                            message: ''
                        },
                        level: {
                            value: detail?.level || 0,
                            status: false,
                            message: ''
                        },
                        quotaActive:{
                            value: detail?.quota_active || 0,
                            status: false,
                            message: ''
                        },
                    }
                }
            })
        }
    }

    const canSubmitForm = [
        !!form?.key?.status,
        !!form?.product?.status,
        !!form?.appId?.status,
        !!form?.packName?.status,
        !!form?.appName?.status,
        !!form?.level?.status,
        !!form?.quotaActive?.status,
    ].includes(true)
    const handleCloseConfirm = _ => pageDispatch({
        type: useLicenseKeyAction.FORM_CREATE_MODAL_OPEN_CONFIRM,
        payload: false
    })
    const handleAcceptCloseModal = ()=>{
        setAnimationClose(true)
        setTimeout(()=>{
            setAnimationClose(false)
            pageDispatch({
                type: useLicenseKeyAction.FORM_CREATE_MODAL_OPEN,
                payload: false
            })
            pageDispatch({
                type: useLicenseKeyAction.FORM_CREATE_MODAL_OPEN_CONFIRM,
                payload: false
            })
            pageDispatch({
                type: useLicenseKeyAction.FORM_IS_CONFIRM,
                payload: false
            })
        },300)
    }
    return{
        create:{
            open: open,
            confirmModal,
            openConfirm,
            canSubmitForm,
            animationClose,
            isConfirm: formCreate?.isConfirm,
            type:formCreate?.type,
            product:{
                list: form?.product?.list,
                active: form?.product?.active,
                keyword: form?.product?.keyword,
                status:  form?.product?.status,
                message:  form?.product?.message,
                onChange: handleChangeProduct,
                onSearch: handleSearchProduct
            },
            key:{
                value: form?.key?.value,
                status:  form?.key?.status,
                message:  form?.key?.message,
                onChange: handleChangeKey,
                onBlur: handleBlurKey
            },
            appId:{
                value: form?.appId?.value,
                status:  form?.appId?.status,
                message:  form?.appId?.message,
                onChange: handleChangeAppID,
                // onBlur: handleBlurKey
            },
            packName:{
                value: form?.packName?.value,
                status:  form?.packName?.status,
                message:  form?.packName?.message,
                onChange: handleChangePackName,
                // onBlur: handleBlurKey
            },
            appName:{
                value: form?.appName?.value,
                status:  form?.appName?.status,
                message:  form?.appName?.message,
                onChange: handleChangeAppName,
                // onBlur: handleBlurKey
            },
            level:{
                value: form?.level?.value,
                status:  form?.level?.status,
                message:  form?.level?.message,
                onChange: handleChangeLevel,
                // onBlur: handleBlurKey
            },
            quotaActive:{
                value: form?.quotaActive?.value,
                status:  form?.quotaActive?.status,
                message:  form?.quotaActive?.message,
                onChange: handleChangeQuota,
                // onBlur: handleBlurKey
            },
        },
        functions:{
            onOpen: handleOpenRightSide,
            onClose: handleCloseOpen,
            onCloseConfirm:handleCloseConfirm ,
            onAcceptCloseModal: handleAcceptCloseModal,
            onSubmit: handleSubmit,
            onEditKey: handleGetEditKey,
            setConfirmModal
        }
    }
}