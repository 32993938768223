import {fNumber} from "../../../../util/formatNumber";

export const pieDonutOption = {
    stroke: {
        width: 2,
    },
    legend: {
        horizontalAlign: 'center',
        position: 'bottom',
        onItemHover: {
            highlightDataSeries: true
        },
        useSeriesColors:true,
        show:true,
    },
    dataLabels: {enabled: true, dropShadow: {enabled: false}},
    tooltip: {
        enabled: true,
        fillSeriesColor: true,
    },
    plotOptions: {
        donut: {donut: {labels: {show: false}}},
        pie: {
            startAngle: 0,
            endAngle: 360,
            expandOnClick: true,
            offsetX: 0,
            offsetY: 0,
            customScale: 1,
            dataLabels: {
                offset: 0,
                minAngleToShowLabel: 10
            },
            donut: {
                background: 'transparent',
                labels: {
                    show: false,
                    name: {
                        show: false,
                    },
                }
            },
        }
    },
    responsive: [{
        breakpoint: 480,
        options: {
            chart: {
                width: 300
            },
            legend: {
                show: true,

            }
        }
    }
    ]
}
    