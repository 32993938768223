import styled from 'styled-components'

export const StyledDashboardPanels = styled.div`
  margin: 12px 0 24px 0;
  padding: 24px 12px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;

  .order-panels {
    &__item {
      width: calc(20% - 12px);
      margin: 0 6px;
    }
  }
`
