import React from "react";
import {Input} from "../../../../common/form/input";
import {THEME_SEMANTICS} from "../../../../common/theme/_semantics";
import {Text} from "../../../../common/text";
import {useLicenseKeyCreate} from "../../hook/useLicenseKeyCreate";

export const Keycode = ()=>{
    const {create} = useLicenseKeyCreate()
    const {key} = create
    return(
        <Input
            label={
                <>
                    KEY
                    <Text color={THEME_SEMANTICS.failed}>*</Text>
                </>
            }
            value={key?.value}
            placeholder='Nhập mã key'
            validateText={key.status ? key.message : null}
            validateType={!key.status ? 'success' : 'danger'}
            onChange={(e) => key.onChange(e.target.value)}
            onBlur={(e) => key.onBlur(e.target.value)}
        />
    )
}