import React, { useContext, useState } from 'react';
import { ProductContext } from "../provider/~context";
import { productActions } from "../provider/~action";
import { postData, sendRequestAuth } from "../../../api/api";
import config from "../../../config";
import { replaceAllCustom } from "../../../util/functionUtil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DISPLAY_NAME_MENU } from "../../../const/display_name_menu";
import ArrayUtils from "../interfaces/array"
import { convertDateTimeToApiFormat } from '../../../common/form/datePicker/_functions'
import useAlert from '../../../hook/useAlert'

const useCreateInfoVersion = () => {
    const { t } = useTranslation()
    const { pageState, pageDispatch } = useContext(ProductContext)
    const formCreate = pageState?.formCreate
    const formInfoPrice = pageState?.formCreate?.price
    const zoomInImage = pageState?.formCreate?.zoomIn
    const modalConfirm = pageState?.modal
    const location = useLocation()?.pathname?.split('/')
    const { productId } = useParams();
    const imageFiles = pageState?.formCreate?.product?.image?.imageFiles
    const {showAlert} = useAlert()

    const handleTogglePopupPrice = bool => pageDispatch({ type: productActions.FORM_CREATE_TOGGLE_MODAL_PRODUCT_PRICE_MANAGER, payload: bool })
    const discountPrice = pageState?.formCreate?.price?.discount.length > 3 ? replaceAllCustom(pageState?.formCreate?.price?.discount, ',', '') : pageState?.formCreate?.price?.discount
    const retailPrice = pageState?.formCreate?.price?.retail.length > 3 ? replaceAllCustom(pageState?.formCreate?.price?.retail, ',', '') : pageState?.formCreate?.price?.retail
    const dateTime = formInfoPrice?.dateTime
    const submitQueries = {
        "product_sku": pageState?.formCreate?.basic?.productSku || '',
        "option_number_devices": pageState?.formCreate?.basic?.devices || '',
        "title": pageState?.formCreate?.basic?.name || '',
        "status": pageState?.formCreate?.basic?.status?.value,
        "option_license_term": pageState?.formCreate?.basic?.copyrightTerm || '',
        "option_publisher": pageState?.formCreate?.basic?.publisher || '',
        "option_platform": pageState?.formCreate?.basic?.platform || '',
        "option_language": pageState?.formCreate?.basic?.language || '',
        "category_id": pageState?.formCreate?.basic?.groupProduct?.value?.map(item => item.id).join(',') || '',
        "price": retailPrice || 0,
        "price_discount": discountPrice || '',
        "discount_started_at": dateTime?.start && dateTime.value ? convertDateTimeToApiFormat(dateTime.value.split(' - ')[0]) : "",
        "discount_ended_at": dateTime?.end && dateTime.value ? convertDateTimeToApiFormat(dateTime.value.split(' - ')[1]) : "",
        "images": pageState?.formCreate?.product?.image?.link,
        "short_description": pageState?.formCreate?.product?.shortDescription || '',
        "description": pageState?.formCreate?.product?.description || '',
        "link_download": pageState?.formCreate?.active?.link_download || '',
        "qrcode": pageState?.formCreate?.active?.image?.link,
        "use_guide": pageState?.formCreate?.active?.file?.file_url || '',
        "option_miniapp_active": pageState?.formCreate?.basic?.optionMiniappActive?.value || 0,
        "option_send_license" : +retailPrice === 0 ? pageState?.formCreate?.basic?.optionMiniappSendKey?.value : 1,
    }

    const validateFormBeforeSubmit = _ => {
        let success = true
        let location = 70
        if (pageState?.formCreate?.price?.discount === "" && !!dateTime.value) {
            pageDispatch({ type: productActions.VALIDATE_FORM_CREATE_DISCOUNT, payload: { status: true, message: 'Hãy nhập giá khuyến mãi' } })
            success = false
            location = 70
        }

        if ( discountPrice > 0 && !dateTime.value) {
            pageDispatch({ type: productActions.VALIDATE_FORM_CREATE_DISCOUNT_DATETIME, payload: { status: true, message: 'Hãy chọn thời gian khuyến mãi' } })
            success = false
            location = 330
        }

        if (+discountPrice > +retailPrice) {
            pageDispatch({ type: productActions.VALIDATE_FORM_CREATE_DISCOUNT, payload: { status: true, message: 'Giảm giá không được lớn hơn giá bán' } })
            success = false
            location = 70
        }
        if (!!!pageState?.formCreate?.basic?.name) {
            pageDispatch({ type: productActions.VALIDATE_FORM_CREATE_NAME, payload: { status: true, message: t(DISPLAY_NAME_MENU.VALIDATE.EMPTY.PRODUCT_NAME) } })
            success = false
            location = 70
        }
        if (!!!pageState?.formCreate?.basic?.copyrightTerm) {
            pageDispatch({
                type: productActions.VALIDATE_FORM_COPYRIGHT,
                payload: {
                    status: true,
                    message: 'Thời hạn bản quyền không được để trống'
                }
            })
            success = false
            location = 70
        }
        if (!!!pageState?.formCreate?.basic?.platform) {
            pageDispatch({
                type: productActions.VALIDATE_FORM_CREATE_BASIC, payload: {
                    platform: { status: true, message: 'Nên tảng không được để trống' }
                }
            })
            success = false
            location = 70
        }
        // if(!!!pageState?.formCreate?.basic?.code) {
        if (pageState?.formCreate?.basic?.groupProduct?.value?.length === 0) {
            pageDispatch({ type: productActions.VALIDATE_FORM_CREATE_GROUP_PRODUCT, payload: { status: true, message: t(DISPLAY_NAME_MENU.VALIDATE.EMPTY.GROUP_PRODUCT) } })
            success = false
            location = 70
        }
        if (!!!pageState?.formCreate?.basic?.status?.value) {
            pageDispatch({
                type: productActions.VALIDATE_FORM_CREATE_BASIC, payload: {
                    status: { status: true, message: 'Trạng thái không được để trống' }
                }
            })

            success = false
            location = 70
        }

        if (!!!pageState?.formCreate?.basic?.devices) {
            pageDispatch({
                type: productActions.VALIDATE_FORM_CREATE_BASIC, payload: {
                    devices: { status: true, message: 'Số lượng thiết bị không được để trống' }
                }
            })
            success = false
            location = 70
        }

        if (+pageState?.formCreate?.price?.discount === 0 && !!pageState?.formCreate?.price?.dateTime?.value) {
            pageDispatch({
                type: productActions.VALIDATE_FORM_CREATE_DISCOUNT,
                payload: {
                    status: true,
                    message: 'Giá khuyễn mãi không được để trống'
                }
            })
            success = false
            location = 70
        }
        if (+pageState?.formCreate?.price?.discount !== 0 && !!!pageState?.formCreate?.price?.dateTime?.value) {

            pageDispatch({
                type: productActions.VALIDATE_FORM_CREATE_DATETIME,
                payload: {
                    status: true,
                    message: 'Vui lòng chọn thời gian khuyến mãi'
                }
            })
            success = false
            location = 70
        }
        setTimeout(() => {
            scrollToItem(location)
            if (!success) showAlert({type: 'danger',content: t(DISPLAY_NAME_MENU.GENERAL.CHECK_INFO_BEFORE_SUBMIT)})
        }, 100)
        return success
    }

    const navigate = useNavigate()
    const [debounceSubmitCreate, setDebounceSubmitCreate] = useState(true)
    const scrollToItem = (location) => {
        const wrapper = document.querySelector('#content-wrap')
        wrapper.scrollTo({
            top: location,
            behavior: "smooth"
        })
    }
    const handleUploadImage = async () => {
        let formDataList = []

        for (let i = 0; i < imageFiles.length; i++) {
            const formData = new FormData()
            formData.append('images[]', imageFiles[i])
            formDataList.push(formData)
        }

        const response = await Promise.all(
            formDataList.map(item =>
                postData(`${config.API}/product/upload-multiple`, item),
            ),
        )

        return response
    }
    const submitCreate = async (option = {}) => {
        const response = await sendRequestAuth(
            'post',
            `${config.API}/product/digital/create`, JSON.stringify({ ...submitQueries, ...option }),
        ).catch(() => showAlert({
            type: 'danger',
            content: t(DISPLAY_NAME_MENU.GENERAL.API_ERROR)
        }))

        if (response?.data?.success) {
            showAlert({
                type:'success',
                content: t(DISPLAY_NAME_MENU.PRODUCT_PAGE.ADDED_SUCCESSFULLY)
            })
            navigate('/digital-product')
        } else {
            let location = 70
            errorResponse(response?.data?.errors?.details)
            if (!!response?.data?.errors?.details?.find(res => +res.code === 1003)) location = 1000
            if (!!response?.data?.errors?.details?.find(res => +res.code === 1002)) location = 70
            setTimeout(() => {
                scrollToItem(location)
                showAlert({
                    type:'danger',
                    content: t(DISPLAY_NAME_MENU.GENERAL.CHECK_INFO_BEFORE_SUBMIT)
                })
            }, 100)
        }
    }
    const submitEdit = async (option = {}) => {
        const response = await sendRequestAuth(
            'post',
            `${config.API}/product/digital/update/${productId}`, JSON.stringify({ ...submitQueries, ...option }),
        ).catch(() => showAlert({
              type:'danger',
              content: t(DISPLAY_NAME_MENU.GENERAL.API_ERROR)
          }))

        if (response?.data?.success) {
            showAlert({
                type:'success',
                content: 'Cập nhật thành công sản phẩm số'
            })
            navigate('/digital-product')
        } else {
            let location = 70
            errorResponse(response?.data?.errors?.details)
            if (!!response?.data?.errors?.details?.find(res => +res.code === 1003)) location = 1000
            if (!!response?.data?.errors?.details?.find(res => +res.code === 1002)) location = 70
            setTimeout(() => {
                scrollToItem(location)
                showAlert({
                    type:'danger',
                    content: t(DISPLAY_NAME_MENU.GENERAL.CHECK_INFO_BEFORE_SUBMIT)
                })
            }, 100)
        }
    }
    const handleSubmit = () => {
        if (debounceSubmitCreate && validateFormBeforeSubmit()) {
            pageDispatch({
                type: productActions.CHANGE_LOADING,
                payload: true
            })
            pageDispatch({
                type: productActions.VALIDATE_FORM_CREATE_WEIGHT_PRODUCT,
                payload: { status: true, message: t(DISPLAY_NAME_MENU.VALIDATE.EMPTY.INVALID_WEIGHT) }
            })
            setDebounceSubmitCreate(false)
            setTimeout(() => setDebounceSubmitCreate(true), 2000)
            const statusForm = pageState?.formCreate?.statusForm

            const uploadResponse = handleUploadImage()
            uploadResponse.then(res => {
                const urlList = res.filter(item =>
                  item?.data?.success &&
                  ArrayUtils.getQualifiedArray(item?.data?.data)[0]
                ).map(dt => `${ArrayUtils.getQualifiedArray(dt?.data?.data)[0]}`)

                if (statusForm === 'create') {
                    submitCreate({ images: urlList })
                }
                // EDIT PRODUCT
                else {
                    const images = [
                        ...pageState.formCreate.product.image.list
                            .filter(item => pageState.formCreate.product.image.listOrigin.includes(item?.id))
                            .map(item => item?.url),
                        ...urlList,
                    ]
                    submitEdit({ images: images })
                }
            })
        }
    }


    const errorResponse = response => {
        response?.map(res => {
            switch (res.field) {
                case 'title':
                    pageDispatch({ type: productActions.VALIDATE_FORM_CREATE_NAME, payload: { status: true, message: res.message } })
                    break
                case 'product_sku':
                    pageDispatch({ type: productActions.VALIDATE_FORM_CREATE_PRODUCT_SKU, payload: { status: true, message: res.message } })
                    break
                case 'status':
                    pageDispatch({
                        type: productActions.VALIDATE_FORM_CREATE_BASIC, payload: {
                            status: { status: true, message: res.message }
                        }
                    })
                    break
                case 'category_id':
                    pageDispatch({
                        type: productActions.VALIDATE_FORM_CREATE_BASIC, payload: {
                            category: { status: true, message: res.message }
                        }
                    })
                    break
                case 'price':
                    pageDispatch({ type: productActions.VALIDATE_FORM_CREATE_RETAIL, payload: { status: true, message: res.message } })
                    break
                default:
                    break
                            }
        })
    }


    const canSaveProduct = [
        pageState?.formCreate?.basic?.validate?.name?.status,
        pageState?.formCreate?.basic?.validate?.productSku?.status,
        pageState?.formCreate?.basic?.validate?.devices?.status,
        pageState?.formCreate?.basic?.validate?.group?.status,
        pageState?.formCreate?.price?.validate?.retail?.status,
        pageState?.formCreate?.price?.validate?.discount?.status,
        pageState?.formCreate?.price?.validate?.devices?.status,
        pageState?.formCreate?.price?.validate?.platform?.status,
    ].includes(true)

    const handleZoomInImage = (bool, image) => {
        pageDispatch({ type: productActions.FORM_CREATE_ZOOM_IN_IMAGE, payload: bool })
        pageDispatch({ type: productActions.FORM_CREATE_ZOOM_IN_IMAGE_LINK_ACTIVE, payload: image })
    }

    const handleCloseModalConfirmEdit = _ => {
        pageDispatch({ type: productActions.EDIT_PRODUCT_CONFIRM_POPUP_1, payload: false })
        pageDispatch({ type: productActions.EDIT_PRODUCT_CONFIRM_POPUP_2, payload: false })
        pageDispatch({ type: productActions.EDIT_PRODUCT_CONFIRM_POPUP_3, payload: false })
    }

    const handleAcceptModalConfirmEdit = async _ => {
        pageDispatch({ type: productActions.EDIT_PRODUCT_CONFIRM_POPUP_1, payload: false })
        pageDispatch({ type: productActions.EDIT_PRODUCT_CONFIRM_POPUP_2, payload: false })
        pageDispatch({ type: productActions.EDIT_PRODUCT_CONFIRM_POPUP_3, payload: false })

        delete submitQueries?.barcode
        const response = await sendRequestAuth(
            'post',
            `${config.API}/product/update/${location[3]}`, JSON.stringify(submitQueries),
        ).catch(() =>
          showAlert({
              type:'danger',
              content: 'Lỗi API, chỉnh sửa sản phẩm số thất bại'
          }))

        if (response?.data?.success) {
            showAlert({
                type:'success',
                content: 'Cập nhật sản phẩm số thành công'
            })
            navigate('/digital-product')
        } else {
            errorResponse(response?.data?.errors?.details)
            // toast.error('Sản phẩm lỗi! Không cập nhật được.')
        }
    }

    return {
        value: {
            formCreate,
            canSaveProduct,
            zoomInImage,
            modalConfirm,
            loading: pageState.loading,
        },
        functions: {
            onTogglePopupPrice: handleTogglePopupPrice,
            submit: handleSubmit,

            onZoomInImage: handleZoomInImage,

            onCloseModalConfirmEdit: handleCloseModalConfirmEdit,
            onAcceptModalConfirmEdit: handleAcceptModalConfirmEdit,
        }
    }
}


export default useCreateInfoVersion;