import React from 'react'
import { useTranslation } from 'react-i18next'
import { AlternativeAutoComplete } from 'common/form/autoComplete/_alternativeAutoComplete'
import { Option } from 'common/form/autoComplete/_option'
import { PAYMENT_TYPE } from '../../interfaces/_constants'
import useInvoiceFilterForm from '../../hooks/useInvoiceFilterForm'

export const PaymentType = () => {
  const { t } = useTranslation()
  const { invoiceType } = useInvoiceFilterForm()
  return (
    <AlternativeAutoComplete
      className="invoice-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: { name: 'Hình thức thanh toán', value: '' }, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 146,
        placeholder: t('Chọn hình thức thanh toán'),
        readOnly: true,
        value: t(invoiceType?.value?.name) || '',
        onIconClick: () => invoiceType.onChange(null),
      }}
      hideSearchBar={true}
    >
      {PAYMENT_TYPE?.map(item => (
        <Option
          key={item.value}
          className="invoice-filter-form__option-text"
          data-active={item.value === invoiceType?.value?.value}
          onClick={() => invoiceType.onChange(item)}
        >
          {t(item.name)}
        </Option>
      ))}
    </AlternativeAutoComplete>
  )
}

