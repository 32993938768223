import { TableLayout } from 'layouts/tableLayout'
import { useContext, useEffect, useRef, useState } from 'react'
import { OrderFilterForm } from './components/orderFilterForm'
import { OrderTBody } from './components/orderTable/_orderTBody'
import { OrderTHead } from './components/orderTable/_orderTHead'
import {
  ORDER_BREADCRUMB,
  ORDER_PAGE_HEADER_ACTIONS,
} from './interfaces/_constants'
import { OrderProvider } from './provider'
import './components/orderTable/index.scss'
import useOrder from './hooks/useOrder'
import { OrderNotifications } from './components/orderNotifications'
import useOrderFilterForm from './hooks/useOrderFilterForm'
import { PageHeader } from './components/orderPageHeader'
import PopupNotPermission from '../permision/popupNotPermission'

export const OrderBook = () => {
  const { fetch, pagination, provider } = useOrder()

  const { state, dispatch } = provider
  const { table } = state

  useEffect(() => {
    fetch.origin()
  }, [])
  return (
    <OrderProvider value={{ pageState: state, pageDispatch: dispatch }}>
      <Header/>
      <OrderNotifications/>
      <TableLayout
        header={
          <>
            <OrderFilterForm/>
          </>
        }
        table={{
          tHead: <OrderTHead/>,
          tBody: <OrderTBody/>,
        }}
        pagination={{
          ...table.pagination,
          onAmountChange: pagination.onAmountChange,
          onPageChange: pagination.onPageChange,
        }}
      />
    </OrderProvider>
  )
}

const Header = () => {
  const [showPermission, setShowPermission] = useState(false)
  return (
    <>
      {showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)}/>}
      <PageHeader
        breadcrumbLinks={ORDER_BREADCRUMB}
        breadcrumbTitle="Quản lý đơn hàng sách"
      />
    </>
  )
}
