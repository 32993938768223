import React, {useContext} from 'react';
import {Tr} from "layouts/tableLayout/_tr";
import {Th} from "layouts/tableLayout/_th";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {EventStatisticContext} from "../../../provider/_context";

const Thead = ({...props}) => {
  const {t} = useTranslation()
  const {pageState} = useContext(EventStatisticContext)
  const {pageOption} = pageState
  return (
    <StyledThead>
      {pageOption.id ===1 && <Tr {...props} type="tHead">
        <Th className="store-upos-table__cell">Campaign</Th>
        <Th className="store-upos-table__cell">Khách hàng</Th>
        <Th className="store-upos-table__cell">Số điện thoại</Th>
        <Th className="store-upos-table__cell">Email</Th>
        <Th className="store-upos-table__cell">Giới thiệu bởi</Th>
        <Th className="store-upos-table__cell">Thời gian tham gia</Th>
      </Tr>}
    </StyledThead>
  )
}

export default Thead


export const StyledThead = styled.div`
  .store-upos-table {
    &__cell {
      margin: 0 8px;
      text-align: left;
    }
    &__cell:nth-child(1) {
      flex: 1;
    }
    &__cell:nth-child(2) {
      width: 14%;
    }
    &__cell:nth-child(3) {
      width: 14%;
    }
    &__cell:nth-child(4) {
      width: 18%;
    }
    &__cell:nth-child(5) {
      width: 14%;
    }
    &__cell:nth-child(6) {
      width: 13%;
      text-align: right;
    }
  }
`
