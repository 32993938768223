import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  BOOK_COLUMN_NAMES,
  STORE_UPOS_BREADCRUMB,
  STORE_UPOS_HEADER_ACTIONS
} from '../../interface/_contants'
import { PageHeader } from '../../../../../layouts/pageHeader'
import useBookManagementFilter from '../../hooks/useBookManagementFilter'
import { BookManagementContext } from '../../provider/_context'
import { bookManagementActions } from '../../provider/_init'
import { ImportFileModal } from '../../../../../Component/importComponent'
import { ResultImport } from '../modal/_resultImport'

const Header = () => {
  const {
    methods,
  } = useBookManagementFilter()
  const navigate = useNavigate()
  const { state, dispatch } = useContext(BookManagementContext)

  const onCloseImport = () => {
    dispatch({ type: bookManagementActions.MODAL_IMPORT_PRINCIPAL, payload: false })
  }
  const handleGetResult = res => {
    if (res) {
      dispatch({
        type: bookManagementActions.MODAL_IMPORT_RESULT_OPEN,
        payload: {
          open: true,
          data: res
        }
      })
      methods?.applyOtherFilter()
    }
  }
  const handleCloseResult = _ => {
    dispatch({
      type: bookManagementActions.MODAL_IMPORT_RESULT_OPEN,
      payload: {
        open: false,
        data: null
      }
    })
  }

  const handleCollectionData = (data) => {
    let sliceData = data?.slice(
      BOOK_COLUMN_NAMES?.startRowNumber - 1
    )
    if(sliceData.length > 1000) sliceData = sliceData.slice(0, 1000)

    let qs = {
      items: sliceData?.map((map, i) => (
        {
          'row': +BOOK_COLUMN_NAMES?.startRowNumber + i,
          'ordering': map[0],
          'title': map[1],
          'product_sku': map[2],
          'category_name': map[3],
          'option_author': map[4],
          'status': map[5],
          'option_year': map[6],
          'option_publisher': map[7],
          'option_language': map[8],
          'price': map[9],
          'price_discount': map[10],
          'discount_started_at': map[11],
          'discount_ended_at': map[12],
          'option_size': map[13],
          'option_book_cover': !!map[14] ? map[14] : '',
          'short_description': map[15],
          'description': map[16]
        }
      ))
    }
    return qs
  }

  const handleCollectionCsv = data =>{
    const sliceData = data?.slice(4)
    const keys = sliceData?.map(map=>Object.keys(map))[0]
    let qs={
      items: sliceData?.map((map, i)=>(
        {
          'row': +BOOK_COLUMN_NAMES?.startRowNumber + i,
          'ordering': map[keys[0]],
          'title': map[keys[1]],
          'product_sku': map[keys[2]],
          'category_name': map[keys[3]],
          'option_author': map[keys[4]],
          'status': map[keys[5]],
          'option_year': map[keys[6]],
          'option_publisher': map[keys[7]],
          'option_language': map[keys[8]],
          'price': map[keys[9]],
          'price_discount': map[keys[10]],
          'discount_started_at': map[keys[11]],
          'discount_ended_at': map[keys[12]],
          'option_size': map[keys[13]],
          'option_book_cover': !!map[keys[14]] ? map[keys[14]] : '',
          'short_description': map[keys[15]],
          'description': map[keys[16]]
        }
      ))
    }
    return qs
  }
  const actions = [
    methods.applyOtherFilter,
    () => dispatch({ type: bookManagementActions.MODAL_IMPORT_PRINCIPAL, payload: true }),
    () => navigate('/product/create')
  ]

  return (
    <>
      <PageHeader
        actions={STORE_UPOS_HEADER_ACTIONS.map((item, i) => ({
          ...item,
          onClick: actions[i],
        }))}
        breadcrumbLinks={STORE_UPOS_BREADCRUMB}
        breadcrumbTitle={'Quản lý sản phẩm sách'}
      />

      {
        state?.importExcel?.open && <ImportFileModal
          onClose={onCloseImport}
          columnNames={BOOK_COLUMN_NAMES?.columnNames}
          startRowNumber={BOOK_COLUMN_NAMES?.startRowNumber}
          collectedData={handleCollectionData}
          url={'product/book/import'}
          title={'Tạo sản phẩm sách bằng Excel'}
          importFile={'/files/product_book_template.xlsx'}
          positionStart={2}
          positionCsvStart={3}
          result={handleGetResult}
          collectedDataCsv={handleCollectionCsv}
        />
      }
      {
        state?.importExcel?.result?.open && <ResultImport
          open={state?.importExcel?.result?.open}
          data={state?.importExcel?.result?.data}
          title={'Sản phẩm sách'}
          onClose={handleCloseResult}
        />
      }
    </>
  )
}

export default Header