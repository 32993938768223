import React from "react";
import {Input} from "../../../../../../Component/Input";
import {Text} from "../../../../../../common/text";
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";
import {useCreatePrincipalForm} from "../../hook/useCreatePrincipalForm";

export const Identity = ()=>{
    const {identify} = useCreatePrincipalForm()
    const {methods} = identify
    return (
        <Input
            label={
                <>
                    Số CMND
                </>
            }
            value={identify?.value}
            onChange={e => methods.onChange(e.target?.value)}
            onBlur={e => methods.onBlur(e.target?.value)}
            validateText={identify?.message}
            validateType={identify?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập số CMND'}
            // maxLength={255}
        />
    )
}