import { Text } from '../../../../../common/text'
import { THEME_SEMANTICS } from '../../../../../common/theme/_semantics'
import { Option } from '../../../../../common/form/autoComplete/_option'
import { AlternativeAutoComplete } from '../../../../../common/form/autoComplete/_alternativeAutoComplete'
import React, { useContext } from 'react'
import { BannerManagementContext } from '../../../provider/_context'
import { useModal } from '../../../hooks/useModal'


export const STATUS_BANNER = [
  { name: 'Hoạt động', value: 1 },
  { name: 'Tắt', value: 0 },
]

export const StatusBanner = () => {
  const { pageState } = useContext(BannerManagementContext)
  const { field_name } = useModal()

  return (
    <AlternativeAutoComplete
      className="principal-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: { name: 'Trạng thái', value: '' }, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 82,
        categoryHidden: true,
        placeholder: 'Chọn trạng thái', label: (
          <>
            Trạng thái <Text color={THEME_SEMANTICS.failed}>*</Text>
          </>
        ),
        readOnly: true,
        value: STATUS_BANNER.find(item => item.value === pageState.create?.status)?.name,
      }}
      hideSearchBar={true}
      // search input in dropdown menu
      validateText={pageState?.moveCustomer?.validate?.teacher}
      validateType={!pageState?.moveCustomer?.validate?.teacher ? 'success' : 'danger'}
    >
      {STATUS_BANNER.map(item => (
        <Option
          key={item.value}
          className="principal-filter-form__option-text"
          data-active={item.value === pageState.create?.status}
          onClick={() => field_name.onChangeStatusBanner(+pageState.create?.status === 1 ? 0 : 1)}
        >
          {item.name}
        </Option>
      ))}
    </AlternativeAutoComplete>
  )
}