import CONFIG from '../config'
import moment from 'moment'

let URL
let FBAPI
let URL_V3
const ENV = CONFIG.env
const days = 7 // Days you want to subtract
const date = new Date()
switch (ENV) {
  case 'DEV':
    URL = CONFIG.API_V2
    // FBAPI = 'http://14.225.241.215/api/v2'
    FBAPI = CONFIG.API_FB
    URL_V3 = CONFIG.API
    break
  default:
    break
}

export function getUrlLogin () {
  return `${URL_V3}/auth/login`
}

export function getUrlLogOut () {
  return `${URL_V3}/auth/logout`
}

export function getUrlSendOTPPassword () {
  return `${URL_V3}/open/shop/send-otp-password`
}

export function getUrlCheckOTPPassword () {
  return `${URL_V3}/open/shop/check-otp-password`
}

export function getUrlResetPassword () {
  return `${URL_V3}/account/reset-password`
}

export function getUrlDetailUserManagement(id){
  return `${URL_V3}/employees/${id}`
}

export function setActiveUserManagement(){
  return `${URL_V3}/admin/employees/active`
}

export function setResetPasswordUserManagement(id){
  return `${URL_V3}/admin/employees/reset-password/${id}`
}

export function getUrlUserInfo () {
  // return `${URL_V3}/users`
  return `${URL_V3}/auth/users`  // Fake upos
}

export function getUrlChangePassword () {
  return `${URL_V3}/account/change-password`
}

export function getUrlUpdateProfile (id) {
  return `${URL_V3}/employees/${id}`
}

export function getUrlDataChart (fromdate, toDate) {
  return `${URL}/dashboard/revenue?date_start=${fromdate}&date_end=${toDate}`
}

export function getUrlWareHouseInfo (is_purchase = '', keyword = '') {
  return `${URL}/warehouse/warehouses?is_purchase=${is_purchase}/keyword=${keyword}`
}

export function getUrlOrigin (keyword = '') {
  let url = `${URL}/order/origins?`
  if (keyword) url += `keyword=${keyword}`
  return url
}

export function getUrlOrderStatus () {
  return `${URL}/order/shipping/status`
}

export function getUrlEmployee (keyword = '', group = '', status = '') {
  let url = `${URL}/employees?`
  if (keyword) url += `keyword=${keyword}`
  if (group) url += `group=${group}`
  if (status) url += `status=${status}`
  return url
}

export function getUrlOrder ({
  keyword = '',
  customer_id = '',
  user_id = '',
  warehouse_id = '',
  shipping_partner = '',
  shipping_status = '',
  order_origin_id = '',
  livestream_id = '',
  product_id = '',
  is_duplicate = '',
  per_page = 10,
  start = '',
  end_date = moment(date).format('YYYY-MM-DD HH:mm'),
  start_date = moment(new Date(date.getTime() - (days * 24 * 60 * 60 * 1000))).format('YYYY-MM-DD HH:mm'),
  date_type = ''
}) {
  if (!start || start === '') start = '0'
  if (!per_page || per_page === '') per_page = '10'
  return `${URL_V3}/order/orders?keyword=${keyword}&date_type=${date_type}&start_date=${start_date}&end_date=${end_date}&customer_id=${customer_id}&user_id=${user_id}&warehouse_id=${warehouse_id}&shipping_partner=${shipping_partner}&shipping_status=${shipping_status}&order_origin_id=${order_origin_id}&livestream_id=${livestream_id}&product_id=${product_id}&is_duplicate=${is_duplicate}&per_page=${per_page}&start=${start}`
}

export function getUrlCustomer ({ ...params }) {
  const {
    keyword = '',
    group = 0,
    city_id = 0,
    district_id = 0,
    ward_id = 0,
    per_page = 10,
    start = 0,
  } = params
  const url = `${URL}/customer/customers?keyword=${keyword}&group=${group}&city_id=${city_id}&district_id=${district_id}&ward_id=${ward_id}&per_page=${per_page}&start=${start}`
  return url
}

export function getListShippingPartner () {
  return `${URL}/order/shipping/partner`
}

export function getUrlShippingFee () {
  return `${URL}/order/shipping/fee`
}

export function getUrlUpdateStatus () {
  return `${URL}/order/update_status`
}

export function getUrldetectAddress (address) {
  // /area/detect-address
  return `${URL}/area/detect-address?address=${address}`
}


export function getShopInfo () {
  return `${URL_V3}/shop/info`
}

export function updateShopInfo (id) {
  return `${URL_V3}/shop/update/${id}`
}

export function updloadLogoShopInfo (id) {
  return `${URL_V3}/shop/upload/${id}`
}

export function uploadImageProduct () {
  return `${URL_V3}/product/upload`
}

export function getListProductGroup(keyword='') {
  return `${URL_V3}/product-category/categories?type=digital&status=&keyword=${keyword}`
}
export function setActiveproductGroup() {
  return `${URL_V3}/product-category/active`
}
export function deleteProductGroup(id) {
  return `${URL_V3}/product-category/delete/${id}`
}export function createProductGroup() {
  return `${URL_V3}/product-category/create`
}
export function detailProductGroup(id) {
  return `${URL_V3}/product-category/detail/${id}`
}
export function updateProductGroup(id) {
  return `${URL_V3}/product-category/update/${id}`
}