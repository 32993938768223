import {RightSightPopup} from 'layouts/rightSightPopup';
import React, {useEffect} from 'react';
import {useContext} from 'react';
import ModalContent from "./contentModal"
import {ConfirmModal} from "layouts/rightSightPopup/confirm";
import {Text} from 'common/text';
import "./index.scss"
import {useTranslation} from "react-i18next";
import {EventManagementContext} from '../../provider/_context'
import {useModal} from '../../hooks/useModal'
import {sendRequestAuth} from "../../../../api/api";
import config from "../../../../config";
import {eventManagementActions} from "../../provider/_init";

export const ModalCreateEvent = () => {
   const {t} = useTranslation()
   const {pageState, pageDispatch} = useContext(EventManagementContext)
   const {animate, confirm, modal} = useModal()

   const getCategories = async () => {
      const response = await sendRequestAuth('get', `${config.API}/product-category/group-categories?type=digital&is_parent=0`)
      if (response.data?.length > 0) {
         pageDispatch({
            type: eventManagementActions.CREATE_EVENT_CATEGORY_UPDATE,
            payload: {
               list: response?.data,
               listOrigin: response?.data
            }
         })
      }
   }
   const getProduct = async () => {
      const response = await sendRequestAuth(
         'get',
         `${
            config.API
         }/order/filter/digitals?keyword=&category_id=&status=&per_page=999&start=`,
      )
      if (response.data?.length > 0) {
         pageDispatch({
            type: eventManagementActions.CREATE_EVENT_PRODUCT_UPDATE,
            payload: {
               list: response?.data,
               listOrigin: response?.data
            }
         })
      }
   }

   useEffect(() => {
      if (pageState.create.category.list?.length < 1) {
         getProduct()
         getCategories()
      }
   }, [pageState.create.category.list])
   return (
      <>
         <RightSightPopup
            openModal={pageState.open_modal}
            confirmBeforeClose={true}
            clickClose={modal.handleClose}
            disableSubmit={modal.checkBeforeSubmit}
            animationClose={animate}
            header={{
               title: pageState?.idEvent ? 'Chỉnh sửa sự kiện' : 'Thêm mới sự kiện'
            }}
            body={[
               {
                  item: <ModalContent/>
               }
            ]}
            footer={
               {
                  cancel: {
                     width: 74,
                     title: t('general_cancel')
                  },
                  save: {
                     width: 110,
                     title: t('save')
                  },
               }
            }
            acceptance={() => modal.handleAccept()}
         />
         <ConfirmModal
            openModal={pageState.modal_confirm}
            body={<Confirm t={t}/>}
            footer={
               {
                  cancel: {
                     width: 110,
                     title: t('general_cancel'),

                  },
                  acceptance: {
                     width: 110,
                     title: t('confirm')
                  },
               }
            }
            footerProps={
               {className: 'product-group-modal_dismiss'}
            }
            closeModal={() => confirm.handleCancelConfirm()}
            acceptance={() => confirm.handleAcceptConfirm()}
         />
      </>

   )
}

const Confirm = ({t}) => {
   return (
      <div style={{marginBottom: '24px'}}>
         <Text
            as={'p'}
            fontSize={20}
            fontWeight={600}
            style={{marginBottom: '16px'}}
         >{t('general_confirm_leaving')}</Text>
         <Text as='p' className='product-group-modal_txt'>{t('general_confirm_leaving_content')}</Text>
      </div>
   )
}