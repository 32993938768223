import React from 'react';
import {STORE_UPOS_ICON} from "../../../interface/_icons";
import styled from 'styled-components'
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";
import {Text} from "../../../../../../common/text";
import useDigitalManagementFilter from "../../../hooks/useDigitalManagementFilter";
import {useTranslation} from "react-i18next";

const DigitalManagementTags = ({...props}) => {
  const {t} = useTranslation()
  const {
    categoryFilter,
    statusFilter,
    search,
    methods,
    shouldShowResetAll
  } = useDigitalManagementFilter()
  return (
    <StyledDigitalManagementTags {...props}>
      {search?.activeValue && (
        <DigitalManagementTag
          onDelete={() => methods.filterTagDelete('search')}
        >
          Sản phẩm: {search?.activeValue}
        </DigitalManagementTag>
      )}
      {categoryFilter?.activeValue && (
        <DigitalManagementTag
          onDelete={() => methods.filterTagDelete('category')}
        >
          Danh mục: {categoryFilter?.activeValue?.title}
        </DigitalManagementTag>
      )}
      {statusFilter?.activeValue && (
        <DigitalManagementTag
          onDelete={() => methods.filterTagDelete('status')}
        >
          Trạng thái: {t(statusFilter?.activeValue?.name)}
        </DigitalManagementTag>
      )}
      {shouldShowResetAll && (
        <Text
          as="b"
          color={THEME_SEMANTICS.delivering}
          lineHeight={28}
          style={{marginBottom: 12, cursor: 'pointer'}}
          onClick={() => methods.filterTagDelete('all')}
        >
          Đặt lại mặc định
        </Text>
      )}
    </StyledDigitalManagementTags>
  )
}

export default DigitalManagementTags

const DigitalManagementTag = ({onDelete, ...props}) => {
  return (
    <StyledDigitalManagementTag {...props}>
      {props?.children && <Text>{props.children}</Text>}
      <div className="store-upos-tag__delete" onClick={onDelete}>
        {STORE_UPOS_ICON.x}
      </div>
    </StyledDigitalManagementTag>
  )
}

export const StyledDigitalManagementTags = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`


const StyledDigitalManagementTag = styled.li`
  position: relative;

  margin: 0 12px 12px 0;
  padding: 4px 28px 4px 12px;

  display: inline-block;

  background: #d1dffd;
  border-radius: 6px;

  .store-upos-tag {
    &__delete {
      position: absolute;
      top: 6px;
      right: 8px;

      width: 16px;
      height: 16px;

      border-radius: 6px;

      transition: all 0.25s;

      cursor: pointer;

      &:hover {
        background: ${THEME_SEMANTICS.failed};

        svg {
          color: #fff;

          path[stroke] {
            stroke: #fff;
          }

          path[fill] {
            fill: #fff;
          }
        }
      }
    }
  }
`
