import {FILTER_TAG} from "../interface/_const";

export const useLicenseKeyAction = {
    FILTER_PRODUCT_NAME_UPDATE: 'FILTER_PRODUCT_NAME_UPDATE',
    FILTER_PRODUCT_NAME_ACTIVE_UPDATE: 'FILTER_PRODUCT_NAME_ACTIVE_UPDATE',
    FILTER_STATUS_VALUE_UPDATE: 'FILTER_STATUS_VALUE_UPDATE',
    FILTER_STATUS_ACTIVE_VALUE_UPDATE: 'FILTER_STATUS_ACTIVE_VALUE_UPDATE',
    FILTER_KEYCODE_ACTIVE_VALUE_UPDATE: 'FILTER_KEYCODE_ACTIVE_VALUE_UPDATE',
    FILTER_ACTIVE_FORM:'FILTER_ACTIVE_FORM',
    FILTER_FORM_TAG_DELETE:'FILTER_FORM_TAG_DELETE',

    TABLE_LOADING_DISPLAY: 'TABLE_LOADING_DISPLAY',
    TABLE_UPDATE_DISPLAY_LIST: 'TABLE_UPDATE_DISPLAY_LIST',
    TABLE_UPDATE_PAGINATION: 'TABLE_UPDATE_PAGINATION',
    TABLE_DISPLAY_DETAIL_ID_UPDATE: 'TABLE_DISPLAY_DETAIL_ID_UPDATE',
    TABLE_DISPLAY_DETAIL_UPDATE: 'TABLE_DISPLAY_DETAIL_UPDATE',
    TABLE_SELECTED_LIST_UPDATE: 'TABLE_SELECTED_LIST_UPDATE',

    MODAL_IMPORT_LICENSEL: 'MODAL_IMPORT_LICENSE',
    MODAL_IMPORT_RESULT_OPEN: 'MODAL_IMPORT_RESULT_OPEN',
    MODAL_IMPORT_RESULT_DATA: 'MODAL_IMPORT_RESULT_DATA',

    FORM_CREATE_MODAL_OPEN:'FORM_CREATE_MODAL_OPEN',
    FORM_CREATE_MODAL_OPEN_CONFIRM:'FORM_CREATE_MODAL_OPEN_CONFIRM',

    FORM_CREATE_MODAL_LIST_PRODUCT:'FORM_CREATE_MODAL_LIST_PRODUCT',
    FORM_CREATE_MODAL_PRODUCT_ACTIVE:'FORM_CREATE_MODAL_PRODUCT_ACTIVE',
    FORM_CREATE_MODAL_PRODUCT_SEARCH:'FORM_CREATE_MODAL_PRODUCT_SEARCH',
    FORM_CREATE_MODAL_PRODUCT_VALIDATE:'FORM_CREATE_MODAL_PRODUCT_VALIDATE',

    FORM_CREATE_MODAL_KEY_VALUE:'FORM_CREATE_MODAL_KEY_VALUE',
    FORM_CREATE_MODAL_KEY_VALIDATE:'FORM_CREATE_MODAL_KEY_VALIDATE',

    FORM_CREATE_MODAL_APPID_VALUE:'FORM_CREATE_MODAL_APPID_VALUE',
    FORM_CREATE_MODAL_APPID_VALIDATE:'FORM_CREATE_MODAL_APPID_VALIDATE',

    FORM_CREATE_MODAL_PACKNAME_VALUE:'FORM_CREATE_MODAL_PACKNAME_VALUE',
    FORM_CREATE_MODAL_PACKNAME_VALIDATE:'FORM_CREATE_MODAL_PACKNAME_VALIDATE',

    FORM_CREATE_MODAL_APPNAME_VALUE:'FORM_CREATE_MODAL_APPNAME_VALUE',
    FORM_CREATE_MODAL_APPNAME_VALIDATE:'FORM_CREATE_MODAL_APPNAME_VALIDATE',

    FORM_CREATE_MODAL_LEVEL_VALUE:'FORM_CREATE_MODAL_LEVEL_VALUE',
    FORM_CREATE_MODAL_LEVEL_VALIDATE:'FORM_CREATE_MODAL_LEVEL_VALIDATE',

    FORM_CREATE_MODAL_QUOTA_VALUE:'FORM_CREATE_MODAL_QUOTA_VALUE',
    FORM_CREATE_MODAL_QUOTA_VALIDATE:'FORM_CREATE_MODAL_QUOTA_VALIDATE',

    FORM_CREATE_MODAL_EDIT_DETAIL:"FORM_CREATE_MODAL_EDIT_DETAIL",
    FORM_IS_CONFIRM:"FORM_IS_CONFIRM"

}
export const useLicenseKeyInitialState = {
    filter: {
        keyCode: {
            value: '',
            active: ''
        },
        status: {
            active: null,
            value: null,
        },
        productName:{
            active: '',
            value: '',
        }
    },
    table: {
        display: {
            list: [],
            listDefault: [],
            loading: true,
        },
        pagination: {
            active: 0,
            amount: 20,
            total: 3,
            totalItems: 59,
        },
        selected: {
            list: [],
        },
        detail: {
            id: null,
            active: null,
            list: [],
        },
    },
    formCreate:{
        open:false,
        openConfirm: false,
        isConfirm: false,
        type: 'addNew',
        editId: 0,
        form:{
            key:{
                value:'',
                status: false,
                message:''
            },
            product:{
                list:[],
                listOrigin:[],
                active:null,
                status: false,
                message:''
            },
            appId: {
                value: 0,
                status: false,
                message: ''
            },
            packName:{
                value: '',
                status: false,
                message: ''
            },
            appName:{
                value: '',
                status: false,
                message: ''
            },
            level: {
                value: 0,
                status: false,
                message: ''
            },
            quotaActive:{
                value: 0,
                status: false,
                message: ''
            },
        },

    },
    importExcel: {
        open: false,
        result: {
            open: false,
            data: null
        },
    }
}

export const useLicenseKeyReducer = (state, action) => {
    switch (action.type) {

        case useLicenseKeyAction.FILTER_PRODUCT_NAME_UPDATE:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    productName: {
                        ...state.filter.productName,
                        ...action.payload,
                    }
                },
            }
        case useLicenseKeyAction.FILTER_PRODUCT_NAME_ACTIVE_UPDATE:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    productName: {
                        ...state.filter.productName,
                        ...action.payload,
                    }
                },
            }

        case useLicenseKeyAction.FILTER_STATUS_VALUE_UPDATE:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    status: {
                        ...state.filter.status,
                        value: action.payload,
                    },
                },
            }
        case useLicenseKeyAction.FILTER_ACTIVE_FORM:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    status: {
                        ...state.filter.status,
                        active: state.filter.status?.value,
                    },
                    keyCode: {
                        ...state.filter.keyCode,
                        active: state.filter.keyCode?.value,
                    },
                    productName:{
                        ...state.filter.productName,
                        active: state.filter.productName?.value,
                    }
                },
            }
        case useLicenseKeyAction.FILTER_KEYCODE_ACTIVE_VALUE_UPDATE:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    keyCode: {
                        ...state.filter.keyCode,
                        value: action.payload,
                    },
                },
            }
        case useLicenseKeyAction.FILTER_FORM_TAG_DELETE:
            switch (action?.payload) {
                case FILTER_TAG[0]:
                    return {
                        ...state,
                        filter: {
                            ...state.filter,
                            productName: {
                                ...state.filter.productName,
                                value: '',
                                active: '',
                            },
                        },
                    }
                case FILTER_TAG[1]:
                    return {
                        ...state,
                        filter: {
                            ...state.filter,
                            keyCode: {
                                ...state.filter.keyCode,
                                value: '',
                                active: '',
                            },
                        },
                    }
                case FILTER_TAG[2]:
                    return {
                        ...state,
                        filter: {
                            ...state.filter,
                            status: {
                                ...state.filter.status,
                                value: null,
                                active: null,
                            },
                        },
                    }
                default:
                    return{...state}
            }
        case useLicenseKeyAction.TABLE_LOADING_DISPLAY:
            return {
                ...state,
                table: {
                    ...state.table,
                    display: {
                        ...state?.table?.display,
                        loading: action?.payload
                    }
                },
            }
        case useLicenseKeyAction.TABLE_UPDATE_DISPLAY_LIST:
            return {
                ...state,
                table: {
                    ...state.table,
                    display: {
                        ...state?.table?.display,
                        list: action?.payload?.list || [],
                        listDefault: action?.payload?.listDefault || [],
                        loading: action?.payload?.loading
                    }
                },
            }
        case useLicenseKeyAction.TABLE_UPDATE_PAGINATION:
            return {
                ...state,
                table: {
                    ...state.table,
                    pagination: {
                        ...state.table.pagination,
                        active: action.payload?.active || 0,
                        amount: action.payload?.amount || 20,
                        total: action.payload?.total || 0,
                        totalItems: action.payload?.totalItems || 0,
                    },
                },
            }
        case useLicenseKeyAction.TABLE_DISPLAY_DETAIL_ID_UPDATE:
            return {
                ...state,
                table: {
                    ...state.table,
                    detail: {
                        ...state.table.detail,
                        id: action.payload?.id || null,
                    },
                },
            }
        case useLicenseKeyAction.TABLE_DISPLAY_DETAIL_UPDATE:
            return {
                ...state,
                table: {
                    ...state.table,
                    detail: {
                        ...state.table.detail,
                        active: action.payload?.active || null,
                    },
                },
            }
        case useLicenseKeyAction.TABLE_SELECTED_LIST_UPDATE:
            return {
                ...state,
                table: {
                    ...state.table,
                    selected: {
                        ...state.table.selected,
                        list: action.payload?.selected?.list || [],
                    },
                },
            }

        case useLicenseKeyAction.MODAL_IMPORT_LICENSEL:
            return {
                ...state,
                importExcel: {
                    ...state.importExcel,
                    open: action.payload
                }
            }
        case useLicenseKeyAction.MODAL_IMPORT_RESULT_OPEN:
            return {
                ...state,
                importExcel: {
                    ...state.importExcel,
                    result:{
                        ...state.importExcel.result,
                        open:action.payload?.open,
                        data: action.payload?.data || null
                    }
                }
            }
        case useLicenseKeyAction.MODAL_IMPORT_RESULT_DATA:
            return {
                ...state,
                importExcel: {
                    ...state.importExcel,
                    result:{
                        ...state.importExcel.result,
                        data:action.payload
                    }
                }
            }
        case useLicenseKeyAction.FORM_CREATE_MODAL_OPEN:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                   open: action.payload,
                    type:"addNew",
                    editId: 0,
                    form:{
                        ...state.formCreate.form,
                        key:{
                            value:'',
                            status: false,
                            message:''
                        },
                        product:{
                            ...state.formCreate.form.product,
                            active:null,
                            status: false,
                            message:''
                        },
                        appId: {
                            value: 0,
                            status: false,
                            message: ''
                        },
                        packName:{
                            value: '',
                            status: false,
                            message: ''
                        },
                        appName:{
                            value: '',
                            status: false,
                            message: ''
                        },
                        level: {
                            value: 0,
                            status: false,
                            message: ''
                        },
                        quotaActive:{
                            value: 0,
                            status: false,
                            message: ''
                        },
                    },
                }
            }
        case useLicenseKeyAction.FORM_CREATE_MODAL_OPEN_CONFIRM:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                   openConfirm: action.payload,
                }
            }
        case useLicenseKeyAction.FORM_CREATE_MODAL_LIST_PRODUCT:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                    form:{
                        ...state.formCreate.form,
                        product:{
                            ...state.formCreate.form.product,
                            list: action.payload?.list || [],
                            listOrigin: action.payload?.listOrigin || [],
                        },
                    },
                }
            }
        case useLicenseKeyAction.FORM_CREATE_MODAL_PRODUCT_ACTIVE:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                    isConfirm: true,
                    form:{
                        ...state.formCreate.form,
                        product:{
                            ...state.formCreate.form.product,
                           active: action.payload
                        },
                    },
                }
            }
        case useLicenseKeyAction.FORM_CREATE_MODAL_PRODUCT_SEARCH:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                    isConfirm: true,
                    form:{
                        ...state.formCreate.form,
                        product:{
                            ...state.formCreate.form.product,
                           keyword: action.payload?.keyword || '',
                           list: action.payload?.list || []
                        },
                    },
                }
            }
            case useLicenseKeyAction.FORM_CREATE_MODAL_PRODUCT_VALIDATE:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                    form:{
                        ...state.formCreate.form,
                        product:{
                            ...state.formCreate.form.product,
                           status: action.payload?.status || false,
                           message: action.payload?.message || '',
                        },
                    },
                }
            }
        case useLicenseKeyAction.FORM_CREATE_MODAL_KEY_VALUE:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                    isConfirm: true,
                    form:{
                        ...state.formCreate.form,
                        key:{
                            ...state.formCreate.form.key,
                            value: action.payload
                        },
                    },
                }
            }
        case useLicenseKeyAction.FORM_CREATE_MODAL_KEY_VALIDATE:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                    form:{
                        ...state.formCreate.form,
                        key:{
                            ...state.formCreate.form.key,
                            status: action.payload?.status,
                            message: action.payload?.message,
                        },
                    },
                }
            }
        case useLicenseKeyAction.FORM_CREATE_MODAL_APPID_VALUE:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                    isConfirm: true,
                    form:{
                        ...state.formCreate.form,
                        appId:{
                            ...state.formCreate.form.appId,
                            value: action.payload
                        },
                    },
                }
            }
        case useLicenseKeyAction.FORM_CREATE_MODAL_APPID_VALIDATE:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                    form:{
                        ...state.formCreate.form,
                        appId:{
                            ...state.formCreate.form.appId,
                            status: action.payload?.status,
                            message: action.payload?.message,
                        },
                    },
                }
            }
        case useLicenseKeyAction.FORM_CREATE_MODAL_APPNAME_VALUE:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                    form:{
                        ...state.formCreate.form,
                        isConfirm: true,
                        appName:{
                            ...state.formCreate.form.appName,
                            value: action.payload
                        },
                    },
                }
            }
        case useLicenseKeyAction.FORM_CREATE_MODAL_APPNAME_VALIDATE:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                    form:{
                        ...state.formCreate.form,
                        appName:{
                            ...state.formCreate.form.appName,
                            status: action.payload?.status,
                            message: action.payload?.message,
                        },
                    },
                }
            }
        case useLicenseKeyAction.FORM_CREATE_MODAL_PACKNAME_VALUE:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                    isConfirm: true,
                    form:{
                        ...state.formCreate.form,
                        packName:{
                            ...state.formCreate.form.packName,
                            value: action.payload
                        },
                    },
                }
            }
        case useLicenseKeyAction.FORM_CREATE_MODAL_PACKNAME_VALIDATE:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                    form:{
                        ...state.formCreate.form,
                        packName:{
                            ...state.formCreate.form.packName,
                            status: action.payload?.status,
                            message: action.payload?.message,
                        },
                    },
                }
            }
        case useLicenseKeyAction.FORM_CREATE_MODAL_LEVEL_VALUE:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                    isConfirm: true,
                    form:{
                        ...state.formCreate.form,
                        level:{
                            ...state.formCreate.form.level,
                            value: action.payload
                        },
                    },
                }
            }
        case useLicenseKeyAction.FORM_CREATE_MODAL_LEVEL_VALIDATE:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                    form:{
                        ...state.formCreate.form,
                        level:{
                            ...state.formCreate.form.level,
                            status: action.payload?.status,
                            message: action.payload?.message,
                        },
                    },
                }
            }
        case useLicenseKeyAction.FORM_CREATE_MODAL_QUOTA_VALUE:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                    isConfirm: true,
                    form:{
                        ...state.formCreate.form,
                        quotaActive:{
                            ...state.formCreate.form.quotaActive,
                            value: action.payload
                        },
                    },
                }
            }
        case useLicenseKeyAction.FORM_CREATE_MODAL_QUOTA_VALIDATE:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                    form:{
                        ...state.formCreate.form,
                        quotaActive:{
                            ...state.formCreate.form.quotaActive,
                            status: action.payload?.status,
                            message: action.payload?.message,
                        },
                    },
                }
            }
        case useLicenseKeyAction.FORM_CREATE_MODAL_EDIT_DETAIL:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                    open: action.payload?.open,
                    type: 'edit',
                    editId: action.payload?.id,
                    isConfirm: false,
                    form:{
                        ...state.formCreate.form,
                       ...action.payload?.form
                    },
                }
            }
        case useLicenseKeyAction.FORM_IS_CONFIRM:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                   isConfirm: action.payload
                }
            }
        default:
            return {...state}
    }
}