import React, {useReducer} from 'react'
import {eventStatisticReducer} from "../provider/_reducer"
import {eventStatisticInitialState, eventStatisticActions} from  "../provider/_init"
import {sendRequestAuth} from "../../../api/api";
import config from "../../../config";
import {convertDateTimeToApiFormat} from "../../../common/form/datePicker/_functions";

const useEventStatistic = () => {
  const [state, dispatch] = useReducer(eventStatisticReducer, eventStatisticInitialState)
  const handleOriginFetch = async () => {
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/event/report/customers?event_id=&principal_id=&teacher_id=&customer_type=&start_date=&end_date=&start=0&per_page=20`),
    ])
    if(response[0]?.data?.success) {
      const event = response[0]?.data
      dispatch({
        type: eventStatisticActions.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: event?.data,
          listDefault: event?.data,
          loading: false
        }
      })
      dispatch({
        type: eventStatisticActions.TABLE_UPDATE_PAGINATION,
        payload: {
          active: 0,
          amount: event?.meta?.per_page,
          total: Math.ceil(event?.meta?.total / event?.meta?.per_page),
          totalItems: event?.meta?.total,
        }
      })
    }
  }

  const {filter} = state
  const principalValue = filter.principal.value
  const campaignValue = filter.campaign.value
  const teacherValue = filter.teacher.value
  const customerTypeValue = filter?.customerType?.value
  const dateTimeEnd = filter.dateTime.end
  const dateTimeStart = filter.dateTime.start
  const dateTimeValue = filter.dateTime.value
  const querySearch = {
    per_page: state?.table?.pagination?.amount || 20,
    page: (state?.table?.pagination?.active * state?.table?.pagination?.amount) || 0,
    principal_id: principalValue?.value || '',
    teacher_id: teacherValue?.value || '',
    event_id: campaignValue?.value || '',
    customer_type: customerTypeValue?.name ? customerTypeValue.value : '',
    start_date:
       dateTimeStart && dateTimeValue
          ? convertDateTimeToApiFormat(dateTimeValue.split(' - ')[0])
          : '',
    end_date:
       dateTimeEnd && dateTimeValue
          ? convertDateTimeToApiFormat(dateTimeValue.split(' - ')[1])
          : '',
  }

  const handleAmountChange = async n => {
    const qs = {...querySearch, per_page: n, page: 1}
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchBannerByFilter(queryString)

    dispatch({
      type: eventStatisticActions.TABLE_SELECTED_LIST_UPDATE,
      payload: {selected: {list: []}},
    })
  }

  const handlePageChange = async page => {
    const qs = {
      ...querySearch,
      per_page: state?.table?.pagination?.amount,
      page: page + 1
    }
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchBannerByFilter(queryString)
  }

  const fetchBannerByFilter = async (qs) => {
    dispatch({
      type: eventStatisticActions.TABLE_LOADING_DISPLAY,
      payload: true
    })
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/event/report/customers${qs}`)
    ])
    if (response[0]?.data?.success) {
      dispatch({
        type: eventStatisticActions.TABLE_LOADING_DISPLAY,
        payload: false
      })
      const event = response[0]?.data
      dispatch({
        type: eventStatisticActions.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: event?.data,
          listDefault: event?.data,
          loading: false
        }
      })
      dispatch({
        type: eventStatisticActions.TABLE_UPDATE_PAGINATION,
        payload: {
          active: 0,
          amount: event?.meta?.per_page,
          total: Math.ceil(event?.meta?.total / event?.meta?.per_page),
          totalItems: event?.meta?.total,
        }
      })
    }
  }
  return {
    fetch: {
      origin: handleOriginFetch
    },
    provider: {
      state,
      dispatch
    },
    pagination: {
      onAmountChange: handleAmountChange,
      onPageChange: handlePageChange,
    }
  }
}

export default useEventStatistic