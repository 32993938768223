import { AlternativeAutoComplete } from 'common/form/autoComplete/_alternativeAutoComplete'
import { Option } from 'common/form/autoComplete/_option'
import useEventStatisticFilter from '../../hooks/useEventStatisticFilter'

export const FilterCampaign = () => {
  const { campaign } = useEventStatisticFilter()

  return (
    <AlternativeAutoComplete
      className="principal-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: { name: 'Tên sự kiện', value: '' }, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 94,
        placeholder: 'Chọn sự kiện',
        readOnly: true,
        value:
          campaign.value.length > 0 ? `Đã chọn ${campaign.value.length}` : '',
        onIconClick: () => campaign.onChange(null),
      }}
      // menu
      menuProps={{
        empty:
          campaign.list.length <= 0
            ? 'Không tìm thấy sự kiện'
            : '',
        multipleChoices: true,
        onReset: campaign.onInputReset, // only use this prop for multiple choice
      }}
      // search input in dropdown menu
      searchInputProps={{
        placeholder: 'Tìm kiếm sự kiện',
        value: campaign.keyword,
        onChange: campaign.onKeywordChange,
      }}
      // tab list <only use this prop for multiple choices>
      tabProps={{
        active: campaign.tab,
        checkedNumber: campaign.value.length,
        onChange: campaign.onTabChange,
      }}
    >
      {campaign.list.length > 0 &&
      campaign.list.map(item => (
        <Option
          key={item.value}
          className="principal-filter-form__option-text"
          data-active={item.value === campaign.value?.value}
          onClick={() => campaign.onChange(item)}
          checked={!!campaign.value.find(find => find.value === item.value)}
          multipleChoices={true}
          style={{marginBottom: 16}}
        >
          {item.name}
        </Option>
      ))}
    </AlternativeAutoComplete>
  )
}
