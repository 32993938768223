import { Th } from 'layouts/tableLayout/_th'
import { Tr } from 'layouts/tableLayout/_tr'
import React from 'react'
import { Text } from 'common/text'

export const TheadByPrincipal = ({ ...props }) => {

  return (
    <>
      <Tr {...props} type="tHead">
        <Th className="commission-principal-table__cell">
          Hiệu trưởng
        </Th>
        <Th className="commission-principal-table__cell">Số đơn hàng</Th>
        <Th className="commission-principal-table__cell">Đơn hàng sách</Th>
        <Th className="commission-principal-table__cell">Đơn hàng số</Th>
        <Th className="commission-principal-table__cell">
          <Text as={'p'} style={{ width: '100%' }} fontWeight={600}>Doanh thu</Text>
          <Text as={'p'} style={{ width: '100%' }}>(Sau giảm giá)</Text>
        </Th>
        {/*<Th className="commission-principal-table__cell">Tổng hoa hồng</Th>*/}
        <Th className="commission-principal-table__cell">Hoa hồng hiệu trưởng</Th>
        <Th className="commission-principal-table__cell">Hoa hồng giáo viên</Th>
      </Tr>
    </>
  )
}
