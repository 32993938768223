import { useContext } from 'react'
import { RevenueContext } from '../../provider/_context'
import { Text } from '../../../../common/text'
import { AlternativeAutoComplete } from '../../../../common/form/autoComplete/_alternativeAutoComplete'
import { revenueActions } from '../../provider/_reducer'
import { AutoCompleteSingleOption } from '../../../../common/form/autocompleteSingleOption'
import styled from 'styled-components'
import useRevenueFilterForm from '../../hooks/useRevenueFilterForm'

const PAGE_OPTION = [
  { id: 1, name: 'Ngày đặt hàng', value: 'date' },
  { id: 2, name: 'Mã đơn hàng', value: 'order' },
  { id: 3, name: 'Hiệu trưởng', value: 'principal' },
  { id: 4, name: 'Giáo viên', value: 'teacher' },
]
export const PageOption = () => {
  const { pageState, pageDispatch } = useContext(RevenueContext)
  const { pageOption } = pageState
  const { functions } = useRevenueFilterForm()
  const handlePageOptionChange = value => {
    pageDispatch({
      type: revenueActions.UPDATE_PAGE_OPTION_VALUE,
      payload: value
    })
    functions.pageOption(value?.value)
  }

  return (
    <PageOptionStyled>
      <Text fontWeight={600}>Nhóm theo: </Text>
      <AlternativeAutoComplete
        className="page-option__select"
        // main input
        inputProps={{
          categoryList: [], // menu list in category dropdown
          categoryValue: { name: 'Trạng thái đơn hàng', value: '' }, // if not exist this value -> default category: categoryList[0]
          categoryHidden: true,
          placeholder: 'Chọn trạng thái đơn hàng',
          readOnly: true,
          value: pageOption?.name || '',
        }}
        // menu
        hideSearchBar={true}
      >
        {PAGE_OPTION.length > 0 &&
        PAGE_OPTION.map(item => (
          <AutoCompleteSingleOption
            key={item.value}
            className="revenue-filter-form__option-text"
            data-active={item.value === pageOption?.value}
            onClick={() => handlePageOptionChange(item)}
          >
            {item.name}
          </AutoCompleteSingleOption>
        ))}
      </AlternativeAutoComplete>
    </PageOptionStyled>
  )
}

const PageOptionStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .page-option{
    &__select{
      width: 25%;
      margin-left: 8px;
    }
  }
`