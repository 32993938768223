import { DISPLAY_NAME_MENU } from '../../../../../const/display_name_menu'
import { Text } from '../../../../../common/text'
import { THEME_SEMANTICS } from '../../../../../common/theme/_semantics'
import { Option } from '../../../../../common/form/autoComplete/_option'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useCreateInfoBasic from '../../../hooks/useCreateInfoBasic'
import { AlternativeAutoComplete } from '../../../../../common/form/autoComplete/_alternativeAutoComplete'

export const Category = () => {

  const { t } = useTranslation()
  const { value, functions, validate } = useCreateInfoBasic()
  const groupProduct = value?.formInfoBasic?.groupProduct

  return (
    <AlternativeAutoComplete
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryHidden: true,
        readOnly: true,
        categoryValue: { name: t(DISPLAY_NAME_MENU.PRODUCT_PAGE.GROUP_PRODUCT), value: '' },
        label: <>
          <div>{t('Danh mục')} <Text color={THEME_SEMANTICS.failed}>*</Text>
          </div>
        </>,
        placeholder: t('Vui lòng chọn danh mục'),
        value: groupProduct?.value.length > 0 ? groupProduct?.value?.map(item => item?.title) : '',
      }}
      // search input in dropdown menu
      menuProps={{
        empty: groupProduct?.list?.length <= 0 ? t('Không tìm thấy danh mục') : '',
        multipleChoices: true,
        onReset: functions.handleCategoryResetInput, // only use this prop for multiple choice
      }}
      // search input in dropdown menu
      searchInputProps={{
        placeholder: t('Tìm theo tên danh mục'),
        value: groupProduct?.keyword || '',
        onChange: functions.onKeywordChange,
      }}
      // tab list <only use this prop for multiple choices>
      tabProps={{
        active: groupProduct.tab,
        checkedNumber: groupProduct.value.length,
        onChange: functions.onTabChange,
      }}
      validateText={validate?.formInfoBasicValidate?.group?.message}
      validateType={validate?.formInfoBasicValidate?.group?.status ? 'danger' : 'success'}
    >
      {groupProduct?.list?.length > 0 &&
      groupProduct?.list?.map((item, index) => (
        <Option
          key={index}
          className="store-upos-filter-form__option-text"
          data-active={+groupProduct?.value?.id === +item.id}
          checked={!!groupProduct?.value.find(find => find.id === item.id)}
          multipleChoices={true}
          style={{marginBottom: 16}}
          onClick={() => functions.onSelectGroupProduct(item)}
        >
          {item.title}
        </Option>
      ))}
    </AlternativeAutoComplete>
  )
}