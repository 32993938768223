import React, { useContext } from 'react'
import { RevenueContext } from '../../provider/_context'
import { TheadByDate } from './thead/_theadByDate'
import { TheadByOrder } from './thead/_theadByOrder'
import { TheadByPrincipal } from './thead/_theadByPrincipal'
import { TheadByTeacher } from './thead/_theadByTeacher'

export const RevenueTHead = ({ ...props }) => {

  const { pageState } = useContext(RevenueContext)
  const { pageOption } = pageState
  const listHeader = [
    { id: 1, value: 'date', component: <TheadByDate/> },
    { id: 2, value: 'order', component: <TheadByOrder/> },
    { id: 3, value: 'principal', component: <TheadByPrincipal/> },
    { id: 5, value: 'teacher', component: <TheadByTeacher/> },
  ]
  return (
    <>
      {listHeader.find(item => item.value === pageOption?.value)?.component}
    </>
  )
}
