import { formatDatetime } from 'common/form/datePicker/_functions'
import {
  REVENUE_FILTER_FORM_DATE_TIME_SORT_TYPES,
  REVENUE_FILTER_TAG_FIELDS
} from '../interfaces/_constants'
import { getDateFromNow } from '../utils/date'

export const revenueActions = {
  TABLE_AMOUNT_UPDATE: 'TABLE_AMOUNT_UPDATE',
  //  FILTER
  FILTER_ADVANCED_SEARCH_UPDATE: 'FILTER_ADVANCED_SEARCH_UPDATE',
  FILTER_DATE_TIME_UPDATE: 'FILTER_DATE_TIME_UPDATE',
  FILTER_ACTIVE_DATE_TIME_UPDATE: 'FILTER_ACTIVE_DATE_TIME_UPDATE',
  FILTER_DATE_TIME_TRIGGER_UPDATE: 'FILTER_DATE_TIME_TRIGGER_UPDATE',
  FILTER_DUPLICATE_UPDATE: 'FILTER_DUPLICATE_UPDATE',
  FILTER_EMPLOYEE_UPDATE: 'FILTER_EMPLOYEE_UPDATE',
  FILTER_EMPLOYEE_CATEGORY_UPDATE: 'FILTER_EMPLOYEE_CATEGORY_UPDATE',
  FILTER_EMPLOYEE_KEYWORD_UPDATE: 'FILTER_EMPLOYEE_KEYWORD_UPDATE',
  FILTER_EMPLOYEE_TAB_UPDATE: 'FILTER_EMPLOYEE_TAB_UPDATE',
  FILTER_ORIGIN_DATA_UPDATE: 'FILTER_ORIGIN_DATA_UPDATE',
  FILTER_PAYMENT_UPDATE: 'FILTER_PAYMENT_UPDATE',
  FILTER_PRODUCT_ENABLE_LOADING: 'FILTER_PRODUCT_ENABLE_LOADING',
  FILTER_PRODUCT_KEYWORD_UPDATE: 'FILTER_PRODUCT_KEYWORD_UPDATE',
  FILTER_PRODUCT_TAB_UPDATE: 'FILTER_PRODUCT_TAB_UPDATE',
  FILTER_PRODUCT_UPDATE: 'FILTER_PRODUCT_UPDATE',
  FILTER_SEARCH_UPDATE: 'FILTER_SEARCH_UPDATE',
  FILTER_PAYMENT_METHOD_KEYWORD_UPDATE:
    'FILTER_PAYMENT_METHOD_KEYWORD_UPDATE',
  FILTER_PAYMENT_METHOD_UPDATE: 'FILTER_PAYMENT_METHOD_UPDATE',
  FILTER_PRINCIPAL_KEYWORD_UPDATE: 'FILTER_PRINCIPAL_KEYWORD_UPDATE',
  FILTER_PRINCIPAL_UPDATE: 'FILTER_PRINCIPAL_UPDATE',
  FILTER_TEACHER_KEYWORD_UPDATE: 'FILTER_TEACHER_KEYWORD_UPDATE',
  FILTER_TEACHER_UPDATE: 'FILTER_TEACHER_UPDATE',
  FILTER_TEACHER_LIST_UPDATE: 'FILTER_TEACHER_LIST_UPDATE',
  FILTER_REVENUE_ID_UPDATE: 'FILTER_REVENUE_ID_UPDATE',
  FILTER_CUSTOMER_INFO_UPDATE: 'FILTER_CUSTOMER_INFO_UPDATE',
  FILTER_SHIPPING_STATUS_KEYWORD_UPDATE:
    'FILTER_SHIPPING_STATUS_KEYWORD_UPDATE',
  FILTER_SHIPPING_STATUS_TAB_UPDATE: 'FILTER_SHIPPING_STATUS_TAB_UPDATE',
  FILTER_SHIPPING_STATUS_UPDATE: 'FILTER_SHIPPING_STATUS_UPDATE',
  FILTER_SOURCE_ORDER_TYPE_UPDATE: 'FILTER_SOURCE_ORDER_TYPE_UPDATE',
  FILTER_ORDER_TYPE_UPDATE: 'FILTER_ORDER_TYPE_UPDATE',
  FILTER_ORDER_STATUS_KEYWORD_UPDATE: 'FILTER_ORDER_STATUS_KEYWORD_UPDATE',
  FILTER_ORDER_STATUS_UPDATE: 'FILTER_ORDER_STATUS_UPDATE',
  FILTER_ORDER_STATUS_ENABLE_LOADING: 'FILTER_ORDER_STATUS_ENABLE_LOADING',
  FILTER_ORDER_STATUS_TAB_UPDATE: 'FILTER_ORDER_STATUS_TAB_UPDATE',
  OTHER_FILTER_APPLY: 'OTHER_FILTER_APPLY',
  DELETE_TAG_UPDATE: 'DELETE_TAG_UPDATE',
  TAG_FILTER_DELETE: 'TAG_FILTER_DELETE',
  // NOTIFICATIONS
  NOTIFICATIONS_LIST_UPDATE: 'NOTIFICATIONS_LIST_UPDATE',
  // PANELS
  PANELS_UPDATE: 'PANELS_UPDATE',
  PRINT_DETAIL_UPDATE: 'PRINT_DETAIL_UPDATE',
  // TABLE
  TABLE_DISPLAY_DATA_UPDATE: 'TABLE_DISPLAY_DATA_UPDATE',
  TABLE_DISPLAY_DETAIL_UPDATE: 'TABLE_DISPLAY_DETAIL_UPDATE',
  TABLE_DISPLAY_DETAIL_ID_UPDATE: 'TABLE_DISPLAY_DETAIL_ID_UPDATE',
  TABLE_DISPLAY_LOADING_UPDATE: 'TABLE_DISPLAY_LOADING_UPDATE',
  TABLE_PAGINATION_UPDATE: 'TABLE_PAGINATION_UPDATE',
  TABLE_SELECTED_LIST_UPDATE: 'TABLE_SELECTED_LIST_UPDATE',

  //selective export excel
  UPDATE_SELECTED_LIST_ACTIVE: 'UPDATE_SELECTED_LIST_ACTIVE',
  SEARCH_SELECTED_LIST_ACTIVE: 'SEARCH_SELECTED_LIST_ACTIVE',
  UPDATE_DRAG_SELECTED_LIST: 'UPDATE_DRAG_SELECTED_LIST',
  UPDATE_PAGE_OPTION_VALUE: 'UPDATE_PAGE_OPTION_VALUE',

}

export const dateTimeDefaultValue = [
  getDateFromNow(-7, { type: 'start' }),
  getDateFromNow(0, { type: 'end' }),
]
export const formatDateTimeDefaultValue = `${formatDatetime(
  dateTimeDefaultValue[0],
)} - ${formatDatetime(dateTimeDefaultValue[1])}`

export const revenueReducer = (state, action) => {
  switch (action.type) {
    case revenueActions.UPDATE_PAGE_OPTION_VALUE:
      return {
        ...state,
        pageOption: action.payload
      }
    case
    revenueActions.FILTER_ADVANCED_SEARCH_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          advancedSearch: {
            ...state.filter.advancedSearch,
            customer: {
              ...state.filter.advancedSearch.customer,
              keyword:
                typeof action.payload?.customer?.keyword === 'string'
                  ? action.payload.customer.keyword
                  : state.filter.advancedSearch.customer.keyword,
              value:
                typeof action.payload?.customer?.value === 'string'
                  ? action.payload.customer.value
                  : state.filter.advancedSearch.customer.value,
            },
            liveVideoId:
              typeof action.payload?.liveVideoId === 'string'
                ? action.payload.liveVideoId
                : state.filter.advancedSearch.liveVideoId,
          },
        },
      }

    case
    revenueActions.FILTER_DATE_TIME_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          dateTime: {
            ...state.filter.dateTime,
            end: action.payload?.end,
            start: action.payload?.start,
            type: action.payload?.type,
            value: action.payload?.value,
          },
        },
      }

    case
    revenueActions.FILTER_ACTIVE_DATE_TIME_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          dateTime: {
            ...state.filter.dateTime,
            activeValue: {
              ...state.filter.dateTime.activeValue,
              end: action.payload?.end,
              start: action.payload?.start,
              type: action.payload?.type,
              value: action.payload?.value,
            },
          },
        },
      }

    case
    revenueActions.FILTER_DATE_TIME_TRIGGER_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          dateTime: {
            ...state.filter.dateTime,
            trigger: action.payload?.trigger,
          },
        },
      }

    case
    revenueActions.FILTER_ORIGIN_DATA_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            list: action.payload?.product?.list || [],
            listOrigin: action.payload?.product?.list || [],
          },
          principal: {
            ...state.filter.principal,
            list: action.payload?.principal?.list || [],
            listOrigin: action.payload?.principal?.list || [],
          },
        },
      }

    case
    revenueActions.FILTER_PAYMENT_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          payment: {
            ...state.filter.payment,
            value: action.payload?.payment?.value || [],
          },
        },
      }

    case
    revenueActions.FILTER_PRODUCT_ENABLE_LOADING
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            loading: true,
          },
        },
      }

    case
    revenueActions.FILTER_PRODUCT_KEYWORD_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            keyword: action.payload.keyword,
            list: action.payload.list,
            loading: false,
            tab: action.payload?.tab || state.filter.product.tab,
          },
        },
      }

    case
    revenueActions.FILTER_PRODUCT_TAB_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            keyword: '',
            tab: action.payload.tab,
            list: action.payload.list,
          },
        },
      }

    case
    revenueActions.FILTER_PRODUCT_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            list: action?.payload?.list,
            value: action.payload?.value || [],
          },
        },
      }
    case
    revenueActions.FILTER_SEARCH_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          search: {
            ...state.filter.search,
            value: action.payload.value || '',
          },
        },
      }

    case
    revenueActions.FILTER_REVENUE_ID_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          orderId: {
            ...state.filter.orderId,
            ...action.payload,
          },
        },
      }

    case
    revenueActions.FILTER_CUSTOMER_INFO_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          customerInfo: {
            ...state.filter.customerInfo,
            ...action.payload,
          },
        },
      }

    // Payment method

    case
    revenueActions.FILTER_PAYMENT_METHOD_KEYWORD_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          paymentMethod: {
            ...state.filter.paymentMethod,
            keyword: action.payload.keyword,
            list: action.payload.list,
          },
        },
      }

    case
    revenueActions.FILTER_PAYMENT_METHOD_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          paymentMethod: {
            ...state.filter.paymentMethod,
            value: action.payload?.value || null,
          },
        },
      }

    //  principals

    case
    revenueActions.FILTER_PRINCIPAL_KEYWORD_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          principal: {
            ...state.filter.principal,
            keyword: action.payload.keyword,
            list: action.payload.list,
          },
        },
      }

    case
    revenueActions.FILTER_PRINCIPAL_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          principal: {
            ...state.filter.principal,
            value: action.payload?.value || null,
          },
        },
      }
    //  teachers

    case
    revenueActions.FILTER_TEACHER_KEYWORD_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          teacher: {
            ...state.filter.teacher,
            keyword: action.payload.keyword,
            list: action.payload.list,
          },
        },
      }

    case
    revenueActions.FILTER_TEACHER_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          teacher: {
            ...state.filter.teacher,
            value: action.payload?.value || null,
          },
        },
      }

    case
    revenueActions.FILTER_TEACHER_LIST_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          teacher: {
            ...state.filter.teacher,
            list: action.payload?.teacher?.list || [],
            listOrigin: action.payload?.teacher?.list || [],
          },
        },
      }

    // order type

    case
    revenueActions.FILTER_SOURCE_ORDER_TYPE_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          orderType: {
            ...state.filter.orderType,
            keyword: action.payload.keyword,
            list: action.payload.list,
          },
        },
      }

    case
    revenueActions.FILTER_ORDER_TYPE_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          orderType: {
            ...state.filter.orderType,
            value: action.payload?.value || null,
          },
        },
      }

    // order status

    case
    revenueActions.FILTER_ORDER_STATUS_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          orderStatus: {
            ...state.filter.orderStatus,
            value: action.payload?.value || null,
          },
        },
      }

    case revenueActions.FILTER_ORDER_STATUS_ENABLE_LOADING
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          orderStatus: {
            ...state.filter.orderStatus,
            loading: true,
          },
        },
      }

    case
    revenueActions.FILTER_ORDER_STATUS_KEYWORD_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          orderStatus: {
            ...state.filter.orderStatus,
            keyword: action.payload.keyword,
            list: action.payload.list,
            loading: false,
            tab: action.payload?.tab || state.filter.orderStatus.tab,
          },
        },
      }
    case
    revenueActions.FILTER_ORDER_STATUS_TAB_UPDATE
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          orderStatus: {
            ...state.filter.orderStatus,
            keyword: '',
            tab: action.payload.tab,
            list: action.payload.list,
          },
        },
      }

    case
    revenueActions.NOTIFICATIONS_LIST_UPDATE
    :
      return {
        ...state,
        notifications: {
          ...state.notifications,
          list: action.payload?.notifications?.list || [],
        },
      }

    case
    revenueActions.OTHER_FILTER_APPLY
    :
      return {
        ...state,
        filter: {
          ...state.filter,
          dateTime: {
            ...state.filter.dateTime,
            activeValue: {
              end: state.filter.dateTime.end,
              start: state.filter.dateTime.start,
              type: state.filter.dateTime.type,
              value: state.filter.dateTime.value,
            },
          },
          paymentMethod: {
            ...state.filter.paymentMethod,
            activeValue: state.filter.paymentMethod.value,
          },
          principal: {
            ...state.filter.principal,
            activeValue: state.filter.principal.value,
          },
          orderType: {
            ...state.filter.orderType,
            activeValue: state.filter.orderType.value,
          },
          orderStatus: {
            ...state.filter.orderStatus,
            activeValue: state.filter.orderStatus.value,
          },
          product: {
            ...state.filter.product,
            activeValue: state.filter.product.value,
          },
          orderId: {
            ...state.filter.orderId,
            activeValue: state.filter.orderId.value,
          },
          customerInfo: {
            ...state.filter.customerInfo,
            activeValue: state.filter.customerInfo.value,
          },
          teacher: {
            ...state.filter.teacher,
            activeValue: state.filter.teacher.value,
          },
        },
        table: {
          ...state.table,
          display: {
            ...state.table.display,
            list: action.payload?.display?.list || [],
          },
          pagination: {
            ...state.table.pagination,
            active: action.payload?.pagination?.active,
            amount: action.payload?.pagination?.amount,
            total: action.payload?.pagination?.total,
            totalItems: action.payload?.pagination?.totalItems,
          },
        },
      }
    case
    revenueActions.DELETE_TAG_UPDATE
    :
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state.table.display,
            list: action.payload?.display?.list || [],
          },
          pagination: {
            ...state.table.pagination,
            active: action.payload?.pagination?.active,
            amount: action.payload?.pagination?.amount,
            total: action.payload?.pagination?.total,
            totalItems: action.payload?.pagination?.totalItems,
          },
        },
      }

    case
    revenueActions.PANELS_UPDATE
    :
      return {
        ...state,
        panels: {
          ...state.panels,
          ...action.payload
        },
      }

    case
    revenueActions.PRINT_DETAIL_UPDATE
    :
      console.log(action.payload)
      return {
        ...state,
        printDetail: action.payload
      }

    case
    revenueActions.TABLE_AMOUNT_UPDATE
    :
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state.table.display,
            list: action.payload?.display?.list || [],
          },
          pagination: {
            ...state.table.pagination,
            active: action.payload?.pagination?.active,
            amount: action.payload?.pagination?.amount,
            total: action.payload?.pagination?.total,
            totalItems: action?.payload?.pagination?.totalItems,
          },
        },
      }

    case
    revenueActions.TABLE_DISPLAY_DATA_UPDATE
    :
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state.table.display,
            list: action.payload?.display?.list || [],
          },
        },
      }

    case
    revenueActions.TABLE_DISPLAY_DETAIL_UPDATE
    :
      return {
        ...state,
        table: {
          ...state.table,
          detail: {
            ...state.table.detail,
            active: action.payload?.active || null,
            list: action.payload?.list || [],
          },
        },
      }

    case
    revenueActions.TABLE_DISPLAY_DETAIL_ID_UPDATE
    :
      return {
        ...state,
        table: {
          ...state.table,
          detail: {
            ...state.table.detail,
            id: action.payload?.id || null,
          },
        },
      }

    case
    revenueActions.TABLE_DISPLAY_LOADING_UPDATE
    :
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state.table.display,
            loading: action.payload?.table?.display?.loading,
          },
        },
      }

    case
    revenueActions.TABLE_PAGINATION_UPDATE
    :
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state.table.display,
            list: action.payload?.display?.list || [],
          },
          pagination: {
            ...state.table.pagination,
            active: action.payload?.pagination?.active,
          },
        },
      }

    case
    revenueActions.TABLE_SELECTED_LIST_UPDATE
    :
      return {
        ...state,
        table: {
          ...state.table,
          selected: {
            ...state.table.selected,
            list: action.payload?.selected?.list || [],
          },
        },
      }

    case
    revenueActions.TAG_FILTER_DELETE
    :
      switch (action.payload?.type) {
        case 'dateTime.current':
          return {
            ...state,
            filter: {
              ...state.filter,
              dateTime: {
                ...state.filter.dateTime,
                activeValue: {
                  end: null,
                  start: null,
                  type: '',
                  value: null,
                },
                end: null,
                start: null,
                type: '',
                value: null,
              },
            },
          }
        case 'status.current':
          return {
            ...state,
            filter: {
              ...state.filter,
              orderStatus: {
                ...state.filter.orderStatus,
                activeValue: [],
                keyword: '',
                loading: false,
                tab: 'all', // all | checked
                value: [],
              },
            },
          }
        case REVENUE_FILTER_TAG_FIELDS[8]:
          return {
            ...state,
            filter: {
              ...state.filter,
              dateTime: {
                ...state.filter.dateTime,
                activeValue: {
                  end: dateTimeDefaultValue[1],
                  start: dateTimeDefaultValue[0],
                  type: REVENUE_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
                  value: formatDateTimeDefaultValue,
                },
                end: dateTimeDefaultValue[1],
                start: dateTimeDefaultValue[0],
                type: REVENUE_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
                value: formatDateTimeDefaultValue,
              },
            },
          }

        case REVENUE_FILTER_TAG_FIELDS[0]:
          return {
            ...state,
            filter: {
              ...state.filter,
              orderId: {
                ...state.filter.orderId,
                activeValue: null,
                value: null,
              },
            },
          }

        case REVENUE_FILTER_TAG_FIELDS[1]:
          return {
            ...state,
            filter: {
              ...state.filter,
              customerInfo: {
                ...state.filter.customerInfo,
                activeValue: null,
                value: null,
              },
            },
          }
        case REVENUE_FILTER_TAG_FIELDS[2]:
          return {
            ...state,
            filter: {
              ...state.filter,
              product: {
                ...state.filter.product,
                activeValue: [],
                keyword: '',
                loading: false,
                tab: 'all', // all | checked
                value: [],
              },
            },
          }
        case REVENUE_FILTER_TAG_FIELDS[3]:
          return {
            ...state,
            filter: {
              ...state.filter,
              orderType: {
                ...state.filter.orderType,
                activeValue: null,
                keyword: '',
                value: null,
              },
            },
          }
        case REVENUE_FILTER_TAG_FIELDS[4]:
          return {
            ...state,
            filter: {
              ...state.filter,
              principal: {
                ...state.filter.principal,
                activeValue: null,
                keyword: '',
                value: null,
              },
              teacher: {
                ...state.filter.teacher,
                activeValue: null,
                keyword: '',
                value: null,
                list: [],
                listOrigin: [],
              },
            },
          }
        case REVENUE_FILTER_TAG_FIELDS[5]:
          return {
            ...state,
            filter: {
              ...state.filter,
              teacher: {
                ...state.filter.teacher,
                activeValue: null,
                keyword: '',
                value: null,
              },
            },
          }
        case REVENUE_FILTER_TAG_FIELDS[6]:
          return {
            ...state,
            filter: {
              ...state.filter,
              paymentMethod: {
                ...state.filter.paymentMethod,
                activeValue: null,
                keyword: '',
                value: null,
              },
            },
          }
        case REVENUE_FILTER_TAG_FIELDS[7]:
          return {
            ...state,
            filter: {
              ...state.filter,
              orderStatus: {
                ...state.filter.orderStatus,
                activeValue: [
                  { id: 1, name: 'Mới', value: 'new' },
                  { id: 4, name: 'Hoàn thành', value: 'completed' },
                ],
                keyword: '',
                loading: false,
                tab: 'all', // all | checked
                value: [
                  { id: 1, name: 'Mới', value: 'new' },
                  { id: 4, name: 'Hoàn thành', value: 'completed' },
                ],
              },
            },
          }
        default:
          return { ...state }
      }
    case
    revenueActions.UPDATE_SELECTED_LIST_ACTIVE
    :
      return {
        ...state,
        exportExcel: {
          ...state.exportExcel,
          activeList: action.payload.active,
          list: action.payload.list,
          selectedList: action.payload.selected,
        }
      }
    case
    revenueActions.SEARCH_SELECTED_LIST_ACTIVE
    :
      return {
        ...state,
        exportExcel: {
          ...state.exportExcel,
          activeList: action.payload.activeList,
          keyword: action.payload.keyword,
        }
      }
    case
    revenueActions.UPDATE_DRAG_SELECTED_LIST
    :
      return {
        ...state,
        exportExcel: {
          ...state.exportExcel,
          selectedList: action.payload,
        }
      }

    default:
      throw new Error()
  }
}
