import {useCallback, useContext} from 'react'
import {BannerManagementContext} from "../provider/_context";
import config from "../../../config";
import {sendRequestAuth} from "../../../api/api";
import {TAG_DELETE} from "../interface/_contants";
import {removeAcent} from "../../../common/fieldText/_functions";
import {bannerManagementActions} from "../provider/_init";

const useBannerManagementFilter = () => {
  const {pageState, pageDispatch} = useContext(BannerManagementContext)
  const {filter, table} = pageState

  // ===== ===== ===== ===== =====
  // SEARCH
  // ===== ===== ===== ===== =====
  const searchValue = filter.search.value
  const searchActive = filter.search.active
  const handleSearchChange = (e) => {
    bannerManagementActions
    if (e == ' ') e = ''
    const keyword = e.replace(/\s+/g, ' ') || ''
    pageDispatch({
      type: bannerManagementActions.FILTER_SEARCH_UPDATE,
      payload: {value: keyword},
    })
  }

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // POSTION FILTER
  // ===== ===== ===== ===== =====
  const positionValue = filter?.position?.value
  const positionActiveValue = filter?.position?.activeValue

  const handlePositionChange = value => {
    pageDispatch({
      type: bannerManagementActions.FILTER_POSITION_VALUE_UPDATE,
      payload: value
    })
  }
  // ===== ===== ===== ===== =====
  // STATUS FILTER
  // ===== ===== ===== ===== =====
  const statusValue = filter?.status?.value
  const statusActiveValue = filter?.status?.activeValue

  const handleStatusChange = value => {
    pageDispatch({
      type: bannerManagementActions.FILTER_STATUS_VALUE_UPDATE,
      payload: value
    })
  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // SUBMIT FILTER
  // ===== ===== ===== ===== =====
  // Badge
  const otherFilterBadge = [
    !!statusActiveValue,
    !!searchActive,
    !!positionActiveValue
  ].filter(item => item === true).length

  const shouldShowResetAll = [
    !!statusActiveValue,
    !!positionActiveValue,
    !!searchActive
  ].includes(true)

  const canSubmitOtherFilter = [
    JSON.stringify(statusValue) !== JSON.stringify(statusActiveValue),
    JSON.stringify(positionValue) !== JSON.stringify(positionActiveValue),
    JSON.stringify(filter?.search?.value) !== JSON.stringify(filter?.search?.active)
  ].includes(true)
  const queries = {
    per_page: table?.pagination?.amount || 20,
    keyword: filter?.search?.value || '',
    position: positionValue?.value || '',
    status: statusValue?.name ? statusValue.value : '',
    start: 0
  }


  const fetchBannerByFilter = async (qs, opt) => {
    pageDispatch({
      type: bannerManagementActions.TABLE_LOADING_DISPLAY,
      payload: true
    })
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/banner${queryString}`),
    ])
    if (response[0]?.data?.success) {
      pageDispatch({
        type: bannerManagementActions.TABLE_LOADING_DISPLAY,
        payload: false
      })
      const books = response[0]?.data
      pageDispatch({
        type: bannerManagementActions.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: books?.data,
          listDefault: books?.data,
          loading: false
        }
      })
      pageDispatch({
        type: bannerManagementActions.TABLE_UPDATE_PAGINATION,
        payload: {
          active: 0,
          amount: books?.meta?.per_page,
          total: Math.ceil(books?.meta?.total / books?.meta?.per_page),
          totalItems: books?.meta?.total,
        }
      })
    }
  }
  const applyOtherFilter = async () => {
    pageDispatch({
      type: bannerManagementActions.TABLE_LOADING_DISPLAY,
      payload: true
    })
    fetchBannerByFilter(queries)
    pageDispatch({type: bannerManagementActions.FILTER_STATUS_ACTIVE_VALUE_UPDATE, payload: statusValue})
    pageDispatch({type: bannerManagementActions.FILTER_POSITION_ACTIVE_VALUE_UPDATE, payload: positionValue})
    pageDispatch({
      type: bannerManagementActions.FILTER_SEARCH_ACTIVE_UPDATE, payload: {
        active: filter?.search?.value
      }
    })

  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  const filterTagDelete = (type) => {
    queries.status = ''
    pageDispatch({
      type: bannerManagementActions.TABLE_LOADING_DISPLAY,
      payload: true
    })
    let qs = {...queries}
    switch (type) {
      case TAG_DELETE[0]:
        qs = {...qs, keyword: ''}
        pageDispatch({
          type: bannerManagementActions.FILTER_TAG_DELETE,
          payload: type
        })
        break;
      case TAG_DELETE[1]:
        qs = {...qs, position: ''}
        pageDispatch({
          type: bannerManagementActions.FILTER_TAG_DELETE,
          payload: type
        })
        break;
      case TAG_DELETE[2]:
        qs = {...qs, status: ''}
        pageDispatch({
          type: bannerManagementActions.FILTER_TAG_DELETE,
          payload: type
        })
        break;
      default:
        break;
    }

    fetchBannerByFilter(qs)
  }
  const filterTagDeleteAll = () => {
    TAG_DELETE.forEach(each => pageDispatch({
      type: bannerManagementActions.FILTER_TAG_DELETE,
      payload: each
    }))
    fetchBannerByFilter({...queries, keyword: '', status: '', position: ''})
  }
  return {
    queries: queries,
    search: {
      value: searchValue,
      active: filter?.search?.active,
      onChange: handleSearchChange,
    },
    position: {
      value: positionValue,
      statusValue: positionActiveValue,
      activeValue: positionActiveValue,
      onChange: handlePositionChange,
    },
    statusFilter: {
      value: statusValue,
      statusValue: statusActiveValue,
      activeValue: statusActiveValue,
      onChange: handleStatusChange,
    },
    badge: {
      others: otherFilterBadge,
    },
    methods: {
      applyOtherFilter,
      filterTagDelete,
      filterTagDeleteAll
    },
    canSubmitOtherFilter,
    shouldShowResetAll,
  }
}

export default useBannerManagementFilter