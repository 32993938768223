import React from 'react';
import {STORE_UPOS_ICON} from "../../../products/book/interface/_icons";
import {Input} from "../../../../common/form/input";
import usePrincipalFilter from "../../hook/usePrincipalFilter";
import {CategoryInput} from "../../../../common/form/input/_categoryInput";

const Search = () => {
  const {search} = usePrincipalFilter()
  return (
    <CategoryInput
      categoryValue={{name:'Hiệu trưởng', value:''}}
      categoryWidth={95}
      className="principal-filter-form__input-wide"
      placeholder={"Tìm kiếm theo tên đăng nhập, tên hiệu trưởng , email"}
      value={search.value}
      onChange={e => search.onChange(e)}
    />
  )
}

export default Search