import React, {useCallback, useContext, useState} from 'react'
import {STORE_UPOS_ICON} from '../../../interface/_icons'
import {EventManagementContext} from '../../../provider/_context'
import {Text} from 'common/text'
import {Tr} from 'layouts/tableLayout/_tr'
import {Td} from 'layouts/tableLayout/_td'
import {debounce, Skeleton} from '@mui/material'
import styled from 'styled-components'
import useEventManagementRow from '../../../hooks/useEventManagementRow'
import {Button} from 'common/button'
import {useTranslation} from 'react-i18next'
import {DeleteConfirmModal} from '../../deleteConfirmModal'
import {fDateTimeCustom, fDateTimeDMY} from '../../../../../util/formatTime'
import {QRCode} from "react-qrcode-logo";
import LogoQr from "interface/icon.svg";
import {eventManagementActions} from "../../../provider/_init";
import {RowMenuPopover} from "./_rowMenuPopover";
import useAlert from "../../../../../hook/useAlert";
import fileSave from "file-saver"
import {CellStatusEvent} from "./_cellStatus";
import {Tooltip} from "../../../../../common/tooltip";

const Tbody = () => {
   const {t} = useTranslation()
   const {pageState} = useContext(EventManagementContext)
   const {table} = pageState
   const displayList = table?.display?.list
   const displayListDefault = table?.display?.listDefault
   const displayLoading = table.display.loading
   const paginationAmount = table.pagination.amount

   return (
      <StyledTBody>
         {displayLoading ? (
            Array.from(Array(paginationAmount), (e, i) => (
               <EventManagementPlaceholder key={i}/>
            ))
         ) : displayList.length > 0 ? (
            displayList.map((item, key) => <EventManagementTr t={t} key={item.id} stt={key} data={item}/>)
         ) : (
            <EventManagementEmpty t={t} list={displayListDefault}/>
         )}
      </StyledTBody>
   )
}

export default Tbody

const EventManagementPlaceholder = ({...props}) => {
   return (
      <Tr {...props} className="store-upos-table__row">
         {Array.from(Array(4), (e, i) => (
            <Td key={i} className="store-upos-table__cell" data-type="td">
               <Skeleton
                  sx={{
                     width: '100%',
                     height: 33,
                     background:
                        'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
                  }}
               />
            </Td>
         ))}
      </Tr>
   )
}

const EventManagementEmpty = ({list, t, ...props}) => {
   return (
      <StyledEventManagementEmpty {...props}>
         <img
            className="store-upos-empty__event"
            src="/img/order/order-empty.png"
            alt="empty"
         />
         <Text as="b" color="#7C88A6" style={{marginBottom: 16}}>
            {!!!list ? t('Bạn chưa có sản phẩm sách nào') : t('no_matching_data')}
         </Text>
         {!!!list && (
            <Button href={'/upos/shop/create'} icon={STORE_UPOS_ICON.plus}>{t('add_new_shop')}</Button>
         )}
      </StyledEventManagementEmpty>
   )
}

const EventManagementTr = ({data, t, ...props}) => {
   const orderRow = useEventManagementRow(data)
   const {row} = orderRow
   const {showAlert} = useAlert()
   const {pageDispatch} = useContext(EventManagementContext)
   const shareLink = () => {
      pageDispatch({
         type: eventManagementActions.SET_DISPLAY_SHARE_MODAL,
         payload: {
            open: true,
            referral: data?.miniapp_link
         }
      })
   }

   const handleDownload = () => {
      const element = document.getElementById(`qr-event-${data.id}`);
      const imgData = element.toDataURL('image/png')
      // eslint-disable-next-line
      fileSave.saveAs(imgData, `qr-code__${data.id}.png`);
   };

   const copyContent = () => {
      navigator.clipboard.writeText(data?.miniapp_link)
      showAlert({
         type: 'success',
         content: "Đã copy link giới thiệu"
      })
   };

   const handleActionApply = action => {
      switch (action) {
         case 'share':
            shareLink()
            break
         case 'copy':
            copyContent()
            break
         case 'download':
            handleDownload()
            break
         default:
            break
      }
   }

   return (
      <>
         <DeleteConfirmModal/>
         <Tr
            {...props}
            className="store-upos-table__row"
         >
            <Td className="store-upos-table__cell" data-type="td">
               {props?.stt + 1}
            </Td>
            <Td className="store-upos-table__cell" data-type="td">
               <Text as={'p'}
                     fontWeight={600}
               >{data?.title || '---'}</Text>
            </Td>
            <Td className="store-upos-table__cell" data-type="td">
               <Text as={'p'}>{fDateTimeCustom(data?.created_at, { format: 'HH:mm:ss' })}</Text>
               <Text as={'p'}>{fDateTimeDMY(data?.created_at)}</Text>
            </Td>
            <Td className="store-upos-table__cell" data-type="td">
               {data?.total_customers || 0}
            </Td>
            <Td className="store-upos-table__cell" data-type="td">
               <div className="qr-code">
                  <QRCode
                     id={`qr-event-${data.id}`}
                     value={data?.miniapp_link}
                     qrStyle={'dots'} level={'L'}
                     eyeRadius={5}
                     eyeColor={"#002140"}
                     fgColor={"#002140"}
                     logoImage={LogoQr} size={600}
                     logoWidth={150} logoHeight={150}
                  />
               </div>
               <div className='link-event'>
                  {!!!data?.miniapp_link
                     ? '---'
                     : data?.miniapp_link?.length < 50
                        ? <Text>{data?.miniapp_link}</Text>
                        : <Tooltip placement="bottom-start"
                                   title={data?.miniapp_link}
                        >
                           <Text>{data?.miniapp_link?.substring(0, 50)+'...'}</Text>
                        </Tooltip>
                  }
               </div>
            </Td>
            <Td className="store-upos-table__cell" data-type="td">
               <CellStatusEvent id={data?.status_code}>
                  {data?.status_name}
               </CellStatusEvent>
            </Td>
            <Td
               className="store-upos-table__cell"
               data-menu="true"
               data-type="td"
               onClick={e => e.stopPropagation()}
            >
               <RowMenuPopover
                  id={data.id}
                  data={data}
                  onActionClick={handleActionApply}
               />
            </Td>
         </Tr>
      </>
   )
}

export const StyledTBody = styled.div`
  .store-upos-table {
    &__loading {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
  
      width: 100vw;
      height: 100vh;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      background: rgba(0, 0, 0, 0.25);
  
      img {
        width: 80px;
        height: 80px;
      }
    }
  
    &__row {
      &:hover {
        .store-upos-table__delete {
          display: block;
        }
        .store-upos-table__edit {
          display: block;
        }
      }
    }
  
    &__cell {
      cursor: pointer;
      margin: 0 8px;
      display: flex;
      justify-content: left;
      align-items: center;
      &[data-menu='true'] {
        position: relative;
      }
  
      &[data-type='th'] {
        &[data-selected='true'] {
          display: flex;
          flex: 1;
          align-items: center;
          height: 44px;
        }
      }
  
      &--success {
        color: #0B74E5 !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #ECF4FE;
        border-radius: 4px;
      }
      &--danger {
        color: #FF424E !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #FFEBEC;
        border-radius: 4px;
      }
       &:nth-child(1) {
          width: 2%;
        }
        &:nth-child(2) {
          flex: 1;
        }
        &:nth-child(3) {
          width: 10%;
          display: block;
          text-align: left;
        }
        &:nth-child(4) {
          width: 10%;
          text-align: center;
          justify-content: center;
        }
        &:nth-child(5) {
          width: 30%;
          text-align: left;
          justify-content: left;
          display: flex;
          .qr-code{
            width: 70px;
            height: 70px;
               canvas {
                max-width: 100%;
                max-height: 100%;
           }
          }
          .link-event{
            width: calc(100% - 100px);
            margin-left: 8px;
             display: -webkit-box;
             -webkit-line-clamp: 1;
             -webkit-box-orient: vertical;
             overflow: hidden;
             text-overflow: ellipsis;
          }
        }
        &:nth-child(6) {
          width: 14%;
          text-align: center;
          justify-content: center;
        }
        &:nth-child(7) {
          width: 80px;
          justify-content: end;
        }
    }
  
    &__delete {
      position: absolute;
      top: 38%;
      right: 8px;
  
      width: 20px;
      height: 20px !important;
  
      background: transparent;
      border: none;
      border-radius: 12px !important;
  
      font-size: 12px !important;
      line-height: 24px !important;
  
      cursor: pointer;
  
    }
  
    &__edit {
      position: absolute;
      top: 40%;
      right: 42px;
  
      width: 20px;
      height: 20px !important;
  
      background: transparent;
      border: none;
      border-radius: 12px !important;
  
      font-size: 12px !important;
      line-height: 24px !important;
  
      cursor: pointer;
  
      &[data-active='true'] {
        display: block !important;
      }
    }
  
    &__tooltipV2 {
      width: 100%;
      padding: 0;
      overflow: hidden;
      position: relative;
      display: inline-block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
}
`
export const StyledEventManagementEmpty = styled.div`
  min-height: calc(100vh - 430px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;

  .store-upos-empty__event {
    width: 133px;
    height: 133px;
    margin-bottom: 16px;

    object-fit: contain;
    object-position: center;
  }
`
