import { Grid } from '@mui/material'
import styled from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Option } from 'common/form/autoComplete/_option'
import { THEME_COLORS } from 'common/theme/_colors'
import { AlternativeAutoComplete } from '../../../../../common/form/autoComplete/_alternativeAutoComplete'
import useInvoiceCreate from '../../../hooks/useInvoiceCreate'
import { Text } from '../../../../../common/text'
import { THEME_SEMANTICS } from '../../../../../common/theme/_semantics'
import { AutoCompleteSingleOption } from '../../../../../common/form/autocompleteSingleOption'
import { formatMoney } from '../../../../../util/functionUtil'
import { fDateTimeCustom } from '../../../../../util/formatTime'

export const PaymentScheduleContent = () => {
  const { t } = useTranslation()
  const { loading, monthTime, principal, validateFormCreate } = useInvoiceCreate()
  return (
    <StyledInfoBasic>
      <Grid container spacing={2}>
        <Grid xs={6} sm={6} md={6} lg={6} item>
          <div className="product-info-basic__form-input">
            <AlternativeAutoComplete
              className="invoice-filter-form__input-wide"
              // main input
              inputProps={{
                categoryList: [], // menu list in category dropdown
                categoryHidden: true,
                placeholder: t('Chọn kỳ thanh toán'),
                readOnly: true,
                value: t(monthTime?.value) || '',
                label: <>
                  <div> Kỳ thanh toán <Text color={THEME_SEMANTICS.failed}>*</Text>
                  </div>
                </>,
              }}

              // menu
              menuProps={{
                empty:
                  !principal.value?.id
                    ? 'Vui lòng chọn hiệu trưởng trước khi chọn kỳ thanh toán'
                    : '',
              }}
              hideSearchBar={true}
              validateText={validateFormCreate?.monthTime}
              validateType={!!validateFormCreate?.monthTime ? 'danger' : 'success'}
            >
              {!!principal.value?.id && monthTime?.list.length > 0 && monthTime?.list?.map((item, i) => (
                <AutoCompleteSingleOption
                  style={{ marginTop: i === 0 ? 8 : 0 }}
                  key={i}
                  className="invoice-filter-form__option-text"
                  data-active={item === monthTime?.value}
                  onClick={() => monthTime.onChange(item)}
                >
                  {t(item)}
                </AutoCompleteSingleOption>
              ))}
            </AlternativeAutoComplete>
          </div>
        </Grid>
      </Grid>
      {
        !!monthTime?.value && !loading ?
          monthTime?.info?.id
            ?
            <Grid container spacing={2} style={{ marginTop: 16 }}>
              <Grid xs={12} sm={12} md={12} lg={12} item>
                <Text fontSize={16} lineHeight={22} fontWeight={500}>Thông tin chi tiết kỳ thanh toán</Text>
              </Grid>
              <Grid xs={3} sm={3} md={3} lg={3} item>
                <div className="product-info-basic__form-input">
                  <Text color={'#7C88A6'}>Tổng phải trả trong kỳ</Text>
                </div>
                <div className="product-info-basic__form-input">
                  <Text>{formatMoney(monthTime?.info?.month_value || 0)}</Text>
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={6} lg={6} item>
                <div className="product-info-basic__form-input">
                  <Text color={'#7C88A6'}>Tổng giá trị chi đã xuất hóa đơn (không tính hóa đơn đã hủy)</Text>
                </div>
                <div className="product-info-basic__form-input">
                  <Text>{formatMoney(monthTime?.info?.month_paid || 0)}</Text>
                </div>
              </Grid>
              <Grid xs={3} sm={3} md={3} lg={3} item>
                <div className="product-info-basic__form-input">
                  <Text color={'#7C88A6'}>Tổng giá trị còn lại</Text>
                </div>
                <div className="product-info-basic__form-input">
                  <Text>{formatMoney(monthTime?.info?.month_remaining || 0)}</Text>
                </div>
              </Grid>

              <Grid xs={12} sm={12} md={12} lg={12} item>
                <div className="row-tab-detail__contents">
                  <div className="row-tab-detail__table">
                    <div className="row-tab-detail__thead">
                      <div className="row-tab-detail__tr">
                        <div className="row-tab-detail__th">Đợt thanh toán</div>
                        <div className="row-tab-detail__th">Ngày tạo</div>
                        <div className="row-tab-detail__th">Giá trị chi</div>
                        <div className="row-tab-detail__th">Giá trị còn lại</div>
                        <div className="row-tab-detail__th">Trạng thái</div>
                      </div>
                    </div>
                    <div className="row-tab-detail__tbody">
                      {
                        Array.isArray(monthTime?.info?.trackings) && monthTime?.info?.trackings?.length > 0 ?
                          monthTime?.info?.trackings.map(item => (
                            <div key={item.id} className="row-tab-detail__tr">
                              <div
                                className="row-tab-detail__td"
                                title={item?.id}
                                style={{
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {item?.tracking_title || '---'}
                              </div>
                              <div className="row-tab-detail__td">
                                <Text>
                                  {!!item?.created_at ? fDateTimeCustom(item?.created_at, { format: 'HH:mm dd/MM/yyyy' }) : '---'}
                                </Text>
                              </div>
                              <div className="row-tab-detail__td">
                                {formatMoney(item?.payment_value || 0)}
                              </div>
                              <div className="row-tab-detail__td">
                                {formatMoney(item?.month_remaining || 0)}
                              </div>
                              <div className="row-tab-detail__td">
                                <Text>{item.payment_status_name}</Text>
                              </div>
                            </div>
                          ))
                          : <div className="row-tab-detail__empty flex row-invoice-detail__empty">
                            <Text as={'p'}>Không tìm thấy chi tiết kỳ thanh toán</Text>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </Grid>

            </Grid>
            : <Grid xs={12} sm={12} md={12} lg={12} item>
              <div className="row-tab-detail__contents">
                <div className="row-tab-detail__table-empty">
                  <Text fontSize={16} fontWeight={500} color={'#7C88A6'}>Không tìm thấy thông tin chi tiết kỳ thanh toán</Text>
                </div>
              </div>
            </Grid>
          :
          ''
      }
    </StyledInfoBasic>
  )
}

export const StyledInfoBasic = styled.div`
  .row-invoice-detail__empty{
    min-height: 100px;
    justify-content: center;
    align-items: center;
  }
  .product-info-basic {
    //&__list-parent--tooltip {
    //  display: -webkit-box;
    //  height: 100%;
    //  -webkit-line-clamp: 1;
    //  -webkit-box-orient: vertical;
    //  overflow: hidden;
    //  text-overflow: ellipsis;
    //}
    &__toggle-item {
      position: absolute;
      top: 27px;
      right: 24px;
    }
    &__form-input-1 {
      margin-top: 24px;
    }
    &__form-input-code {
      margin-right: 8px;
    }
    &__form-input-barcode {
      margin-left: 8px;
    }
    &__form-input-group-product {
      position: relative;
      &--add {
        position: absolute;
        bottom: 72px;
        right: 24px;
        font-size: 14px;
        z-index: 2;
        
        svg {
          margin-left: 4px;
        }
        
        div {
          position: absolute;
          left: -24px;
          top: -2px;
          cursor: pointer;
        }
        span {
          color: #1A94FF;
          cursor: pointer;
        }
      }
    }
    
    &__list-parent {
      width: 50%;
      height: 260px;
      overflow: auto;
      &--wrapper {
        //height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 8px 0;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        
        & span:hover {
          color: rgb(229, 16, 29) !important;
        }
        &[data-type='true'] span{
          color: rgb(229, 16, 29) !important;
        }
      }
      &--tooltip {
        max-width: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 8px;
      }
    }
    &__list-child {
      border-left: 1px solid #EBEEF5;
      padding-left: 20px;
      width: 48%;
      height: 260px;
      overflow: auto;
      &--wrapper {
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 16px 0;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
      }
      &--tooltip {
        display: -webkit-box;
        height: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  
.store-upos-filter-form {
    &__group {
      width: calc(100% + 12px);
      margin: 0 -6px 16px -6px;

      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & > * {
        margin: 0 6px;
      }
    }

    &__collapse {
      max-height: 0;
      margin-bottom: 0 !important;

      overflow: hidden;

      transition: all 0.25s;

      &[data-collapse='true'] {
        max-height: 50vh;
        margin-bottom: 16px;

        overflow: unset;
      }
    }

    &__input-wide {
      width: calc(25% - 12px);
      margin: 0 6px;

      @media screen and (max-width: 1599px) {
        width: calc((100% / 3) - 12px);
      }
    }

    &__option-text {
      min-height: 36px;

      display: flex;
      align-items: center;

      color: ${THEME_COLORS.gray_900};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      cursor: pointer;

      transition: color 0.25s;

      &[data-active='true'] {
        color: ${THEME_COLORS.primary_300};
        font-weight: 600;
      }
      &:hover {
        color: ${THEME_COLORS.primary_300};
      }
    }

    &__option-container {
      min-height: 45px;
      margin-bottom: 4px;

      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__option-tabs {
      position: sticky;
      top: 0;
      z-index: 1;

      width: 100%;
      height: 28px;
      margin-bottom: 16px;

      display: flex;
      align-items: center;

      background: #fff;

      &::before {
        position: absolute;
        top: -20px;
        left: -20px;

        width: calc(100% + 40px);
        height: calc(100% + 36px);

        background: #fff;
        border-radius: 8px 8px 0 0;

        content: '';
      }
    }

    &__option-tab {
      position: relative;
      z-index: 2;

      margin-right: 16px;

      color: #808089;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      cursor: pointer;

      &[data-active='true'] {
        color: ${THEME_COLORS.primary_300};
        font-weight: 600;

        cursor: default;
      }
    }

    &__collapse {
      .store-upos-filter-form__input-wide {
        margin-bottom: 16px;
      }
    }
  }
  
.row-tab-detail {
  &__table {
    margin-top: 24px;
    margin-bottom: 12px;
    
    overflow: hidden;
    
    border: 1px solid #e2eaf8;
    border-radius: 8px;
    &-empty{
      margin-top: 24px;
      border: 1px solid #e2eaf8;
      border-radius: 8px;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  &__thead {
    .row-tab-detail__tr {
      background: #f7f9fd;
    }           
  }

  &__tr {
  display: flex;
  }
  
  &__th {
  min-height: 44px;
  padding: 12px;
  
  color: ${THEME_COLORS.secondary_100};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  
    &:nth-child(1) {
      width: 16%;
    }
    &:nth-child(2) {
      flex: 1;
    }
    &:nth-child(3) {
      width: 16%;
    }
    &:nth-child(4) {
      width: 16%;
      text-align: right;
    }
    &:nth-child(5) {
      width: 16%;
      text-align: center;
    }
    &:nth-child(6) {
      width: 16%;
      text-align: center;
    }
  }
  
  &__td {
    min-height: 56px;
    padding: 18px 12px;
    
    border-top: 1px solid #e2eaf8;
    
    color: ${THEME_COLORS.secondary_100};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    
    &:nth-child(1) {
      width: 16%;
    }
    &:nth-child(2) {
      flex: 1;
      
      color: ${THEME_SEMANTICS.delivering};
    }
    &:nth-child(3) {
      width: 16%;
    }
    &:nth-child(4) {
      width: 16%;
      text-align: right;
    }
    &:nth-child(5) {
      width: 16%;
      display: flex;
      justify-content: center;
    }
    &:nth-child(6) {
      width: 16%;
      text-align: center;
    }
  }

}
`
