import React, { useContext } from 'react'
import { STORE_UPOS_ICON } from '../../../interface/_icons'
import { BookManagementContext } from '../../../provider/_context'
import { Text } from '../../../../../../common/text'
import { Tr } from '../../../../../../layouts/tableLayout/_tr'
import { Td } from '../../../../../../layouts/tableLayout/_td'
import { Skeleton } from '@mui/material'
import styled from 'styled-components'
import useBookManagementRow from '../../../hooks/useBookManagementRow'
import { Button } from '../../../../../../common/button'
import { useTranslation } from 'react-i18next'
import { GLOBAL_ICONS } from '../../../../../../interface/icon'
import { formatMoney } from '../../../../../../util/functionUtil'
import { DeleteConfirmModal } from '../../deleteConfirmModal'
import { Tooltip } from '../../../../../../common/tooltipv2'

const Tbody = () => {
  const { t } = useTranslation()
  const { state } = useContext(BookManagementContext)
  const { table } = state

  const displayList = table?.display?.list
  const displayListDefault = table?.display?.listDefault
  const displayLoading = table.display.loading
  const paginationAmount = table.pagination.amount
  const paginationTotalItems = table.pagination.totalItems

  return (
    <StyledTBody>
      {displayLoading ? (
        Array.from(Array(paginationAmount), (e, i) => (
          <BookManagementPlaceholder key={i}/>
        ))
      ) : paginationTotalItems > 0 ? (
        displayList.map((item, key) => <BookManagementTr t={t} key={item.id} stt={key} data={item}/>)
      ) : (
        <BookManagementEmpty t={t} list={displayListDefault}/>
      )}
    </StyledTBody>
  )
}

export default Tbody

const BookManagementPlaceholder = ({ ...props }) => {
  return (
    <Tr {...props} className="store-upos-table__row">
      {Array.from(Array(9), (e, i) => (
        <Td key={i} className="store-upos-table__cell" data-type="td">
          <Skeleton
            sx={{
              width: '100%',
              height: 33,
              background:
                'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
            }}
          />
        </Td>
      ))}
    </Tr>
  )
}

const BookManagementEmpty = ({ list, t, ...props }) => {
  return (
    <StyledBookManagementEmpty {...props}>
      <img
        className="store-upos-empty__banner"
        src="/img/order/order-empty.png"
        alt="empty"
      />
      <Text as="b" color="#7C88A6" style={{ marginBottom: 16 }}>
        {!!!list ? t('Bạn chưa có sản phẩm sách nào') : t('no_matching_data')}
      </Text>
      {!!!list && (
        <Button href={'/upos/shop/create'} icon={STORE_UPOS_ICON.plus}>{t('add_new_shop')}</Button>
      )}
    </StyledBookManagementEmpty>
  )
}

const BookManagementTr = ({ data, t, ...props }) => {
  const orderRow = useBookManagementRow(data)
  const { row } = orderRow
  return (
    <>
      <DeleteConfirmModal/>
      <Tr
        {...props}
        className="store-upos-table__row"
      >
        <Td className="store-upos-table__cell" data-type="td">
          {props?.stt + 1}
        </Td>

        <Td className="store-upos-table__cell" data-type="td">
          <Tooltip baseOn={'height'} title={data?.title} className={'store-upos-table__tooltipV2'} >
            <Text as={'p'}
                  fontWeight={600}
            >{data?.title || '---'}</Text>
          </Tooltip>
        </Td>
        <Td className="store-upos-table__cell" data-type="td">
          <Tooltip baseOn={'height'} title={data?.option_author} className={'store-upos-table__tooltipV2'} >
            <Text>{data?.option_author || '---'}</Text>
          </Tooltip>
        </Td>
        <Td className="store-upos-table__cell" data-type="td">
          <Tooltip baseOn={'height'} title={data?.category_name} className={'store-upos-table__tooltipV2'} >
            <Text as={'p'}>{data?.category_name}</Text>
          </Tooltip>
        </Td>
        <Td className="store-upos-table__cell" data-type="td">
          <Text>{formatMoney(data?.price) || '---'}</Text>
        </Td>
        <Td className="store-upos-table__cell" data-type="td">
          <img style={{ maxWidth: '40%' }} src={data?.image} alt=""/>
        </Td>
        <Td className="store-upos-table__cell" data-type="td">
          {data?.status === 'enable' && <Text className={'store-upos-table__cell--success'}>{data?.status_name}</Text>}
          {data?.status === 'sold_out' && <Text className={'store-upos-table__cell--danger'}>{data?.status_name}</Text>}
          {data?.status === 'disable' && <Text className={'store-upos-table__cell--disable'}>{data?.status_name}</Text>}
        </Td>
        <Td
          className="store-upos-table__cell"
          data-menu="true"
          data-type="td"
          onClick={e => e.stopPropagation()}
        >
          <Button
            className="store-upos-table__edit"
            data-active={row.shouldOpenDetail}
            href={`/product/edit/${data?.id}`}
          >
            {GLOBAL_ICONS.edit}
          </Button>
          <button
            className="store-upos-table__delete"
            onClick={() => row.onDisplayDeleteModal(true, data?.id)}
          >
            {GLOBAL_ICONS.recycle}
          </button>
        </Td>
      </Tr>
    </>
  )
}

export const StyledTBody = styled.div`
  .store-upos-table {
    &__loading {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
  
      width: 100vw;
      height: 100vh;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      background: rgba(0, 0, 0, 0.25);
  
      img {
        width: 80px;
        height: 80px;
      }
    }
  
    &__row {
      &:hover {
        .store-upos-table__delete {
          display: block;
        }
        .store-upos-table__edit {
          display: block;
        }
      }
    }
  
    &__cell {
      cursor: pointer;
      margin: 0 8px;
      display: flex;
      justify-content: left;
      align-items: center;
      &[data-menu='true'] {
        position: relative;
      }
  
      &[data-type='th'] {
        &[data-selected='true'] {
          display: flex;
          flex: 1;
          align-items: center;
          height: 44px;
        }
      }
  
      &--success {
        color: #0B74E5 !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #ECF4FE;
        border-radius: 4px;
      }
      &--danger {
        color: #FF424E !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #FFEBEC;
        border-radius: 4px;
      }
      &--disable {
        color: #848484 !important;
        font-size: 12px !important;
        padding: 4px 12px;
        //border: 1px solid #848484;
        background: #EBEBEB;
        border-radius: 4px;
      }
      &:nth-child(1) {
        width: 2%;
        padding-left: 16px;
      }
  
      &:nth-child(2) {
        width: 20%;
      }
  
      &:nth-child(4) {
        width: 14%;
      }
  
      &:nth-child(3) {
        width: 15.2%;
      }
  
      &:nth-child(5) {
        width: 11.5%;
      }
  
      &:nth-child(6) {
        width: 8.5%;
        justify-content: center;
      }
  
      &:nth-child(7) {
        width: 11.5%;
        justify-content: center;
      }
  
      &:nth-child(8) {
        flex: 1;
      }
    }
  
    &__delete {
      position: absolute;
      top: 38%;
      right: 8px;
  
      width: 20px;
      height: 20px !important;
  
      background: transparent;
      border: none;
      border-radius: 12px !important;
  
      font-size: 12px !important;
      line-height: 24px !important;
  
      cursor: pointer;
  
    }
  
    &__edit {
      position: absolute;
      top: 40%;
      right: 42px;
  
      width: 20px;
      height: 20px !important;
  
      background: transparent;
      border: none;
      border-radius: 12px !important;
  
      font-size: 12px !important;
      line-height: 24px !important;
  
      cursor: pointer;
  
      &[data-active='true'] {
        display: block !important;
      }
    }
  
    &__selected-action-dropdown {
      position: relative;
  
      margin-left: 12px;
    }
  
    &__selected-action-toggle {
      width: 88px;
      padding: 0 !important;
  
      border-radius: 14px !important;
  
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  
    &__selected-action-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 11;
  
      width: 100vw;
      height: 100vh;
    }
  
    &__selected-action-menu {
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      z-index: 12;
  
      width: 150px;
      padding: 8px;
  
      background: #ffffff;
      border-radius: 6px;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    }
  
    &__selected-action-menu-item {
      padding: 8px;
  
      color: #191d32;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
  
      transition: color 0.25s;
  
      cursor: pointer;
  
      &:hover {
        color: #3264FF;
      }
    }
  
    &__tooltipV2 {
      display: -webkit-box;
      height: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }
}

.tab-detail-order {
  &__link-hover {
    color: #1A94FF;

    &:hover {
      color: #1373DB;
    }
  }
}
`
export const StyledBookManagementEmpty = styled.div`
  min-height: calc(100vh - 430px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;

  .store-upos-empty__banner {
    width: 133px;
    height: 133px;
    margin-bottom: 16px;

    object-fit: contain;
    object-position: center;
  }
`
