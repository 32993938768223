export const MODAL_ICONS = {
  x: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 6.74197L14.4821 0.261839C14.5646 0.179236 14.6625 0.113685 14.7703 0.0689304C14.8781 0.0241757 14.9937 0.00109261 15.1104 0.000999753C15.2272 0.000906893 15.3428 0.0238065 15.4506 0.0683897C15.5585 0.112973 15.6566 0.178367 15.7392 0.260838C15.8218 0.34331 15.8873 0.441245 15.9321 0.54905C15.9768 0.656855 15.9999 0.772419 16 0.889145C16.0001 1.00587 15.9772 1.12147 15.9326 1.22935C15.888 1.33722 15.8226 1.43526 15.7402 1.51786L9.25802 8L15.7382 14.4821C15.8208 14.5646 15.8863 14.6625 15.9311 14.7703C15.9758 14.8781 15.9989 14.9937 15.999 15.1104C15.9991 15.2272 15.9762 15.3428 15.9316 15.4506C15.887 15.5585 15.8216 15.6566 15.7392 15.7392C15.6567 15.8218 15.5588 15.8873 15.4509 15.9321C15.3431 15.9768 15.2276 15.9999 15.1109 16C14.9941 16.0001 14.8785 15.9772 14.7707 15.9326C14.6628 15.888 14.5647 15.8226 14.4821 15.7402L8 9.25802L1.51786 15.7382C1.43539 15.8208 1.33746 15.8863 1.22965 15.9311C1.12185 15.9758 1.00629 15.9989 0.889559 15.999C0.772834 15.9991 0.657233 15.9762 0.549357 15.9316C0.441481 15.887 0.343442 15.8216 0.260838 15.7392C0.178235 15.6567 0.112686 15.5588 0.067931 15.4509C0.0231762 15.3431 9.31395e-05 15.2276 2.81288e-07 15.1109C-9.25769e-05 14.9941 0.0228061 14.8785 0.0673893 14.7707C0.111972 14.6628 0.177367 14.5647 0.259839 14.4821L6.74197 8L0.261839 1.51786C0.0950143 1.35131 0.00118729 1.1253 0.000999753 0.889559C0.000812215 0.653821 0.0942791 0.427663 0.260838 0.260838C0.427398 0.0940138 0.653406 0.000187824 0.889145 2.81686e-07C1.12488 -0.000187261 1.35104 0.0932796 1.51786 0.259839L8 6.74197Z"
        fill="#191D32"
      />
      <path
        d="M8 6.74197L14.4821 0.261839C14.5646 0.179236 14.6625 0.113685 14.7703 0.0689304C14.8781 0.0241757 14.9937 0.00109261 15.1104 0.000999753C15.2272 0.000906893 15.3428 0.0238065 15.4506 0.0683897C15.5585 0.112973 15.6566 0.178367 15.7392 0.260838C15.8218 0.34331 15.8873 0.441245 15.9321 0.54905C15.9768 0.656855 15.9999 0.772419 16 0.889145C16.0001 1.00587 15.9772 1.12147 15.9326 1.22935C15.888 1.33722 15.8226 1.43526 15.7402 1.51786L9.25802 8L15.7382 14.4821C15.8208 14.5646 15.8863 14.6625 15.9311 14.7703C15.9758 14.8781 15.9989 14.9937 15.999 15.1104C15.9991 15.2272 15.9762 15.3428 15.9316 15.4506C15.887 15.5585 15.8216 15.6566 15.7392 15.7392C15.6567 15.8218 15.5588 15.8873 15.4509 15.9321C15.3431 15.9768 15.2276 15.9999 15.1109 16C14.9941 16.0001 14.8785 15.9772 14.7707 15.9326C14.6628 15.888 14.5647 15.8226 14.4821 15.7402L8 9.25802L1.51786 15.7382C1.43539 15.8208 1.33746 15.8863 1.22965 15.9311C1.12185 15.9758 1.00629 15.9989 0.889559 15.999C0.772834 15.9991 0.657233 15.9762 0.549357 15.9316C0.441481 15.887 0.343442 15.8216 0.260838 15.7392C0.178235 15.6567 0.112686 15.5588 0.067931 15.4509C0.0231762 15.3431 9.31395e-05 15.2276 2.81288e-07 15.1109C-9.25769e-05 14.9941 0.0228061 14.8785 0.0673893 14.7707C0.111972 14.6628 0.177367 14.5647 0.259839 14.4821L6.74197 8L0.261839 1.51786C0.0950143 1.35131 0.00118729 1.1253 0.000999753 0.889559C0.000812215 0.653821 0.0942791 0.427663 0.260838 0.260838C0.427398 0.0940138 0.653406 0.000187824 0.889145 2.81686e-07C1.12488 -0.000187261 1.35104 0.0932796 1.51786 0.259839L8 6.74197Z"
        fill="#F4F7FC"
        fillOpacity="0.6"
      />
    </svg>
  ),
}
