import {CategoryDateTimeRangePicker} from 'common/form/datePicker/_categoryDateTimeRangePicker'
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import { getDateFromNow } from 'util/date'
import useEventManagementFilter from "../../hooks/useEventManagementFilter";
import {EVENT_FILTER_FORM_DATE_TIME_SORT_TYPES} from "../../interface/_contants";

export const EventDateTime = () => {
  const {dateTime} = useEventManagementFilter()
  const predefinedRanges = [
    {
      label: 'Hôm qua',
      value: [addDays(getDateFromNow(0, {type: 'start'}), -1), addDays(getDateFromNow(0, {type: 'end'}), -1)],
    },
    {
      label: 'Hôm nay',
      value: [getDateFromNow(0, {type: 'start'}), getDateFromNow(0, {type: 'end'})],
    },
    {
      label: '7 ngày trước',
      value: [subDays(getDateFromNow(0, {type: 'start'}), 6), new Date()],
    },
    {
      label: 'Tháng này',
      value: [startOfMonth(getDateFromNow(0, {type: 'start'})), new Date()],
    },
  ];
  return (
    <CategoryDateTimeRangePicker
      className="principal-filter-form__input-wide"
      categoryList={EVENT_FILTER_FORM_DATE_TIME_SORT_TYPES}
      categoryWidth={140}
      value={dateTime.value}
      triggerDefault={dateTime.triggerDefault}
      onChange={dateTime.onChange}
      placement={'autoVerticalEnd'}
      datePickerProps={{
        defaultValue: dateTime.defaultValue,
        placeholder: 'dd/mm/yyyy ~ dd/mm/yyyy',
        range: predefinedRanges,
        format: 'dd/MM/yyyy'
      }}
    />
  )
}
