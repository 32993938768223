export const formatDatetime = (data, opt = {}) => {
  const D = new Date(data)

  let d = D.getDate()
  d = d < 10 ? `0${d}` : d

  let m = D.getMonth()
  m = m + 1
  m = m < 10 ? `0${m}` : m

  let y = D.getFullYear()

  let h = D.getHours()
  h = h < 10 ? `0${h}` : h

  let min = D.getMinutes()
  min = min < 10 ? `0${min}` : min
  // let time = !! opt?.onlyDate ? `${d}/${m}/${y}` : `${d}/${m}/${y} ${h}:${min}`
  let time = !!opt?.fullTime ? `${d}/${m}/${y} ${h}:${min}` : `${d}/${m}/${y}`
  if (opt.about) time = `Khoảng ${h}:${min} ngày ${d}/${m}/${y}`
  return time
}
export const formatDatetimeV2 = (data, opt = {}) => {
  const D = new Date(data)

  let d = D.getDate()
  d = d < 10 ? `0${d}` : d

  let m = D.getMonth()
  m = m + 1
  m = m < 10 ? `0${m}` : m

  let y = D.getFullYear()

  let time = !!opt?.onlyDate ? `${d}/${m}/${y}` : `${d}/${m}/${y} `
  return time
}
export const convertDateTimeToApiFormat = data => {
  if (!!data) {
    const dateTimeSplit = data?.split(' ')
    const dmy = dateTimeSplit[0] ? dateTimeSplit[0].split('/') : []
    const ymd = `${dmy[2]}-${dmy[1]}-${dmy[0]}`
    return `${ymd} ${dateTimeSplit[1] || ''}`.trim()
  }
}

export const convertDateTimeToApiFormatV2 = data => {
  if (!!data) {
    const dateTimeSplit = data?.split(' ')
    const dmy = dateTimeSplit[0] ? dateTimeSplit[0].split('/') : []
    const ymd = `${dmy[2]}-${dmy[1]}-${dmy[0]}`
    return `${ymd}`.trim()
  }
}