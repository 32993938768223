import React from "react";
import {AlternativeAutoComplete} from "../../../../common/form/autoComplete/_alternativeAutoComplete";
import {Option} from "../../../../common/form/autoComplete/_option";
import {useLicenseKeyCreate} from "../../hook/useLicenseKeyCreate";
import styled from 'styled-components'
import {Text} from "../../../../common/text";
import {THEME_SEMANTICS} from "../../../../common/theme/_semantics";
import {THEME_COLORS} from "../../../../common/theme/_colors";
export const Product = ()=>{
    const {create} = useLicenseKeyCreate()
    const {product} = create

    return(
        <StyleProduct>
            <AlternativeAutoComplete
                className="license-filter-form__input-wide"
                // main input
                inputProps={{
                    categoryList: [], // menu list in category dropdown
                    categoryValue: { name: '', value: '' }, // if not exist this value -> default category: categoryList[0]
                    categoryWidth: 90,
                    placeholder: 'Chọn sản phẩm',
                    readOnly: true,
                    value: product?.active?.title || '',
                    categoryHidden: true,
                    label:
                        <>
                            Sản phẩm
                            <Text color={THEME_SEMANTICS.failed}>*</Text>
                        </>,
                    // onIconClick: () => statusFilter.onChange(null),
                }}

                menuProps={{
                    empty: +product?.list?.length < 0
                        ? 'Không tìm thấy sản phẩm'
                        : '',

                }}

                searchInputProps={{
                    placeholder: 'Tìm kiếm sản phẩm',
                    value: product.keyword,
                    onChange: product.onSearch,
                }}
                validateText={product?.message ? product?.message : ''}
                validateType={product?.status ? 'danger' : 'success'}
            >
                {product?.list?.length > 0 && product?.list?.map(item => (
                    <Option
                        key={item.value}
                        className="license-filter-form__option-text common-scrollbar"
                        data-active={item.id === product?.active?.id}
                        onClick={() => product.onChange(item)}
                    >
                        {item.title}
                    </Option>
                ))}
            </AlternativeAutoComplete>
        </StyleProduct>

    )
}
const StyleProduct = styled.div`
    .license-filter-form__option-text{
        margin-top: 16px;
        font-size: 14px;
        cursor: pointer;
        &[data-active='true']{
            color: ${THEME_COLORS.primary_300};
        }
    }
`