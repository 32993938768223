import React from 'react';
import { Option } from "../../../../common/form/select/_option";
import { SCRIPT } from "../../interfaces/~script";
import useFilterUserManagement from "../../hooks/useFilterUserManagement";
import { useTranslation } from "react-i18next";
import { AlternativeAutoComplete } from '../../../../common/form/autoComplete/_alternativeAutoComplete'

const UserStatus = () => {
    const { t } = useTranslation()
    const { groupStatus } = useFilterUserManagement()
    return (
        <AlternativeAutoComplete
            className={'status-filter-form__input-wide'}
            inputProps={{
                categoryList: [],
                categoryValue: { name: SCRIPT.SELECT.STATUS.TITLE, value: '' },
                categoryWidth: 85,
                placeholder: t(SCRIPT.SELECT.STATUS.PLACEHOLDER),
                readOnly: true,
                value: groupStatus?.value?.name || '',
                // onIconClick: () => groupStatus.onSelected(null),
            }}
            // menu
            menuProps={{
                empty: groupStatus.list.length <= 0 ? t(SCRIPT.SELECT.STATUS.EMPTY) : '',
            }}
            hideSearchBar={true}
        >
            {groupStatus.list.length > 0 &&
                groupStatus.list.map(item => (
                    <Option
                        key={item.id}
                        className={"user-management-filter-form__option-text"}
                        data-active={item.id === groupStatus.value?.id}
                        onClick={() => groupStatus.onSelected(item)}
                    >
                        {t(item.name)}
                    </Option>
                ))}
        </AlternativeAutoComplete>
    );
};

export default UserStatus;