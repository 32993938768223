import { useReducer } from 'react'
import { profileInitialState } from '../provider/~initState'
import { profileReducer } from '../provider/~reducer'
import { profileActions } from '../provider/~action'
import useGlobalContext from '../../../containerContext/storeContext'

const useProfile = () => {
    const [state, dispatch] = useReducer(profileReducer, profileInitialState)
    const [globalState,] = useGlobalContext()
    const user = globalState;
    
    const handleOriginFetch = async () => {
        dispatch({
            type: profileActions.FORM_INIT_USER_DATA, payload: {
                userData: user?.user
            }
        })
    }

    return {
        fetch: {
            origin: handleOriginFetch
        },
        provider: {
            state,
            dispatch
        }
    }
}
export default useProfile