import { Skeleton } from '@mui/material'
import { Text } from 'common/text'
import config from 'config'
import useAlert from 'hook/useAlert'
import { Td } from 'layouts/tableLayout/_td'
import { Tr } from 'layouts/tableLayout/_tr'
import useInvoiceFilterForm from 'Pages/invoice/hooks/useInvoiceFilterForm'
import useInvoiceRow from 'Pages/invoice/hooks/useInvoiceRow'
import { InvoiceContext } from 'Pages/invoice/provider/_context'
import React, { useContext, useState } from 'react'
import { InvoiceEmpty } from '../invoiceEmpty'
import { RowInvoiceExtra } from './_rowExtra'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { PrintInvoiceDetail } from '../printDetail'
import { fDateTimeCustom, fDateTimeDMY } from '../../../../util/formatTime'
import { GLOBAL_ICONS } from '../../../../interface/icon'
import { Loading } from '../../../../common/loading'
import { sendRequestAuth } from '../../../../api/api'
import { CellCodeOrder } from '../../../orderBook/components/orderTable/_cellCodeOrder'
import { CellCustomer } from '../../../orderBook/components/orderTable/_cellCustomer'
import { formatMoney } from '../../../../util/functionUtil'
import { RowMenuPopover } from './_rowMenuPopover'
import toast from '../../../../Component/Toast'
import { ConfirmDeleteModal } from '../../../orderBook/components/orderTable/_confirmDeleteModal'
import EditModal from '../modal/index'
import { invoiceActions } from '../../provider/_reducer'
import { formatDatetimeV2 } from '../../../../common/form/datePicker/_functions'

export const InvoiceTBody = () => {
  const { pageState } = useContext(InvoiceContext)
  const { table } = pageState

  const displayList = table.display.list
  const displayLoading = table.display.loading
  const paginationTotalItems = table.pagination.totalItems
  return (
    <>
      {displayLoading ? (
        Array.from(Array(20), (e, i) => (
          <InvoicePlaceholder key={i}/>
        ))
      ) : paginationTotalItems > 0 ? (
        displayList.map(item => <InvoiceTr key={item.id} data={item}/>)
      ) : (
        <InvoiceEmpty/>
      )}
      <PrintInvoiceDetail/>
      <EditModal/>
    </>
  )
}

const InvoicePlaceholder = ({ ...props }) => {
  return (
    <Tr {...props} className="invoice-table__row">
      {Array.from(Array(9), (e, i) => (
        <Td key={i} className="invoice-table__cell" data-type="td">
          <Skeleton
            sx={{
              width: '100%',
              height: 33,
              background:
                'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
            }}
          />
        </Td>
      ))}
    </Tr>
  )
}

const InvoiceTr = ({ data, ...props }) => {
  const { showAlert } = useAlert()
  const { pageState, pageDispatch } = useContext(InvoiceContext)
  const { functions } = useInvoiceFilterForm()
  const invoiceRow = useInvoiceRow(data)
  const { cell, detail, row } = invoiceRow

  const [isLoading, setIsLoading] = useState(false)

  const [searchParams] = useSearchParams()
  useEffect(() => {
    const querySearch = searchParams.get('search') || ''

    if (querySearch && pageState?.table?.display?.list?.length === 1)
      row.onToggleDetail()
  }, [])

  const [confirmDeleteModalData, setConfirmDeleteModalData] = useState(null)

  const handlePrint = async _ => {
    // in
    setIsLoading(true)
    const response = await sendRequestAuth(
      'post',
      `${config.API}/commission/tracking/print/${data.id}`,
      {},
    )
    if (!!response.data.url && response.data.url !== '#') {
      let linkPrint = document.createElement('a')
      linkPrint.href = response.data.url
      linkPrint.target = '_blank'
      document.body.appendChild(linkPrint)
      linkPrint.click()
      setIsLoading(false)
    } else {
      setIsLoading(false)
      showAlert({
        content: response?.data?.message,
        type: 'danger',
      })
    }
  }
  const handleEditOrder = dataOrder => {
    pageDispatch({
      type: invoiceActions.FORM_INVOICE_UPDATE, payload: {
        paymentReference: {
          value: dataOrder?.payment_reference,
          message: ''
        },
        paymentReason: {
          value: dataOrder?.payment_reason,
          message: ''
        },
        paymentDate: {
          value: !!dataOrder?.payment_date ? new Date(dataOrder?.payment_date) : '',
          message: ''
        },
        formId: {
          value: dataOrder?.id,
          message: ''
        },
      }
    })
    pageDispatch({
      type: invoiceActions.DISPLAY_FORM_EDIT,
      payload: true
    })
  }

  const handleCancelInvoice = async () => {
    setIsLoading(true)

    const response = await sendRequestAuth(
      'post',
      `${config.API}/commission/tracking/cancel/${data.id}`,
    )

    if (response?.data?.success) {
      showAlert({ content: 'Cập nhật phiếu chi thành công', type: 'success' })
      await functions.fetchUpdateData()
    } else {
      showAlert({
        content: response?.data?.message,
        type: 'danger',
      })
    }

    setIsLoading(false)
    setConfirmDeleteModalData(null)
  }
  const handleCompletedOrder = async () => {
    setIsLoading(true)
    const response = await sendRequestAuth(
      'post',
      `${config.API}/commission/tracking/complete/${data.id}`,
    )

    if (response?.data?.success) {
      showAlert({ content: 'Cập nhật phiếu chi thành công', type: 'success' })
      await functions.fetchUpdateData()
    } else {
      showAlert({
        content: response?.data?.message,
        type: 'danger',
      })
    }

    setIsLoading(false)
    setConfirmDeleteModalData(null)
  }

  const handleActionApply = (action, dataOrder) => {
    switch (action) {
      case 'print':
        handlePrint()
        break
      case 'edit':
        handleEditOrder(dataOrder)
        break
      case 'cancel-invoice':
        setConfirmDeleteModalData({
          content: (
            <>
              <Text as={'p'}>Bạn có chắc chắn muốn hủy phiếu chi #{data.tracking_code} không?</Text>
              <Text as={'p'}>Lưu ý:</Text>
              <ul>
                <li>
                  <Text as={'p'}> &bull; Thao tác này sẽ hủy bỏ giá trị của phiếu chi đang chọn</Text>
                </li>
                <li>
                  <Text as={'p'}> &bull; Sau khi phiếu chi bị hủy, toàn bộ thông tin “Giá trị còn lại" của phiếu chi của
                    hiệu trưởng phát sinh trong kỳ đang chọn sẽ được tính toán lại.</Text>
                </li>
                <li>
                  <Text as={'p'}> &bull; Phiếu chi bị huỷ sẽ không thể hoàn tác hoặc thay đổi trạng thái</Text>
                </li>
              </ul>
            </>
          ),
          title: ' Xác nhận huỷ phiếu chi #' + data.tracking_code,
          onClose: () => setConfirmDeleteModalData(null),
          onSubmit: () => handleCancelInvoice(),
        })
        break
      case 'completed':
        setConfirmDeleteModalData({
          content: (
            <>
              <Text as={'p'}> Bạn có chắc chắn muốn cập nhật trạng thái phiếu chi #{data.tracking_code} sang hoàn thành?</Text>
              <Text as={'p'}>Lưu ý:</Text>
              <ul>
                <li>
                  <Text as={'p'}> &bull; Phiếu chi đã hoàn thành sẽ không thể thay đổi về các trạng thái khác</Text>
                </li>
                <li>
                  <Text as={'p'}> &bull; Phiếu chi đã hoàn thành sẽ không thể chỉnh sửa</Text>
                </li>
              </ul>
            </>
          ),
          title: ' Xác nhận hoàn thành phiếu chi #' + data.tracking_code,
          onClose: () => setConfirmDeleteModalData(null),
          onSubmit: () => handleCompletedOrder(),
        })
        break

      default:
        break
    }
  }

  return (
    <>
      {isLoading && (
        <Loading/>
      )}
      <Tr
        {...props}
        className="invoice-table__row"
        extra={
          <RowInvoiceExtra
            id={detail?.id}
            active={row.shouldOpenDetail}
            data={detail?.active}
            rowData={invoiceRow}
          />
        }
        data-active={row.shouldOpenDetail}
        onClick={row.onToggleDetail}
      >
        <Td className="invoice-table__cell" data-type="td">
          <Td className="principal-table__cell" data-type="td">
            <CellCodeOrder
              id={data.tracking_code}
              time={fDateTimeDMY(data.created_at)}
            />
          </Td>
        </Td>
        <Td className="invoice-table__cell" data-type="td">

          <CellCustomer
            id={data?.id}
            name={data.recipient_name}
            phone={data.recipient_phone}
          />
        </Td>
        <Td className="invoice-table__cell" data-type="td">
          <CellCustomer
            id={data?.id}
            name={data.month_time}
            phone={data.tracking_title}
          />
        </Td>
        <Td className="invoice-table__cell" data-type="td">
          <Text>{formatMoney(data?.month_value || 0)}</Text>
        </Td>
        <Td className="invoice-table__cell" data-type="td">

          <Text>{formatMoney(data?.payment_value || 0)}</Text>
        </Td>
        <Td className="invoice-table__cell" data-type="td">
          <Text>{formatMoney(data?.month_remaining || 0)}</Text>
        </Td>
        <Td className="invoice-table__cell" data-type="td">
          <Text>{data?.payment_name || '---'}</Text>
        </Td>
        <Td className="invoice-table__cell" data-type="td">
          <Text as={'p'} className={'invoice-table__status'}
                data-status={data?.payment_status}>{data?.payment_statust_name}</Text>
        </Td>
        <Td
          className="invoice-table__cell"
          data-menu="true"
          data-type="td"
          onClick={e => e.stopPropagation()}
        >
          <button
            className="invoice-table__detail-toggle"
            data-active={row.shouldOpenDetail}
            onClick={row.onToggleDetail}
          >
            {GLOBAL_ICONS.up}
          </button>
          <RowMenuPopover
            id={data.id}
            shippingStatus={data.payment_status}
            dataOrder={data}
            onActionClick={handleActionApply}
          />
        </Td>
      </Tr>
      {!!confirmDeleteModalData && (
        <ConfirmDeleteModal {...confirmDeleteModalData} />
      )}
    </>
  )
}
