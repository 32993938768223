import React, { useContext } from 'react'
import {
  STORE_UPOS_BREADCRUMB,
  STORE_UPOS_HEADER_ACTIONS
} from '../../interface/_contants'
import { PageHeader } from 'layouts/pageHeader'
import { EventManagementContext } from '../../provider/_context'
import { eventManagementActions } from '../../provider/_init'
import useEventManagementRow from '../../hooks/useEventManagementRow'

const Header = () => {
  const {
    row
  } = useEventManagementRow()
  const { pageDispatch } = useContext(EventManagementContext)
  const actions = [
    () => pageDispatch({
      type: eventManagementActions.OPEN_MODAL,
      payload: true
    })
  ]

  return (
    <>
      <PageHeader
        actions={STORE_UPOS_HEADER_ACTIONS.map((item, i) => ({
          ...item,
          onClick: actions[i],
        }))}
        breadcrumbLinks={STORE_UPOS_BREADCRUMB}
        breadcrumbTitle={'Quản lý sự kiện'}
      />
    </>
  )
}

export default Header