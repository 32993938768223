import {eventManagementActions} from './_init'
import {TAG_DELETE} from "../../productGroup/interface";

export const eventManagementReducer = (state, action) => {
   switch (action.type) {
      case eventManagementActions.PANEL_STORE_TOTAL:
         return {
            ...state,
            panels: {
               ...state.panels,
               storeTotal: action.payload,
            }
         }
      case eventManagementActions.SET_LOADING:
         return {
            ...state,
            loading: action.payload,
         }
      case eventManagementActions.PANEL_ORDER_TOTAL:
         return {
            ...state,
            panels: {
               ...state.panels,
               orderTotal: action.payload,
            }
         }

      case eventManagementActions.TABLE_LOADING_DISPLAY:
         return {
            ...state,
            table: {
               ...state.table,
               display: {
                  ...state?.table?.display,
                  loading: action?.payload
               }
            },
         }
      case eventManagementActions.TABLE_UPDATE_DISPLAY_LIST:
         return {
            ...state,
            table: {
               ...state.table,
               display: {
                  ...state?.table?.display,
                  list: action?.payload?.list || [],
                  listDefault: action?.payload?.listDefault || [],
                  loading: action?.payload?.loading
               }
            },
         }
      case eventManagementActions.TABLE_UPDATE_PAGINATION:
         return {
            ...state,
            table: {
               ...state.table,
               pagination: {
                  ...state.table.pagination,
                  active: action.payload?.active || 0,
                  amount: action.payload?.amount || 20,
                  total: action.payload?.total || 0,
                  totalItems: action.payload?.totalItems || 0,
               },
            },
         }
      case eventManagementActions.TABLE_DISPLAY_DETAIL_ID_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               detail: {
                  ...state.table.detail,
                  id: action.payload?.id || null,
               },
            },
         }
      case eventManagementActions.TABLE_DISPLAY_DETAIL_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               detail: {
                  ...state.table.detail,
                  active: action.payload?.active || null,
                  list: action.payload?.list || [],
               },
            },
         }
      case eventManagementActions.TABLE_SELECTED_LIST_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               selected: {
                  ...state.table.selected,
                  list: action.payload?.selected?.list || [],
               },
            },
         }
      case eventManagementActions.MODAL_DELETE_PRODUCT:
         return {
            ...state,
            delete: {
               ...state.delete,
               ...action.payload,
            },
         }
      case eventManagementActions.OPEN_MODAL:
         return {
            ...state,
            open_modal: action.payload,
         }
      case eventManagementActions.MODAL_CONFIRM:
         return {
            ...state,
            modal_confirm: action.payload,
         }
      case eventManagementActions.CHANGE_MODAL:
         return {
            ...state,
            change_modal: action.payload,
         }
      case eventManagementActions.UPDATE_FORM_CREATE:
         return {
            ...state,
            create: {
               ...state.create,
               ...action.payload
            },
         }

      case eventManagementActions.CREATE_EVENT_CATEGORY_UPDATE:
         return {
            ...state,
            create: {
               ...state.create,
               category: {
                  ...state.create.category,
                  ...action.payload
               }
            },
         }

      case eventManagementActions.CREATE_EVENT_PRODUCT_UPDATE:
         return {
            ...state,
            create: {
               ...state.create,
               product: {
                  ...state.create.product,
                  ...action.payload
               }
            },
         }
      case eventManagementActions.CREATE_EVENT_LINK_TO_UPDATE:
         return {
            ...state,
            create: {
               ...state.create,
               linkTo: action.payload
            },
         }
      case eventManagementActions.CREATE_EVENT_COLOR_UPDATE:
         return {
            ...state,
            create: {
               ...state.create,
               color: action.payload
            },
         }
      case eventManagementActions.CREATE_EVENT_DESCRIPTION_UPDATE:
         return {
            ...state,
            create: {
               ...state.create,
               description: action.payload
            },
         }
      case eventManagementActions.CREATE_EVENT_POSITION_UPDATE:
         return {
            ...state,
            create: {
               ...state.create,
               position: action.payload
            },
         }

      case eventManagementActions.CREATE_EVENT_VALIDATE_UPDATE:
         return {
            ...state,
            create: {
               ...state.create,
               validate: {
                  ...state.create.validate,
                  ...action.payload
               }
            },
         }

      case eventManagementActions.SET_ID:
         return {
            ...state,
            idEvent: action.payload
         }
      case eventManagementActions.FILTER_SEARCH_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               search: {
                  ...state.filter.search,
                  ...action.payload,
               }
            },
         }
      case eventManagementActions.FILTER_CATEGORY:
         return {
            ...state,
            filter: {
               ...state.filter,
               parentCategory: {
                  ...state.filter.parentCategory,
                  list: action.payload,
                  listOrigin: action.payload,
               }
            },
         }
      case eventManagementActions.FILTER_CATEGORY_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               parentCategory: {
                  ...state.filter.parentCategory,
                  value: action.payload?.value || null,
               }
            },
         }
      case
      eventManagementActions.FILTER_CATEGORY_KEYWORD_UPDATE
      :
         return {
            ...state,
            filter: {
               ...state.filter,
               parentCategory: {
                  ...state.filter.parentCategory,
                  keyword: action.payload.keyword,
                  list: action.payload.list,
               },
            },
         }
      case eventManagementActions.FILTER_SEARCH_ACTIVE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               search: {
                  ...state.filter.search,
                  ...action.payload,
               }
            },
         }

      case eventManagementActions.FILTER_STATUS_VALUE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               status: {
                  ...state.filter.status,
                  value: action.payload,
               },
            },
         }

      case eventManagementActions.FILTER_POSITION_VALUE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               position: {
                  ...state.filter.position,
                  value: action.payload,
               },
            },
         }
      case eventManagementActions.FILTER_STATUS_ACTIVE_VALUE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               status: {
                  ...state.filter.status,
                  activeValue: action.payload,
               },
            },
         }
      case eventManagementActions.FILTER_POSITION_ACTIVE_VALUE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               position: {
                  ...state.filter.position,
                  activeValue: action.payload,
               },
            },
         }
      case eventManagementActions.FILTER_CATEGORY_ACTIVE_VALUE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               parentCategory: {
                  ...state.filter.parentCategory,
                  activeValue: action.payload,
               },
            },
         }

         // Date time

      case eventManagementActions.FILTER_DATE_TIME_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               dateTime: {
                  ...state.filter.dateTime,
                  end: action.payload?.end,
                  start: action.payload?.start,
                  type: action.payload?.type,
                  value: action.payload?.value,
               },
            },
         }

      case eventManagementActions.FILTER_ACTIVE_DATE_TIME_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               dateTime: {
                  ...state.filter.dateTime,
                  activeValue: {
                     ...state.filter.dateTime.activeValue,
                     end: action.payload?.end,
                     start: action.payload?.start,
                     type: action.payload?.type,
                     value: action.payload?.value,
                  },
               },
            },
         }

      case eventManagementActions.FILTER_DATE_TIME_TRIGGER_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               dateTime: {
                  ...state.filter.dateTime,
                  trigger: action.payload?.trigger,
               },
            },
         }

      case eventManagementActions.SET_DISPLAY_SHARE_MODAL:
         return {
            ...state,
            shareModal: {
               ...state.shareModal,
               ...action?.payload
            }
         }
      case eventManagementActions.FILTER_TAG_DELETE:
         switch (action.payload) {
            case TAG_DELETE[0]:
               return {
                  ...state,
                  filter: {
                     ...state.filter,
                     search: {
                        ...state.filter.search,
                        active: '',
                        value: ''
                     },
                  },
               }
            case TAG_DELETE[1]:
               return {
                  ...state,
                  filter: {
                     ...state.filter,
                     position: {
                        ...state.filter.position,
                        activeValue: null,
                        value: null
                     },
                  },
               }
            case TAG_DELETE[2]:
               return {
                  ...state,
                  filter: {
                     ...state.filter,
                     status: {
                        ...state.filter.status,
                        activeValue: null,
                        value: null
                     },
                  },
               }
            default:
               return {...state};
         }
      default:
         break
   }
}