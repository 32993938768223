import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {GLOBAL_ICONS} from "../../../../interface/icon";
import {Tr} from "../../../../layouts/tableLayout/_tr";
import {Th} from "../../../../layouts/tableLayout/_th";

const Thead = ({...props}) => {
  const {t} = useTranslation()
  return (
    <StyledThead>
      <Tr {...props} type="tHead">
        <Th className="principal-table__cell">STT</Th>
        <Th className="principal-table__cell">Hiệu trưởng</Th>
        <Th className="principal-table__cell">Email</Th>
        <Th className="principal-table__cell">% Hoa hồng</Th>
        <Th className="principal-table__cell">Tổng số GV</Th>
        <Th className="principal-table__cell">Tổng sản phẩm</Th>
        <Th className="principal-table__cell"
             // icon={<PaymentFilterPopover />}
        >
          Ngày tạo
        </Th>
        <Th className="principal-table__cell">Trạng thái</Th>
        <Th className="principal-table__cell" style={{display: 'flex'}}>
          {GLOBAL_ICONS.setting}
        </Th>
      </Tr>
    </StyledThead>
  )
}

export default Thead


export const StyledThead = styled.div`
  .principal-table {
    &__cell {
      margin: 0 8px;
      display: flex;
      align-items: center;
    }
     &__cell:nth-child(1) {
      width: 50px;
      justify-content: center;
    }
    &__cell:nth-child(2) {
      width: 23%;
    }
    &__cell:nth-child(3) {
      width:18%;
    }
    &__cell:nth-child(4) {
      width: 15%;
      justify-content: center;
    }
    &__cell:nth-child(5) {
      width: 10%;
      justify-content: center;
    }
    &__cell:nth-child(6) {
        width: 10%;
    }
    &__cell:nth-child(7) {
        width: 10%;
    }
    &__cell:nth-child(8) {
      width: 15%;
      justify-content: center;
      padding-right: 23px;
    }
    &__cell:nth-child(9) {
      width:100px;
    }
  }
`
