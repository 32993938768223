import { TableLayout } from 'layouts/tableLayout'
import { useContext, useEffect, useRef, useState } from 'react'
import { InvoiceFilterForm } from './components/invoiceFilterForm'
import { InvoiceTBody } from './components/invoiceTable/_Body'
import { InvoiceTHead } from './components/invoiceTable/_Thead'
import {
  INVOICE_BREADCRUMB,
} from './interfaces/_constants'
import { InvoiceProvider } from './provider'
import './components/invoiceTable/index.scss'
import useInvoice from './hooks/useInvoice'
import { InvoiceNotifications } from './components/invoiceNotifications'
import { PageHeader } from './components/invoicePageHeader'
import PopupNotPermission from '../permision/popupNotPermission'
import { useNavigate } from 'react-router-dom'
import { GLOBAL_ICONS } from '../../interface/icon'
import { PATH } from '../../const/path'

export const InvoicePage = () => {
  const { fetch, pagination, provider } = useInvoice()

  const { state, dispatch } = provider
  const { table } = state

  useEffect(() => {
    fetch.origin()
  }, [])
  return (
    <InvoiceProvider value={{ pageState: state, pageDispatch: dispatch }}>
      <Header/>
      <InvoiceNotifications/>
      <TableLayout
        header={
          <>
            <InvoiceFilterForm/>
          </>
        }
        table={{
          tHead: <InvoiceTHead/>,
          tBody: <InvoiceTBody/>,
        }}
        pagination={{
          ...table.pagination,
          onAmountChange: pagination.onAmountChange,
          onPageChange: pagination.onPageChange,
        }}
      />
    </InvoiceProvider>
  )
}

const Header = () => {
  const [showPermission, setShowPermission] = useState(false)
  const nav = useNavigate()
  return (
    <>
      {showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)}/>}
      <PageHeader
        breadcrumbLinks={INVOICE_BREADCRUMB}
        breadcrumbTitle="Quản lý phiếu chi hoa hồng"
        actions={[
          {
            name: 'Thêm mới phiếu chi',
            icon: GLOBAL_ICONS.plus,
            onClick: () => nav(PATH.CREATE_COMMISSION_VOUCHER)
          }
        ]}
      />
    </>
  )
}
