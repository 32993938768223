const config = {
  env: 'DEV',
  PHONE: '',
  API: 'https://zapi.dtpsoft.vn/api/v1',
  API_V2: 'https://zapi.dtpsoft.vn/api/v2',
  API_FB: 'https://zapi.dtpsoft.vn/api/v2',
  WS: 'https://zapi.dtpsoft.vn/',
  HREF: 'https://zadmin.dtpsoft.vn',
}
export default config
