import React from "react";
import {RightSightPopup} from "../../../../layouts/rightSightPopup";
import styled from 'styled-components'
import {useLicenseKeyCreate} from "../../hook/useLicenseKeyCreate";
import {Keycode} from "./_keycode";
import {Product} from "./_product";
import {AppId} from "./_appId";
import {PackName} from "./_packName";
import {AppName} from "./_appName";
import {Level} from "./_level";
import {QuotaActive} from "./_quotaActive";
import {ConfirmModalV2} from "../../../../Component/ConfirmModal";
import {Text} from "../../../../common/text";

export const CreateLicenseKey = ({open})=>{
    const {functions, create} = useLicenseKeyCreate()
    const {canSubmitForm, animationClose} = create
    return(
        <StyleRightSideLicense>
            <RightSightPopup
                openModal={open}
                confirmBeforeClose={true}
                clickClose={() => functions?.onClose()}
                acceptance={() => functions.onSubmit()}
                disableSubmit={canSubmitForm}
                animationClose={animationClose}
                header={{
                    title: create?.type === 'edit' ? 'Chỉnh sửa key' : 'Tạo mới KEY',

                }}
                headerProps={{className:'license-key-create-form_header'}}
                body={[
                    {
                        item:  (
                            <div style={{marginTop: 16, overflow:'auto'}}>
                                <div style={{height: 81}} className={'license-key-create-form'}>
                                    <Keycode/>
                                </div>
                                <div style={{height: 81}} className={'license-key-create-form'}>
                                    <Product/>
                                </div>
                                <div style={{height: 81}} className={'license-key-create-form'}>
                                    <AppId/>
                                </div>
                                <div style={{height: 81}} className={'license-key-create-form'}>
                                    <PackName/>
                                </div>
                                <div style={{height: 81}} className={'license-key-create-form'}>
                                    <AppName/>
                                </div>
                                <div style={{height: 81}} className={'license-key-create-form'}>
                                    <Level/>
                                </div>
                                <div style={{height: 81}} className={'license-key-create-form'}>
                                    <QuotaActive/>
                                </div>

                            </div>
                        )
                    }
                ]}
                footer={
                    {
                        cancel: {
                            width: 74,
                            title: 'Hủy'
                        },
                        save: {
                            width: 110,
                            title: 'Lưu'
                        },
                    }
                }
            />
            {
                create?.openConfirm && <ConfirmModalV2
                    open={create?.openConfirm}
                    width={480}
                    body={
                        <BodyConfirm  />
                    }
                    footerButton={{
                        cancel:{
                            title: 'Hủy',
                            action: () => functions?.onCloseConfirm()
                        },
                        confirm:{
                            title: 'Thoát',
                            action: () => functions?.onAcceptCloseModal(),
                            disabled: false
                        }
                    }}
                />
            }
        </StyleRightSideLicense>
    )
}
const StyleRightSideLicense = styled.div`
     
`
const BodyConfirm = ()=>{
    return(
        <div>
            <Text fontWeight={600} fontSize={20}>Xác nhận rời khỏi trang</Text>
            <div style={{marginTop: 24}}>
                <Text>
                    Một số thông tin đã thay đổi, bạn có chắc chắn muốn rời khỏi trang khi thay đổi chưa được lưu?
                </Text>
            </div>
        </div>
    )
}