import React, { useContext } from 'react'
import {
  STORE_UPOS_BREADCRUMB,
  STORE_UPOS_HEADER_ACTIONS
} from '../../interface/_contants'
import { PageHeader } from 'layouts/pageHeader'
import { BannerManagementContext } from '../../provider/_context'
import { bannerManagementActions } from '../../provider/_init'
import useBannerManagementRow from '../../hooks/useBannerManagementRow'

const Header = () => {
  const {
    row
  } = useBannerManagementRow()
  const { pageDispatch } = useContext(BannerManagementContext)
  const actions = [
    () => pageDispatch({
      type: bannerManagementActions.OPEN_MODAL,
      payload: true
    })
  ]

  return (
    <>
      <PageHeader
        actions={STORE_UPOS_HEADER_ACTIONS.map((item, i) => ({
          ...item,
          onClick: actions[i],
        }))}
        breadcrumbLinks={STORE_UPOS_BREADCRUMB}
        breadcrumbTitle={'Quản lý banner'}
      />
    </>
  )
}

export default Header