import { Skeleton } from '@mui/material'
import { Text } from 'common/text'
import useAlert from 'hook/useAlert'
import { Td } from 'layouts/tableLayout/_td'
import { Tr } from 'layouts/tableLayout/_tr'
import useCommissionRow from 'Pages/commission/hooks/useCommissionRow'
import { CommissionContext } from 'Pages/commission/provider/_context'
import React, { useContext, useState } from 'react'
import { CommissionEmpty } from '../../commissionEmpty'
import { formatMoney } from 'util/functionUtil'
import { fDateTimeDMY } from 'util/formatTime'
import { fNumber } from 'util/formatNumber'
import { CellStatusOrder } from '../../../../orderBook/components/orderTable/_cellStatusOrder'
import { THEME_SEMANTICS } from '../../../../../common/theme/_semantics'

export const TBodyByOrder = () => {
  const { pageState } = useContext(CommissionContext)
  const { table } = pageState

  const displayList = table.display.list
  const displayLoading = table.display.loading
  const paginationTotalItems = table.pagination.totalItems
  return (
    <>
      {displayLoading ? (
        Array.from(Array(20), (e, i) => (
          <CommissionPlaceholder key={i}/>
        ))
      ) : paginationTotalItems > 0 ? (
        displayList.map(item => <CommissionTr key={item.id} data={item}/>)
      ) : (
        <CommissionEmpty/>
      )}
    </>
  )
}

const CommissionPlaceholder = ({ ...props }) => {
  return (
    <Tr {...props} className="commission-order-table__row">
      {Array.from(Array(9), (e, i) => (
        <Td key={i} className="commission-order-table__cell" data-type="td">
          <Skeleton
            sx={{
              width: '100%',
              height: 33,
              background:
                'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
            }}
          />
        </Td>
      ))}
    </Tr>
  )
}

const CommissionTr = ({ data, ...props }) => {
  const { showAlert } = useAlert()
  const commissionRow = useCommissionRow(data)
  const { cell, detail, row } = commissionRow
  const { codeCommission, payment } = cell

  return (
    <>
      <Tr
        {...props}
        className="commission-order-table__row"
      >
        <Td className="commission-order-table__cell" data-type="td">
          <Text>{fDateTimeDMY(data?.date_completed)}</Text>
        </Td>
        <Td className="commission-order-table__cell" data-type="td">
          <Text>{data?.id}</Text>
        </Td>
        <Td className="commission-order-table__cell" data-type="td">
          <Text>{data?.type == 1 ? 'Đơn hàng sách' : 'Đơn hàng số'}</Text>
        </Td>
        <Td className="commission-order-table__cell" data-type="td">
          <Text>{formatMoney(data?.total_revenue)}</Text>
        </Td>
        {/*<Td className="commission-order-table__cell" data-type="td">*/}
        {/*  <Text>{formatMoney(data?.total_commission)}</Text>*/}
        {/*</Td>*/}
        <Td className="commission-order-table__cell" data-type="td">
          <Text as={'a'}
                target={"_blank"}
                href={`/principal/${data?.principal_id}`}
                color={THEME_SEMANTICS.delivering}
                style={{cursor: 'pointer'}} >{data?.principal_name ? ((!!data?.principal_last_name ? (data?.principal_last_name + " ") : ``)  +  data?.principal_name) : '---'}</Text>

        </Td>
        <Td className="commission-order-table__cell" data-type="td">
          <Text>{formatMoney(data?.commission_principal)}</Text>
        </Td>
        <Td className="commission-order-table__cell" data-type="td">
          <Text as={'a'}
                href={`/principal/${data?.principal_id}?teacherId=${data?.teacher_id}`}
                target={"_blank"}
                color={THEME_SEMANTICS.delivering}
                style={{cursor: 'pointer'}}>{data?.teacher_name ? ((!!data?.teacher_last_name ? (data?.teacher_last_name + " ") : ``)  +  data?.teacher_name) : '---'}</Text>
        </Td>
        <Td className="commission-order-table__cell" data-type="td">
          <Text>{formatMoney(data?.commission_teacher)}</Text>
        </Td>
      </Tr>
    </>
  )
}
