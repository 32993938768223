import {STORE_UPOS_ICON} from "./_icons";
import { GLOBAL_ICONS } from '../../../../interface/icon'

export const STORE_UPOS_BREADCRUMB = [
  {id: 1, name: 'Sản phẩm số', url: '#'},
  {id: 2, name: 'Quản lý sản phẩm số', url: '#'},
]

export const STORE_UPOS_HEADER_ACTIONS = [
  {
    id: 1,
    name: null,
    appearance: 'secondary',
    icon: STORE_UPOS_ICON.repeat,
  },
  {
    id: 2,
    name: 'Import Excel',
    appearance: 'secondary',
    icon: GLOBAL_ICONS.filePlus,
  },
  {
    id: 3,
    name: 'Xuất excel',
    icon: GLOBAL_ICONS.export,
    appearance: 'secondary',
  },
  {
    id: 4,
    name: 'Thêm mới sản phẩm số',
    appearance: 'primary',
    icon: STORE_UPOS_ICON.plus,
  },
]


export const STORE_UPOS_FILTER_FORM_DATE_TIME_SORT_TYPES = [
  {id: 1, name: 'general_date_created', value: 'created'},
  {id: 2, name: 'expiration_date', value: 'expired'},
]

export const STORE_UPOS_FILTER_FORM_STATUS = [
  {id: 1, name: 'Hết hàng', value: 'sold_out'},
  {id: 2, name: 'Đang mở bán', value: 'enable'},
  {id: 3, name: 'Không kinh doanh', value: 'disable'},
]

export const DIGITAL_COLUMN_NAMES = {
  columnNames: [
    null,
    'Tên sản phẩm (*)',
    'Mã sản phẩm / ISBN (*)',
    'Danh mục (*)',
    'Nền tảng (*)',
    'Số lượng thiết bị (*)',
    'Trạng thái (*)',
    'Ngôn ngữ',
    'Thời hạn bản quyền',
    'Giá bán (*)',
    'Giá khuyến mãi',
    'Thời gian bắt đầu khuyến mãi',
    'Thời gian kết thúc khuyến mãi',
    'Mô tả ngắn sản phẩm',
    'Mô tả sản phẩm'
  ],
  startRowNumber: 6,
};