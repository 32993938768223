import React from "react";
import {Input} from "../../../../common/form/input";
import {THEME_SEMANTICS} from "../../../../common/theme/_semantics";
import {Text} from "../../../../common/text";
import {useLicenseKeyCreate} from "../../hook/useLicenseKeyCreate";

export const PackName = ()=>{
    const {create} = useLicenseKeyCreate()
    const {packName} = create
    return(
        <Input
            label={
                <>
                    Pack name
                </>
            }
            value={packName?.value}
            placeholder='Nhập pack name'
            // validateText={pageState.check_submit.name_check.status ? pageState.check_submit.name_check.message : null}
            // validateType={!pageState.check_submit.name_check.status ? 'success' : 'danger'}
            onChange={(e) => packName.onChange(e.target.value)}
            // onBlur={(e) => onBlurNameSupplier(e)}
        />
    )
}