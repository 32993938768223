import { useEffect, useState } from 'react'
import { useContext } from 'react'
import { InvoiceContext } from '../provider/_context'
import { getData, postData, sendRequestAuth } from '../../../api/api'
import { detailProductGroup, updateProductGroup } from '../../../api/url'
import { useTranslation } from 'react-i18next'
import { invoiceActions } from '../provider/_reducer'
import { convertDateTimeToApiFormat, formatDatetimeV2 } from '../../../common/form/datePicker/_functions'
import config from '../../../config'
import useAlert from '../../../hook/useAlert'

export const useModal = () => {
  const { t } = useTranslation()
  const { pageState, pageDispatch } = useContext(InvoiceContext)
  const [animate, setAnimate] = useState(false)
  const { formEdit } = pageState
  const { showAlert } = useAlert()
  const { filter,table } = pageState

  const filterQueries = {
    date_type: filter.dateTime?.activeValue?.type?.value || '',
    start_date:
      filter.dateTime.activeValue?.start && filter.dateTime.activeValue.value
        ? convertDateTimeToApiFormat(
        filter.dateTime.activeValue.value.split(' - ')[0],
        )
        : '',
    end_date:
      filter.dateTime.activeValue?.end && filter.dateTime.activeValue.value
        ? convertDateTimeToApiFormat(
        filter.dateTime.activeValue.value.split(' - ')[1],
        )
        : '',
    payment_status: filter.status.activeValue?.value || '',
    month_time: filter.monthTime.activeValue || '',
    payment_method: filter.invoiceType.activeValue?.value || '',
    principal_id: filter.principal.activeValue?.value?.value || '',
    user_id: filter.employee.activeValue?.value?.value || '',
    tracking_code: filter.invoiceInfo.activeValue || '',
  }

  const fetchInvoiceByFilter = async (qs, opt) => {
    pageDispatch({
      type: invoiceActions.TABLE_DISPLAY_LOADING_UPDATE,
      payload: { table: { display: { loading: true } } },
    })

    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }

    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/commission/tracking/trackings${queryString}`),
    ])

    if (!!response[0]?.data?.success) {
      pageDispatch({
        type: invoiceActions.OTHER_FILTER_APPLY,
        payload: {
          display: {
            list: response[0].data.data
          },
          pagination: {
            active: opt?.activePage || 0,
            amount: table.pagination.amount,
            total: response[0]?.data?.meta?.total
              ? Math.ceil(
                response[0]?.data?.meta?.total / table.pagination.amount,
              )
              : 0,
            totalItems: response[0]?.data?.meta?.total || 0,
          },
        },
      })

      pageDispatch({
        type: invoiceActions.TABLE_DISPLAY_LOADING_UPDATE,
        payload: { table: { display: { loading: false } } },
      })
    }

    pageDispatch({
      type: invoiceActions.TABLE_DISPLAY_DETAIL_ID_UPDATE,
      payload: { id: null },
    })
  }
  //=======Birthday========
  const valueBirthday = formEdit.paymentDate?.value
  const statusBirthday = formEdit.paymentDate?.status
  const messageBirthday = formEdit.paymentDate?.message

  const handleChangeBirthday = data => {
    pageDispatch({
      type: invoiceActions.FORM_INVOICE_UPDATE, payload: {
        paymentDate: {
          value: data,
          message: ''
        }
      }
    })
  }

  //=======payment reference========
  const valuePaymentReference = formEdit.paymentReference?.value
  const messagePaymentReference = formEdit.paymentReference?.message

  const handleChangePaymentReference = data => {
    pageDispatch({
      type: invoiceActions.FORM_INVOICE_UPDATE, payload: {
        paymentReference: {
          value: data,
          message: ''
        }
      }
    })
  }

  //======= payment Reason ========
  const valuePaymentReason = formEdit.paymentReason?.value
  const messagePaymentReason = formEdit.paymentReason?.message

  const handleChangePaymentReason = data => {
    pageDispatch({
      type: invoiceActions.FORM_INVOICE_UPDATE, payload: {
        paymentReason: {
          value: data,
          message: ''
        }
      }
    })
  }

  const handleClose = () => {
    if (!pageState.change_modal) {
      pageDispatch({ type: invoiceActions.DISPLAY_FORM_EDIT, payload: false })
      //reset modal
    } else {

      pageDispatch({ type: invoiceActions.MODAL_CONFIRM, payload: true })
    }
  }

  const checkBeforeSubmit = [
    pageState.valid?.filde_name?.status,
    pageState.valid?.filde_parent?.status,
  ].includes(true)
  const handleAccept = async () => {
    pageDispatch({
      type: invoiceActions.UPDATE_LOADING,
      payload: true
    })
    if (!checkBeforeSubmit) {
      const invoicePayment = {
        'payment_date': convertDateTimeToApiFormat(pageState?.formEdit?.paymentDate?.value?.formatValue),
        'payment_reference': pageState?.formEdit?.paymentReference?.value,
        'payment_reason': pageState?.formEdit?.paymentReason?.value,
      }
      try {
        const res = await sendRequestAuth('post',
          `${config.API}/commission/tracking/update/${pageState?.formEdit?.formId?.value}`
          , invoicePayment)
        if (res.data.success) {
          setAnimate(true)
          setTimeout(() => {
            pageDispatch({ type: invoiceActions.DISPLAY_FORM_EDIT, payload: false })
            setAnimate(false)
          }, 300)
          showAlert({
            type: 'success',
            content: 'Cập nhật phiếu chi hoa hồng thành công.'
          })
          fetchInvoiceByFilter(filterQueries, {})
        } else {
          errorResponse(res?.data?.errors)
          showAlert({
            type: 'danger',
            content: 'Cập nhật phiếu chi hoa hồng thất bại.'
          })
        }

        pageDispatch({
          type: invoiceActions.UPDATE_LOADING,
          payload: false
        })
      } catch (e) {
        console.log(e)
        showAlert({
          type: 'danger',
          content: 'Cập nhật phiếu chi hoa hồng thất bại.'
        })
      }

      pageDispatch({
        type: invoiceActions.UPDATE_LOADING,
        payload: false
      })
    }
  }
  const errorResponse = response => {
    response?.map(res => {
      switch (res.field) {
        case 'title':
          pageDispatch({
            type: invoiceActions.VALID_NAME_PRODUCT,
            payload: { status: true, message: res.message }
          })
          break
        case 'parent':
          pageDispatch({
            type: invoiceActions.VALID_PARENT_CATEGORY,
            payload: { status: true, message: res.message }
          })
          break
        default:
          break
      }
    })
  }
  const getDetailProduct = async productId => {
    try {
      const res = await getData(detailProductGroup(pageState.id_product || productId))
      if (res.data.success) {
        let detail = res.data.data

      }
    } catch (e) {
      console.log(e)
    }
  }
  const handleCancelConfirm = () => {
    pageDispatch({ type: invoiceActions.MODAL_CONFIRM, payload: false })
  }
  const handleAcceptConfirm = () => {
    pageDispatch({ type: invoiceActions.MODAL_CONFIRM, payload: false })
    pageDispatch({ type: invoiceActions.CHANGE_MODAL, payload: false })
    setAnimate(true)
    setTimeout(() => {
      pageDispatch({ type: invoiceActions.OPEN_MODAL, payload: false })
      setAnimate(false)
    }, 300)

    //reset modal
  }
  return {
    modal: {
      handleAccept,
      handleClose,
      checkBeforeSubmit,
    },
    confirm: {
      handleCancelConfirm,
      handleAcceptConfirm,
    },
    animate,
    getDetailProduct,
    paymentDate: {
      value: valueBirthday,
      message: messageBirthday,
      methods: {
        onChange: handleChangeBirthday,
      }
    },
    paymentReference: {
      value: valuePaymentReference,
      message: messagePaymentReference,
      methods: {
        onChange: handleChangePaymentReference,
      }
    },
    paymentReason: {
      value: valuePaymentReason,
      message: messagePaymentReason,
      methods: {
        onChange: handleChangePaymentReason,
      }
    },
  }
}