import { bookManagementActions } from './_init'

export const bookManagementReducer = (state, action) => {
  switch (action.type) {
    case bookManagementActions.FILTER_SEARCH_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          search: {
            ...state.filter.search,
            value: action.payload
          },
        },
      }

    case bookManagementActions.FILTER_SEARCH_ACTIVE_VALUE_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          search: {
            ...state.filter.search,
            activeValue: action.payload,
          },
        },
      }
    case bookManagementActions.FILTER_DATE_TIME_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          dateTime: {
            ...state.filter.dateTime,
            end: action.payload?.end,
            start: action.payload?.start,
            type: action.payload?.type,
            value: action.payload?.value,
          },
        },
      }
    case bookManagementActions.FILTER_DATE_TIME_ACTIVE_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          dateTime: {
            ...state.filter.dateTime,
            activeValue: {
              ...state.filter.dateTime.activeValue,
              end: action.payload?.end,
              start: action.payload?.start,
              type: action.payload?.type,
              value: action.payload?.value,
            }
          },
        },
      }
    case bookManagementActions.FILTER_CATEGORY_LIST_ORIGIN_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          category: {
            ...state.filter.category,
            list: action.payload?.list,
            listOrigin: action.payload?.listOrigin,
          },
        },
      }
    case bookManagementActions.FILTER_CATEGORY_LIST_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          category: {
            ...state.filter.category,
            list: action.payload,
          },
        },
      }
    case bookManagementActions.FILTER_CATEGORY_VALUE_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          category: {
            ...state.filter.category,
            value: action.payload,
          },
        },
      }
    case bookManagementActions.FILTER_CATEGORY_ACTIVE_VALUE_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          category: {
            ...state.filter.category,
            activeValue: action.payload,
          },
        },
      }
    case bookManagementActions.FILTER_SOURCE_LIST_ORIGIN_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          source: {
            ...state.filter.source,
            list: action.payload?.list,
            listOrigin: action.payload?.listOrigin,
          },
        },
      }
    case bookManagementActions.FILTER_SOURCE_LIST_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          source: {
            ...state.filter.source,
            list: action.payload,
          },
        },
      }
    case bookManagementActions.FILTER_SOURCE_VALUE_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          source: {
            ...state.filter.source,
            value: action.payload,
          },
        },
      }
    case bookManagementActions.FILTER_SOURCE_ACTIVE_VALUE_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          source: {
            ...state.filter.source,
            activeValue: action.payload,
          },
        },
      }
    case bookManagementActions.FILTER_SOURCE_TAB_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          source: {
            ...state.filter.source,
            tab: action.payload.tab,
            list: action.payload.list,
          },
        },
      }
    case bookManagementActions.FILTER_STATUS_VALUE_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          status: {
            ...state.filter.status,
            value: action.payload,
          },
        },
      }
    case bookManagementActions.FILTER_STATUS_ACTIVE_VALUE_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          status: {
            ...state.filter.status,
            activeValue: action.payload,
          },
        },
      }
    case bookManagementActions.PANEL_STORE_TOTAL:
      return {
        ...state,
        panels: {
          ...state.panels,
          storeTotal: action.payload,
        }
      }
    case bookManagementActions.PANEL_ORDER_TOTAL:
      return {
        ...state,
        panels: {
          ...state.panels,
          orderTotal: action.payload,
        }
      }

    case bookManagementActions.TABLE_LOADING_DISPLAY:
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state?.table?.display,
            loading: action?.payload
          }
        },
      }
    case bookManagementActions.TABLE_UPDATE_DISPLAY_LIST:
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state?.table?.display,
            list: action?.payload?.list || [],
            listDefault: action?.payload?.listDefault || [],
            loading: action?.payload?.loading
          }
        },
      }
    case bookManagementActions.TABLE_UPDATE_PAGINATION:
      return {
        ...state,
        table: {
          ...state.table,
          pagination: {
            ...state.table.pagination,
            active: action.payload?.active || 0,
            amount: action.payload?.amount || 20,
            total: action.payload?.total || 0,
            totalItems: action.payload?.totalItems || 0,
          },
        },
      }
    case bookManagementActions.TABLE_DISPLAY_DETAIL_ID_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          detail: {
            ...state.table.detail,
            id: action.payload?.id || null,
          },
        },
      }
    case bookManagementActions.TABLE_DISPLAY_DETAIL_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          detail: {
            ...state.table.detail,
            active: action.payload?.active || null,
            list: action.payload?.list || [],
          },
        },
      }
    case bookManagementActions.TABLE_SELECTED_LIST_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          selected: {
            ...state.table.selected,
            list: action.payload?.selected?.list || [],
          },
        },
      }
    case bookManagementActions.MODAL_DELETE_PRODUCT:
      return {
        ...state,
        delete: {
          ...state.delete,
          ...action.payload,
        },
      }

    case bookManagementActions.MODAL_IMPORT_PRINCIPAL:
      return {
        ...state,
        importExcel: {
          ...state.importExcel,
          open: action.payload
        }
      }
    case bookManagementActions.MODAL_IMPORT_RESULT_OPEN:
      return {
        ...state,
        importExcel: {
          ...state.importExcel,
          result:{
            ...state.importExcel.result,
            open:action.payload?.open,
            data: action.payload?.data || null
          }
        }
      }
    case bookManagementActions.MODAL_IMPORT_RESULT_DATA:
      return {
        ...state,
        importExcel: {
          ...state.importExcel,
          result:{
            ...state.importExcel.result,
            data:action.payload
          }
        }
      }
    default:
      break
  }
}