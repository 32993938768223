import React from "react";
import {Input} from "../../../../../../Component/Input";
import {Text} from "../../../../../../common/text";
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";
import {useCreatePrincipalForm} from "../../hook/useCreatePrincipalForm";
import {Option} from "../../../../../../common/form/autoComplete/_option";
import {AlternativeAutoComplete} from "../../../../../creatDigitalProduct/createProduct/conponents/infoBasic/~alternativeAutoComplete";
import {useTranslation} from "react-i18next";
import {STATUS_PRINCIPAL} from "../../interfaces/constant";

export const StatusPerson = ()=>{
    const {t} = useTranslation()
    const {statusPerson} = useCreatePrincipalForm()
    const {methods} = statusPerson
    return (
        <AlternativeAutoComplete
            // main input
            inputProps={{
                categoryList: [], // menu list in category dropdown
                categoryHidden: true,
                readOnly: true,
                categoryValue: { name: t('Vui lòng chọn trạng thái'), value: '' },
                label: <>
                    <div> Trạng thái <Text color={THEME_SEMANTICS.failed}>*</Text>
                    </div>
                </>,
                placeholder: t('Vui lòng chọn trạng thái'),
                value: statusPerson?.value?.title,
            }}
            hideSearchBar={true}
            // search input in dropdown menu
            menuProps={{
                empty: '',
            }}
            // search input in dropdown menu
            validateText={statusPerson?.message}
            validateType={statusPerson?.status ? 'danger' : 'success'}
        >
            {
                STATUS_PRINCIPAL?.map((item, index) => (
                    <Option
                        key={index}
                        className="principal__option-text"
                        data-active={statusPerson?.value?.value === item.value}
                        onClick={() => methods.onChange(item)}
                        style={{marginTop: 16, cursor:'pointer'}}
                    >
                        {item.title}
                    </Option>
                ))}
        </AlternativeAutoComplete>
    )
}