import React, { useCallback, useContext } from 'react'
import { EventStatisticContext } from '../provider/_context'
import config from '../../../config'
import { sendRequestAuth } from '../../../api/api'
import { TAG_DELETE } from '../interface/_contants'
import { eventStatisticActions } from '../provider/_init'
import { DateRangePicker } from 'rsuite'
import { convertDateTimeToApiFormat } from '../../../common/form/datePicker/_functions'
import { removeAcent } from '../../../common/fieldText/_functions'
import { Button } from '../../../common/button'
import { orderActions } from '../../orderBook/provider/_reducer'

const useEventStatisticFilter = () => {
  const { pageState, pageDispatch } = useContext(EventStatisticContext)
  const { filter, table } = pageState

  // ===== ===== ===== ===== =====
  // SEARCH
  // ===== ===== ===== ===== =====
  const searchValue = filter.search.value
  const searchActive = filter.search.active
  const handleSearchChange = (e) => {
    eventStatisticActions
    if (e == ' ') e = ''
    const keyword = e.replace(/\s+/g, ' ') || ''
    pageDispatch({
      type: eventStatisticActions.FILTER_SEARCH_UPDATE,
      payload: { value: keyword },
    })
  }

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // DATE TIME
  // ===== ===== ===== ===== =====
  const { afterToday } = DateRangePicker
  const dateTimeActiveValue = filter.dateTime.activeValue
  const dateTimeDefaultValue = [filter.dateTime.end, filter.dateTime.start]
  const dateTimeEnd = filter.dateTime.end
  const dateTimeStart = filter.dateTime.start
  const dateTimeType = filter.dateTime.type
  const dateTimeValue = filter.dateTime.value
  const dateTimeTrigger = filter.dateTime.trigger

  const handleDateTimeChange = data =>
    pageDispatch({
      type: eventStatisticActions.FILTER_DATE_TIME_UPDATE,
      payload: {
        end: data.value[1],
        start: data.value[0],
        type: data.category,
        value: data.formatValue,
      },
    })

// ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== =====
  // principal
  // ===== ===== ===== ===== =====
  const principalActiveValue = filter.principal.activeValue
  const principalKeyword = filter.principal.keyword
  const principalList = filter.principal.list
  const principalListOrigin = filter.principal.listOrigin
  const principalValue = filter.principal.value

  const handlePrincipalChange = async (data) => {
    pageDispatch({
      type: eventStatisticActions.FILTER_PRINCIPAL_UPDATE,
      payload: { value: data },
    })
    pageDispatch({
      type: eventStatisticActions.FILTER_TEACHER_UPDATE,
      payload: { value: null },
    })
    // get list teacher
    const response = await sendRequestAuth('get', `${config.API}/order/filter/teachers?principal_id=${data?.value}`)
    pageDispatch({
      type: eventStatisticActions.FILTER_TEACHER_LIST_UPDATE,
      payload: {
        teacher: {
          list: response.data?.map(item => ({
            name: item?.last_name + (!!item?.last_name ? ' ' : '') + item?.name || '',
            value: item?.id || '',
          })),
        }
      },
    })

  }

  const handlePrincipalKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const principalListData = principalListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: eventStatisticActions.FILTER_PRINCIPAL_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: principalListData,
      },
    })
  }
// ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // campaign
  // ===== ===== ===== ===== =====
  const campaignActiveValue = filter.campaign.activeValue
  const campaignKeyword = filter.campaign.keyword
  const campaignList = filter.campaign.list
  const campaignListOrigin = filter.campaign.listOrigin
  const campaignValue = filter.campaign.value
  const campaignTab = filter.campaign.tab
  const campaignLoading = filter.campaign.loading

  const handleCampaignChange = async (data) => {

    const find = campaignValue.find(item => item.value === data.value)
    const campaignListData =
      campaignTab === 'checked'
        ? campaignValue.filter(item => item.value !== data.value)
        : campaignList
    const campaignValueData = find
      ? campaignValue.filter(item => item.value !== data.value)
      : [...campaignValue, data]

    pageDispatch({
      type: eventStatisticActions.FILTER_CAMPAIGN_UPDATE,
      payload: {
        list: campaignListData,
        value: campaignValueData,
      },
    })
  }

  const handleCampaignResetInput = () => {
    pageDispatch({
      type: eventStatisticActions.FILTER_CAMPAIGN_UPDATE,
      payload: {
        list: campaignList,
        value: [],
      },
    })
  }
  const handleCampaignKeywordChange = data => {
    const formatDataValue = data?.value.trim()
      ? removeAcent(data?.value.trim()?.toLowerCase())
      : ''

    const campaignListData = campaignListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (
        formatNameItem.includes(formatDataValue) &&
        (data.category.value !== ''
          ? item.groups.includes(data.category.value)
          : true)
      )
        return true
      return false
    })

    pageDispatch({
      type: eventStatisticActions.FILTER_CAMPAIGN_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: campaignListData,
      },
    })
  }

  const fetchCampaign = async (keyword) => {
    const res = await sendRequestAuth(
      'get',
      `${
        config.API
      }/event/filter?keyword=${keyword}`,
    )
    return res
  }

  const handleCampaignTabChange = tab => {
    let campaignListData = []
    if (tab === 'checked') {
      campaignListData = campaignValue

      pageDispatch({
        type: eventStatisticActions.FILTER_CAMPAIGN_TAB_UPDATE,
        payload: { list: campaignListData, tab },
      })
    } else {
      const response = fetchCampaign(campaignKeyword)
      response.then(res => {
        if (res?.data?.length >= 0) {
          const campaignListData = res?.data || []

          pageDispatch({
            type: eventStatisticActions.FILTER_CAMPAIGN_KEYWORD_UPDATE,
            payload: {
              isFetchNew: true,
              keyword: campaignKeyword,
              list: campaignListData.map(item => ({
                data: item || null,
                name: item?.title || null,
                value: item?.id || '',
              })),
              tab,
            },
          })
        }
      })
    }
  }
// ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== =====
  // Teacher
  // ===== ===== ===== ===== =====
  const teacherActiveValue = filter.teacher.activeValue
  const teacherKeyword = filter.teacher.keyword
  const teacherList = filter.teacher.list
  const teacherListOrigin = filter.teacher.listOrigin
  const teacherValue = filter.teacher.value

  const handleTeacherChange = data =>
    pageDispatch({
      type: eventStatisticActions.FILTER_TEACHER_UPDATE,
      payload: { value: data },
    })

  const handleTeacherKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const teacherListData = teacherListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: eventStatisticActions.FILTER_TEACHER_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: teacherListData,
      },
    })
  }

  // ===== ===== ===== ===== =====
  // STATUS FILTER
  // ===== ===== ===== ===== =====
  const customerTypeValue = filter?.customerType?.value
  const customerTypeActiveValue = filter?.customerType?.activeValue

  const handleStatusChange = value => {
    pageDispatch({
      type: eventStatisticActions.FILTER_STATUS_VALUE_UPDATE,
      payload: value
    })
  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // SUBMIT FILTER
  // ===== ===== ===== ===== =====
  // Badge
  const otherFilterBadge = [
    !!customerTypeActiveValue,
  ].filter(item => item === true).length

  const shouldShowResetAll = [
    !!customerTypeActiveValue,
    !!searchActive
  ].includes(true)

  const canSubmitOtherFilter = [
    JSON.stringify(customerTypeValue) !== JSON.stringify(customerTypeActiveValue),
    JSON.stringify(filter?.search?.value) !== JSON.stringify(filter?.search?.active)
  ].includes(true)
  const queries = {
    per_page: table?.pagination?.amount || 20,
    principal_id: principalValue?.value || '',
    teacher_id: teacherValue?.value || '',
    event_ids: Array.isArray(campaignValue)
      ? campaignValue.map(item => item?.value).join(',')
      : '',
    customer_type: customerTypeValue?.name ? customerTypeValue.value : '',
    start_date:
      dateTimeStart && dateTimeValue
        ? convertDateTimeToApiFormat(dateTimeValue.split(' - ')[0])
        : '',
    end_date:
      dateTimeEnd && dateTimeValue
        ? convertDateTimeToApiFormat(dateTimeValue.split(' - ')[1])
        : '',
    start: 0
  }

  const fetchEventByFilter = async (qs, opt) => {
    pageDispatch({
      type: eventStatisticActions.TABLE_LOADING_DISPLAY,
      payload: true
    })
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/event/report/customers${queryString}`),
    ])
    if (response[0]?.data?.success) {
      pageDispatch({
        type: eventStatisticActions.TABLE_LOADING_DISPLAY,
        payload: false
      })
      const events = response[0]?.data
      pageDispatch({
        type: eventStatisticActions.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: events?.data,
          listDefault: events?.data,
          loading: false
        }
      })
      pageDispatch({
        type: eventStatisticActions.TABLE_UPDATE_PAGINATION,
        payload: {
          active: 0,
          amount: events?.meta?.per_page,
          total: Math.ceil(events?.meta?.total / events?.meta?.per_page),
          totalItems: events?.meta?.total,
        }
      })
    }
  }

  const fetchChartEventByFilter = async (qs, opt) => {
    pageDispatch({
      type: eventStatisticActions.SET_LOADING_CHART,
      payload: true
    })
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/event/report/charts${queryString}`),
    ])
    if (response[0]?.data?.length >= 0) {
      const charts = response[0]?.data
      pageDispatch({
        type: eventStatisticActions.TABLE_UPDATE_DISPLAY_CHART,
        payload: {
          title: charts.length > 0 ? charts.map(chart => chart.title) : [],
          hasReferral: charts.length > 0 ? charts.map(chart => chart.customer_has_referral) : [],
          nonReferral: charts.length > 0 ? charts.map(chart => chart.customer_non_referral) : [],
        }
      })

      pageDispatch({
        type: eventStatisticActions.SET_LOADING_CHART,
        payload: false
      })
    }
    pageDispatch({
      type: eventStatisticActions.SET_LOADING_CHART,
      payload: false
    })
  }
  const applyOtherFilter = async (pageOption = 1) => {
    pageDispatch({
      type: eventStatisticActions.TABLE_LOADING_DISPLAY,
      payload: true
    })
    if (pageOption.id === 1)
      fetchEventByFilter(queries)
    else fetchChartEventByFilter(queries)

    pageDispatch({ type: eventStatisticActions.FILTER_ACTIVE_VALUE_UPDATE })

  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  const filterTagDelete = (type) => {
    queries.customer_type = ''
    pageDispatch({
      type: eventStatisticActions.TABLE_LOADING_DISPLAY,
      payload: true
    })

    let qs = { ...queries }
    switch (type) {

      case 'dateTime.current':
        qs = {
          ...qs,
          date_type: '',
          start_date: '',
          end_date: '',
        }
        pageDispatch({
          type: eventStatisticActions.FILTER_DATE_TIME_TRIGGER_UPDATE,
          payload: { trigger: dateTimeTrigger === null ? true : !dateTimeTrigger, },
        })
        pageDispatch({
          type: eventStatisticActions.FILTER_TAG_DELETE,
          payload: 'dateTime.current'
        })

        break
      case TAG_DELETE[0]:
        qs = { ...qs, event_ids: '' }
        pageDispatch({
          type: eventStatisticActions.FILTER_TAG_DELETE,
          payload: type
        })
        break
      case TAG_DELETE[1]:
        qs = { ...qs, principal_id: '' }
        pageDispatch({
          type: eventStatisticActions.FILTER_TAG_DELETE,
          payload: type
        })
        break
      case TAG_DELETE[2]:
        qs = { ...qs, teacher_id: '' }
        pageDispatch({
          type: eventStatisticActions.FILTER_TAG_DELETE,
          payload: type
        })
        break
      case TAG_DELETE[3]:
        qs = { ...qs, customer_type: '' }
        pageDispatch({
          type: eventStatisticActions.FILTER_TAG_DELETE,
          payload: type
        })
        break
      case TAG_DELETE[4]:
        qs = { ...qs, customerType: '' }
        pageDispatch({
          type: eventStatisticActions.FILTER_TAG_DELETE,
          payload: type
        })
        break
      default:
        break
    }

    fetchEventByFilter(qs)
  }
  const filterTagDeleteAll = () => {
    const { pageOption } = pageState
    TAG_DELETE.forEach(each => pageDispatch({
      type: eventStatisticActions.FILTER_TAG_DELETE,
      payload: each
    }))

    if (pageOption.id === 1)
      fetchEventByFilter({
        ...queries,
        date_type: 'created',
        start_date: '',
        end_date: '',
        keyword: '',
        customer_type: '',
        principal_id: '',
        teacher_id: '',
        event_ids: '',
      })
    else fetchChartEventByFilter({
      ...queries,
      date_type: 'created',
      start_date: '',
      end_date: '',
      keyword: '',
      customer_type: '',
      principal_id: '',
      teacher_id: '',
      event_ids: '',
    })

  }
  return {
    queries: queries,
    search: {
      value: searchValue,
      active: filter?.search?.active,
      onChange: handleSearchChange,
    },
    dateTime: {
      activeValue: dateTimeActiveValue,
      defaultValue: dateTimeDefaultValue,
      disabledDate: afterToday(),
      triggerDefault: dateTimeTrigger,
      value: dateTimeValue,
      onChange: handleDateTimeChange,
    },

    campaign: {
      activeValue: campaignActiveValue,
      keyword: campaignKeyword,
      list: campaignList,
      value: campaignValue,
      loading: campaignLoading,
      tab: campaignTab,
      onChange: handleCampaignChange,
      onKeywordChange: handleCampaignKeywordChange,
      onInputReset: handleCampaignResetInput,
      onTabChange: handleCampaignTabChange
    },

    principal: {
      activeValue: principalActiveValue,
      keyword: principalKeyword,
      list: principalList,
      value: principalValue,
      onChange: handlePrincipalChange,
      onKeywordChange: handlePrincipalKeywordChange,
    },
    teacher: {
      activeValue: teacherActiveValue,
      keyword: teacherKeyword,
      list: teacherList,
      listOrigin: teacherListOrigin,
      value: teacherValue,
      onChange: handleTeacherChange,
      onKeywordChange: handleTeacherKeywordChange,
    },
    customerTypeFilter: {
      value: customerTypeValue,
      customerTypeValue: customerTypeActiveValue,
      activeValue: customerTypeActiveValue,
      onChange: handleStatusChange,
    },
    badge: {
      others: otherFilterBadge,
    },
    methods: {
      applyOtherFilter,
      filterTagDelete,
      filterTagDeleteAll,
      hasFilter: () => [
        !!dateTimeActiveValue?.value,
        !!principalActiveValue?.name,
        !!teacherActiveValue?.name,
        !!customerTypeActiveValue?.name,
        !!campaignActiveValue?.value,
      ].includes(true),
    },
    canSubmitOtherFilter,
    shouldShowResetAll,
  }
}

export default useEventStatisticFilter