import {AlternativeAutoComplete} from 'common/form/autoComplete/_alternativeAutoComplete'
import {Option} from 'common/form/autoComplete/_option'
import useRevenueFilterForm from 'Pages/revenue/hooks/useRevenueFilterForm'

export const RevenuePaymentMethod = () => {
  const {paymentMethod} = useRevenueFilterForm()

  return (
    <AlternativeAutoComplete
      className="revenue-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: {name: 'Hình thức thanh toán', value: ''}, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 146,
        placeholder: 'Chọn hình thức thanh toán',
        readOnly: true,
        value: paymentMethod.value?.name || '',
        onIconClick: () => paymentMethod.onChange(null),
      }}
      // menu
      hideSearchBar={true}
    >
      {paymentMethod.list.length > 0 &&
        paymentMethod.list.map(item => (
          <Option
            key={item.value}
            className="revenue-filter-form__option-text"
            data-active={item.value === paymentMethod.value?.value}
            onClick={() => paymentMethod.onChange(item)}
          >
            {item.name}
          </Option>
        ))}
    </AlternativeAutoComplete>
  )
}
