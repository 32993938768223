import React from 'react'
import { useTranslation } from 'react-i18next'
import {AlternativeAutoComplete} from "../../../../common/form/autoComplete/_alternativeAutoComplete";
import {Option} from "../../../../common/form/autoComplete/_option";
import {LICENSE_STATUS} from "../../interface/_const";
import {useLicenseKeyFilter} from "../../hook/useLicenseKeyFilter";

const LicenseKeyStatus = () => {
  const { t } = useTranslation()
    const {status} = useLicenseKeyFilter()
  return (
    <AlternativeAutoComplete
      className="license-key-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: { name: t('general_status'), value: '' }, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 90,
        placeholder: t('general_select_status'),
        readOnly: true,
        value: t(status?.value?.name) || '',
        // onIconClick: () => statusFilter.onChange(null),
      }}
      hideSearchBar={true}
    >
      {LICENSE_STATUS?.map(item => (
        <Option
          key={item.value}
          className="license-key-filter-form__option-text"
          data-active={item.value === status?.value?.value}
          onClick={() => status.onChangeValue(item)}
        >
          {t(item.name)}
        </Option>
      ))}
    </AlternativeAutoComplete>
  )
}

export default LicenseKeyStatus
