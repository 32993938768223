import React, { useEffect, useRef, useState } from 'react'
import { Text } from 'common/text'
import styled from 'styled-components'
import { DatePicker } from 'rsuite'
import { useModal } from '../../hooks/useModal'
import { GLOBAL_ICONS } from '../../../../interface/icon'
import { CategoryDatePicker } from '../../../../common/form/datePicker'

export const DateNote = () => {
  const { paymentDate } = useModal()
  const { methods } = paymentDate
  const positionRef = useRef(null)
  return (
    <StyleBirthday>
      <Text>Ngày ghi sổ</Text>

      <CategoryDatePicker
        ref={positionRef}
        onChange={methods.onChange}
        onTab={false}
        format={'dd/MM/yyyy'}
        datePickerProps={{
          placement: 'bottomEnd',
          defaultValue: paymentDate?.value || null,
        }}
        trigger={!!paymentDate?.trigger}
        inputProps={{
          editable: 'false',
        }}
        className={`content-request__item-input`}
        style={{ zIndex: 999999, width: '100%', marginTop: '8px' }}
      />

    </StyleBirthday>
  )
}
const StyleBirthday = styled.div`
.principal_paymentDate{
    width: 100%;
    height: 34px;
    padding: 0px 21px 0px 11px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(235, 238, 245);
    border-radius: 6px;
    color: rgb(0, 8, 29);
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    transition: all 0.25s ease 0s;
    margin-top: 8px;
    position: relative;
    &-date{
        opacity: 0;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 12;
    }
    &-icon{
        position: absolute;
        top: 9px;
        right: 16px;
        pointer-events: none;
        cursor: pointer;
    }
    &-placehodaler{
        position: absolute;
        top: 5px;
        left: 16px;
    }
}
`