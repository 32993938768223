import { DISPLAY_NAME_MENU } from '../../../const/display_name_menu'

export const CREATE_PRODUCT_CONSTANTS = {
  header: {
    breadcrumb: [
      { id: 1, name: 'Sản phẩm số', url: '#' },
      { id: 2, name: 'Tạo mới sản phẩm số', url: '#' },
    ],
  },
}

export const OPTION_MINIAPP_SEND_KEY = [
  { name: 'Gửi mã khi hoàn thành đơn hàng', value: 1 },
  { name: 'Không gửi mã khi hoàn thành đơn hàng', value: 0 }]

export const OPTION_MINIAPP_ACTIVE = [
  { name: 'Không kích hoạt', value: 0 },
  { name: 'Kích hoạt qua Edufun', value: 1 },
  { name: 'Kích hoạt qua Eduhome', value: 2 },
  { name: 'Kích hoạt qua Itest ', value: 3 },
  ]
export const EDIT_PRODUCT_CONSTANTS = {
  header: {
    breadcrumb: [
      { id: 1, name: 'Sản phẩm số', url: '#' },
      { id: 2, name: 'Chỉnh sửa sản phẩm số', url: '#' },
    ],
  },
}

export const BACKGOUND_PRODUCT = [
  { id: 1, title: 'Bìa cứng', value: 'hardcover' },
  { id: 2, title: 'Bìa mềm', value: 'paperback' },
]

export const CREATE_PRODUCT_REDIRECT_CONSTANTS = [
  { id: 'basic', name: DISPLAY_NAME_MENU.PRODUCT_PAGE.BASIC_INFO, location: 70, active: true },
  { id: 'price', name: DISPLAY_NAME_MENU.PRODUCT_PAGE.PRICE_INFO, location: 420, active: false },
  { id: 'product', name: DISPLAY_NAME_MENU.PRODUCT_PAGE.PRODUCT_SPECIFICATIONS, location: 700, active: false },
  { id: 'active', name: 'Thông số kích hoạt', location: 960, active: false },
]

export const EDIT_SINGLE_PRODUCT_REDIRECT_CONSTANTS = [
  { id: 'basic', name: DISPLAY_NAME_MENU.PRODUCT_PAGE.BASIC_INFO, location: 70, active: true },
  { id: 'price', name: DISPLAY_NAME_MENU.PRODUCT_PAGE.PRICE_INFO, location: 420, active: false },
  { id: 'product', name: DISPLAY_NAME_MENU.PRODUCT_PAGE.PRODUCT_SPECIFICATIONS, location: 700, active: false },
  { id: 'active', name: 'Thông số kích hoạt', location: 960, active: false },
]

export const EDIT_MULTIPLE_PRODUCT_REDIRECT_CONSTANTS = [
  { id: 'basic', name: DISPLAY_NAME_MENU.PRODUCT_PAGE.BASIC_INFO, location: 70, active: true },
  { id: 'price', name: DISPLAY_NAME_MENU.PRODUCT_PAGE.PRICE_INFO, location: 420, active: false },
  { id: 'product', name: DISPLAY_NAME_MENU.PRODUCT_PAGE.PRODUCT_SPECIFICATIONS, location: 700, active: false },
  { id: 'active', name: 'Thông số kích hoạt', location: 960, active: false },
]