import { Text } from 'common/text'
import { StyledRevenueEmpty } from './_styled'

export const RevenueEmpty = ({ ...props }) => {
  return (
    <StyledRevenueEmpty {...props}>
      <img
        className="revenue-empty__banner"
        src="/img/order/order-empty.png"
        alt="empty"
      />
      <Text as="b" color="#7C88A6" style={{ marginBottom: 16 }}> Không tìm thấy dữ liệu </Text>
    </StyledRevenueEmpty>
  )
}
