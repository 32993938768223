import { convertDateTimeToApiFormat, formatDatetime } from 'common/form/datePicker/_functions'

export const bannerManagementInitialState = {
  filter: {
    search: {
      value: ''
    },
    parentCategory: {
      list: [],
      listOrigin: [],
      activeValue: null,
      value: null
    },
    status: null
  },
  delete: {
    open: false,
    id: ''
  },
  table: {
    display: {
      list: [],
      listDefault: [],
      loading: true,
    },
    pagination: {
      active: 0,
      amount: 20,
      total: 3,
      totalItems: 59,
    },
    selected: {
      list: [],
    },
    detail: {
      id: null,
      active: null,
      list: [],
    },
  },
  create: {
    title: {
      value: null,
      validate: null,
    },
    image: {
      link: null,
      file: null
    },
    startedDate: {
      value: new Date(),
      originValue: new Date(),
      formatValue:  convertDateTimeToApiFormat(formatDatetime(new Date())),
      originFormatValue:  convertDateTimeToApiFormat(formatDatetime(new Date()))
    },
    endedDate: {
      value: null,
      originValue: null,
      formatValue:  null,
      originFormatValue:  null
    },
    status: 1,
    ordering: null,
    position: null,
    description: null,
    color: null,
    linkTo: null,
    category: {
      list: [],
      listOrigin: [],
      value: null,
      keyword: '',
    },
    product: {
      list: [],
      listOrigin: [],
      value: null,
      keyword: '',
    },
    validate: null
  },
  open_modal: false,
  modal_confirm: false,
  change_modal: false,
  idBanner: null,
  loading: false
}

export const bannerManagementActions = {
  SET_LOADING: 'SET_LOADING',
  PANEL_STORE_TOTAL: 'PANEL_STORE_TOTAL',
  PANEL_ORDER_TOTAL: 'PANEL_ORDER_TOTAL',

  TABLE_LOADING_DISPLAY: 'TABLE_LOADING_DISPLAY',
  TABLE_UPDATE_DISPLAY_LIST: 'TABLE_UPDATE_DISPLAY_LIST',
  TABLE_UPDATE_PAGINATION: 'TABLE_UPDATE_PAGINATION',
  TABLE_DISPLAY_DETAIL_ID_UPDATE: 'TABLE_DISPLAY_DETAIL_ID_UPDATE',
  TABLE_DISPLAY_DETAIL_UPDATE: 'TABLE_DISPLAY_DETAIL_UPDATE',
  TABLE_SELECTED_LIST_UPDATE: 'TABLE_SELECTED_LIST_UPDATE',
  MODAL_DELETE_PRODUCT: 'MODAL_DELETE_PRODUCT',
  OPEN_MODAL: "OPEN_MODAL",
  MODAL_CONFIRM: "MODAL_CONFIRM",
  CHANGE_MODAL: "CHANGE_MODAL",
  UPDATE_FORM_CREATE: "UPDATE_FORM_CREATE",
  SET_ID: "SET_ID",

  FILTER_SEARCH_UPDATE: 'FILTER_SEARCH_UPDATE',
  FILTER_SEARCH_ACTIVE_UPDATE: 'FILTER_SEARCH_ACTIVE_UPDATE',
  FILTER_STATUS_VALUE_UPDATE: 'FILTER_STATUS_VALUE_UPDATE',
  FILTER_POSITION_ACTIVE_VALUE_UPDATE: 'FILTER_POSITION_ACTIVE_VALUE_UPDATE',
  FILTER_POSITION_VALUE_UPDATE: 'FILTER_POSITION_VALUE_UPDATE',
  FILTER_STATUS_ACTIVE_VALUE_UPDATE: 'FILTER_STATUS_ACTIVE_VALUE_UPDATE',
  FILTER_TAG_DELETE: 'FILTER_TAG_DELETE',
  FILTER_CATEGORY: 'FILTER_CATEGORY',
  FILTER_CATEGORY_UPDATE: 'FILTER_CATEGORY_UPDATE',
  FILTER_CATEGORY_KEYWORD_UPDATE: 'FILTER_CATEGORY_KEYWORD_UPDATE',
  FILTER_CATEGORY_ACTIVE_VALUE_UPDATE: 'FILTER_CATEGORY_ACTIVE_VALUE_UPDATE',

  CREATE_BANNER_CATEGORY_UPDATE: 'CREATE_BANNER_CATEGORY_UPDATE',
  CREATE_BANNER_PRODUCT_UPDATE: 'CREATE_BANNER_PRODUCT_UPDATE',
  CREATE_BANNER_LINK_TO_UPDATE: 'CREATE_BANNER_LINK_TO_UPDATE',
  CREATE_BANNER_DESCRIPTION_UPDATE: 'CREATE_BANNER_DESCRIPTION_UPDATE',
  CREATE_BANNER_COLOR_UPDATE: 'CREATE_BANNER_COLOR_UPDATE',
  CREATE_BANNER_POSITION_UPDATE: 'CREATE_BANNER_POSITION_UPDATE',
  CREATE_BANNER_VALIDATE_UPDATE: 'CREATE_BANNER_VALIDATE_UPDATE',
}