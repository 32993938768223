export const PATH = {
  HOME: '/',
  NOT_FOUND: '/404',
  NO_CONNECTION: '/no-connection',
  ACCOUNT_VERIFY: '/account-verify',
  ADMIN_DASHBOAR: '/dashboard',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  UPDATE_PROFILE: '/profile',

  USER_MANAGEMENT: '/users',
  CREATE_USER: '/users/create',
  UPDATE_USER: '/users/edit/:userId',
  USER_ROLE: '/user/groups',
  USER_ROLE_CREATE: '/user/groups/create',
  USER_ROLE_EDIT: '/user/groups/edit/:id',

  NOT_PERMISSION: '/not-permission',

  CATEGORY: '/category',

  PRODUCT: '/product',
  CREATE_PRODUCT: '/product/create',
  DETAIL_PRODUCT: '/product/edit/:productId',
  DIGITAL_PRODUCT: '/digital-product',
  CREATE_DIGITAL_PRODUCT: '/digital-product/create',
  DETAIL_DIGITAL_PRODUCT: '/digital-product/edit/:productId',

  ORDER_BOOK: '/order/books',
  ORDER_DIGITAL: '/order/digital',
  CREATE_ORDER: '/order/create',
  EDIT_ORDER_BOOK: '/order/:orderId',
  HISTORY_ORDER_BOOK: '/order/history/:historyId',
  CREATE_DIGITAL_ORDER: '/digital-order/create',
  DETAIL_DIGITAL_ORDER: '/digital-order/:orderId',

  PRINCIPAL: '/principal',
  CREATE_PRINCIPAL: '/principal/create',
  DETAIL_PRINCIPAL: '/principal/:principalId',
  CUSTOMER: '/customer',
  CREATE_CUSTOMER: '/customer/create',
  DETAIL_CUSTOMER: '/customer/:teacherId',

  COMMISSION: '/commission',
  DETAIL_COMMISSION: '/commission/:commissionId',
  REVENUE: '/revenue',
  DETAIL_REVENUE: '/revenue/:revenueId',

  COMMISSION_VOUCHER: '/accountant/invoice-commission',
  CREATE_COMMISSION_VOUCHER: '/accountant/invoice-commission/create',
  BANNER: '/banners',
  EVENT: '/events',
  EVENT_REVENUE: '/statistic-events',
  KEY_PRODUCT: '/key-dproduct',
  CONFIG_PAYMENT: '/payment/config',
  CONFIG_NOTIFICATION: '/notification/config',
}
