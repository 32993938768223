import React, {memo} from "react";
import useProductGroup from "./hook/useProductGroup";
import {ProductGroupProvider} from "./provider";
import PageHeader from "./component/header/index";
import {TableLayout} from "layouts/tableLayout";
import ModalDelete from "./component/delete/index"
import ModalCreate from "./component/modal/index"
import FilterForm from "./component/filter";
import ProductGroupHeader from "./component/table/thead"
import ProductGroupBody from "./component/table/tbody"

const Index = memo(() => {
   const {provider,pagination} = useProductGroup()
   const {state, dispatch} = provider
   const {table} = state
   return (
      <ProductGroupProvider value={{pageState: state, pageDispatch: dispatch}}>
         <PageHeader/>
         {/*<SearchProduct/>*/}
         <TableLayout
            header={
               <FilterForm/>
            }
            table={{
               tHead: <ProductGroupHeader/>,
               tBody: <ProductGroupBody/>,
            }}
            pagination={{
               ...table.pagination,
               onAmountChange: pagination.onAmountChange,
               onPageChange: pagination.onPageChange,
            }}
         />
         {state.check_confirm_delete && <ModalDelete/>}
         {state.open_modal && <ModalCreate/>}
      </ProductGroupProvider>

   )
})
export default Index