import { PageHeader } from "layouts/pageHeader";
import React from "react";
import { useContext } from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {CREATE_PRINCIPAL_BREARDCUM, EDIT_PRINCIPAL_BREARDCUM} from "../../interfaces/constant";
import {CreatePrincipalContext} from "../../provider/_context";
import {useParams} from "react-router-dom";

const Index = () => {
    const {t} = useTranslation()
    const {principalId} = useParams()
    return (
        <StyledProductGroupHeader>
            <div className={"principal-header"} >
                <PageHeader
                    breadcrumbLinks={!!principalId ?  EDIT_PRINCIPAL_BREARDCUM : CREATE_PRINCIPAL_BREARDCUM}
                    breadcrumbTitle={ !!principalId ? 'Chỉnh sửa hiệu trưởng' : 'Thêm mới hiệu trưởng'}
                />
            </div>
        </StyledProductGroupHeader>
    )
}
export default Index;
const StyledProductGroupHeader = styled.div``