import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {AlternativeAutoComplete} from "../../../../common/form/autoComplete/_alternativeAutoComplete";
import {PRINCIPAL_STATUS} from "../../interface";
import {Option} from "../../../../common/form/autoComplete/_option";
import usePrincipalFilter from "../../hook/usePrincipalFilter";

const PrincipalStatus = () => {
  const { t } = useTranslation()
  const { statusFilter } = usePrincipalFilter()
  return (
    <AlternativeAutoComplete
      className="principal-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: { name: t('general_status'), value: '' }, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 90,
        placeholder: t('general_select_status'),
        readOnly: true,
        value: t(statusFilter?.value?.name) || '',
        onIconClick: () => statusFilter.onChange(null),
      }}
      hideSearchBar={true}

    >
      {PRINCIPAL_STATUS?.map(item => (
        <Option
          key={item.value}
          className="principal-filter-form__option-text"
          data-active={item.value === statusFilter?.value?.value}
          onClick={() => statusFilter.onChange(item)}
        >
          {t(item.name)}
        </Option>
      ))}
    </AlternativeAutoComplete>
  )
}

export default PrincipalStatus
