import React from 'react';
import useProductGroupFilter from "../../hook/useProductGroupFilter";
import {CategoryInput} from "../../../../common/form/input/_categoryInput";

const Search = () => {
  const {search} = useProductGroupFilter()
  return (
    <CategoryInput
      categoryValue={{name:'Danh mục', value:''}}
      categoryWidth={90}
      className="principal-filter-form__input-wide"
      placeholder={"Tìm kiếm theo tên danh mục"}
      value={search.value}
      onChange={e => search.onChange(e)}
    />
  )
}

export default Search