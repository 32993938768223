import {AlternativeAutoComplete} from 'common/form/autoComplete/_alternativeAutoComplete'
import {Option} from 'common/form/autoComplete/_option'
import useOrderFilterForm from 'Pages/orderBook/hooks/useOrderFilterForm'
import React from 'react'

export const PaymentStatus = () => {
  const {paymentStatus} = useOrderFilterForm()

  return (
    <AlternativeAutoComplete
      className="order-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: {name: 'Trạng thái thanh toán', value: ''}, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 150,
        placeholder: 'Chọn trạng thái thanh toán',
        readOnly: true,
        value: paymentStatus.value?.name || '',
        onIconClick: () => paymentStatus.onChange(null),
      }}
      // menu
      menuProps={{
        empty: paymentStatus.list.length <= 0 ? 'Không tìm thấy trạng thái thanh toán' : '',
      }}
      // search input in dropdown menu
      searchInputProps={{
        placeholder: 'Tìm kiếm trạng thái thanh toán',
        value: paymentStatus.keyword,
        onChange: paymentStatus.onKeywordChange,
      }}
      hideSearchBar={true}
    >
      {paymentStatus.list.length > 0 &&
        paymentStatus.list.map(item => (
          <Option
            key={item.value}
            className="order-filter-form__option-text"
            data-active={item.value === paymentStatus.value?.value}
            onClick={() => paymentStatus.onChange(item)}
          >
            {item.name}
          </Option>
        ))}
    </AlternativeAutoComplete>
  )
}
