import React, { useEffect } from 'react'
import { OrderProvider } from '../provider'
import '../components/orderTable/index.scss'
import useOrder from '../hooks/useOrder'
import useOrderFilterForm from '../hooks/useOrderFilterForm'
import { PageHeader } from '../components/orderPageHeader'
import PopupNotPermission from '../../permision/popupNotPermission'
import { useParams } from 'react-router-dom'
import { GridLayout } from '../../../layouts/gridLayout'
import { CustomerInfo } from '../components/formEdit/customerInfo'
import { ActionFormBtnList } from '../components/formEdit/actionFormBtnList'

export const EditOrderBook = () => {
  const { fetch, provider } = useOrder()
  const { orderId } = useParams()
  const { state, dispatch } = provider

  useEffect(() => {
    fetch.detail(orderId)
  }, [])
  return (
    <OrderProvider value={{ pageState: state, pageDispatch: dispatch }}>
      <GridLayout
        header={
          <Header/>
        }
        grid={[
          {
            width: 100,
            sections: [
              {
                title: "Thông tin khách hàng",
                props: {
                  style: {
                    position: 'relative',
                    padding: 24
                  },
                  children: <CustomerInfo/>,
                },
              },

              {
                type: 'sticky-bottom-transparent',
                props: {
                  style: {
                    position: 'sticky',
                    bottom: -44,
                    marginBottom: 0,
                    zIndex: 10,
                    padding: "12px 24px 0 12px"
                  },
                  children: <ActionFormBtnList />,
                },
              },
            ],
            props: { style: { position: 'relative' } },
          },
        ]}
        data-model="container"
      />
    </OrderProvider>
  )
}

const Header = () => {
  const { queries, functions } = useOrderFilterForm()

  // const [showPermission, setShowPermission] = useState(false)

  const { orderId } = useParams()
  return (
    <>
      {/*{showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)}/>}*/}
      <PageHeader
        breadcrumbLinks={
          [
            { id: 1, name: 'Trang chủ', url: '/' },
            { id: 2, name: 'Đơn hàng sách', url: '/order/books' },
            { id: 3, name: 'Đơn hàng #' + orderId, url: '#' },
          ]
        }
        breadcrumbTitle="Chỉnh sửa đơn hàng sách"
      />
    </>
  )
}
