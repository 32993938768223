import {GLOBAL_ICONS} from "../../../interface/icon";

export const ACTION_HEADER_LICENSE=[
    {
        id: 1,
        name: null,
        appearance: 'secondary',
        icon: GLOBAL_ICONS.repeat,
        tooltip: 'refresh_data',
    },
    {
        id: 2,
        name: 'Import key',
        appearance: 'secondary',
        icon: GLOBAL_ICONS.upload,
    },
    {
        id: 3,
        name: 'Xuất excel',
        icon: GLOBAL_ICONS.export,
        appearance: 'secondary',
    },
    {
        id: 4,
        name: 'Thêm mới key',
        appearance: 'primary',
        icon: GLOBAL_ICONS.plus,
    },
]
export const LICENSE_BREADCUM = [
    {id: 1, name: 'Home', url: '#'},
    {id: 2, name: 'Quản lý Key sản phẩm số', url: '#'}
]

export const LICENSE_STATUS=[
    {id: 1, name: 'Mới', value:'new'},
    {id: 2, name: 'Đã bán', value:'sold'},
]


export const FILTER_TAG=[
    'product name',
    'key code',
    'status'
]
export const LICENSE_COLUMN_NAMES = {
    columnNames: [
        'STT',
        'Mã sản phẩm (*)',
        'Tên sản phẩm',
        'Code (*)',
        'appId',
        'pack_name',
        'app_name',
        'Level',
        'quota_active',
    ],
    startRowNumber: 5,
};