import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import BannerStatus from './_status'
import {Button} from "../../../../common/button";
import {THEME_COLORS} from "../../../../common/theme/_colors"
import useEventStatisticFilter from "../../hooks/useEventStatisticFilter";
import Search from "./_search";
import {EventDateTime} from "./_eventDateTime";
import {sendRequestAuth} from "../../../../api/api";
import config from "../../../../config";
import {eventStatisticActions} from "../../provider/_init";
import {FilterTeacher} from "./_teacher";
import {FilterPrincipal} from "./_principal";
import {EventStatisticContext} from "../../provider/_context";
import {EventTags} from "./eventTags";
import {FilterCampaign} from "./_campaign";

const FilterForm = ({...props}) => {

   const {methods} = useEventStatisticFilter()
   const { pageState,pageDispatch } = useContext(EventStatisticContext)
   useEffect(() => {
      const fetchData = async () => {
         const response = await Promise.all([
            sendRequestAuth(
               'get',
               `${
                  config.API
               }/order/filter/principals?keyword=`,
            ),
            sendRequestAuth(
               'get',
               `${
                  config.API
               }/event/filter?keyword=`,
            )
         ])
         pageDispatch({
            type: eventStatisticActions.FILTER_ORIGIN_DATA_UPDATE,
            payload: {
               principal: {
                  list: response[0].data.map(item => ({
                     data: item || null,
                     name: (!!item?.last_name ? `${item?.last_name} ` : '' ) + (!!item?.name ? item?.name : ''),
                     value: item?.id || '',
                  })),
               },
               campaign: {
                  list: response[1].data.map(item => ({
                     data: item || null,
                     name: item?.title || null,
                     value: item?.id || '',
                  })),
               },
            },
         })
      }

      fetchData()
   }, [])
   return (
      <StyledBookManagementFilterForm {...props}>
         <div className="principal-filter-form__group">
            <FilterCampaign/>
            <FilterPrincipal/>
            <FilterTeacher/>
            <BannerStatus/>
            <EventDateTime/>
            <Button
               size="md-"
               style={{width: 101, marginLeft: '16px'}}
               onClick={() => methods.applyOtherFilter(pageState.pageOption)}
            >
               Tìm kiếm
            </Button>
         </div>
         <div className="principal-filter-form__group-tag">
            <EventTags/>
         </div>
      </StyledBookManagementFilterForm>
   )
}

export default FilterForm

export const StyledBookManagementFilterForm = styled.div`

margin-bottom: -16px;
.principal-filter-form {
    &__group {
      width: calc(100%);

      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & > * {
        margin: 16px 6px;
      }
    }

    &__collapse {
      max-height: 0;
      margin-bottom: 0 !important;

      overflow: hidden;

      transition: all 0.25s;

      &[data-collapse='true'] {
        max-height: 50vh;
        margin-bottom: 16px;

        overflow: unset;
      }
    }

    &__input-wide {
      width: calc(25% - 12px);
      margin: 0 6px;
    }

    &__option-text {
      min-height: 36px;

      display: flex;
      align-items: center;

      color: ${THEME_COLORS.gray_900};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      cursor: pointer;

      transition: color 0.25s;

      &[data-active='true'] {
        color: ${THEME_COLORS.primary_300};
        font-weight: 600;
      }
      &:hover {
        color: ${THEME_COLORS.primary_300};
      }
    }

    &__option-container {
      min-height: 45px;
      margin-bottom: 4px;

      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__option-tabs {
      position: sticky;
      top: 0;
      z-index: 1;

      width: 100%;
      height: 28px;
      margin-bottom: 16px;

      display: flex;
      align-items: center;

      background: #fff;

      &::before {
        position: absolute;
        top: -20px;
        left: -20px;

        width: calc(100% + 40px);
        height: calc(100% + 36px);

        background: #fff;
        border-radius: 8px 8px 0 0;

        content: '';
      }
    }

    &__option-tab {
      position: relative;
      z-index: 2;

      margin-right: 16px;

      color: #808089;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      cursor: pointer;

      &[data-active='true'] {
        color: ${THEME_COLORS.primary_300};
        font-weight: 600;

        cursor: default;
      }
    }

    &__collapse {
      .store-upos-filter-form__input-wide {
        margin-bottom: 16px;
      }
    }
  }
`
