import { sendRequestAuth } from 'api/api'
import { Button } from 'common/button'
import { Select } from 'common/form/select'
import { Option } from 'common/form/select/_option'
import { Text } from 'common/text'
import { THEME_COLORS } from 'common/theme/_colors'
import config from 'config'
import useAlert from 'hook/useAlert'
import useOrderFilterForm from 'Pages/orderBook/hooks/useOrderFilterForm'
import { ORDER_TABLE_ROW_EXTRA_TAB_PAYMENT_SUBMIT_MODAL_FIGURES } from 'Pages/orderBook/interfaces/_constants'
import { OrderContext } from 'Pages/orderBook/provider/_context'
import { orderActions } from 'Pages/orderBook/provider/_reducer'
import { useContext, useState } from 'react'
import styled from 'styled-components'
import { formatMoney, replaceAllCustom } from '../../../../util/functionUtil'

export const SubmitPaymentModal = ({ data, onClose, ...props }) => {
  const { showAlert } = useAlert()
  const { functions } = useOrderFilterForm()

  const PAYMENT_METHOD = data?.payment_method === 'VNPAY' ? [
    { value: 'VNPAY', name: 'VNPAY' },
    { value: 'COD', name: 'COD - Tiền mặt' },
    { value: 'BANK', name: 'COD - Chuyển Khoản' },
  ] : [
    { value: 'COD', name: 'COD - Tiền mặt' },
    { value: 'BANK', name: 'COD - Chuyển Khoản' },
  ]

  const [listPaymentStatus, setListPaymentStatus] = useState((data?.payment_method === 'VNPAY' && data?.payment_status == 'fail') ? [
    { value: 'paid', name: 'Đã thanh toán' },
    { value: 'fail', name: 'Thanh toán thất bại' },
  ] : [
    { value: 'unpaid', name: 'Chưa thanh toán' },
    { value: 'paid', name: 'Đã thanh toán' },
  ])
  const { pageState, pageDispatch } = useContext(OrderContext)
  const [paymentMethod, setPaymentMethodValue] = useState(PAYMENT_METHOD.find(item => item.value === data?.payment_method))
  const [paymentStatus, setpaymentStatus] = useState(listPaymentStatus.find(item => item.value === data?.payment_status))

  const paymentMethodChange = item => {
    setPaymentMethodValue(item)
    setListPaymentStatus(item.value !== 'VNPAY' ? [
      { value: 'unpaid', name: 'Chưa thanh toán' },
      { value: 'paid', name: 'Đã thanh toán' },
    ] : [
      { value: 'paid', name: 'Đã thanh toán' },
      { value: 'fail', name: 'Thanh toán thất bại' },
    ])
    setpaymentStatus(item.value !== 'VNPAY' ? { value: 'unpaid', name: 'Chưa thanh toán' } : {
      value: 'paid',
      name: 'Đã thanh toán'
    })
  }

  const paid = Number(data?.total_payment || 0)
  const total = Number(data?.total_amount || 0)

  const haveToPay = total - paid

  const canSubmit = !!paymentMethod?.name && paymentStatus?.value

  const valueList = [data?.id, formatMoney(total)]

  const figureList = ORDER_TABLE_ROW_EXTRA_TAB_PAYMENT_SUBMIT_MODAL_FIGURES.map(
    (item, i) => ({ ...item, value: valueList[i] || 0 }),
  )

  const fetchRowDetail = async () => {
    const response = await sendRequestAuth(
      'get',
      `${config.API}/order/detail/${data?.id}`,
    )

    if (!!response?.data?.success) {
      const newItem = response?.data?.data

      let newDetailList = [...pageState.table.detail.list]
      const findIndex = newDetailList.findIndex(item => item?.id === data?.id)
      if (findIndex !== -1) newDetailList[findIndex] = newItem

      pageDispatch({
        type: orderActions.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: {
          active: newItem,
          list: [...newDetailList],
        },
      })
    }
  }

  const handleSubmit = async () => {
    const response = await sendRequestAuth(
      'post',
      `${config.API}/order/confirm-payment/${data.id}`,
      JSON.stringify({
        payment_method: paymentMethod?.value,
        payment_status: paymentStatus?.value
      }),
    )

    if (response?.data?.success) {
      showAlert({
        content: paymentStatus?.value === 'unpaid' ? 'Cập nhật thành công' : 'Thanh toán thành công',
        type: 'success'
      })
      fetchRowDetail()
      functions.fetchUpdateData()
      if (onClose) onClose()
    } else showAlert({
      content: paymentStatus?.value === 'unpaid' ? 'Cập nhật thất bại' : 'Thanh toán thất bại',
      type: 'danger'
    })
  }

  return (
    <StyledSubmitPaymentModal {...props} onClick={onClose}>
      <div
        className="order-table__submit-payment-modal__container"
        onClick={e => e.stopPropagation()}
      >
        <div className="order-table__submit-payment-modal__header">
          <Text as="h2" fontSize={20} lineHeight={28}>
            XÁC NHẬN THANH TOÁN CHO ĐƠN HÀNG #{data.id}
          </Text>
        </div>
        <div className="order-table__submit-payment-modal__body">
          <div className="order-table__submit-payment-modal__figures">
            {figureList.map(item => (
              <div
                key={item.id}
                className="order-table__submit-payment-modal__figure-item"
              >
                <Text color="#7C88A6" style={{ display: 'block' }}>
                  {item.name}
                </Text>
                <Text as="b" color={item.color} fontSize={16} lineHeight={22}>
                  {item?.value}
                </Text>
              </div>
            ))}
          </div>
          <div className="order-table__submit-payment-modal__form">
            <div className="order-table__submit-payment-modal__input">
              <Select
                className="order-filter-form__input-wide"
                value={paymentMethod?.name}
              >
                {PAYMENT_METHOD.map(item => (
                  <Option
                    key={item.value}
                    className="order-filter-form__option-text common-scrollbar"
                    data-active={item.value === paymentMethod?.value}
                    onClick={() => paymentMethodChange(item)}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="order-table__submit-payment-modal__input">
              <Select
                className="order-filter-form__input-wide"
                value={paymentStatus?.name}
              >
                {listPaymentStatus.map(item => (
                  <Option
                    key={item.value}
                    className="order-filter-form__option-text common-scrollbar"
                    data-active={item.value === paymentStatus?.value}
                    onClick={() => setpaymentStatus(item)}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="order-table__submit-payment-modal__footer">
          <Button
            size="sm"
            appearance="ghost"
            style={{ minWidth: 110 }}
            onClick={onClose}
          >
            Đóng
          </Button>
          <Button
            disabled={!canSubmit}
            size="sm"
            style={{ minWidth: 110, marginLeft: 8 }}
            onClick={() => canSubmit && handleSubmit()}
          >
            Xác nhận
          </Button>
        </div>
      </div>
    </StyledSubmitPaymentModal>
  )
}

const StyledSubmitPaymentModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.25);
  .order-filter-form__option-text{
    margin-bottom: 16px;
    :last-child{
      margin-bottom: 0;
    }
  }
  .order-table__submit-payment-modal {
    &__container {
      width: 600px;
      padding: 24px;

      background: #ffffff;
      border-radius: 8px;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    }

    &__header {
      margin-bottom: 24px;
    }

    &__figures {
      width: calc(100% + 24px);
      margin: 0 -12px 8px -12px;

      display: flex;
      flex-wrap: wrap;
    }

    &__figure-item {
      width: calc(50% - 24px);
      margin: 0 12px 24px 12px;
    }

    &__form {
      width: calc(100% + 24px);
      margin: 0 -12px;

      display: flex;
      flex-wrap: wrap;
    }

    &__input {
      width: calc(50% - 24px);
      margin: 0 12px;
    }

    &__select {
      .select__menu {
        max-height: 150px;
      }
    }

    &__option {
      min-height: 36px;

      display: flex;
      align-items: center;

      color: ${THEME_COLORS.gray_900};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      cursor: pointer;

      transition: color 0.25s;

      &[data-active='true'],
      &:hover {
        color: ${THEME_COLORS.primary_300};
      }
    }

    &__footer {
      margin-top: 40px;

      display: flex;
      justify-content: flex-end;
    }
  }
  .select__menu {
    font-size: 14px;
    padding: 16px;
    height: auto;
  }
`
