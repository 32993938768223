import React, {useEffect} from 'react';
import {GridLayout} from "../../layouts/gridLayout";
import {PageHeader} from "../../layouts/pageHeader";
import {CREATE_PRODUCT_CONSTANTS, EDIT_PRODUCT_CONSTANTS} from "./interfaces/~constants";
import styled from "styled-components";
import ActionFormBrnList from "./createProduct/conponents/actionFormBrnList";
import InfoBasic from "./createProduct/conponents/infoBasic";
import InfoProduct from "./createProduct/conponents/infoProduct";
import InfoPrice from "./createProduct/conponents/infoPrice";
import InfoScroll from "./createProduct/conponents/infoScroll";
import {ProductProvider} from "./provider";
import useProduct from "./hooks/useProduct";
import {useLocation} from "react-router-dom";
import {
  SkeletonProductBasic, SkeletonProductExtraInfo,
  SkeletonProductInfo,
  SkeletonProductVersion
} from "./createProduct/conponents/skelenton";
import {useTranslation} from "react-i18next";
import {DISPLAY_NAME_MENU} from "../../const/display_name_menu";
import { InfoActive } from './createProduct/conponents/infoActive'
import {Text} from "../../common/text";
import {ProductRelated} from "./createProduct/conponents/productRelate";

const CreateDigitalProduct = ({...props}) => {
  const { t } = useTranslation()
  const {fetch, provider} = useProduct()
  const {state, dispatch} = provider
  const location = useLocation()?.pathname?.split('/')

  useEffect(() => {
    fetch.origin()
  }, [])

  return (
    <ProductProvider value={{pageState: state, pageDispatch: dispatch}}>
      <StyledCreateProduct>
        {(location[2] === 'edit' && state?.formCreate?.statusForm !== 'edit') ? (
            <GridLayout
              {...props}
              header={
                <PageHeader
                  breadcrumbLinks={EDIT_PRODUCT_CONSTANTS?.header?.breadcrumb}
                  breadcrumbTitle={t('Chỉnh sửa sản phẩm số')}
                />
              }
              grid={[
                {
                  width: 70,
                  sections: [
                    {
                      title: '',
                      props: {
                        style: {
                          position: 'relative',
                          padding: '24px',
                          // maxHeight: '453px'
                        },
                        children: <SkeletonProductBasic />,
                      },
                    },
                    {
                      title: '',
                      props: {
                        style: {
                          position: 'relative',
                          padding: '24px'
                        },
                        children: <SkeletonProductInfo />,
                      },
                    },
                    {
                      title: '',
                      props: {
                        style: {position: 'relative', marginBottom: 0, padding: '24px'},
                        children: <SkeletonProductVersion />,
                      },
                    },
                  ],
                  props: {style: {position: 'relative'}},
                },
                {
                  width: 30,
                  sections: [
                    {
                      title: '',
                      props: {
                        style: {
                          padding: '24px',
                        },
                        children: <SkeletonProductExtraInfo />,
                      },
                    },
                  ],
                },
              ]}
              data-model="container"
            />
        ) : null}


        {state?.formCreate?.statusForm === 'edit' ? (
          <GridLayout
            {...props}
            header={
              <PageHeader
                breadcrumbLinks={EDIT_PRODUCT_CONSTANTS?.header?.breadcrumb}
                breadcrumbTitle={t('Chỉnh sửa sản phẩm số')}
              />
            }
            grid={[
              {
                width: 70,
                sections: [
                  {
                    title: t(DISPLAY_NAME_MENU.PRODUCT_PAGE.BASIC_INFO),
                    props: {
                      style: {
                        position: 'relative',
                        padding: 24
                      },
                      children: <InfoBasic />,
                    },
                  },
                  {
                    title: state?.formCreate?.statusForm === 'editSingleVersion' ? t(DISPLAY_NAME_MENU.PRODUCT_PAGE.PRICE_AND_INVENTORY_INFO) : t(DISPLAY_NAME_MENU.PRODUCT_PAGE.PRICE_INFO),
                    props: {
                      style: {
                        position: 'relative',
                        padding: 24
                      },
                      children: <InfoPrice />,
                    },
                  },
                  {
                    title: t(DISPLAY_NAME_MENU.PRODUCT_PAGE.PRODUCT_SPECIFICATIONS),
                    props: {
                      style: {
                        position: 'relative',
                        padding: 24
                      },
                      children: <InfoProduct />,
                    },
                  },
                  {
                    title: "Thông số kích hoạt",
                    props: {
                      style: {
                        position: 'relative',
                        padding: 24
                      },
                      children: <InfoActive />,
                    },
                  },
                  {
                    title: "Sản phẩm liên quan",
                    props: {
                      style: {
                        position: 'relative',
                        padding: 24
                      },
                      children: <ProductRelated/>
                    },
                  },
                  {
                    type: 'sticky-bottom-transparent',
                    props: {
                      style: {
                        position: 'sticky',
                        bottom: -44,
                        marginBottom: 0,
                        zIndex: 10,
                        padding: "12px 24px 0 12px"
                      },
                      children: <ActionFormBrnList />,
                    },
                  },
                ],
                props: {style: {position: 'relative'}},
              },
              {
                width: 30,
                sections: [{
                  title: '',
                  props: {
                    children: <InfoScroll />,
                    style: {
                      padding: '30px 24px 32px 24px',
                    },
                  },
                },],
                props: {
                  style: {
                    position: 'sticky',
                    top: 39,
                  },
                }
              },
            ]}
            data-model="container"
          />
        ) : null}

        {(state?.formCreate?.statusForm === 'create' && location[2] !== 'edit') ? (
          <GridLayout
            {...props}
            header={
              <PageHeader
                breadcrumbLinks={CREATE_PRODUCT_CONSTANTS.header.breadcrumb}
                breadcrumbTitle={t('Tạo mới sản phẩm số')}
              />
            }
            grid={[
              {
                width: 70,
                sections: [
                  {
                    title: t(DISPLAY_NAME_MENU.PRODUCT_PAGE.BASIC_INFO),
                    props: {
                      style: {
                        position: 'relative',
                        padding: 24
                      },
                      children: <InfoBasic />,
                    },
                  },
                  {
                    title: state?.formCreate?.statusForm === 'editSingleVersion' ? t(DISPLAY_NAME_MENU.PRODUCT_PAGE.PRICE_AND_INVENTORY_INFO) : t(DISPLAY_NAME_MENU.PRODUCT_PAGE.PRICE_INFO),
                    props: {
                      style: {
                        position: 'relative',
                        padding: 24
                      },
                      children: <InfoPrice />,
                    },
                  },
                  {
                    title: t(DISPLAY_NAME_MENU.PRODUCT_PAGE.PRODUCT_SPECIFICATIONS),
                    props: {
                      style: {
                        position: 'relative',
                        padding: 24
                      },
                      children: <InfoProduct />,
                    },
                  },
                  {
                    title: "Thông số kích hoạt",
                    props: {
                      style: {
                        position: 'relative',
                        padding: 24
                      },
                      children: <InfoActive />,
                    },
                  },
                  {
                    title: "Sản phẩm liên quan",
                    props: {
                      style: {
                        position: 'relative',
                        padding: 24
                      },
                      children: <Text color={'#FF0808'}>Sản phẩm liên quan chỉ được cài đặt sau khi sản phẩm được tạo</Text>,
                    },
                  },
                  {
                    type: 'sticky-bottom-transparent',
                    props: {
                      style: {
                        position: 'sticky',
                        bottom: -44,
                        marginBottom: 0,
                        zIndex: 10,
                        padding: "12px 24px 0 12px"
                      },
                      children: <ActionFormBrnList />,
                    },
                  },
                ],
                props: {style: {position: 'relative'}},
              },
              {
                width: 30,
                sections: [{
                  title: '',
                  props: {
                    children: <InfoScroll />,
                    style: {
                      padding: '30px 24px 32px 24px',
                    },
                  },
                },],
                props: {
                  style: {
                    position: 'sticky',
                    top: 39,
                  },
                }
              },
            ]}
            data-model="container"
          />
        ) : null}
    </StyledCreateProduct>
    </ProductProvider>
  );
};

export default CreateDigitalProduct;

export const StyledCreateProduct = styled.div`
  .grid-layout {
    &__header {
      & .breadcrumb__title h1 {
        line-height: 140% !important;
        min-width: 13.1875rem !important;
        height: 2.125rem !important;
      }
    }
  }
`