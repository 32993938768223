import React from "react";
import {Input} from "Component/Input";
import {Text} from "common/text";
import {THEME_SEMANTICS} from "common/theme/_semantics";
import { useEditOrder } from '../../../hooks/useEditOrder'

export const Note = ()=>{
    const {orderNotes} = useEditOrder()
    const {methods} = orderNotes
    return (
        <Input
            label={
                <>
                    Ghi chú đơn hàng
                </>
            }
            value={orderNotes?.value}
            onChange={e => methods.onChange(e.target?.value)}
            onBlur={e => methods.onBlur(e.target?.value)}
            validateText={orderNotes?.message}
            validateType={orderNotes?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập ghi chú đơn hàng'}
            maxLength={201}
        />
    )
}