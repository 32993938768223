import { sendRequestAuth } from 'api/api'
import { Button } from 'common/button'
import config from 'config'
import useInvoiceFilterForm from 'Pages/invoice/hooks/useInvoiceFilterForm'
import { InvoiceContext } from 'Pages/invoice/provider/_context'
import { invoiceActions } from 'Pages/invoice/provider/_reducer'
import { memo, useContext, useEffect } from 'react'
import { InvoiceTags } from '../invoiceTags'
import { InvoiceDateTime } from './_dateTime'
import { StyledInvoiceFilterForm } from './_styled'
import { InvoiceInfo } from './_info'
import { InvoicePrincipal } from './_principal'
import { InvoiceEmployee } from './_employee'
import { GLOBAL_ICONS } from '../../../../interface/icon'
import { InvoiceStatus } from './_status'
import { PaymentType } from './_type'
import { PaymentSchedule } from './_paymentSchedule'

export const InvoiceFilterForm = memo(({ ...props }) => {
  const { canSubmitOtherFilter, functions } = useInvoiceFilterForm()

  const { pageDispatch } = useContext(InvoiceContext)

  useEffect(() => {
    const fetchData = async () => {
      const response = await Promise.all([
        sendRequestAuth(
          'get',
          `${
            config.API
          }/order/filter/principals?keyword=`,
        ),
        sendRequestAuth(
          'get',
          `${
            config.API
          }/user/users?keyword=&group=2&status=&start=0&per_page=20`,
        )
      ])
      pageDispatch({
        type: invoiceActions.FILTER_ORIGIN_DATA_UPDATE,
        payload: {
          principal: {
            list: response[0].data.map(item => ({
              data: item || null,
              name: ((!!item?.last_name ? (item?.last_name + ' ') : '' )+ item?.name) || '',
              value: item?.id || '',
            })),
          },
          employee: {
            list: response[1].data?.data.map(item => ({
              data: item || null,
              name: (item?.last_name + ' ' + item?.name) || '',
              value: item?.id || '',
            })),
          },
        },
      })
    }
    fetchData()
  }, [])

  return (
    <StyledInvoiceFilterForm {...props}>
      <div
        className="invoice-filter-form__group invoice-filter-form__collapse"
        data-collapse={true}
      >
        <InvoiceInfo/>
        <InvoiceDateTime/>
        <InvoicePrincipal/>
        <InvoiceEmployee/>
        <PaymentSchedule/>
        <InvoiceStatus/>
        <PaymentType/>
        <div className="invoice-filter-form__input-wide flex">
          <Button
            appearance="secondary"
            disabled={!canSubmitOtherFilter}
            size="md-"
            onClick={() =>
              canSubmitOtherFilter && functions.applyInvoiceOtherFilter()
            }
          >
            Tìm kiếm
          </Button>
          <Button
            appearance="secondary"
            size="md-"
            onClick={functions.refresh}
            className={'invoice-filter-form__btn-refresh'}
          >
            {GLOBAL_ICONS.repeat}
          </Button>
        </div>
      </div>
      <div className="invoice-filter-form__group" style={{ marginBottom: 4 }}>
        <InvoiceTags/>
      </div>
    </StyledInvoiceFilterForm>
  )
})
