import {
  EXPORT_EXCEL_SELECTIVE_ACTIVE,
  EXPORT_COMMISSION_EXCEL_SELECTIVE,
  COMMISSION_FILTER_FORM_DATE_TIME_SORT_TYPES, COMMISSION_FILTER_FORM_COMMISSION_STATUS,
  COMMISSION_FILTER_FORM_PAYMENT_METHOD, COMMISSION_FILTER_FORM_PAYMENT_STATUS,
  COMMISSION_TABLE_THEAD_PAYMENT_FILTER_LIST
} from '../interfaces/_constants'
import {
  REVENUE_FILTER_FORM_DATE_TIME_SORT_TYPES,
  REVENUE_FILTER_FORM_PAYMENT_STATUS
} from '../../revenue/interfaces/_constants'
import { getDateFromNow } from '../../revenue/utils/date'
import { fDateTimeDMY } from '../../../util/formatTime'

const dateTimeDefaultValue = [getDateFromNow(-7), getDateFromNow(0, { type: 'end' })]
export const formatDateTimeDefaultValue = `${fDateTimeDMY(
  dateTimeDefaultValue[0],
)} - ${fDateTimeDMY(dateTimeDefaultValue[1])}`
export const commissionInitialState = {
  filter: {
    orderId: {
      value: null,
      activeValue: null
    },
    customerInfo: {
      value: null,
      activeValue: null
    },
    dateTime: {
      activeValue: {
        end: dateTimeDefaultValue[1],
        start: dateTimeDefaultValue[0],
        type: REVENUE_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
        value: formatDateTimeDefaultValue,
      },
      end: dateTimeDefaultValue[1],
      start: dateTimeDefaultValue[0],
      type: REVENUE_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
      trigger: true,
      value: formatDateTimeDefaultValue,
    },
    payment: {
      value: COMMISSION_TABLE_THEAD_PAYMENT_FILTER_LIST.map(item => item?.value),
    },
    product: {
      activeValue: [],
      keyword: '',
      list: [],
      listOrigin: [],
      loading: false,
      tab: 'all', // all | checked
      value: [],
    },
    search: {
      value: '',
    },
    paymentMethod: {
      activeValue: null,
      keyword: '',
      list: COMMISSION_FILTER_FORM_PAYMENT_METHOD,
      listOrigin: COMMISSION_FILTER_FORM_PAYMENT_METHOD,
      value: null,
    },
    principal: {
      activeValue: null,
      keyword: '',
      list: [],
      listOrigin: [],
      value: null,
    },
    teacher: {
      activeValue: null,
      keyword: '',
      list: [],
      listOrigin: [],
      value: null,
    },
    orderType: {
      activeValue: null,
      keyword: '',
      list: REVENUE_FILTER_FORM_PAYMENT_STATUS,
      listOrigin: REVENUE_FILTER_FORM_PAYMENT_STATUS,
      value: null,
    },
  },
  pageOption: {
    id: 1, name: 'Ngày tính hoa hồng', value: 'date'
  },
  notifications: {
    list: [],
  },
  table: {
    display: {
      list: [],
      loading: true,
    },
    detail: {
      id: null,
      active: null,
      list: [],
    },
    pagination: {
      active: 0,
      amount: 20,
      total: 0,
      totalItems: 0,
    },
    selected: {
      list: [],
    },
  },
  panels: null,
  printDetail: {},
  exportExcel: {
    list: EXPORT_COMMISSION_EXCEL_SELECTIVE,
    activeList: EXPORT_COMMISSION_EXCEL_SELECTIVE,
    selectedList: EXPORT_EXCEL_SELECTIVE_ACTIVE,
    keyword: '',
    hiddenList: EXPORT_COMMISSION_EXCEL_SELECTIVE
  },
}