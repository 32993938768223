import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ProductContext } from '../provider/~context'
import { productActions } from '../provider/~action'
import { postData, sendRequestAuth } from '../../../api/api'
import config from '../../../config'
import { removeVietnameseTones } from '../../../util/checkPasswordVN'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DISPLAY_NAME_MENU } from '../../../const/display_name_menu'
import { removeAcent } from '../../../common/fieldText/_functions'
import { replaceAllCustom } from '../../../util/functionUtil'
import { orderActions } from '../../orderDigital/provider/_reducer'

const useCreateInfoBasic = () => {
  const { t } = useTranslation()
  const { pageState, pageDispatch } = useContext(ProductContext)
  const [groupParent, setGroupParent] = useState('')
  const [animationClose, setAnimationClose] = useState(false)
  const [modalGroupParent, setModalGroupParent] = useState('')
  const [modalGroupParentConfirm, setModalGroupParentConfirm] = useState(false)
  const [isChangeModalGroupParent, setIsChangeModalGroupParent] = useState(false)
  const formCreate = pageState?.formCreate
  const formInfoBasic = pageState?.formCreate?.basic
  const formInfoBasicValidate = pageState?.formCreate?.basic?.validate
  const formVersion = pageState?.formCreate?.version?.valueVersion
  const modalGroupProduct = pageState?.formCreate?.basic?.modal

  // const handleOriginFetch = async () => {
  //   const response = await Promise.all([
  //     sendRequestAuth('get', `${config.API}/product-category/categories?keyword=&status`),
  //   ])
  //   if (response[0]?.data?.success) {
  //     pageDispatch({
  //       type: productActions.FORM_CREATE_ADD_LIST_ORIGIN, payload: {
  //         list: response[0]?.data?.data[1]?.childs,
  //         listOrigin: response[0]?.data?.data[1]?.childs,
  //       }
  //     })
  //     setModalGroupParent({
  //       list: response[0]?.data?.data[1]?.childs,
  //       listOrigin: response[0]?.data?.data[1]?.childs,
  //     })
  //   }
  // }
  //
  // useEffect(() => {
  //   handleOriginFetch()
  // }, [])

  const handleChangeStatus = () => {
    pageDispatch({ type: productActions.FORM_CREATE_CHANGE_ACTIVE_INFO_BASIC, payload: !formInfoBasic.active })
  }

  const handleChangeName = value => {
    pageDispatch({ type: productActions.FORM_CREATE_CHANGE_NAME_INFO_BASIC, payload: value })

    pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_NAME,
      payload: {
        status: (!!value && value?.length >= 255),
        message: (!!value && value?.length >= 255) ? t(DISPLAY_NAME_MENU.VALIDATE.MAX.PRODUCT_NAME) : ''
      }
    })
  }

  const handleChangePublisher = value => {
    pageDispatch({ type: productActions.FORM_CREATE_CHANGE_PUBLISHER_BASIC, payload: value })
    pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_BASIC,
      payload: {
        publisher: {
          status: (!!value && value?.length >= 50),
          message: (!!value && value?.length >= 50) ? 'Nhà xuất bản không  được vượt quá 50 ký tự' : ''
        }
      }
    })
  }

  const handleBlurName = () => {
    pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_NAME,
      payload: {
        status: (!!!formInfoBasic?.name || formInfoBasic?.name?.length >= 255),
        message: !!!formInfoBasic?.name
          ? t(DISPLAY_NAME_MENU.VALIDATE.EMPTY.PRODUCT_NAME) :
          (formInfoBasic?.name?.length >= 255) ? t(DISPLAY_NAME_MENU.VALIDATE.MAX.PRODUCT_NAME) : ''
      }
    })
  }

  const handleChangeCode = value => {
    const productCodeFormat = removeVietnameseTones(value).replaceAll('  ', ' ')
    pageDispatch({ type: productActions.FORM_CREATE_CHANGE_CODE_INFO_BASIC, payload: productCodeFormat })

    pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_CODE,
      payload: {
        status: (!!value && value?.length >= 50),
        message: (!!value && value?.length >= 50) ? t(DISPLAY_NAME_MENU.VALIDATE.MAX.PRODUCT_CODE) : ''
      }
    })
  }

  const handleChangePlatform = value => {
    if (value === '' || value.trim() !== '')
      pageDispatch({ type: productActions.FORM_CREATE_CHANGE_AUTHOR_INFO_BASIC, payload: value })
  }

  const handleChangeLanguage = value => {
    if (value.trim() !== '' || value === '')
      pageDispatch({ type: productActions.FORM_CREATE_CHANGE_LANGUAGE_INFO_BASIC, payload: value })
  }

  const handleChangeCopyrightTerm = value => {
    if (value.trim() !== '' || value === '') {
      pageDispatch({ type: productActions.FORM_CREATE_CHANGE_PUBLISH_YEAR_INFO_BASIC, payload: value })
    }
  }

  const handleBlurCode = () => {
    pageDispatch({ type: productActions.FORM_CREATE_CHANGE_CODE_INFO_BASIC, payload: formInfoBasic?.code?.trim() })
    pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_CODE,
      payload: {
        status: (!!!formInfoBasic?.code || formInfoBasic?.code?.length >= 50),
        message: !!!formInfoBasic?.code
          ? t(DISPLAY_NAME_MENU.VALIDATE.EMPTY.PRODUCT_CODE) :
          (formInfoBasic?.code?.length >= 50) ? t(DISPLAY_NAME_MENU.VALIDATE.MAX.PRODUCT_CODE) : ''
      }
    })
  }

  const handleBlurCopyrightTerm = () => {
    pageDispatch({
      type: productActions.VALIDATE_FORM_COPYRIGHT,
      payload: {
        status: formInfoBasic?.copyrightTerm.trim()?.length == 0,
        message: formInfoBasic?.copyrightTerm.trim()?.length == 0 ? 'Thời hạn bản quyền không được để trống' : ''
      }
    })
  }

  const handleChangeSku = value => {
    const re = /^[0-9\b]+$/
    if (value === '' || re.test(value)) {
      pageDispatch({ type: productActions.FORM_CREATE_CHANGE_PRODUCT_SKU_INFO_BASIC, payload: value })
      // pageDispatch({
      //     type: productActions.VALIDATE_FORM_CREATE_BARCODE,
      //     payload: {
      //         status: value?.length > 13,
      //         message: value?.length > 13 ? t(DISPLAY_NAME_MENU.VALIDATE.MAX.PRODUCT_BARCODE) : ''
      //     }
      // })
    }
  }
  const handleBlurSku = () => {
  }

  const handleChangeDevices = value => {
    pageDispatch({ type: productActions.FORM_CREATE_CHANGE_BARCODE_INFO_BASIC, payload: value })
  }
  const handleBlurDevices = () => {
    pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_BARCODE,
      payload: {
        status: formInfoBasic?.devices.trim()?.length == 0,
        message: formInfoBasic?.devices.trim()?.length == 0 ? 'Số lượng thiết bị không được để trống' : ''
      }
    })
  }

  const handleBlurPlatform = () => {
    pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_BASIC,
      payload: {
        platform: {
          status: formInfoBasic?.platform.trim()?.length == 0,
          message: formInfoBasic?.platform.trim()?.length == 0 ? 'Nền tảng không được để trống' : ''
        }
      }
    })
  }

  const handleSelectStatus = value => {
    pageDispatch({
      type: productActions.FORM_CREATE_CHANGE_STATUS_BASIC,
      payload: value
    })
    //update status validate
    pageDispatch({
      type: productActions.VALIDATE_FORM_CREATE_BASIC, payload: {
        status: { status: false, message: '' }
      }
    })
  }

  const location = useLocation()?.pathname?.split('/')
  const navigate = useNavigate()
  const handlePrintBarcode = async _ => {
    if (!!location[3]) {
      const responseIdDefault = await sendRequestAuth('get', `${config.API}/product/detail/${location[3]}`)
      if (responseIdDefault?.data?.success) {
        navigate(`/product/print-barcode/${responseIdDefault?.data?.data?.arr_product_details[0]?.id}`)
      }
    }
  }

  const handleGroupProductKeywordChange = data => {

  }

  const handleToggleShowGroupProduct = bool => {
    if (isChangeModalGroupParent) {
      setModalGroupParentConfirm(true)
    } else {
      setAnimationClose(true)
      setTimeout(() => {
        setAnimationClose(false)
        pageDispatch({ type: productActions.FORM_CREATE_OPEN_MODAL_INFO_BASIC, payload: bool })

        clearFormModalGroupProduct()
      }, 300)
    }
  }

  const closeModalGroupProductConfirm = _ => setModalGroupParentConfirm(false)
  const acceptanceModalGroupProductConfirm = _ => {
    setModalGroupParentConfirm(false)
    setAnimationClose(true)
    setTimeout(() => {
      setAnimationClose(false)
      pageDispatch({ type: productActions.FORM_CREATE_OPEN_MODAL_INFO_BASIC, payload: false })

      clearFormModalGroupProduct()
    }, 300)
  }

  const handleChangeModalCode = value => {
    const productCodeFormat = removeVietnameseTones(value)?.replaceAll(' ', '')
    pageDispatch({ type: productActions.FORM_CREATE_MODAL_CODE_INFO_BASIC, payload: productCodeFormat })

    if (!!value) {
      setIsChangeModalGroupParent(true)
      pageDispatch({
        type: productActions.FORM_CREATE_MODAL_VALIDATE_CODE_INFO_BASIC,
        payload: { status: false, message: '' }
      })
    }
  }

  const handleChangeModalName = value => {
    setIsChangeModalGroupParent(true)
    pageDispatch({ type: productActions.FORM_CREATE_MODAL_NAME_INFO_BASIC, payload: value })
    if (!!value) {
      pageDispatch({
        type: productActions.FORM_CREATE_MODAL_VALIDATE_NAME_INFO_BASIC,
        payload: { status: false, message: '' }
      })
    }
  }

  const handleChangeModalNote = value => {
    setIsChangeModalGroupParent(true)
    pageDispatch({ type: productActions.FORM_CREATE_MODAL_NOTE_INFO_BASIC, payload: value })
  }

  const handleSelectOptionMiniApp = data => {

    pageDispatch({
      type: productActions.FORM_CREATE_CHANGE_INFO_BASIC, payload: {
        optionMiniappActive: data
      }
    })

  }

  const handleSelectOptionMiniAppSendApp = data => {

    pageDispatch({
      type: productActions.FORM_CREATE_CHANGE_INFO_BASIC, payload: {
        optionMiniappSendKey: data
      }
    })

  }

  const categoryListOrigin = pageState?.formCreate?.basic?.groupProduct?.listOrigin
  const categoryList = pageState?.formCreate?.basic?.groupProduct?.list
  const categoryValue = pageState?.formCreate?.basic?.groupProduct?.value
  const categoryKeyword = pageState?.formCreate?.basic?.groupProduct?.keyword
  const categoryTab = pageState?.formCreate?.basic?.groupProduct?.tab

  const handleSelectGroupProduct = data => {
    const find = categoryValue.find(item => item.id === data.id)
    const productListData =
      categoryTab === 'checked'
        ? categoryValue.filter(item => item.id !== data.id)
        : categoryList
    const categoryValueData = find
      ? categoryValue.filter(item => item.id !== data.id)
      : [...categoryValue, data]

    pageDispatch({
      type: productActions.FORM_UPDATE_GROUP_PRODUCT_INFO_BASIC,
      payload: {
        list: productListData,
        value: categoryValueData,
      },
    })

    pageDispatch({ type: productActions.VALIDATE_FORM_CREATE_GROUP_PRODUCT, payload: { status: false, message: '' } })
  }
  const handleCategoryKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const categoryListData = categoryListOrigin.filter(item => {
      const formatNameItem = item?.title
        ? removeAcent(item?.title.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue?.trim())) return true
      return false
    })
    pageDispatch({
      type: productActions.FORM_UPDATE_GROUP_PRODUCT_INFO_BASIC,
      payload: { list: categoryListData, keyword: data?.value }
    })
  }
  const handleCategoryResetInput = () => {
    pageDispatch({
      type: productActions.FORM_UPDATE_GROUP_PRODUCT_INFO_BASIC,
      payload: {
        list: categoryList,
        value: [],
      },
    })
  }

  const handleCategoryTabChange = tab => {
    let productListData = []
    if (tab === 'checked') {
      productListData = categoryValue

      pageDispatch({
        type: productActions.FILTER_PRODUCT_TAB_UPDATE,
        payload: { list: productListData, tab },
      })
    } else {

      pageDispatch({
        type: productActions.FILTER_PRODUCT_TAB_UPDATE,
        payload: { list: [], tab },
      })
      handleCategoryKeywordChange({ value: categoryKeyword })
    }
  }

  const clearFormModalGroupProduct = _ => {
    pageDispatch({ type: productActions.FORM_CREATE_MODAL_CODE_INFO_BASIC, payload: '' })
    pageDispatch({ type: productActions.FORM_CREATE_MODAL_NAME_INFO_BASIC, payload: '' })
    pageDispatch({ type: productActions.FORM_CREATE_MODAL_NOTE_INFO_BASIC, payload: '' })
    pageDispatch({ type: productActions.FORM_CREATE_MODAL_GROUP_PRODUCT_INFO_BASIC, payload: [] })
    pageDispatch({
      type: productActions.FORM_CREATE_MODAL_VALIDATE_CODE_INFO_BASIC,
      payload: { status: false, message: '' }
    })
    pageDispatch({
      type: productActions.FORM_CREATE_MODAL_VALIDATE_NAME_INFO_BASIC,
      payload: { status: false, message: '' }
    })
  }

  const validateGroupProduct = _ => {
    let countError = 0
    if (!!!modalGroupProduct?.form?.code) {
      pageDispatch({
        type: productActions.FORM_CREATE_MODAL_VALIDATE_CODE_INFO_BASIC,
        payload: { status: true, message: t(DISPLAY_NAME_MENU.VALIDATE.EMPTY.GROUP_PRODUCT_CODE) }
      })
      countError++
    }
    if (!!!modalGroupProduct?.form?.name) {
      pageDispatch({
        type: productActions.FORM_CREATE_MODAL_VALIDATE_NAME_INFO_BASIC,
        payload: { status: true, message: t(DISPLAY_NAME_MENU.VALIDATE.EMPTY.GROUP_PRODUCT_NAME) }
      })
      countError++
    }
    return countError === 0
  }

  const handleBlurModalCode = _ => {
    pageDispatch({
      type: productActions.FORM_CREATE_MODAL_VALIDATE_CODE_INFO_BASIC,
      payload: {
        status: !!!modalGroupProduct?.form?.code,
        message: !!!modalGroupProduct?.form?.code ? t(DISPLAY_NAME_MENU.VALIDATE.EMPTY.GROUP_PRODUCT_CODE) : ''
      }
    })
  }

  const handleBlurModalName = _ => {
    pageDispatch({
      type: productActions.FORM_CREATE_MODAL_VALIDATE_NAME_INFO_BASIC,
      payload: {
        status: !!!modalGroupProduct?.form?.name,
        message: !!!modalGroupProduct?.form?.name ? t(DISPLAY_NAME_MENU.VALIDATE.EMPTY.GROUP_PRODUCT_NAME) : ''
      }
    })
  }

  const canSubmitGroupProduct = [
    modalGroupProduct?.validate?.code?.status,
    modalGroupProduct?.validate?.name?.status,
  ].includes(true)
  const retailPrice = pageState?.formCreate?.price?.retail.length > 3 ? replaceAllCustom(pageState?.formCreate?.price?.retail, ',', '') : pageState?.formCreate?.price?.retail

  return {
    value: {
      formInfoBasic,
      formVersion,
      formCreate,
      modalGroupProduct,
      modalGroupParent,
      modalGroupParentConfirm,
      retailPrice
    },
    functions: {
      onChangeStatus: handleChangeStatus,
      onChangeName: handleChangeName,
      onChangePublisher: handleChangePublisher,
      onChangeCode: handleChangeCode,
      onChangeSku: handleChangeSku,
      onChangePlatform: handleChangePlatform,
      onChangeLanguage: handleChangeLanguage,
      onChangeCopyrightTerm: handleChangeCopyrightTerm,
      onChangeDevices: handleChangeDevices,
      onGroupProductKeywordChange: handleGroupProductKeywordChange,
      onSelectStatus: handleSelectStatus,
      onPrintBarcode: handlePrintBarcode,
      animationClose,
      canSubmitGroupProduct,
      closeModalGroupProductConfirm,
      acceptanceModalGroupProductConfirm,
      onToggleShowGroupProduct: handleToggleShowGroupProduct,
      onChangeModalCode: handleChangeModalCode,
      onChangeModalName: handleChangeModalName,
      onChangeModalNote: handleChangeModalNote,
      onSelectGroupProduct: handleSelectGroupProduct,
      onSelectOptionMiniApp: handleSelectOptionMiniApp,
      onSelectOptionMiniAppSendApp: handleSelectOptionMiniAppSendApp,
      onKeywordChange: handleCategoryKeywordChange,
      onTabChange: handleCategoryTabChange,
      handleCategoryResetInput
    },
    validate: {
      formInfoBasicValidate,
      onBlurName: handleBlurName,
      onBlurCode: handleBlurCode,
      onBlurSku: handleBlurSku,
      onBlurDevices: handleBlurDevices,
      onBlurCopyrightTerm: handleBlurCopyrightTerm,
      onBlurPlatform: handleBlurPlatform,
      onBlurModalCode: handleBlurModalCode,
      onBlurModalName: handleBlurModalName,
    }
  }
}

export default useCreateInfoBasic