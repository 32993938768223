import React, { useContext } from 'react'
import { Skeleton } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {CreatePrincipalContext} from "../../provider/_context";
import {GLOBAL_ICONS, PRINCIPAL_GROUP_ICON} from "../../../../interface/icon";
import {Tr} from "../../../../../../layouts/tableLayout/_tr";
import {Td} from "../../../../../../layouts/tableLayout/_td";
import {Text} from "../../../../../../common/text";
import {Button} from "../../../../../../common/button";
import {formatDatetime} from "../../../../../../common/form/datePicker/_functions";
import {STORE_UPOS_ICON} from "../../../../../products/book/interface/_icons";
import {RowTeacherExtra} from "./_tableRowExtra";
import useCreatePrincipalRow from "../../hook/useCreatePrincipalRow";

const TbodyTeacher = () => {
  const { t } = useTranslation()
  const { pageState } = useContext(CreatePrincipalContext)
  const { table } = pageState
  const displayList = table?.display?.list
  const displayListDefault = table?.display?.listDefault
  const displayLoading = table.display.loading
  const paginationAmount = table.pagination.amount
  const paginationTotalItems = table.pagination.totalItems
  return (
    <StyledTBody>
      {displayLoading ? (
        Array.from(Array(paginationAmount), (e, i) => (
          <TeacherPlaceholder key={i}/>
        ))
      ) : paginationTotalItems > 0 ? (
        displayList.map((item, key) => <TeacherTr t={t} key={item.id} stt={key} data={item}/>)
      ) : (
        <TeacherEmpty t={t} list={displayListDefault}/>
      )}
    </StyledTBody>
  )
}

export default TbodyTeacher

const TeacherPlaceholder = ({ ...props }) => {
  return (
    <Tr {...props} className="create-teacher-table">
      {Array.from(Array(6), (e, i) => (
        <Td key={i} className="create-teacher-table_cell" data-type="td">
          <Skeleton
            sx={{
              width: '100%',
              height: 33,
              background:
                'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
            }}
          />
        </Td>
      ))}
    </Tr>
  )
}

const TeacherEmpty = ({ list, t, ...props }) => {
  return (
    <StyledTeacherEmpty {...props}>
      <img
        className="create-teacher-table-empty__banner"
        src="/img/order/order-empty.png"
        alt="empty"
      />
      <Text as="b" color="#7C88A6" style={{ marginBottom: 16 }}>
        {!!!list ? t('Bạn chưa có danh sách giáo viên nào') : t('no_matching_data')}
      </Text>
      {!!!list && (
        <Button href={'/upos/shop/create'} icon={STORE_UPOS_ICON.plus}>{t('add_new_shop')}</Button>
      )}
    </StyledTeacherEmpty>
  )
}

const TeacherTr = ({ data, t, stt, ...props }) => {
  const fullName = `${data?.last_name || ''} ${data?.name || ''}`
  const orderRow = useCreatePrincipalRow(data)
  const { detail, row} = orderRow
  return (
    <>
      <Tr
        {...props}
        className="create-teacher-table__row"
        extra={
          <RowTeacherExtra
              id={detail?.code}
              active={row.shouldOpenDetail}
              data={detail?.active}
              rowData={orderRow}
          />
        }
        data-active={row.shouldOpenDetail}
        onClick={row.onToggleDetail}
      >
        <Td className="create-teacher-table__cell" data-type="td">
          {+stt + 1}
        </Td>
        <Td className="create-teacher-table__cell" data-type="td">
          <Text>{data?.username || '---'}</Text>
        </Td>
        <Td className="create-teacher-table__cell" data-type="td">
          <Text>{data?.email || '---'}</Text>
        </Td>
        <Td className="create-teacher-table__cell" data-type="td">
          <Text>{fullName || '---'}</Text>
        </Td>
        <Td className="create-teacher-table__cell" data-type="td">
          {!!data?.created_at ? formatDatetime(data?.created_at) : '---'}
        </Td>
        <Td className="create-teacher-table__cell" data-type="td">
          {data?.status !== 'inactive'
            ? <Text className={'create-teacher-table__cell--success'}>{data?.status_name}</Text>
            : <Text className={'create-teacher-table__cell--danger'}>{data?.status_name}</Text>
          }
        </Td>
        <Td
          className="create-teacher-table__cell"
          data-menu="true"
          data-type="td"
          onClick={row.onToggleDetail}
        >
          <Text
            className="create-teacher-table__edit"
            // data-active={row.shouldOpenDetail}
            // href={`/teacher/${data?.id}`}
            // as={'a'}
            onClick={row.onToggleDetail}
          >
            <img src={'/svg/arrow.svg'} alt={'arrow'} />
          </Text>
        </Td>
      </Tr>
    </>
  )
}

export const StyledTBody = styled.div`
  .create-teacher-table {
    &__loading {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;

      width: 100vw;
      height: 100vh;

      display: flex;
      align-items: center;
      justify-content: center;

      background: rgba(0, 0, 0, 0.25);

      img {
        width: 80px;
        height: 80px;
      }
    }

    &__row {
      &:hover {
        .create-teacher-table__delete {
          display: block;
        }
        .create-teacher-table__edit {
          display: block;
        }
      }
    }

    &__cell {
      cursor: pointer;
      display: flex;
      justify-content: left;
      align-items: center;
      &[data-menu='true'] {
        position: relative;
      }

      &--success {
        color: #0B74E5 !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #ECF4FE;
        border-radius: 4px;
      }
      &--danger {
        color: #FF424E !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #FFEBEC;
        border-radius: 4px;
      }
      &:nth-child(1) {
        width: 50px;
        justify-content: center;
      }
      &:nth-child(2) {
        width: 210px;
      }
      &:nth-child(3) {
        width: 250px;
        flex: 1;
      }

      &:nth-child(4) {
        width: 210px;
      }

      &:nth-child(5) {
        width: 154px;
      }

      &:nth-child(6) {
        width: 150px;
        text-align: center;
        justify-content: center;
        align-items: center;
      }

      &:nth-child(7) {
        width: 100px;
        align-items: center;
        justify-content: center;
      }

    }
    &__edit {
      width: 20px;
      height: 20px !important;
      display: none;
      margin-right: 16px;
      background: transparent;
      border: none;
      border-radius: 12px !important;

      font-size: 12px !important;
      line-height: 24px !important;

      cursor: pointer;

      &[data-active='true'] {
        display: block !important;
      }
    }

    &__selected-action-dropdown {
      position: relative;

      margin-left: 12px;
    }

    &__selected-action-toggle {
      width: 88px;
      padding: 0 !important;

      border-radius: 14px !important;

      font-size: 14px !important;
      font-weight: 500 !important;
    }

    &__selected-action-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 11;

      width: 100vw;
      height: 100vh;
    }

    &__selected-action-menu {
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      z-index: 12;

      width: 150px;
      padding: 8px;

      background: #ffffff;
      border-radius: 6px;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    }

    &__selected-action-menu-item {
      padding: 8px;

      color: #191d32;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      transition: color 0.25s;

      cursor: pointer;

      &:hover {
        color: #3264FF;
      }
    }

    &__tooltipV2 {
      width: 100%;
      padding: 0;
      overflow: hidden;
      position: relative;
      display: inline-block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
}

.tab-detail-order {
  &__link-hover {
    color: #1A94FF;

    &:hover {
      color: #1373DB;
    }
  }
}
`
export const StyledTeacherEmpty = styled.div`
  min-height: calc(100vh - 430px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;

  .principal-empty__banner {
    width: 133px;
    height: 133px;
    margin-bottom: 16px;

    object-fit: contain;
    object-position: center;
  }
`
