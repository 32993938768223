import React, { useContext, useState } from 'react'
import { ProductContext } from '../provider/~context'
import { productActions } from '../provider/~action'
import { postData, sendRequestAuth } from '../../../api/api'
import config from '../../../config'
import { replaceAllCustom } from '../../../util/functionUtil'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DISPLAY_NAME_MENU } from '../../../const/display_name_menu'
import ArrayUtils from '../interfaces/array'
import { convertDateTimeToApiFormat } from '../../../common/form/datePicker/_functions'
import { fDateTimeApi } from '../../../util/formatTime'
import useAlert from '../../../hook/useAlert'

const useCreateInfoVersion = () => {
  const { t } = useTranslation()
  const { pageState, pageDispatch } = useContext(ProductContext)
  const formCreate = pageState?.formCreate
  const formInfoPrice = pageState?.formCreate?.price
  const zoomInImage = pageState?.formCreate?.zoomIn
  const modalConfirm = pageState?.modal
  const location = useLocation()?.pathname?.split('/')
  const { productId } = useParams()
  const imageFiles = pageState?.formCreate?.product?.image?.imageFiles
  const {showAlert} = useAlert()

  const handleTogglePopupPrice = bool => pageDispatch({
    type: productActions.FORM_CREATE_TOGGLE_MODAL_PRODUCT_PRICE_MANAGER,
    payload: bool
  })
  const discountPrice = pageState?.formCreate?.price?.discount.length > 3 ? replaceAllCustom(pageState?.formCreate?.price?.discount, ',', '') : pageState?.formCreate?.price?.discount
  const retailPrice = pageState?.formCreate?.price?.retail.length > 3 ? replaceAllCustom(pageState?.formCreate?.price?.retail, ',', '') : pageState?.formCreate?.price?.retail
  const dateTime = formInfoPrice?.dateTime
  const submitQueries = {
    'product_sku': pageState?.formCreate?.basic?.barCode || '',
    'title': pageState?.formCreate?.basic?.name || '',
    'status': pageState?.formCreate?.basic?.status?.value,
    'option_year': pageState?.formCreate?.basic?.publicationYear || '',
    'option_publisher': pageState?.formCreate?.basic?.publisher || '',
    'option_author': pageState?.formCreate?.basic?.author || '',
    'option_language': pageState?.formCreate?.basic?.language || '',
    'option_size': pageState?.formCreate?.product?.size || '',
    'option_book_cover': pageState?.formCreate?.product?.cover?.value || '',
    'category_id': pageState?.formCreate?.basic?.groupProduct?.value?.id || '',
    'price': retailPrice || '',
    'price_discount': discountPrice || '',
    'discount_started_at': dateTime?.start && dateTime.value ? convertDateTimeToApiFormat(dateTime.value.split(' - ')[0]) : '',
    'discount_ended_at': dateTime?.end && dateTime.value ? convertDateTimeToApiFormat(dateTime.value.split(' - ')[1]) : '',
    'images': pageState?.formCreate?.product?.image?.link,
    'short_description': pageState?.formCreate?.product?.shortDescription || '',
    'description': pageState?.formCreate?.product?.description || '',
  }

  const validateFormBeforeSubmit = _ => {
    let success = true
    let location = 70
    if (!!!pageState?.formCreate?.price?.retail) {
      pageDispatch({
        type: productActions.VALIDATE_FORM_CREATE_RETAIL,
        payload: { status: true, message: t(DISPLAY_NAME_MENU.VALIDATE.EMPTY.RETAIL_PRICE) }
      })
      success = false
      location = 330
    }
    if ((!!!pageState?.formCreate?.price?.discount || pageState?.formCreate?.price?.discount == 0) && !!dateTime.value) {
      pageDispatch({ type: productActions.VALIDATE_FORM_CREATE_DISCOUNT, payload: { status: true, message: 'Hãy nhập giá khuyến mãi' } })
      success = false
      location = 330
    }

    if ( discountPrice > 0 && !dateTime.value) {
      pageDispatch({ type: productActions.VALIDATE_FORM_CREATE_DISCOUNT_DATETIME, payload: { status: true, message: 'Hãy chọn thời gian khuyến mãi' } })
      success = false
      location = 330
    }
    if (+discountPrice > +retailPrice) {
      pageDispatch({
        type: productActions.VALIDATE_FORM_CREATE_DISCOUNT,
        payload: { status: true, message: 'Giảm giá không được lớn hơn giá bán' }
      })
      success = false
      location = 330
    }
    if (!!!pageState?.formCreate?.basic?.name) {
      pageDispatch({
        type: productActions.VALIDATE_FORM_CREATE_NAME,
        payload: { status: true, message: t(DISPLAY_NAME_MENU.VALIDATE.EMPTY.PRODUCT_NAME) }
      })
      success = false
      location = 70
    }
    if (!!!pageState?.formCreate?.basic?.author) {
      pageDispatch({
        type: productActions.VALIDATE_FORM_CREATE_BASIC, payload: {
          author: { status: true, message: 'Tác giả không được để trống' }
        }
      })
      success = false
      location = 70
    }
    // if(!!!pageState?.formCreate?.basic?.code) {
    if (!!!pageState?.formCreate?.basic?.groupProduct?.value?.title) {
      pageDispatch({
        type: productActions.VALIDATE_FORM_CREATE_GROUP_PRODUCT,
        payload: { status: true, message: t(DISPLAY_NAME_MENU.VALIDATE.EMPTY.GROUP_PRODUCT) }
      })
      success = false
      location = 70
    }
    if (!!!pageState?.formCreate?.basic?.status?.value) {
      pageDispatch({
        type: productActions.VALIDATE_FORM_CREATE_BASIC, payload: {
          status: { status: true, message: 'Trạng thái không được để trống' }
        }
      })

      success = false
      location = 70
    }

    if (!!!pageState?.formCreate?.basic?.barCode) {
      pageDispatch({
        type: productActions.VALIDATE_FORM_CREATE_BASIC, payload: {
          barCode: { status: true, message: 'ISBN không được để trống' }
        }
      })
      success = false
      location = 70
    }

    setTimeout(() => {
      scrollToItem(location)
      if (!success) showAlert({type: 'danger',content: t(DISPLAY_NAME_MENU.GENERAL.CHECK_INFO_BEFORE_SUBMIT)})
    }, 100)
    return success
  }

  const navigate = useNavigate()
  const [debounceSubmitCreate, setDebounceSubmitCreate] = useState(true)
  const scrollToItem = (location) => {
    const wrapper = document.querySelector('#content-wrap')
    wrapper.scrollTo({
      top: location,
      behavior: 'smooth'
    })
  }
  const handleUploadImage = async () => {
    let formDataList = []

    for (let i = 0; i < imageFiles.length; i++) {
      const formData = new FormData()
      formData.append('images[]', imageFiles[i])
      formDataList.push(formData)
    }

    const response = await Promise.all(
      formDataList.map(item =>
        postData(`${config.API}/product/upload-multiple`, item),
      ),
    )

    return response
  }
  const submitCreate = async (option = {}) => {
    const response = await sendRequestAuth(
      'post',
      `${config.API}/product/book/create`, JSON.stringify({ ...submitQueries, ...option }),
    ).catch(() => showAlert({
      type: 'danger',
      content: t(DISPLAY_NAME_MENU.GENERAL.API_ERROR)
    }))

    if (response?.data?.success) {
      showAlert({
        type:'success',
        content: t(DISPLAY_NAME_MENU.PRODUCT_PAGE.ADDED_SUCCESSFULLY)
      })
      navigate('/product')
    } else {
      let location = 70
      errorResponse(response?.data?.errors?.details)
      if (!!response?.data?.errors?.details?.find(res => +res.code === 1003)) location = 1000
      if (!!response?.data?.errors?.details?.find(res => +res.code === 1002)) location = 70
      setTimeout(() => {
        scrollToItem(location)
        showAlert({type: 'danger',content: t(DISPLAY_NAME_MENU.GENERAL.CHECK_INFO_BEFORE_SUBMIT)})
      }, 100)
    }
  }
  const submitEdit = async (option = {}) => {
    const response = await sendRequestAuth(
      'post',
      `${config.API}/product/book/update/${productId}`, JSON.stringify({ ...submitQueries, ...option }),
    ).catch(() => showAlert({
      type: 'danger',
      content: t(DISPLAY_NAME_MENU.GENERAL.API_ERROR)
    }))

    if (response?.data?.success) {
      showAlert({
        type:'success',
        content: 'Cập nhật thành công sản phẩm'
      })
      navigate('/product')
    } else {
      let location = 70
      errorResponse(response?.data?.errors?.details)
      // if(!!response?.data?.errors?.details?.find(res => +res.code === 1003)) location = 1000
      // if(!!response?.data?.errors?.details?.find(res => +res.code === 1002)) location = 70
      setTimeout(() => {
        scrollToItem(location)
        showAlert({type: 'danger',content: t(DISPLAY_NAME_MENU.GENERAL.CHECK_INFO_BEFORE_SUBMIT)})
      }, 100)
    }
  }
  const handleSubmit = () => {
    if (debounceSubmitCreate && validateFormBeforeSubmit()) {
      pageDispatch({
        type: productActions.CHANGE_LOADING,
        payload: true
      })
      pageDispatch({
        type: productActions.VALIDATE_FORM_CREATE_WEIGHT_PRODUCT,
        payload: { status: true, message: t(DISPLAY_NAME_MENU.VALIDATE.EMPTY.INVALID_WEIGHT) }
      })
      setDebounceSubmitCreate(false)
      setTimeout(() => setDebounceSubmitCreate(true), 2000)
      const statusForm = pageState?.formCreate?.statusForm

      const uploadResponse = handleUploadImage()
      uploadResponse.then(res => {
        const urlList = res.filter(item =>
          item?.data?.success &&
          ArrayUtils.getQualifiedArray(item?.data?.data)[0]
        ).map(dt => `${ArrayUtils.getQualifiedArray(dt?.data?.data)[0]}`)
        if (statusForm === 'create') {
          submitCreate({ images: urlList })
        }
        // EDIT PRODUCT
        else {
          const images = [
            ...pageState.formCreate.product.image.list
              .filter(item => pageState.formCreate.product.image.listOrigin.includes(item?.id))
              .map(item => item?.url),
            ...urlList,
          ]
          submitEdit({ images: images })
        }
      })
    }
  }
  const errorResponse = response => {
    response?.map(res => {
      switch (res.field) {
        case 'title':
          pageDispatch({
            type: productActions.VALIDATE_FORM_CREATE_NAME,
            payload: { status: true, message: res.message }
          })
          break
        case 'status':
          pageDispatch({
            type: productActions.VALIDATE_FORM_CREATE_BASIC, payload: {
              status: { status: true, message: res.message }
            }
          })
          break
        case 'category_id':
          pageDispatch({
            type: productActions.VALIDATE_FORM_CREATE_BASIC, payload: {
              category: { status: true, message: res.message }
            }
          })
          break
        case 'price':
          pageDispatch({
            type: productActions.VALIDATE_FORM_CREATE_RETAIL,
            payload: { status: true, message: res.message }
          })
          break
        default:
          break
      }
    })
  }

  const canSaveProduct = [
    pageState?.formCreate?.basic?.validate?.name?.status,
    pageState?.formCreate?.basic?.validate?.barCode?.status,
    pageState?.formCreate?.basic?.validate?.group?.status,
    pageState?.formCreate?.price?.validate?.retail?.status,
    pageState?.formCreate?.price?.validate?.barCode?.status,
    pageState?.formCreate?.price?.validate?.author?.status,
  ].includes(true)

  const handleZoomInImage = (bool, image) => {
    pageDispatch({ type: productActions.FORM_CREATE_ZOOM_IN_IMAGE, payload: bool })
    pageDispatch({ type: productActions.FORM_CREATE_ZOOM_IN_IMAGE_LINK_ACTIVE, payload: image })
  }

  const handleCloseModalConfirmEdit = _ => {
    pageDispatch({ type: productActions.EDIT_PRODUCT_CONFIRM_POPUP_1, payload: false })
    pageDispatch({ type: productActions.EDIT_PRODUCT_CONFIRM_POPUP_2, payload: false })
    pageDispatch({ type: productActions.EDIT_PRODUCT_CONFIRM_POPUP_3, payload: false })
  }

  const handleAcceptModalConfirmEdit = async _ => {
    pageDispatch({ type: productActions.EDIT_PRODUCT_CONFIRM_POPUP_1, payload: false })
    pageDispatch({ type: productActions.EDIT_PRODUCT_CONFIRM_POPUP_2, payload: false })
    pageDispatch({ type: productActions.EDIT_PRODUCT_CONFIRM_POPUP_3, payload: false })

    delete submitQueries?.barcode
    const response = await sendRequestAuth(
      'post',
      `${config.API}/product/update/${location[3]}`, JSON.stringify(submitQueries),
    ).catch(() =>
      showAlert({
        type:'danger',
        content: 'Lỗi API, chỉnh sửa sản phẩm thất bại'
      }))

    if (response?.data?.success) {
      showAlert({
        type:'success',
        content: 'Cập nhật sản phẩm thành công'
      })
      navigate('/product')
    } else {
      errorResponse(response?.data?.errors?.details)
    }
  }

  return {
    value: {
      formCreate,
      canSaveProduct,
      zoomInImage,
      modalConfirm,
      loading: pageState.loading,
    },
    functions: {
      onTogglePopupPrice: handleTogglePopupPrice,
      submit: handleSubmit,

      onZoomInImage: handleZoomInImage,

      onCloseModalConfirmEdit: handleCloseModalConfirmEdit,
      onAcceptModalConfirmEdit: handleAcceptModalConfirmEdit,
    }
  }
}

export default useCreateInfoVersion