import React, {memo, useEffect} from "react";
import PageHeader from "./component/header/index";
import useCreatePrincipal from "./hook/useCreatePrincipal";
import {CreatePrincipalProvider} from './provider'
import {GridLayout} from "../../../../layouts/gridLayout";
import {InfoPerson} from "./component/infoPerson";
import {InfoBank} from "./component/infoBank";
import ActionFormBrnList from "./component/actionFormBrnList";
import { useParams } from 'react-router-dom'
import {useCreatePrincipalTab} from "./hook/useCreatePrincipalTab";
import {Text} from "../../../../common/text";
import styled from 'styled-components'
import {AffiliateTeachers} from "./component/affilateTeacher";
import ReactImageFallback from "react-image-fallback";
import {useCreatePrincipalForm} from "./hook/useCreatePrincipalForm";
import {InfoCommission} from "./component/infoComission";
import { ProductTeachers } from './component/productTeacher'
const PrincipalCreate= memo(() =>{
    const {provider, fetch} = useCreatePrincipal()
    const {state, dispatch} = provider
    const {principalId} = useParams()
    useEffect(()=>{
        if(!!principalId) fetch.origin(principalId)
    },[principalId])
    return (
        <CreatePrincipalProvider  value={{ pageState: state, pageDispatch: dispatch }}>
            {
                !!principalId ? <PrincipalEdit/>
                   :
                    <GridLayout
                        header={
                            <PageHeader/>
                        }
                        grid={[
                            {
                                width: 100,
                                sections: [
                                    {
                                        title: 'Thông tin cá nhân',
                                        props: {
                                            style: {
                                                position: 'relative',
                                                padding: 24
                                            },
                                            children: <InfoPerson />,
                                        },
                                    },
                                    {
                                        title: 'Thông tin hoa hồng',
                                        props: {
                                            style: {
                                                position: 'relative',
                                                padding: 24
                                            },
                                            children: <InfoCommission />,
                                        },
                                    },
                                    {
                                        title: 'Thông tin ngân hàng',
                                        props: {
                                            style: {
                                                position: 'relative',
                                                padding: 24
                                            },
                                            children: <InfoBank />,
                                        },
                                    },
                                    {
                                        type: 'sticky-bottom-transparent',
                                        props: {
                                            style: {
                                                position: 'sticky',
                                                bottom: -44,
                                                marginBottom: 0,
                                                zIndex: 10,
                                                padding: "12px 24px 0 12px"
                                            },
                                            children: <ActionFormBrnList />,
                                        },
                                    },
                                ],
                                props: {style: {position: 'relative'}},
                            },

                        ]}
                        data-model="container"
                    />
            }

        </CreatePrincipalProvider>

    )
})
const PrincipalEdit = ()=>{
    const {tab} = useCreatePrincipalTab()
    const {list, active, onChange} = tab
    const { avatar, detail} = useCreatePrincipalForm()
    const fullName = `${detail?.last_name || ''} ${detail?.name || ''}`
    return(
        <StylePrincipalEdit>
            <PageHeader/>
            <div className='create-principal-avatar'>
                <div className='create-principal-avatar_teacher'  >
                    {/*<input*/}
                    {/*    title=" "*/}
                    {/*    type='file'*/}
                    {/*    accept="image/png, image/jpeg"*/}
                    {/*    onChange={onUploadAvatar}*/}
                    {/*    className='create-principal_update_image'/>*/}
                    <ReactImageFallback
                        src={avatar?.value || '/img/useravatar.jpg'}
                        // alt={data?.shopname}
                        fallbackImage='/img/avatar.png'
                        style={{width: 102, height: 102, borderRadius: 8}}
                    />
                </div>
                <div style={{marginLeft: 20, display: 'flex', alignItems:'center'}}>
                    <div>
                        <Text as={'p'} color={'rgba(0, 0, 0, 0.50)'} >{detail?.username}</Text>
                        <Text as={'p'} >{fullName}</Text>
                        <Text as={'p'} >{detail?.school_name}</Text>
                    </div>
                </div>
            </div>
            <div className='create-principal-tabs'>
                {
                    list?.map((map,i)=><div
                        className='create-principal-tab'
                        data-active={map?.tab === active?.tab}
                        key={i} onClick={()=>onChange(map)}>
                        <Text>{map?.name}</Text>
                    </div>)
                }

            </div>
            {
                active?.tab === 'principal' &&  <GridLayout
                    grid={[
                        {
                            width: 100,
                            sections: [
                                {
                                    title: 'Thông tin cá nhân',
                                    props: {
                                        style: {
                                            position: 'relative',
                                            padding: 24
                                        },
                                        children: <InfoPerson />,
                                    },
                                },
                                {
                                    title: 'Thông tin hoa hồng',
                                    props: {
                                        style: {
                                            position: 'relative',
                                            padding: 24
                                        },
                                        children: <InfoCommission />,
                                    },
                                },
                                {
                                    title: 'Thông tin ngân hàng',
                                    props: {
                                        style: {
                                            position: 'relative',
                                            padding: 24
                                        },
                                        children: <InfoBank />,
                                    },
                                },
                                {
                                    type: 'sticky-bottom-transparent',
                                    props: {
                                        style: {
                                            position: 'sticky',
                                            bottom: -44,
                                            marginBottom: 0,
                                            zIndex: 10,
                                            padding: "12px 24px 0 12px"
                                        },
                                        children: <ActionFormBrnList />,
                                    },
                                },
                            ],
                            props: {style: {position: 'relative'}},
                        },

                    ]}
                    data-model="container"
                />

            }
            {
                active?.tab === 'teachers' &&
                <div  className='create-principal-tabs_content'>
                    <AffiliateTeachers/>
                </div>
            }
            {
                active?.tab === 'customers' &&
                <div  className='create-principal-tabs_content'>
                    <AffiliateTeachers/>
                </div>
            }
            {
                active?.tab === 'products' &&
                <div  className='create-principal-tabs_content'>
                    <ProductTeachers/>
                </div>
            }

        </StylePrincipalEdit>
    )
}

const StylePrincipalEdit = styled.div`
    max-width: 1366px;
    margin: 0 auto;
    .create-principal{
        &-tabs{
            display: flex;
        }
        &-tab{
            height: 36px;
            padding: 10px 32px;
            border-radius: 8px 8px 0px 0px;
            background: #E2EAF8;
            cursor: pointer;
            margin-right: 4px;
            &[data-active = true]{
               background: #ffffff;
            }
           
        }
        &-tabs_content{
            padding-top: 24px;
            background: #ffffff;
            max-width: 1360px;
        }
        &-avatar{
            height: 150px;
            background: #ffffff;   
            margin-bottom: 24px;   
            border-radius: 8px;  
            display: flex;
            padding: 24px;
        }
        &-avatar_teacher{
            position: relative;
           
        }
        &_update_image{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
    .grid-layout__section {
        margin: 0px 9px 24px;
        &:nth-child(1){
            border-radius: 0px 8px 8px 8px;
        }    
    }
`

export default PrincipalCreate;
