import { sendRequestAuth } from 'api/api'
import { convertDateTimeToApiFormat } from 'common/form/datePicker/_functions'
import config from 'config'
import { useReducer } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  orderActions,
  orderReducer,
} from '../provider/_reducer'

import {
  orderInitialState,
} from '../provider/_initState'

const useOrder = () => {
  const [searchParams] = useSearchParams()
  const nav = useNavigate()
  const [state, dispatch] = useReducer(orderReducer, orderInitialState)
  const { filter, table } = state

  const filterQueries = {
    date_type: filter.dateTime?.activeValue?.type?.value || '',
    start_date:
      filter.dateTime.activeValue?.start && filter.dateTime.activeValue.value
        ? convertDateTimeToApiFormat(
        filter.dateTime.activeValue.value.split(' - ')[0],
        )
        : '',
    end_date:
      filter.dateTime.activeValue?.end && filter.dateTime.activeValue.value
        ? convertDateTimeToApiFormat(
        filter.dateTime.activeValue.value.split(' - ')[1],
        )
        : '',
    product_ids: Array.isArray(filter.product.activeValue?.value)
      ? filter.product.activeValue?.value.map(item => item?.value).join(',')
      : '',
    order_status: filter.orderStatus.activeValue?.value || '',
    payment_status: filter.paymentStatus.activeValue?.value || '',
    payment_method: filter.paymentMethod.activeValue?.value || '',
    principal_id: filter.principal.activeValue?.value || '',
    teacher_id: filter.teacher.activeValue?.value || '',
    order_id: filter.orderId.activeValue || '',
    customer: filter.customerInfo.activeValue || '',
    type: 2,
  }

  const fetchRowDetail = async data => {
    if (!!!data?.id) {
      dispatch({
        type: orderActions.TABLE_DISPLAY_LOADING_UPDATE,
        payload: { table: { display: { loading: false } } },
      })
      return
    }

    const response = await sendRequestAuth(
      'get',
      `${config.API}/order/detail/${data?.id}`,
    )

    if (!!response?.data?.success) {
      const newItem = response?.data?.data
      dispatch({
        type: orderActions.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: { active: newItem, list: [newItem] },
      })
    }

    dispatch({
      type: orderActions.TABLE_DISPLAY_LOADING_UPDATE,
      payload: { table: { display: { loading: false } } },
    })
  }

  const handleOriginFetch = async () => {
    const startDate = convertDateTimeToApiFormat(
      filter.dateTime.activeValue.value.split(' - ')[0],
    )
    const endDate = convertDateTimeToApiFormat(
      filter.dateTime.activeValue.value.split(' - ')[1],
    )
    const querySearch = searchParams.get('search') || ''
    if (querySearch)
      dispatch({
        type: orderActions.FILTER_ACTIVE_DATE_TIME_UPDATE,
        payload: {
          end: '',
          start: '',
          type: filter.dateTime.type,
          value: '',
        },
      })
    const response = await sendRequestAuth(
      'get',
      `${config.API}/order/filter?order_id=${querySearch}&customer=&order_status=&payment_status=&payment_method=&principal_id=&teacher_id=&start_date=${startDate}&end_date=${endDate}&product_ids=&start=0&per_page=20&type=2`,
    )

    if (!!response?.data?.success) {
      const displayListData = Array.isArray(response?.data?.data)
        ? response.data.data
        : []
      // set default value for input filter
      dispatch({
        type: orderActions.FILTER_ORDER_ID_UPDATE,
        payload: { value: querySearch },
      })
      // update display list
      dispatch({
        type: orderActions.TABLE_AMOUNT_UPDATE,
        payload: {
          display: {
            list: displayListData,
          },
          pagination: {
            totalItems: response?.data?.meta?.total || 0,
            active: 0,
            amount: 20,
            total: response?.data?.meta?.total
              ? Math.ceil(
                response?.data?.meta?.total / 20,
              )
              : 0,
          },
        },
      })
      // auto fetch detail order id and open
      if (querySearch) {
        dispatch({
          type: orderActions.TABLE_DISPLAY_DETAIL_UPDATE,
          payload: { active: displayListData[0] },
        })
        fetchRowDetail({ id: querySearch })
      }
    }

    if (!querySearch) {
      dispatch({
        type: orderActions.TABLE_DISPLAY_LOADING_UPDATE,
        payload: { table: { display: { loading: false } } },
      })
    }
  }

  const handleDetailFetch = async (orderId) => {
    const response = await sendRequestAuth(
      'get',
      `${config.API}/order/detail/${orderId}`,
    )

    if (!!response?.data?.success) {
      // set default value for input filter
      const orderDetail = response.data.data
      if(!['new','processing'].includes(orderDetail.order_status)) nav('/order/digital')
      dispatch({
        type: orderActions.UPDATE_ORIGIN_DATA_FORM_EDIT,
        payload: {
          order: orderDetail,
          customerName: {
            value: orderDetail?.customer_name,
            status: true,
            message: ''
          },
          customerPhone: {
            value: orderDetail?.customer_phone,
            status: true,
            message: ''
          },
          customerEmail: {
            value: orderDetail?.customer_email,
            status: true,
            message: ''
          },
          customerAddress: {
            value: orderDetail?.customer_address,
            status: true,
            message: ''
          },
          orderNotes: {
            value: orderDetail?.order_notes,
            status: true,
            message: ''
          },
          feeShip: {
            value: orderDetail?.shipping_fee,
            status: true,
            message: ''
          },
          details: orderDetail?.details,
        },
      })
    }
  }


  const handleFetchDetailHistory = async (historyId) => {
    const response = await sendRequestAuth(
      'get',
      `${config.API}/order/log/detail/${historyId}`,
    )

    if (!!response?.data?.success) {}
  }

  const handlePaginationAmountChange = async n => {
    dispatch({
      type: orderActions.TABLE_SELECTED_LIST_UPDATE,
      payload: { selected: { list: [] } },
    })
    dispatch({
      type: orderActions.TABLE_DISPLAY_LOADING_UPDATE,
      payload: { table: { display: { loading: true } } },
    })

    const currentPage = table.pagination.active || 0
    const totalPages = Math.ceil(table.pagination.totalItems / n)
    const page =
      table.pagination.totalItems < currentPage * n
        ? totalPages - 1
        : currentPage

    let queryStr = '?'
    let i = 0
    for (const [key, value] of Object.entries({
      ...filterQueries,
      type: 1,
      per_page: n,
      start: page * n,
    })) {
      queryStr += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }

    const response = await sendRequestAuth(
      'get',
      `${config.API}/order/filter${queryStr}&type=2`,
    )

    if (!!response?.data?.success) {
      const displayListData = Array.isArray(response?.data?.data)
        ? response.data.data
        : []
      dispatch({
        type: orderActions.TABLE_AMOUNT_UPDATE,
        payload: {
          display: {
            list: displayListData,
          },
          pagination: { active: page, amount: n, totalItems: response?.data?.meta?.total || 0,total: totalPages },
        },
      })

    }

    dispatch({
      type: orderActions.TABLE_DISPLAY_DETAIL_UPDATE,
      payload: { active: null },
    })

    dispatch({
      type: orderActions.TABLE_DISPLAY_LOADING_UPDATE,
      payload: { table: { display: { loading: false } } },
    })
  }

  const handlePaginationPageChange = async page => {
    dispatch({
      type: orderActions.TABLE_SELECTED_LIST_UPDATE,
      payload: { selected: { list: [] } },
    })
    dispatch({
      type: orderActions.TABLE_DISPLAY_LOADING_UPDATE,
      payload: { table: { display: { loading: true } } },
    })

    const amount = table.pagination?.amount || 20

    let queryStr = '?'
    let i = 0
    for (const [key, value] of Object.entries({
      ...filterQueries,
      type: 2,
      per_page: amount,
      start: page * amount,
    })) {
      queryStr += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }

    const response = await sendRequestAuth(
      'get',
      `${config.API}/order/filter${queryStr}`,
    )

    if (!!response?.data?.success) {
      const displayListData = Array.isArray(response?.data?.data)
        ? response.data.data
        : []

      dispatch({
        type: orderActions.TABLE_PAGINATION_UPDATE,
        payload: {
          display: {
            list: displayListData,
          },
          pagination: { active: page },
        },
      })
    }

    dispatch({
      type: orderActions.TABLE_DISPLAY_DETAIL_UPDATE,
      payload: { active: null },
    })

    dispatch({
      type: orderActions.TABLE_DISPLAY_LOADING_UPDATE,
      payload: { table: { display: { loading: false } } },
    })
  }

  return {
    fetch: {
      origin: handleOriginFetch,
      detail: handleDetailFetch,
      history: handleFetchDetailHistory,
    },
    pagination: {
      onAmountChange: handlePaginationAmountChange,
      onPageChange: handlePaginationPageChange,
    },
    provider: { state, dispatch },
  }
}

export default useOrder
