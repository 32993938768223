import {AlternativeAutoComplete} from 'common/form/autoComplete/_alternativeAutoComplete'
import {Option} from 'common/form/autoComplete/_option'
import useCommissionFilterForm from '../../hooks/useCommissionFilterForm'
import { AutoCompleteSingleOption } from '../../../../common/form/autocompleteSingleOption'

export const OrderType = () => {
  const {orderType} = useCommissionFilterForm()

  return (
    <AlternativeAutoComplete
      className="commission-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: {name: 'Loại đơn hàng', value: ''}, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 108,
        placeholder: 'Chọn loại đơn hàng',
        readOnly: true,
        value: orderType.value?.name || '',
        onIconClick: () => orderType.onChange(null),
      }}
      // menu
      hideSearchBar={true}
    >
      {orderType.list.length > 0 &&
        orderType.list.map(item => (
          <AutoCompleteSingleOption
            key={item.value}
            className="revenue-filter-form__option-text"
            data-active={item.value === orderType.value?.value}
            onClick={() => orderType.onChange(item)}
          >
            {item.name}
          </AutoCompleteSingleOption>
        ))}
    </AlternativeAutoComplete>
  )
}
