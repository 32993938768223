import {formatDatetime} from 'common/form/datePicker/_functions'
import {getDateFromNow} from 'util/date'

export const dateTimeDefaultValue = [
  getDateFromNow(0, {type: 'start'}),
  getDateFromNow(7, {type: 'end'}),
]
export const formatDateTimeDefaultValue = `${formatDatetime(
  dateTimeDefaultValue[0],
)} - ${formatDatetime(dateTimeDefaultValue[1])}`

export const productInitialState = {
  formCreate: {
    basic: {
      active: true,
      name: '',
      code: '',
      publicationYear: '',
      publisher: '',
      author: '',
      language: '',
      barCode: '',
      status: {},
      groupProduct: {
        id: '',
        value: {},
        list: [],
        listOrigin: [],
        keyword: '',
      },
      validate: {
        name: {
          status: false,
          message: ''
        },
        code: {
          status: false,
          message: ''
        },
        barCode: {
          status: false,
          message: ''
        },
        group: {
          status: false,
          message: '',
        },
      },
      modal: {
        open: false,
        form: {
          code: '',
          name: '',
          group: [],
          note: '',
        },
        validate: {
          code: {
            status: false,
            message: '',
          },
          name: {
            status: false,
            message: '',
          }
        }
      }
    },
    product: {
      image: {
        id: '',
        name: '',
        link: '',
        list: [],
        listOrigin: [],
        imageFiles: []
      },
      size: '',
      cover: {},
      description: '',
      shortDescription: '',
      note: {
        open: true,
        content: '',
      },
      validate: {
        image: {
          status: false,
          message: ''
        },
      },
      modal: {
        open: false,
        form: {
          unit: '',
          symbol: '',
        },
        validate: {
          unit: {
            status: false,
            message: '',
          },
          symbol: {
            status: false,
            message: '',
          },
        }
      },
    },
    price: {
      retail: '',
      discount: '',
      dateTime: {
        end: null,
        start: null,
        trigger: true,
        value: '',
      },
      validate: {
        retail: {
          status: false,
          message: '',
        },
        discount: {
          status: false,
          message: '',
        },
      }
    },
    zoomIn: {
      open: false,
      linkActive: '',
    },
    statusForm: 'create',
  },
  modal: {
    confirmProductStatus: false,
    dataChangeProductStatus: {},
    confirmRemoveProduct: false,
    dataRemoveProduct: {},
    confirmProductDetailStatus: false,
    confirmProductGroupStatus: false,
    dataChangeProductDetailStatus: {},
    confirmPopup1: false,
    confirmPopup2: false,
    confirmPopup3: false,
    statusConfirmEdit: {
      warehouse_quantity: 0,
      order: 0
    }

  },
  export_file: '#',
  import_file: null,
  tab: {
    tab_id: [],
    list_id: []
  },
  notifications: {
    list: [],
    total: ''
  },
  loading: false
}