import React from "react";
import {Input} from "Component/Input";
import { useEditOrder } from '../../../hooks/useEditOrder'
import { THEME_SEMANTICS } from '../../../../../common/theme/_semantics'
import { Text } from '../../../../../common/text'

export const Email = ()=>{
    const {email} = useEditOrder()
    const {methods} = email
    return (
        <Input
            label={
                <>
                    Email <Text color={THEME_SEMANTICS.failed}>*</Text>
                </>
            }
            value={email?.value}
            onChange={e => methods.onChange(e.target?.value)}
            onBlur={e => methods.onBlur(e.target?.value)}
            validateText={email?.message}
            validateType={email?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập email'}
            maxLength={201}
        />
    )
}