import { sendRequestAuth } from 'api/api'
import config from 'config'
import useAlert from 'hook/useAlert'
import { useContext, useRef, useState } from 'react'
import {CreatePrincipalContext} from "../provider/_context";
import {useCreatePrincipalAction} from "../provider/_reducer";
import {useParams} from "react-router-dom";

const useCreatePrincipalRow = data => {
    const { showAlert } = useAlert()

    const { pageState, pageDispatch } = useContext(CreatePrincipalContext)
    const { table} = pageState
    const isLoading = table.detail.loading
    const detailActive = table.detail.active
    const detailList = table.detail.list
    const shouldOpenDetail =
        data?.id && detailActive?.id === data.id

    const {principalId} = useParams()
    // ==================== ROW ========================================
    const rowDetailToggle = async () => {
        if (!data?.id) return
        if (data.id === detailActive?.id) {
            pageDispatch({
                type: useCreatePrincipalAction.TABLE_DISPLAY_DETAIL_UPDATE,
                payload: { active: null},
            })
            return
        }


        const findDetail = detailList.find(item => +item?.id === +data.id)
        if (findDetail) {
            pageDispatch({
                type: useCreatePrincipalAction.TABLE_DISPLAY_DETAIL_UPDATE,
                payload: { active: findDetail },
            })
            return
        }

        fetchOrderDetail()
    }

    const fetchOrderDetail = async loading => {
        pageDispatch({ type: useCreatePrincipalAction.TABLE_DETAIL_LOADING_UPDATE, payload: !!loading })
        const response = await sendRequestAuth(
            'get',
            `${config.API}/principal/${principalId}/teacher-detail/${data.id}`,
        )
        if (!!response?.data?.success) {
            const newItem = response?.data?.data
            pageDispatch({
                type: useCreatePrincipalAction.TABLE_DISPLAY_DETAIL_UPDATE,
                payload: { active: newItem, list: [...detailList,newItem]},
            })
        }
        pageDispatch({ type: useCreatePrincipalAction.TABLE_DETAIL_LOADING_UPDATE, payload: false })
    }

    return {
        detail: {
            isLoading,
            active: detailActive,
        },
        row: {
            shouldOpenDetail,
            onToggleDetail: rowDetailToggle,
            refreshOrderDetails: fetchOrderDetail,
        },
    }
}

export default useCreatePrincipalRow
