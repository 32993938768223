import React, {useContext} from 'react';
import {AlternativeAutoComplete} from "common/form/autoComplete/_alternativeAutoComplete";
import { Option } from 'common/form/autoComplete/_option';
import { useModal } from '../../../hooks/useModal'
import { Text } from '../../../../../common/text'
import { THEME_SEMANTICS } from '../../../../../common/theme/_semantics'
import {EventManagementContext} from "../../../provider/_context";

export const ProductEvent = () => {
   const { pageState } = useContext(EventManagementContext)
  const {product} = useModal()
  return (
    <AlternativeAutoComplete
      className="principal-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: {name: 'Sản phẩm', value: ''}, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 90,
        categoryHidden: true,
        placeholder: 'Chọn sản phẩm',
        readOnly: true,
        label: (
          <>
            Sản phẩm <Text color={THEME_SEMANTICS.failed}>*</Text>
          </>
        ),
        value: product?.value?.title || '',
        // onIconClick: () => product.onChange(null),
      }}
      // menu
      menuProps={{
        empty: product?.list?.length <= 0
                ? 'Không tìm thấy sản phẩm'
                : '',
      }}
      // search input in dropdown menu
      searchInputProps={{
        placeholder: 'Tìm kiếm sản phẩm',
        // value: category.keyword,
        onChange: product?.onKeywordChange,
      }}

      validateText={pageState.create.validate?.product}
      validateType={!pageState.create.validate?.product ? 'success' : 'danger'}
    >
      {product?.list?.length > 0 &&
      product?.list?.map(item => (
        <Option
          key={item.id}
          className="principal-filter-form__option-text"
          data-active={item.id === product.value?.id}
          onClick={() => product.onChange(item)}
        >
          {item?.title}
        </Option>
      ))}
    </AlternativeAutoComplete>
  )
}
