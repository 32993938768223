import { CREATE_PRINCIPAL_GENDER, TAB_CREATE_PRINCIPAL_EDIT } from '../interfaces/constant'

export const useCreatePrincipalInitialState = {
  infomation: {
    userName: {
      value: '',
      status: false,
      message: ''
    },
    schoolName: {
      value: '',
      status: false,
      message: ''
    },
    phone: {
      value: '',
      status: false,
      message: ''
    },
    email: {
      value: '',
      status: false,
      message: ''
    },
    name: {
      value: '',
      status: false,
      message: ''
    },
    lastName: {
      value: '',
      status: false,
      message: ''
    },
    birthdate: {
      value: '',
      status: false,
      message: '',
      trigger: false,
    },
    identify: {
      value: '',
      status: false,
      message: ''
    },
    gender: {
      value: CREATE_PRINCIPAL_GENDER[0],
      status: false,
      message: '',
      activeValue: CREATE_PRINCIPAL_GENDER[0]
    },
    address: {
      value: '',
      status: false,
      message: ''
    },
    password: {
      value: '',
      status: false,
      message: ''
    },
    statusPerson: {
      value: '',
      status: false,
      message: ''
    },
    accountNumber: {
      value: '',
      status: false,
      message: ''
    },
    commission: {
      value: '',
      status: false,
      message: ''
    },
    accountOwner: {
      value: '',
      status: false,
      message: ''
    },
    bankName: {
      value: '',
      status: false,
      message: ''
    },
    avatar: {
      value: '',
    },
    detail: null
  },
  tab: {
    list: TAB_CREATE_PRINCIPAL_EDIT,
    activeTab: TAB_CREATE_PRINCIPAL_EDIT[0]
  },
  productTab: {
    filter: {
      search: {
        value: null,
        active: null
      },
      status: {
        activeValue: null,
        value: null,
      },
      category: {
        list: [],
        listOrigin: [],
        activeValue: null,
        value: null,
        keyword: '',
      },
    },
    table: {
      display: {
        list: [],
        listDefault: [],
        loading: true,
      },
      selected: {
        list: [],
      },
      detail: {
        id: null,
        active: null,
        list: [],
      },
      pagination: {
        active: 0,
        amount: 20,
        total: 0,
        totalItems: 0,
      },
    },
    modalProduct: {
      show: false,
      filter: {
        search: {
          value: null,
          active: null
        },
        category: {
          list: [],
          listOrigin: [],
          activeValue: null,
          value: null,
          keyword: '',
        },
      },
      table: {
        display: {
          list: [],
          listDefault: [],
          loading: true,
        },
        detail: {
          id: null,
          active: null,
          list: [],
        },
        selected: {
          list: [],
        },
        pagination: {
          active: 0,
          amount: 200,
          total: 0,
          totalItems: 0,
        },
      },
    }
  },
  table: {
    display: {
      list: [],
      listDefault: [],
      loading: true,
    },
    detail: {
      id: null,
      active: null,
      list: [],
    },
    pagination: {
      active: 0,
      amount: 20,
      total: 0,
      totalItems: 0,
    },
  },
  deleteConfirm: {
    open: false,
    value: '',
    status: false,
    message: ''
  }

}