import React, {useEffect} from 'react';
import {TableLayout} from "../../layouts/tableLayout";
import useEventManagement from "./hooks/useEventManagement"
import {EventManagementProvider} from "./provider/index"
import Header from "./components/header";
import Thead from "./components/body/table/thead";
import Tbody from "./components/body/table/tbody";
import { ModalCreateEvent } from './components/modal'
import FilterForm from "./components/filter";
import {Loading} from "../../common/loading";
import {ShareModal} from "./components/shareModal";
import {eventManagementActions} from "./provider/_init";

const EventManagement = () => {
  const {provider, pagination, fetch} = useEventManagement()
  const {state, dispatch} = provider
  const {table} = state
  // const location = useLocation()

  useEffect(() => {
    fetch.origin()
  }, [])

  return (
    <EventManagementProvider value={{pageState: state, pageDispatch: dispatch}}>
      <Header />
      <TableLayout
         header={
            <FilterForm/>
         }
        table={{
          tHead: <Thead />,
          tBody: <Tbody />,
        }}
        pagination={{
          ...table.pagination,
          onAmountChange: pagination.onAmountChange,
          onPageChange: pagination.onPageChange,
        }}
      />
      <ModalCreateEvent/>
       {!!state?.loading && <Loading/>}

       {!!state.shareModal.open && <ShareModal onClose={() => dispatch({
          type: eventManagementActions.SET_DISPLAY_SHARE_MODAL,
          payload: {
             open: false,
             referral: null
          }
       })}/>}
   </EventManagementProvider>
  )
}

export default EventManagement