import {PATH} from "../../../../../const/path";

export const CREATE_PRINCIPAL_BREARDCUM=[
    {id: 1, name: 'Home', url: PATH.SETTING},
    {id: 2, name: 'Khách hàng', url: '#'},
    {id: 3, name: 'Hiệu trưởng/GV ', url: '#'},
    {id: 4, name: 'Thêm mới hiệu trưởng ', url: '#'}
]
export const EDIT_PRINCIPAL_BREARDCUM=[
    {id: 1, name: 'Home', url: PATH.SETTING},
    {id: 2, name: 'Khách hàng', url: '#'},
    {id: 3, name: 'Hiệu trưởng/GV ', url: '#'},
    {id: 4, name: 'Chỉnh sửa hiệu trưởng ', url: '#'}
]
export const CREATE_PRINCIPAL_FORM = [
    'userName',
    'schoolName',
    'phone',
    'email',
    'name',
    'lastName',
    'birthdate',
    'identify',
    'gender',
    'address',
    'password',
    'statusPerson',
    'accountNumber',
    'accountOwner',
    'nameBank'
]
export const CREATE_PRINCIPAL_GENDER=[
    {name: 'Nam', value: 'male'},
    {name: 'Nữ', value: 'female'},
]
export const STATUS_PRINCIPAL = [
    {id: 1, title: 'Đang hoạt động', value: 'active'},
    {id: 2, title: 'Đang khóa', value: 'inactive'},
]

export const TAB_CREATE_PRINCIPAL_EDIT = [
    {name: 'Thông tin hiệu trưởng', tab:'principal'},
    {name: 'Giáo viên trực thuộc', tab:'teachers'},
    {name: 'Khách hàng trực thuộc', tab:'customers'},
    {name: 'Sản phấm bán', tab:'products'},
]

export const TEACHER_TABLE_HEADER =[
    {name: 'Tên đăng nhập', id: 1},
    {name: 'Email', id: 2},
    {name: 'Tên giáo viên', id: 3},
    {name: 'Ngày tạo', id: 4},
    {name: 'Trạng thái', id: 5},
]

export const PRODUCT_TABLE_HEADER =[
    {name: 'Tên sản phẩm', id: 1},
    {name: 'Giá bán', id: 2},
    {name: 'Giá khuyến mãi', id: 3},
    {name: 'Thời hạn bản quyền', id: 4},
]
