import React from "react";
import {Input} from "../../../../../../Component/Input";
import {Text} from "../../../../../../common/text";
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";
import {useCreatePrincipalForm} from "../../hook/useCreatePrincipalForm";

export const BankName = ()=>{
    const {bankName} = useCreatePrincipalForm()
    const {methods} = bankName
    return (
        <Input
            label={
                <>
                    Ngân hàng
                </>
            }
            value={bankName?.value}
            onChange={e => methods.onChange(e.target?.value)}
            onBlur={e => methods.onBlur(e.target?.value)}
            validateText={bankName?.message}
            validateType={bankName?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập tên ngân hàng'}
            maxLength={201}
        />
    )
}