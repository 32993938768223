import { Button } from 'common/button'
import { Text } from 'common/text'
import { THEME_SEMANTICS } from 'common/theme/_semantics'
import { useState } from 'react'
import styled from 'styled-components'
import { SubmitPaymentModal } from './_submitPaymentModal'
import { formatMoney } from '../../../../util/functionUtil'
import { checkPermission } from '../../../../Component/NavBar/until'

export const RowTabPayment = ({ data, rowData, ...props }) => {
  const { row } = rowData

  const paid = Number(data?.total_payment || 0)
  const total = Number(data?.total_amount || 0)
  const pay = Number(data?.customer_pay || 0)
  const haveToPay = paid === 0 ? total : paid > total ? 0 : total - paid
  const customerPay = pay == 0 ? 0 : pay - total

  const shouldShowSubmitPayment = data?.payment_status !== 'paid' && data?.order_status !== 'canceled'

  const validPaymentPermission = checkPermission('order', 'payment')
  const [showPermission, setShowPermission] = useState(false)
  const handleOpenPayment = _ => {
    if (validPaymentPermission) {
      row.onOpenSubmitPaymentModal()
    } else setShowPermission(true)
  }
  return (
    <StyledRowTabPayment {...props}>
      {
        data && (
          <div className="row-tab-detail__content flex">
            {/*{showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)}/>}*/}
            <div className="row-tab-detail__content-group">
              <Text as="h4" fontSize={16} lineHeight={22}>
                Tiền thanh toán
              </Text>
              {!!data?.created_at && (
                <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">
                    Đã thanh toán
                  </Text>
                  <Text
                  >
                    {formatMoney(data?.payment_status === 'paid' ? data?.grand_total : 0)}
                  </Text>
                </div>
              )}
              {!!data?.updated_at && (
                <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">
                    Còn phải trả
                  </Text>
                  <Text> {formatMoney(data?.payment_status === 'paid' ? 0 : data?.grand_total)}</Text>
                </div>
              )}
            </div>


            <div className="row-tab-detail__content-group">
              <Text as="h4" fontSize={16} lineHeight={22}>
                Thông tin thanh toán
              </Text>
              {!!data?.payment_method && (
                <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">
                    Phương thức thanh toán
                  </Text>
                  <Text
                  >
                    {data.payment_method === 'COD' ? 'Thanh toán khi nhận hàng' : data?.payment_method}
                  </Text>
                </div>
              )}
              {!!data?.payment_status && (
                <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">
                    Trạng thái thanh toán
                  </Text>
                  <Text>
                    {data?.payment_status === 'paid' ? 'Đã thanh toán' : data?.payment_status === 'fail' ? 'Thanh toán thất bại' : 'Chưa thanh toán'}</Text>
                </div>
              )}
            </div>
            <div style={{ position: 'absolute', top: 0, right: 0 }}>
              {shouldShowSubmitPayment && (
                <>
                  <Button size="sm" onClick={() => handleOpenPayment()}>
                    Xác nhận thanh toán
                  </Button>
                  {row.shouldOpenSubmitPaymentModal && (
                    <SubmitPaymentModal
                      data={data}
                      onClose={row.onCloseSubmitPaymentModal}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )
      }
    </StyledRowTabPayment>
  )
}

const StyledRowTabPayment = styled.div`
  position: relative;
  .row-tab-detail__content{
    display: flex;
    &-group{
      width: 33.33%;
      h4{
        margin-bottom: 24px;
      }
      &--items{
        margin-bottom: 16px;
        p{
          margin-bottom: 8px;
        }
      }
    }
  }
  .row-tab-payment {
    &__list-item {
      margin-bottom: 12px;
      padding: 12px 24px;

      border-left: 3px solid ${THEME_SEMANTICS.delivered};

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__list-item-toggle {
      display: flex;
      align-items: center;

      cursor: pointer;

      &[data-active='true'] {
        svg {
          transform: rotate(0deg);
        }
      }

      svg {
        margin-left: 8px;

        transform: rotate(180deg);
        transition: transform 0.6s;
      }
    }

    &__list-item-content {
      max-height: 0;

      display: flex;
      flex-wrap: wrap;
      overflow: hidden;

      transition: max-height 0.5s;

      &[data-active='true'] {
        max-height: 75vh;
      }
    }

    &__list-item-figure {
      width: 250px;
      margin-top: 8px;
    }
  }
`
