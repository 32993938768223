import useRevenueFilterForm from 'Pages/revenue/hooks/useRevenueFilterForm'
import { CategoryInput } from '../../../../common/form/input/_categoryInput'

export const RevenueSearch = () => {
  const {orderId} = useRevenueFilterForm()
  return (
  <CategoryInput
    className={'revenue-filter-form__input-wide'}
    categoryList={[]}
    categoryValue={{name: 'Mã đơn hàng', value: ''}}
    categoryWidth={104}
    placeholder='Nhập mã đơn hàng'
    value={orderId?.value || ''}
    onChange={orderId.onChange}
    style={{ position: 'relative'}}
  />
  )
}
