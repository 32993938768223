import useCreateInfoProduct from '../../../hooks/useCreateInfoProduct'
import { PRODUCT_ICONS } from '../../../interfaces/~icon'
import { Text } from '../../../../../common/text'
import { THEME_SEMANTICS } from '../../../../../common/theme/_semantics'
import { THEME_COLORS } from 'common/theme/_colors'
import { Input } from '../../../../../common/form/input'
import { Textarea } from '../../../../../common/form/textarea'
import { CKEditor as Editor } from 'ckeditor4-react'
import styled from 'styled-components'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import CreateUnitManage from './createUnitManage'
import ZoomIn from '../modal/zoomIn'
import { DISPLAY_NAME_MENU } from '../../../../../const/display_name_menu'
import { useTranslation } from 'react-i18next'
import { GLOBAL_ICONS } from '../../../../../interface/icon'
import { Grid } from '@mui/material'
import { config } from './config'
import { AlternativeAutoComplete } from '../infoBasic/~alternativeAutoComplete'
import { Option } from '../../../../../common/form/autoComplete/_option'
import { BACKGOUND_PRODUCT } from '../../../interfaces/~constants'
import useAlert from '../../../../../hook/useAlert'
import { postData } from '../../../../../api/api'
import Slider from 'react-slick'

import {
  StyledFacebookResponseContentScriptCarouselModal,
  StyledFacebookResponseContentScriptDetailDrawer
} from './_styled'
import { Modal } from '../../../../../common/modal'
import { ProductContext } from '../../../provider/~context'
import { productActions } from '../../../provider/~action'

const InfoProduct = () => {
  const { t } = useTranslation()
  const { pageState, pageDispatch } = useContext(ProductContext)
  const { value, functions, validate } = useCreateInfoProduct()
  const images = pageState?.formCreate?.product?.image?.list
  const originImageIdList = pageState?.formCreate?.product?.image?.listOrigin
  const imageFiles = pageState?.formCreate?.product?.image?.imageFiles
  const { showAlert } = useAlert()

  const [validates, setValidates] = useState({
    images: { overload: false },
  })

  const [carouselModalData, setCarouselModalData] = useState(null)

  const handleImageFileChange = e => {
    const files = e.target.files
    let imageArr = []
    let count = 0
    for (let i = 0; i < files.length; i++) {
      if (count + images.length >= 5) {
        showAlert({
          type: 'danger',
          content: 'Chỉ được phép tải lên tối đa 5 ảnh',
        })
        break
      }
      const item = files[i]
      if (
        !['image/jpg', 'image/jpeg', 'image/png'].includes(
          item.type.toLowerCase(),
        )
      ) {
        showAlert({
          type: 'danger',
          content: 'Chỉ hỗ trợ các định dạng: .jpg, .jpeg, .png.',
        })
        break
      }
      if (item?.size <= 3000000) {
        imageArr.push({
          id: Math.random(),
          name: item?.name,
          url: window.URL.createObjectURL(item),
        })
        count++
      } else
        showAlert({
          type: 'danger',
          content: 'Ảnh tải lên không được vượt quá 3MB',
        })
    }
    pageDispatch(
      {
        type: productActions.FORM_CREATE_UPDATE_IMAGE_PRODUCT,
        payload: {
          list: [...images, ...imageArr].filter((item, index) => index < 5),
          imageFiles: [...imageFiles, ...files].filter((item, index) => index < (5 - images.length)),
        }
      }
    )
  }

  const handleImageDelete = id => {

    const index = images.findIndex(item => +item?.id === +id)
    imageFiles.splice(index, 1)
    pageDispatch(
      {
        type: productActions.FORM_CREATE_UPDATE_IMAGE_PRODUCT,
        payload: {
          list: images.filter(item => item?.id !== id),
          imageFiles: imageFiles,
        }
      }
    )
  }

  return (
    <StyledInfoProduct>
      <div className={'product-info-product'}>
        <p className={'product-info-product__title'}>{t(DISPLAY_NAME_MENU.PRODUCT_PAGE.IMAGE_PRODUCT)}</p>
        <div className={'product-info-product__img'}>
          <StyledFacebookResponseContentScriptDetailDrawer>
            <Galleries
              data={images}
              validate={validates?.images}
              onChange={handleImageFileChange}
              onCreateClick={() =>
                setValidates({
                  ...validates,
                  images: { ...validates.images, overload: false },
                })
              }
              onImageClick={index => setCarouselModalData({ defaultIndex: index })}
              onImageDelete={imageId => handleImageDelete(imageId)}
            />
            {!!carouselModalData && (
              <CarouselModal
                config={carouselModalData}
                data={images}
                onClose={() => setCarouselModalData(null)}
              />
            )}
          </StyledFacebookResponseContentScriptDetailDrawer>
        </div>
        <Grid container spacing={2}>
          <Grid xs={6} sm={6} md={6} lg={6} item>
            <div className="product-info-basic__form-input-1">
              <Input
                label={
                  <>
                    {t('Kích thước')}
                  </>
                }
                value={value?.formInfoProduct?.size}
                onChange={e => functions.onChangeSize(e.target?.value)}
                placeholder={t('Vui lòng chọn kích thước')}
                maxLength={50}
              />
            </div>
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} item>
            <div className="product-info-basic__form-input-1">

              <AlternativeAutoComplete
                // main input
                inputProps={{
                  categoryList: [], // menu list in category dropdown
                  categoryHidden: true,
                  readOnly: true,
                  categoryValue: { name: t('Vui lòng chọn Bìa'), value: '' },
                  label: <>
                    <div> Bìa
                    </div>
                  </>,
                  placeholder: t('Vui lòng chọn Bìa'),
                  value: value?.formInfoProduct?.cover?.title,
                }}
                hideSearchBar={true}
                // search input in dropdown menu
                menuProps={{
                  empty: '',
                }}
              >
                {
                  BACKGOUND_PRODUCT?.map((item, index) => (
                    <Option
                      key={index}
                      className="store-upos-filter-form__option-text"
                      data-active={+value?.formInfoProduct?.cover?.value === +item.value}
                      onClick={() => functions.onSelectCover(item)}
                    >
                      {item.title}
                    </Option>
                  ))}
              </AlternativeAutoComplete>
            </div>
          </Grid>
        </Grid>
        <div className={'product-info-product__click-more'}>
          <Textarea
            label={t('Mô tả ngắn sản phẩm')}
            placeholder={t('Vui lòng nhập mô tả ngắn sản phẩm')}
            style={{ resize: 'none' }}
            className={'product-info-product__note-group'}
            value={value?.formInfoProduct?.shortDescription}
            onChange={e => functions.onChangeShortDescription(e.target.value)}
            maxLength={200}
          />
          <Text as={'p'} style={{ margin: '24px 0 8px 0' }}>Mô tả sản phẩm</Text>
          <Editor
            initData={value?.formInfoProduct?.description}
            config={config}
            readOnly={false}
            type={'classic'}
            onChange={functions?.onChangeDescription}
          />
        </div>
      </div>
      <CreateUnitManage/>
      {value?.zoomInImage?.open && (
        <ZoomIn
          closeModal={() => functions.onZoomInImage(false, '')}
          linkActive={value?.zoomInImage?.linkActive}
        />
      )}
    </StyledInfoProduct>
  )
}

const CreateBox = ({ ...props }) => {
  const { t } = useTranslation()
  return (
    <StyledCreateBox {...props}>
      <div className="create-box__container">
        <i style={{ margin: '4px 4px 0 0' }}>{GLOBAL_ICONS.plus}</i>
        <Text color={THEME_SEMANTICS.delivering}>{t(DISPLAY_NAME_MENU.GENERAL.ADD_UNIT)}</Text>
      </div>
    </StyledCreateBox>
  )
}

export default InfoProduct

const Galleries = ({
  data,
  validate,
  onChange,
  onCreateClick,
  onImageClick,
  onImageDelete,
  ...props
}) => {
  const inputFileRef = useRef(null)
  return (
    <div
      {...props}
      className={`facebook-response-content-script-detail-drawer__galleries ${
        props?.className || ''
      }`}
    >

      <div className="facebook-response-content-script-detail-drawer__galleries-list">
        {data?.length > 0 && data.map((item, i) => (
          <div
            key={item?.id}
            className="facebook-response-content-script-detail-drawer__galleries-item"
            onClick={() => onImageClick && onImageClick(i)}
          >
            <img
              className="facebook-response-content-script-detail-drawer__galleries-background"
              src={item?.url}
              alt={item?.name}
            />
            <div
              className="facebook-response-content-script-detail-drawer__galleries-delete"
              onClick={e => {
                e.stopPropagation()
                if (onImageDelete) onImageDelete(item?.id)
              }}
            >
              {GLOBAL_ICONS.x2}
            </div>
          </div>
        ))}
        {data.length < 5 && (
          <>
            <div
              className="facebook-response-content-script-detail-drawer__galleries-create"
              onDrop={e => {
                e.preventDefault()
                onChange(e)
              }}
              onClick={() => {
                if (onCreateClick) onCreateClick()
                if (inputFileRef?.current) {
                  inputFileRef.current.value = ''
                  inputFileRef.current.click()
                }
              }}
            >
              {
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.5 3.74997V14.25"
                    stroke="#C8CBD4"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.25 8.99997H14.75"
                    stroke="#C8CBD4"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              <Text color={THEME_COLORS.primary_300} style={{ display: 'block' }}>
                Tải ảnh
              </Text>
              <Text color="#c8cbd4" style={{ display: 'block' }}>
                {data.length}/{5}
              </Text>
            </div>
            <input
              ref={inputFileRef}
              accept="image/png, image/jpeg, image/jpg"
              type="file"
              multiple={true}
              style={{ display: 'none' }}
              onChange={onChange}
            />
          </>
        )}
      </div>
      <Text
        as="p"
        color={validate?.overload ? THEME_SEMANTICS.failed : '#7C88A6'}
      >
        Tải tối đa 5 ảnh, mỗi ảnh không quá 3MB. Hỗ trợ các định dạng: .jpg, .jpeg, .png.
      </Text>
      <Text
        as="p"
        color={'rgb(234 166 36)'}
      >
        Đề xuất kích thước hình ảnh 600x800, tỉ lệ 3x4
      </Text>
    </div>
  )
}
export const CarouselModal = ({ config, data, onClose }) => {
  const [activeIndex, setActiveIndex] = useState(config?.defaultIndex || 0)

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    variableWidth: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true
  }

  return (
    <Modal
      closeIcon={true}
      containerProps={{
        style: {
          width: window.screen.width < 1400 ? 740 : 841,
          padding: '15px 0',
        },
      }}
      style={{
        background: 'rgba(0, 0, 0, 0.25)',
      }}
      onClose={onClose}
    >
      <StyledFacebookResponseContentScriptCarouselModal>
        <div className="facebook-response-content-script-carousel-modal__container">
          <div className="facebook-response-content-script-carousel-modal__slides">
            {data.map((item, i) => (
              <div
                key={item?.id}
                className="facebook-response-content-script-carousel-modal__slide"
                style={{ left: `${(i - activeIndex) * 100}%` }}
              >
                <img src={item?.url} alt={item?.name}/>
              </div>
            ))}
          </div>
          <div className="facebook-response-content-script-carousel-modal__arrows">
            <div
              className="facebook-response-content-script-carousel-modal__arrow"
              data-arrow="prev"
              data-disabled={activeIndex <= 0}
              onClick={() => {
                activeIndex > 0 && setActiveIndex(activeIndex - 1)
                // sliderRef?.current?.slickPrev()
              }}
            >
              {GLOBAL_ICONS.chevronDown}
            </div>
            <div
              className="facebook-response-content-script-carousel-modal__arrow"
              data-arrow="next"
              data-disabled={activeIndex >= data.length - 1}
              onClick={() => {
                activeIndex < data.length - 1 && setActiveIndex(activeIndex + 1)
                // sliderRef?.current?.slickNext()
              }
              }
            >
              {GLOBAL_ICONS.chevronDown}
            </div>
          </div>
        </div>
      </StyledFacebookResponseContentScriptCarouselModal>
    </Modal>
  )
}
export const StyledInfoProduct = styled.div`
  .tooltip_select {
    display: -webkit-box;
    height: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    
    &:hover span {
      color: rgb(229, 16, 29) !important;
    }
  }
  .product-info-product {
    &__title {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 16px;
    }
    &__img {
      display: flex;
      position: relative;
      
      &--remove {
        position: absolute;
        left: 91px;
        top: -10px;
        cursor: pointer;
      }
      
      &-src {
        width: 104px;
        height: 104px;
        margin-left: -40px;
        margin-top: -26px;
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      
      &--current {
        width: 104px;
        height: 104px;
        text-align: center;
        background: white;
        border: 1px solid #cfdbe8;
        border-radius: 6px;
        img {
          border-radius: 6px;
          max-width: 102px;
          height: 102px;
        }
        &:hover {
          cursor: pointer;
          &::before {
            content: 'See photo';
            position: absolute;
            width: 104px;
            height: 104px;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 6px;
            
            color: white;
            text-align: center;
            padding-top: 42px;
            font-size: 14px;
            font-weight: 400;
            left: 0;
          }
        }
      }
    }
    &__img-placeholder {
      background: rgba(26, 148, 255, 0.05);
      width: 104px;
      height: 104px;
      padding: 28px 22px 26px 22px;
      text-align: center;
      border-radius: 6px;
      cursor: pointer;
      &--error {
        padding: 28px 20px 26px 20px;
        border: 1px dashed red;
      }
      
      p {
        margin-top: 10px;
        font-weight: 400;
        font-size: 14px;
      }
    }
    &__note-image {
      font-style: italic;
      font-weight: 400;
      font-size: 13px;
      color: #7C88A6;
      margin: auto 16px;
    }
    &__unit-and-weight {
      display: flex;
    }
    &__unit-manage {
      width: 27.5rem;
      margin-right: 8px;
      flex-basis: 50%;
    }
    &__weight {
      width: 27.5rem;
      position: relative;
      flex-basis: 50%;
      
      &--dropdown-icon {
        color: rgb(229, 16, 29) !important; 
        position: absolute;
        right: 4px;
        display: flex;
        bottom: 8px;
        cursor: pointer;
      }
      
      & .input__dropdown {
        width: 36px;
        padding: 12px;
        margin-left: 25.125rem;
        margin-top: -8px;
        cursor: pointer;
        font-size: 14px;
        overflow: hidden;
      }
      &--dropdown {
        position: absolute;
        right: 0;
        background: white;
        box-shadow: var(--box-shadow);
        padding: 10px 16px;
        border-radius: 4px;
        top: 60px;
      }
    }
    &__weight-title {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 12px;
    }
    &__click-more {
      margin-top: 24px;
      cursor: pointer;
      &--collapse {
        margin-left: 2px;
        svg {
          transform: rotate(180deg);
        }
      }
    }
    &__option-text[data-active='true'] span{
      color: rgb(229, 16, 29) !important;
    }
    &__weight-dropdown-toggle {
      i[data-active='true'] {
        transform: rotate(180deg);
        transition: transform 0.25s;
      }
      i[data-active='false'] {
        transform: rotate(0);
        transition: transform 0.25s;
      }
    }
    &__note-group {
      height: 80px;
      margin-bottom: 40px;
      textarea {
        height: 80px;
      }
    }
  }
  
  .product-info-basic {
    //&__list-parent--tooltip {
    //  display: -webkit-box;
    //  height: 100%;
    //  -webkit-line-clamp: 1;
    //  -webkit-box-orient: vertical;
    //  overflow: hidden;
    //  text-overflow: ellipsis;
    //}
    &__toggle-item {
      position: absolute;
      top: 27px;
      right: 24px;
    }
    &__form-input-1 {
      margin-top: 24px;
    }
    &__form-input-code {
      margin-right: 8px;
    }
    &__form-input-barcode {
      margin-left: 8px;
    }
    &__form-input-group-product {
      position: relative;
      &--add {
        position: absolute;
        bottom: 72px;
        right: 24px;
        font-size: 14px;
        z-index: 2;
        
        svg {
          margin-left: 4px;
        }
        
        div {
          position: absolute;
          left: -24px;
          top: -2px;
          cursor: pointer;
        }
        span {
          color: #1A94FF;
          cursor: pointer;
        }
      }
    }
    
    &__list-parent {
      width: 50%;
      height: 260px;
      overflow: auto;
      &--wrapper {
        //height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 8px 0;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        
        & span:hover {
          color: rgb(229, 16, 29) !important;
        }
        &[data-type='true'] span{
          color: rgb(229, 16, 29) !important;
        }
      }
      &--tooltip {
        max-width: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 8px;
      }
    }
    &__list-child {
      border-left: 1px solid #EBEEF5;
      padding-left: 20px;
      width: 48%;
      height: 260px;
      overflow: auto;
      &--wrapper {
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 16px 0;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
      }
      &--tooltip {
        display: -webkit-box;
        height: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
   
.store-upos-filter-form {
    &__group {
      width: calc(100% + 12px);
      margin: 0 -6px 16px -6px;

      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & > * {
        margin: 0 6px;
      }
    }

    &__collapse {
      max-height: 0;
      margin-bottom: 0 !important;

      overflow: hidden;

      transition: all 0.25s;

      &[data-collapse='true'] {
        max-height: 50vh;
        margin-bottom: 16px;

        overflow: unset;
      }
    }

    &__input-wide {
      width: calc(25% - 12px);
      margin: 0 6px;

      @media screen and (max-width: 1599px) {
        width: calc((100% / 3) - 12px);
      }
    }

    &__option-text {
      min-height: 36px;

      display: flex;
      align-items: center;

      color: ${THEME_COLORS.gray_900};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      cursor: pointer;

      transition: color 0.25s;

      &[data-active='true'] {
        color: ${THEME_COLORS.primary_300};
        font-weight: 600;
      }
      &:hover {
        color: ${THEME_COLORS.primary_300};
      }
    }

    &__option-container {
      min-height: 45px;
      margin-bottom: 4px;

      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__option-tabs {
      position: sticky;
      top: 0;
      z-index: 1;

      width: 100%;
      height: 28px;
      margin-bottom: 16px;

      display: flex;
      align-items: center;

      background: #fff;

      &::before {
        position: absolute;
        top: -20px;
        left: -20px;

        width: calc(100% + 40px);
        height: calc(100% + 36px);

        background: #fff;
        border-radius: 8px 8px 0 0;

        content: '';
      }
    }

    &__option-tab {
      position: relative;
      z-index: 2;

      margin-right: 16px;

      color: #808089;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      cursor: pointer;

      &[data-active='true'] {
        color: ${THEME_COLORS.primary_300};
        font-weight: 600;

        cursor: default;
      }
    }

    &__collapse {
      .store-upos-filter-form__input-wide {
        margin-bottom: 16px;
      }
    }
  }
`

const StyledCreateBox = styled(Text)`
  position: sticky;
  bottom: -12px;
  z-index: 1;

  height: 48px;
  width: 100% !important;

  display: block;

  background: #fff;

  cursor: pointer;

  .create-box {
    &__container {
      height: 100%;

      display: flex;
      align-items: center;
    }
  }

`
