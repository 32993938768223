import {useContext} from "react";
import {PrincipalContext} from "../provider/_context";
import {usePrincipalAction} from "../provider/_reducer";
import {sendRequestAuth} from "../../../api/api";
import config from "../../../config";
import useAlert from "../../../hook/useAlert";

export const usePrincipalTable = ()=>{
    const { pageState, pageDispatch } = useContext(PrincipalContext)
    const { filter, table } = pageState

    const {showAlert} = useAlert()

    const statusValue = filter?.status?.value
    const queries = {
        per_page: table?.pagination?.amount || 20,
        keyword: filter?.search?.value || '',
        status: statusValue?.value || '',
        start: 0
    }
    const fetchPrincipalByFilter = async (qs, opt) => {
        pageDispatch({
            type: usePrincipalAction.TABLE_LOADING_DISPLAY,
            payload: true
        })
        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(qs)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        const response = await Promise.all([
            sendRequestAuth('get', `${config.API}/principal/principals${queryString}`),
        ])
        if (response[0]?.data?.success) {
            pageDispatch({
                type: usePrincipalAction.TABLE_LOADING_DISPLAY,
                payload: false
            })
            const books = response[0]?.data
            pageDispatch({
                type: usePrincipalAction.TABLE_UPDATE_DISPLAY_LIST,
                payload: {
                    list: books?.data,
                    listDefault: books?.data,
                    loading: false
                }
            })
            pageDispatch({
                type: usePrincipalAction.TABLE_UPDATE_PAGINATION,
                payload: {
                    active: 0,
                    amount: books?.meta?.per_page,
                    total: Math.ceil(books?.meta?.total / books?.meta?.per_page),
                    totalItems: books?.meta?.total,
                }
            })
        }
    }
    const handleStatus = async (id, type)=>{
        const url = type === "inactive" ? 'unlock' : 'lock'
        const response = await sendRequestAuth('post',`${config.API}/principal/${url}/${id}`)
        if(response?.data?.success){
            showAlert({type:'success', content: response?.data?.message})
            fetchPrincipalByFilter(queries)
        }else showAlert({type:'danger', content: response?.data?.message})
    }

    return{
        methods:{
            onChangeStatus: handleStatus
        }
    }
}