import {STORE_UPOS_FILTER_FORM_DATE_TIME_SORT_TYPES} from "../interface/_contants"

export const bookManagementInitialState = {
  filter: {
    search: {
      value: '',
      activeValue:''
    },
    advancedSearch: {
      customer: {
        keyword: '',
        value: '',
      },
      liveVideoId: '',
    },
    dateTime: {
      activeValue: {
        end: '',
        start: '',
        type: STORE_UPOS_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
        value: '',
      },
      end: '',
      start: '',
      type: STORE_UPOS_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
      trigger: true,
      value: '',
    },
    category: {
      list: [],
      listOrigin: [],
      activeValue: null,
      value: null,
      keyword: '',
    },
    source: {
      list: [],
      listOrigin: [],
      keyword: '',
      tab: 'all', // all | checked
      activeValue: [],
      value: [],
    },
    status: {
      activeValue: null,
      value: null,
    },
  },
  delete: {
    open: false,
    id: ''
  },
  panels: {
    storeTotal: 0,
    orderTotal: 0,
  },
  table: {
    display: {
      list: [],
      listDefault: [],
      loading: true,
    },
    pagination: {
      active: 0,
      amount: 20,
      total: 3,
      totalItems: 59,
    },
    selected: {
      list: [],
    },
    detail: {
      id: null,
      active: null,
      list: [],
    },
  },

  importExcel: {
    open: false,
    result: {
      open: false,
      data: null
    },
  }
}

export const bookManagementActions = {
  FILTER_SEARCH_UPDATE: 'FILTER_SEARCH_UPDATE',
  FILTER_SEARCH_ACTIVE_VALUE_UPDATE: 'FILTER_SEARCH_ACTIVE_VALUE_UPDATE',
  FILTER_DATE_TIME_UPDATE: 'FILTER_DATE_TIME_UPDATE',
  FILTER_DATE_TIME_ACTIVE_UPDATE: 'FILTER_DATE_TIME_ACTIVE_UPDATE',
  FILTER_CATEGORY_LIST_UPDATE: 'FILTER_CATEGORY_LIST_UPDATE',
  FILTER_CATEGORY_LIST_ORIGIN_UPDATE: 'FILTER_CATEGORY_LIST_ORIGIN_UPDATE',
  FILTER_CATEGORY_VALUE_UPDATE: 'FILTER_CATEGORY_VALUE_UPDATE',
  FILTER_CATEGORY_ACTIVE_VALUE_UPDATE: 'FILTER_CATEGORY_ACTIVE_VALUE_UPDATE',
  FILTER_SOURCE_LIST_UPDATE: 'FILTER_SOURCE_LIST_UPDATE',
  FILTER_SOURCE_LIST_ORIGIN_UPDATE: 'FILTER_SOURCE_LIST_ORIGIN_UPDATE',
  FILTER_SOURCE_VALUE_UPDATE: 'FILTER_SOURCE_VALUE_UPDATE',
  FILTER_SOURCE_ACTIVE_VALUE_UPDATE: 'FILTER_SOURCE_ACTIVE_VALUE_UPDATE',
  FILTER_SOURCE_TAB_UPDATE: 'FILTER_SOURCE_TAB_UPDATE',
  FILTER_STATUS_VALUE_UPDATE: 'FILTER_STATUS_VALUE_UPDATE',
  FILTER_STATUS_ACTIVE_VALUE_UPDATE: 'FILTER_STATUS_ACTIVE_VALUE_UPDATE',

  PANEL_STORE_TOTAL: 'PANEL_STORE_TOTAL',
  PANEL_ORDER_TOTAL: 'PANEL_ORDER_TOTAL',

  TABLE_LOADING_DISPLAY: 'TABLE_LOADING_DISPLAY',
  TABLE_UPDATE_DISPLAY_LIST: 'TABLE_UPDATE_DISPLAY_LIST',
  TABLE_UPDATE_PAGINATION: 'TABLE_UPDATE_PAGINATION',
  TABLE_DISPLAY_DETAIL_ID_UPDATE: 'TABLE_DISPLAY_DETAIL_ID_UPDATE',
  TABLE_DISPLAY_DETAIL_UPDATE: 'TABLE_DISPLAY_DETAIL_UPDATE',
  TABLE_SELECTED_LIST_UPDATE: 'TABLE_SELECTED_LIST_UPDATE',
  MODAL_DELETE_PRODUCT: 'MODAL_DELETE_PRODUCT',

  MODAL_IMPORT_PRINCIPAL: 'MODAL_IMPORT_PRINCIPAL',
  MODAL_IMPORT_RESULT_OPEN: 'MODAL_IMPORT_RESULT_OPEN',
  MODAL_IMPORT_RESULT_DATA: 'MODAL_IMPORT_PRINCIPAL',
}