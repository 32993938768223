import {CircularProgress} from '@mui/material'
import {sendRequestAuth} from 'api/api'
import {Button} from 'common/button'
import {Text} from 'common/text'
import {THEME_SEMANTICS} from 'common/theme/_semantics'
import config from 'config'
import useAlert from 'hook/useAlert'
import {useContext, useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import readXlsxFile from 'read-excel-file'
import {StyledImportFileModal} from './_styled'
import {IMPORT_ICON} from "./_icon";
import Papa from 'papaparse';
export const ImportFileModal = ({onClose, ...props}) => {

    const {
        columnNames,
        startRowNumber,
        collectedData,
        url,
        title,
        importFile,
        body,
        positionStart,
        result,
        positionCsvStart,
        collectedDataCsv
    } = props

    const {showAlert} = useAlert()
    const navigate = useNavigate()
    const [canRunProgress, setCanRunProgress] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [shouldShowProgress, setShouldShowProgress] = useState(false)

    const [fileName, setFileName] = useState('')
    const [progressValue, setProgressValue] = useState(0)
    const [rowsGroup, setRowsGroup] = useState([])
    const [submitData, setSubmitData] = useState(null)
    const [validate, setValidate] = useState(null)

    const inputFileRef = useRef()
    const handleClose = () => {
        if (isSubmitting) return

        handleInputReset()
        onClose()
    }

    const handleReadCsv = async (file)=>{
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                header: true, // Set to true if your CSV file has a header row
                dynamicTyping: true, // Convert numeric values to numbers
                skipEmptyLines: true,
                complete: (result) => {
                    resolve(result.data);
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    }

    const handleInputFileChange = async e => {
        const file = e.target.files[0]
        const fileName = file.name;
        if (!!!fileName.endsWith('.xls') && !!!fileName.endsWith('.xlsx') && !!!fileName.endsWith('.csv')){
            // Không thay đổi thứ tự các cột trong file mẫu.
            warningWrongValidation(
              'Mẫu file tải lên có định dạng không hợp lệ, vui lòng kiểm tra lại.',
              '',
            )
            return
        }
        if (!file) return

        setShouldShowProgress(true)
        setFileName(file.name)

        const size = file?.size ? file.size : 0


        if(size > 3000000){
            warningWrongValidation(
              'File tải lên hiện đang vượt quá kích thước quy định.',
              '',
            )
            return
        }
        let formatSize = ''
        if (size < 1000) formatSize = `${size} Bytes`
        else if (size < 3000000) formatSize = `${Math.ceil(size / 100) / 10} KB`
        else formatSize = `${Math.ceil(size / 100000) / 10} MB`

        let data = ''
        let dataCsv = ''
        if (!!fileName.endsWith('.csv')) dataCsv = await handleReadCsv(file)
        else data = await readXlsxFile(file)


        if (Array.isArray(data)) {
            if (
                JSON.stringify(
                    data[startRowNumber - positionStart] ,
                ).toLowerCase() !==
                JSON.stringify(
                    columnNames,
                ).toLowerCase()
            ) {
                // Không thay đổi thứ tự các cột trong file mẫu.
                warningWrongValidation(
                    'Mẫu file tải lên không hợp lệ, vui lòng kiểm tra lại.',
                    formatSize,
                )
                return
            }

            if (validate) setValidate(null)
            let sliceData = data.slice(
                startRowNumber - 1,
            )
            if(sliceData.length > 1000) sliceData = sliceData.slice(0, 1001)
            const collected = collectedData(data)
            // collectedData là 1 func nhận data sliceData để truyền cái giá trị vào params truyền lên api

            // split to chunks
            const rawData = [...sliceData]

            let result = []
            for (let i = 50; i > 0; i--) {
                result.push(rawData.splice(0, Math.ceil(rawData.length / i)))
            }
            setSubmitData(collected)
            setRowsGroup(result)
            setCanRunProgress(true)
        } else if (Array.isArray(dataCsv)) {
            if (
                JSON.stringify(
                    Object.values(dataCsv[positionCsvStart] || "")
                )?.toLowerCase() !==
                JSON.stringify(
                    columnNames,
                ).toLowerCase()
            ) {
                // Không thay đổi thứ tự các cột trong file mẫu.
                warningWrongValidation(
                    'Mẫu file tải lên không hợp lệ, vui lòng kiểm tra lại.',
                    formatSize,
                )
                return
            }
            if (validate) setValidate(null)
            let sliceData = dataCsv.slice(
                positionCsvStart + 1,
            )
            if (sliceData.length > 1000) sliceData = sliceData.slice(0, 1001)
            const collected = collectedDataCsv(dataCsv)
            // collectedData là 1 func nhận data sliceData để truyền cái giá trị vào params truyền lên api
            // split to chunks
            const rawData = [...sliceData]

            let result = []
            for (let i = 50; i > 0; i--) {
                result.push(rawData.splice(0, Math.ceil(rawData.length / i)))
            }
            setSubmitData(collected)
            setRowsGroup(result)
            setCanRunProgress(true)
        } else
            warningWrongValidation(
                'Mẫu file tải lên không hợp lệ, vui lòng kiểm tra lại.',
                formatSize,
            )
    }

    const handleInputReset = () => {
        if (inputFileRef?.current) inputFileRef.current.value = null

        setCanRunProgress(false)
        setCanSubmit(false)
        setIsSubmitting(false)
        setShouldShowProgress(false)

        setFileName('')
        setProgressValue(0)
        setRowsGroup([])
        setSubmitData(null)
        setValidate(null)
    }

    const handleRunProgress = () => {
        rowsGroup.forEach((rows, i) => {
            const currentProgress = ((i + 1) * 100) / rowsGroup.length
            setProgressValue(currentProgress)
        })

        const currentSubmitData = submitData
        const submitDetails = currentSubmitData?.details

        if (Array.isArray(submitDetails)) {
            currentSubmitData.details = submitDetails
            setSubmitData(currentSubmitData)
        }

        setTimeout(() => setCanSubmit(true), 500)
    }

    const handleSubmit = async () => {
        setIsSubmitting(true)
        let queries = {}
        queries = {
            ...submitData,
        }
        const response = await sendRequestAuth(
            'post',
            `${config.API}/${url}`,
            JSON.stringify(queries),
        )

        if (!!response.data?.success) {
            if (onClose) onClose()
            result(response.data)
        } else {
            showAlert({
                content: 'Mẫu file tải lên không hợp lệ, vui lòng kiểm tra lại',
                type: 'danger',
            })
            // methods.onUpdateNotification(response.data)
            if (onClose) onClose()
        }

        handleInputReset()

    }

    const handleUploadBtnClick = () =>
        inputFileRef?.current && inputFileRef.current.click()

    const warningWrongValidation = (content, size) => {
        if (inputFileRef?.current) inputFileRef.current.value = null

        setValidate({
            content,
            size,
        })

        setShouldShowProgress(false)
    }

    useEffect(() => {
        if (canRunProgress) handleRunProgress()
    }, [canRunProgress])
    return (
        <StyledImportFileModal {...props} onClick={handleClose}>
            <div
                className="import-file-modal__container"
                onClick={e => e.stopPropagation()}
            >
                <div className="import-file-modal__header">
                    <Text as="h2" fontSize={20} lineHeight={28} style={{marginBottom: 8}}>
                        {title}
                    </Text>
                    {isSubmitting && <Text
                        color={'#7c88a6'}
                    >Giúp bạn tải lên và import hàng loạt thông tin từ file excel</Text>}
                </div>
                <div className="import-file-modal__body">
                    {!isSubmitting ? (
                        <div className="import-file-modal__content">
                            <div className="import-file-modal__content-items">
                                <img
                                    className="import-file-modal__banner"
                                    src="/img/import-file-banner.png"
                                    alt="banner"
                                />
                                {shouldShowProgress ? (
                                    <div className="import-file-modal__progress">
                                        <Text as="b">{fileName}</Text>
                                        <div
                                            className="import-file-modal__runner"
                                            style={{'--value': `${progressValue}%`}}
                                        ></div>
                                        <button
                                            className="import-file-modal__reset"
                                            onClick={handleInputReset}
                                        >
                                            {IMPORT_ICON.delete}
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        {validate ? (
                                            <>
                                                <Text
                                                    as="b"
                                                    color={THEME_SEMANTICS.failed}
                                                    style={{marginTop: 16}}
                                                >
                                                    {validate?.content}
                                                </Text>
                                                <Text
                                                    color="#7C88A6"
                                                    fontSize={12}
                                                    lineHeight={17}
                                                    style={{marginBottom: 8}}
                                                >
                                                    {validate?.size}
                                                </Text>
                                            </>
                                        ) : (
                                            <Text as="b">Chọn file import</Text>
                                        )}
                                        <Button
                                            className="import-file-modal__trigger-file"
                                            appearance="secondary"
                                            size="xs"
                                            onClick={handleUploadBtnClick}
                                        >
                                            Chọn file
                                        </Button>
                                    </>
                                )}
                                <input
                                    ref={inputFileRef}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    type="file"
                                    style={{display: 'none'}}
                                    onChange={handleInputFileChange}
                                />
                                {
                                    body &&  <div style={{width: '100%', marginTop: '24px'}}
                                                  className={'import-file-modal__content-general-info'}>
                                        {body}
                                    </div>
                                }

                            </div>
                            <div className="import-file-modal__description">
                                <ul>
                                    <li>
                                        <Text color="#7C88A6" fontSize={13}>
                                            Tải xuống file mẫu
                                            <Text as={'a'} href={importFile} target={'blank'}
                                                  color={'#1A94FF'}
                                                  style={{cursor: 'pointer'}}> tại đây</Text>
                                            , điền đủ và đúng thông tin theo mẫu.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text color="#7C88A6" fontSize={13}>
                                            Chỉ nhận file có định dạng .xls, .xlsx, .csv và dung lượng dưới 3MB.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text color="#7C88A6" fontSize={13}>
                                            Chỉ hỗ trợ tối đa 1000 dòng cho một lần nhập. Trường hợp trên 1.000 đơn hệ
                                            thống sẽ tự động loại bỏ những dòng thừa.
                                        </Text>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    ) : (
                        <div className="import-file-modal__uploading">
                            <CircularProgress
                                className="import-file-modal__spinner"
                                color="success"
                                size={54}
                                thickness={5}
                            />
                            <Text style={{marginTop: 24}}>
                                Hệ thống đang xử lý dữ liệu, chờ chúng tôi một tí nhé!
                            </Text>
                        </div>
                    )}
                </div>
                {!isSubmitting && (
                    <div className="import-file-modal__footer">
                        <Button
                            className="import-file-modal__footer-btn"
                            appearance="ghost"
                            size="sm"
                            onClick={handleClose}
                        >
                            Đóng
                        </Button>
                        <Button
                            className="import-file-modal__footer-btn"
                            disabled={!canSubmit}
                            size="sm"
                            onClick={() => canSubmit && handleSubmit()}
                        >
                            Tải lên
                        </Button>
                    </div>
                )}
            </div>

        </StyledImportFileModal>
    )
}
