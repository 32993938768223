import styled from 'styled-components'
import {EVENT_STATUSES} from "../../../interface/_contants";

export const CellStatusEvent = ({id, ...props}) => {
  return (
    <StyledCellStatusEvent
      {...props}
      style={{
        background: EVENT_STATUSES[`${id}`].background,
        color: EVENT_STATUSES[`${id}`].color,
      }}
    >
      {props?.children}
    </StyledCellStatusEvent>
  )
}

const StyledCellStatusEvent = styled.div`
  padding: 3px 12px;

  display: inline-block;

  background: #eff3fb;
  border-radius: 4px;

  color: #7c88a6;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`
