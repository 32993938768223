import {Text} from 'common/text'
import {THEME_COLORS} from 'common/theme/_colors'
import {THEME_SEMANTICS} from 'common/theme/_semantics'
import {useContext, useState} from 'react'
import styled from 'styled-components'
import {Tooltip as TooltipV2} from 'common/tooltipv2'
import {Loading} from '../../../../common/loading'
import {customerActions} from "../../provider/_reducer";
import {CustomerContext} from "../../provider/_context";
import {Button} from "../../../../common/button";

export const RowTabDetail = ({data, rowData, ...props}) => {
   const [isLoading, setIsLoading] = useState(null)
   const {pageState, pageDispatch} = useContext(CustomerContext)

   const {detail} = rowData
   const openMoveCustomerModal = () => {
      pageDispatch({
         type: customerActions.UPDATE_CUSTOMER_MODAL,
         payload: {
            dataCurrent: rowData?.row?.data,
            open_modal: true
         }
      })
   }

   return (
      <StyledRowTabDetail>
         {/*{showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)}/>}*/}
         <div className="row-tab-detail__content">
            <div className="row-tab-detail__content-group">
               <Text as="h4" fontSize={16} lineHeight={22}>
                  Thông tin khách hàng
               </Text>

               <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">
                     Tên khách hàng
                  </Text>
                  <Text>
                     {detail?.active?.name || '---'}
                  </Text>
               </div>

               <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">
                     IDzalo
                  </Text>
                  <Text>
                     {detail?.active?.zalo_id || '---'}
                  </Text>
               </div>
            </div>
            <div className="row-tab-detail__content-group">
               <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">
                     Số điện thoại / Email
                  </Text>
                  <Text
                     color={THEME_SEMANTICS.delivering}
                  >
                     {(detail?.active?.phone || '---') + ' / ' + (detail?.active?.email || '---')}
                  </Text>
               </div>
               <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">
                     Địa chỉ
                  </Text>
                  <TooltipV2
                     title={detail?.active?.address}
                     className="customer-table__tooltipV2"
                  >
                     <Text>{detail?.active?.address || '---'}</Text>
                  </TooltipV2>
               </div>
            </div>
            <div className="row-tab-detail__content-group">
               <Text as="h4" fontSize={16} lineHeight={22}>
                  Nhóm khách hàng
               </Text>
               <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">
                     Loại khách hàng
                  </Text>
                  <Text>{!!detail?.active?.teacher_name ? 'Có giáo viên giới thiệu' : 'Không có giáo viên giới thiệu'}</Text>
               </div>
               {!!detail?.active?.teacher_name &&
               <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">
                     Giáo viên giới thiệu
                  </Text>
                  <Text>{detail.active.teacher_name}</Text>
               </div>
               }
            </div>
            <Button className="row-tab-detail__content-group_btn" onClick={openMoveCustomerModal}>Chuyển GV giới thiệu</Button>
         </div>
         {!!isLoading && (
            <Loading/>
         )}
      </StyledRowTabDetail>
   )
}

const StyledRowTabDetail = styled.div`
  .row-tab-detail {
    &__content {
      position: relative;

      margin-bottom: 12px;

      display: flex;
      flex-wrap: wrap;
      &-note {
        display: flex;
        justify-content: right;
        margin-top: -12px;
      }
    }

    &__content-group {
      width: calc(100% / 3 - 12px);
      margin-bottom: 12px;
      margin-right: 12px;
      &_btn{
        position: absolute;
        top: 0;
        right: 0;
      }
      h4 {
        margin-bottom: 12px;
      }
      &--items {
        margin-bottom: 12px;
      }
    }

    &__info-table {
      margin-bottom: 12px;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__inventory {
      min-height: 40px;
      margin-bottom: 12px;
      padding: 10px 0;

      display: flex;
      align-items: center;

      border-bottom: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &__table {
      margin-bottom: 12px;

      overflow: hidden;

      border: 1px solid #e2eaf8;
      border-radius: 8px;
    }

    &__thead {
      .row-tab-detail__tr {
        background: #f7f9fd;
      }
    }

    &__tr {
      display: flex;
    }

    &__th {
      min-height: 44px;
      padding: 12px;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;

      &:nth-child(1) {
        width: 13%;
      }
      &:nth-child(2) {
        flex: 1;
      }
      &:nth-child(3) {
        width: 16%;
      }
      &:nth-child(4) {
        width: 7%;
      }
      &:nth-child(5) {
        width: 13%;

        text-align: right;
      }
      &:nth-child(6) {
        width: 13%;

        text-align: right;
      }
      &:nth-child(7) {
        width: 5%;

        text-align: right;
      }
    }

    &__td {
      min-height: 56px;
      padding: 18px 12px;

      border-top: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &:nth-child(1) {
        width: 13%;
      }
      &:nth-child(2) {
        flex: 1;

        color: ${THEME_SEMANTICS.delivering};
      }
      &:nth-child(3) {
        width: 16%;
      }
      &:nth-child(4) {
        width: 7%;
      }
      &:nth-child(5) {
        width: 13%;

        text-align: right;
      }
      &:nth-child(6) {
        width: 13%;

        text-align: right;
      }
      &:nth-child(7) {
        width: 5%;
        cursor: pointer;
        text-align: right;
      }
    }

    &__result-item {
      margin-bottom: 8px;

      display: flex;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      line-height: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__result-label {
      padding: 0 12px;

      flex: 1;

      text-align: right;
    }

    &__result-value {
      width: 13%;
      padding: 0 12px;

      text-align: right;
    }
  }
`
