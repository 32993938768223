import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {GLOBAL_ICONS} from "../../../../interface/icon";
import {Tr} from "../../../../layouts/tableLayout/_tr";
import {Th} from "../../../../layouts/tableLayout/_th";
import CheckBoxConsignment from "../../../../Component/CheckBoxConsignment/CheckBoxConsignment";

const TheadLicense = ({...props}) => {
    const {t} = useTranslation()
    return (
        <StyledThead>
            <Tr {...props} type="tHead" style={{ height: '44px' }}>
                <Th className="lincese-key-table__cell">
                    {/*<CheckBoxConsignment/>*/}
                    STT
                </Th>
                <Th className="lincese-key-table__cell">Mã Key</Th>
                <Th className="lincese-key-table__cell">Sản phẩm</Th>
                <Th className="lincese-key-table__cell">Ngày tạo</Th>
                <Th className="lincese-key-table__cell">Ngày bán</Th>
                <Th className="lincese-key-table__cell">Đơn hàng</Th>
                <Th className="lincese-key-table__cell">Trạng thái</Th>
                <Th className="lincese-key-table__cell" style={{display: 'flex'}}/>
            </Tr>
        </StyledThead>
    )
}

export default TheadLicense


export const StyledThead = styled.div`
  .lincese-key-table {
    &__cell {
      margin: 0 8px;
      display: flex;
      align-items: center;
    }
     &__cell:nth-child(1) {
      width: 50px;
      justify-content: center;
    }
    &__cell:nth-child(2) {
      width: 20%;
    }
    &__cell:nth-child(3) {
      width:30%;
    }
    &__cell:nth-child(4) {
      width: 17%;
    }
    &__cell:nth-child(5) {
      width: 17%;
    }
    &__cell:nth-child(6) {
        width: 10%;
         justify-content: center;
        
    }
    &__cell:nth-child(7) {
      width: 10%;
      justify-content: center;
      padding-right: 23px;
    }
    &__cell:nth-child(8) {
      width:100px;
    }
  }
`
