import useCreateInfoPrice from '../../../hooks/useCreateInfoPrice'
import { Grid } from '@mui/material'
import { Input } from '../../../../../common/form/input'
import { Text } from '../../../../../common/text'
import { THEME_SEMANTICS } from '../../../../../common/theme/_semantics'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CategoryDateTimeRangePicker } from '../../../../../common/form/datePicker/_categoryDateTimeRangePicker'
import { DateRangePicker } from 'rsuite'
const {  beforeToday } = DateRangePicker;

const InfoPrice = () => {
  const { t } = useTranslation()
  const { dateTime, value, functions, validate } = useCreateInfoPrice()
  const dateTimeDefaultValue = [dateTime?.start, dateTime?.end]
  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={6} sm={6} md={6} lg={6} item>
          <div className="product-info-price__form-input">
            <Input
              label={
                <>{t('Giá bán')} <Text color={THEME_SEMANTICS.failed}>*</Text></>
              }
              value={value?.formInfoPrice?.retail}
              onChange={e => functions.onChangeRetail(e.target?.value)}
              onBlur={_ => validate.onBlurRetail()}
              validateText={validate?.formInfoPriceValidate?.retail?.message}
              validateType={validate?.formInfoPriceValidate?.retail?.status ? 'danger' : 'success'}
              placeholder={t('Vui lòng nhập giá bán')}
              maxLength={11}
              icon={
                <Text as="u"
                      style={{ color: '#7C88A6', position: 'absolute', right: 0 }}>
                  ₫
                </Text>
              }
            />
          </div>
        </Grid>
        <Grid xs={6} sm={6} md={6} lg={6} item>
          <div className="product-info-price__form-input">
            <Input
              label={
                <div style={{ marginLeft: 4 }}>
                  {t('Giá khuyến mãi')}
                </div>
              }
              value={value?.formInfoPrice?.discount}
              onChange={e => functions.onChangeDiscount(e.target?.value)}
              onBlur={e => validate.onBlurDiscount()}
              placeholder={t('Vui lòng nhập giá khuyến mãi')}
              validateText={validate?.formInfoPriceValidate?.discount?.message}
              validateType={validate?.formInfoPriceValidate?.discount?.status ? 'danger' : 'success'}
              maxLength={11}
              icon={
                <Text as="u"
                      style={{ color: '#7C88A6', position: 'absolute', right: -8 }}>
                  ₫
                </Text>
              }
            />
          </div>
        </Grid>
        <Grid xs={6} sm={6} md={6} lg={6} item>
          <div className="product-info-price__form-input" style={{ margin: '24px 4px 4px 0px'}}>
            <CategoryDateTimeRangePicker
              className="store-upos-filter-form__input-wide"
              categoryList={[{ id: 1, name: 'Thời gian khuyến mãi', value: 'create' }]}
              categoryWidth={148}
              value={dateTime.value}
              triggerDefault={dateTime.triggerDefault}
              onChange={dateTime.onChange}
              placement={'autoVerticalStart'}
              datePickerProps={{
                defaultValue: dateTimeDefaultValue,
                disabledDate: beforeToday(),
                placeholder: 'dd/mm/yyyy HH:mm ~ dd/mm/yyyy HH:mm',
              }}
              validateText={validate?.formInfoPriceValidate?.dateTime?.message}
              validateType={validate?.formInfoPriceValidate?.dateTime?.status ? 'danger' : 'success'}
            />
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default InfoPrice