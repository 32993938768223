import {Button} from 'common/button'
import {Text} from 'common/text'
import styled from 'styled-components'
import {GLOBAL_ICONS} from "../../../interface/icon";
import config from "../../../config";
import {useContext} from "react";
import {EventManagementContext} from "../provider/_context";

export const ShareModal = ({
                               isLoading,
                               onClose,
                               ...props
                           }) => {

    const { pageState } = useContext(EventManagementContext)
    const referral = pageState.shareModal?.referral
    const handleShare = (type) => {
        // Create the Messenger send dialog link
        const messengerLink = `http://www.facebook.com/dialog/send?app_id=${config.REACT_APP_FACEBOOK_APP_ID}&link=${referral}&redirect_uri=${encodeURIComponent(config.HREF)}`;
        const facebookLink = `http://www.facebook.com/dialog/share?app_id=${config.REACT_APP_FACEBOOK_APP_ID}&display=popup&href=${referral}&redirect_uri=${encodeURIComponent(config.HREF)}`;
        const zaloLink = `http://www.facebook.com/dialog/share?app_id=${config.REACT_APP_FACEBOOK_APP_ID}&display=popup&href=${referral}&redirect_uri=${encodeURIComponent(config.HREF)}`;
        // Open a new window with the Messenger send dialog
        window.open(type == 1 ? messengerLink : type == 2 ? facebookLink : zaloLink, 'Share on Messenger', 'width=800,height=600');
    };


    return (
        <StyledConfirmDeleteModal {...props} onClick={onClose}>
            <div
                className="order-table__confirm-delete-modal__container"
                onClick={e => e.stopPropagation()}
            >
                <div className="order-table__confirm-delete-modal__header">
                    <Text as="h2" fontSize={20} lineHeight={28} color={'#3264FF'}>
                        {GLOBAL_ICONS.share} Chia sẻ với bạn bè qua
                    </Text>
                </div>
                <div className="order-table__confirm-delete-modal__body">
                    <div className={'order-table__confirm-delete-modal__body-content'}>
                        <div className={'order-table__confirm-delete-modal__body-content___item'}
                             onClick={() => handleShare(2)}>
                            <img src="img/facebook.png" alt="facebook"/>
                            <Text as={'p'}>Facebook</Text>
                        </div>
                        <div className={'order-table__confirm-delete-modal__body-content___item'}
                             onClick={() => handleShare(1)}>
                            <img src="img/messenger.png" alt="facebook mess"/>
                            <Text as={'p'}>Messenger</Text>
                        </div>
                        <div className={'order-table__confirm-delete-modal__body-content___item'}
                             onClick={() => handleShare(3)}>
                            <img src="img/zalo.png" alt="zalo"/>
                            <Text as={'p'}>Zalo</Text>
                        </div>
                    </div>

                </div>
                <div className="order-table__confirm-delete-modal__footer">
                    <Button
                        size="sm"
                        appearance="ghost"
                        style={{minWidth: 110}}
                        onClick={onClose}
                    >
                        Đóng
                    </Button>
                </div>
            </div>
        </StyledConfirmDeleteModal>
    )
}

const StyledConfirmDeleteModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.25);

  .order-table__confirm-delete-modal {
    &__container {
      width: 480px;
      padding: 24px;

      background: #ffffff;
      border-radius: 8px;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    }

    &__header {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      h2{
      display: flex;
      align-items: center;
      svg{
        margin-right: 16px;
        margin-left: -16px;
      }
      }
    }

    &__body {
      margin-bottom: 32px;
      &-content{
        display: flex;
        align-items: center;
        justify-content: center;
        &___item{
          cursor: pointer;
          margin-right: 48px;
          text-align: center;
          &:last-child{
            margin-right: 0;
          }
          p{
            width: 100%!important;
          }
        }
      }
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
    }
  }
  .order-table__confirm-delete-modal__radio{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    cursor: pointer;
    p{
      margin-left: 8px;
    }
  }
`
