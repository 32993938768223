import {useReducer} from "react";
import {useLicenseKeyInitialState, useLicenseKeyReducer, useLicenseKeyAction} from "../provider/_reducer";
import {sendRequestAuth} from "../../../api/api";
import config from "../../../config";
export const useLicenseKey = ()=>{
    const [state, dispatch] = useReducer(useLicenseKeyReducer,useLicenseKeyInitialState)
    const handleOriginFetch = async () => {
        const response = await Promise.all([
            sendRequestAuth('get', `${config.API}/product/license/filter?keyword=&product_name=&status=&per_page=20&start=0`),
            sendRequestAuth('get', `${config.API}/product/license/filter-products?keyword=`),

        ])
        if(response[0]?.data?.success) {
            const books = response[0]?.data
            dispatch({
                type: useLicenseKeyAction.TABLE_UPDATE_DISPLAY_LIST,
                payload: {
                    list: books?.data,
                    listDefault: books?.data,
                    loading: false
                }
            })
            dispatch({
                type: useLicenseKeyAction.TABLE_UPDATE_PAGINATION,
                payload: {
                    active: 0,
                    amount: books?.meta?.per_page,
                    total: Math.ceil(books?.meta?.total / books?.meta?.per_page),
                    totalItems: books?.meta?.total,
                }
            })
            dispatch({
                type:useLicenseKeyAction.FORM_CREATE_MODAL_LIST_PRODUCT,
                payload:{
                    list:response[1].data,
                    listOrigin:response[1].data,
                }
            })
        }

    }

    const querySearch = {
        per_page: state?.table?.pagination?.amount || 20,
        start: (state?.table?.pagination?.active * state?.table?.pagination?.amount) || 0,
        keyword: state.filter?.keyCode?.value || '',
        status: state.filter?.status?.value?.value || '',
        product_name:state.filter?.productName?.value || ''
    }

    const handleAmountChange = async n => {
        const qs = {...querySearch, per_page: n, start: 0}
        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(qs)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        fetchLicenseKeyByFilter(queryString)

        dispatch({
            type: useLicenseKeyAction.TABLE_SELECTED_LIST_UPDATE,
            payload: {selected: {list: []}},
        })
    }

    const handlePageChange = async page => {
        const amount = state?.table?.pagination?.amount || 20
        const qs = {
            ...querySearch,
            per_page: state?.table?.pagination?.amount,
            start: page * amount
        }
        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(qs)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        fetchLicenseKeyByFilter(queryString)
    }

    const fetchLicenseKeyByFilter = async (qs) => {
        dispatch({
            type: useLicenseKeyAction.TABLE_LOADING_DISPLAY,
            payload: true
        })
        const response = await Promise.all([
            sendRequestAuth('get', `${config.API}/product/license/filter${qs}`),
        ])
        if (response[0]?.data?.success) {
            dispatch({
                type: useLicenseKeyAction.TABLE_LOADING_DISPLAY,
                payload: false
            })
            const books = response[0]?.data
            dispatch({
                type: useLicenseKeyAction.TABLE_UPDATE_DISPLAY_LIST,
                payload: {
                    list: books?.data,
                    listDefault: books?.data,
                    loading: false
                }
            })
            dispatch({
                type: useLicenseKeyAction.TABLE_UPDATE_PAGINATION,
                payload: {
                    active:Math.ceil(books?.meta?.start / books?.meta?.per_page),
                    amount: books?.meta?.per_page,
                    total: Math.ceil(books?.meta?.total / books?.meta?.per_page),
                    totalItems: books?.meta?.total,
                }
            })
        }

    }
    return {
        fetch: {
            origin: handleOriginFetch
        },
        provider: {
            state,
            dispatch
        },
        pagination: {
            onAmountChange: handleAmountChange,
            onPageChange: handlePageChange,
        }
    }
}