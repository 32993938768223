import {Input} from 'common/form/input';
import {Text} from 'common/text';
import {THEME_SEMANTICS} from 'common/theme/_semantics';
import {useModal} from 'Pages/productGroup/hook/useModal';
import {ProductGroup} from 'Pages/productGroup/provider/_context';
import React, {useEffect, useState} from 'react';
import {useContext} from 'react';
import {StyledProductGroupContentModal} from './_styled';
import {AlternativeAutoComplete} from 'common/form/autoComplete/_alternativeAutoComplete';
import {Tooltip} from 'common/tooltipv2';
import {Tooltip as TooltipV1} from 'common/tooltip';
import {useTranslation} from "react-i18next";
import {AutoCompleteSingleOption} from '../autocompleteSingleOption'
import {GLOBAL_ICONS} from "../../../../interface/icon";

const Index = ({...props}) => {
   const {t} = useTranslation()
   const {pageState} = useContext(ProductGroup)
   const {onPickup, field_name, field_status, search} = useModal()
   const disabled = pageState.disabled

   const PRODUCT_GROUP_STATUS = [
      {name: 'Đang hoạt động', value: 'active'},
      {name: 'Không hoạt động', value: 'inactive'},
   ]
   return (
      <StyledProductGroupContentModal>
         <div className={'product-group-content'}>
            <div className='product-group-content_group'>
               <Input
                  {...props}
                  label={
                     <>
                        {t('product_page_name_group_product')} <Text color={THEME_SEMANTICS.failed}>*</Text>
                     </>
                  }
                  autoComplete={'false'}
                  maxLength={80}
                  value={pageState.name_product}
                  placeholder={t('product_page_enter_name_group_product')}
                  validateText={pageState.valid.filde_name.status ? pageState.valid.filde_name.message : null}
                  validateType={!pageState.valid.filde_name.status ? 'success' : 'danger'}
                  onChange={(e) => field_name.onChangeNameProduct(e)}
                  onBlur={(e) => field_name.onBlurNameProduct(e)}
               />
            </div>
            <div className='product-group-content_group'>
               <AlternativeAutoComplete
                  {...props}
                  inputProps={{
                     categoryList: [], // menu list in category dropdown
                     categoryValue: {name: '', value: ''}, // if not exist this value -> default category: categoryList[0]
                     // categoryWidth: 140,
                     categoryHidden: true,
                     label: (
                        <div style={{display: 'flex', alignItems: 'center'}}>
                           <Text style={{marginRight: 4}}>{t('product_page_parent_group_product')}</Text>
                           <TooltipV1
                              title={'Nếu bạn bỏ trống Danh mục cha, hệ thống sẽ mặc định quy định đây là danh mục cấp 1 và không thể thay đổi'}>{GLOBAL_ICONS.question}</TooltipV1>
                        </div>
                     ),
                     placeholder: t('product_page_select_parent_group_product'),
                     readOnly: true,
                     disabled,
                     value: pageState.item_category.item ? pageState.item_category.item : '',

                  }}
                  menuProps={{
                     empty: pageState.category_list.length <= 0 ? t('product_page_empty_group_product') : '',
                  }}
                  searchInputProps={{
                     placeholder: t('product_page_find_group_product'),
                     value: pageState.keyword || '',
                     onChange: search.searchkeyWord,
                  }}
                  className={disabled && 'product-group-content_alternative'}
                  validateText={pageState.valid.filde_parent.status ? pageState.valid.filde_parent.message : null}
                  validateType={!pageState.valid.filde_parent.status ? 'success' : 'danger'}
               >
                  {pageState.category_list && disabled === false &&
                  pageState.category_list.map(item => {
                        if (pageState.check_parent === item.item) {
                           return (
                              <AutoCompleteSingleOption
                                 key={item.value}
                                 data-active={item.item === pageState.item_category?.item}
                                 onClick={(e) => e.stopPropagation()}
                                 className={'product-group-content_alternative-option'}
                              >
                                 <Tooltip className='tooltip_select' title={item.item} baseOn='height'
                                          placement='top-center'>
                                    {item.item}
                                 </Tooltip>
                              </AutoCompleteSingleOption>
                           )
                        } else {
                           return (
                              <AutoCompleteSingleOption
                                 key={item.value}
                                 data-active={item.item === pageState.item_category?.item}
                                 onClick={() => onPickup(item.item, item.id)}
                              >
                                 <Tooltip className='tooltip_select' title={item.item} baseOn='height'
                                          placement='top-center'>
                                    {item.item}
                                 </Tooltip>
                              </AutoCompleteSingleOption>
                           )
                        }
                     }
                  )}
               </AlternativeAutoComplete>
            </div>
            <div className='product-group-content_group'>
               <AlternativeAutoComplete
                  {...props}
                  inputProps={{
                     categoryList: [], // menu list in category dropdown
                     categoryValue: {name: '', value: ''}, // if not exist this value -> default category: categoryList[0]
                     categoryHidden: true,
                     label: 'Trạng thái',
                     value: t(pageState?.status_category?.name) || '',

                  }}
                  hideSearchBar={true}
               >
                  {PRODUCT_GROUP_STATUS.map(item => (
                        <AutoCompleteSingleOption
                           key={item.value}
                           data-active={item.value === pageState?.status_category?.value}
                           onClick={() => field_status.onChangeStatus(item)}
                        >
                           <Tooltip className='tooltip_select' title={item.name} baseOn='height'
                                    placement='top-center'>
                              {item.name}
                           </Tooltip>
                        </AutoCompleteSingleOption>
                     )
                  )}
               </AlternativeAutoComplete>
            </div>
         </div>

      </StyledProductGroupContentModal>

   )
}
export default Index;