import {Input} from 'common/form/input'
import {Text} from 'common/text'
import {THEME_SEMANTICS} from 'common/theme/_semantics'
import React, {useRef, useState} from 'react'
import {useContext} from 'react'
import {StyledProductGroupContentModal} from './_styled'
import {useTranslation} from 'react-i18next'
import {BannerManagementContext} from '../../provider/_context'
import {useModal} from '../../hooks/useModal'
import useAlert from '../../../../hook/useAlert'
import {bannerManagementActions} from '../../provider/_init'
import {StyledInfoProduct} from '../../../creatDigitalProduct/createProduct/conponents/infoProduct'
import {THEME_COLORS} from 'common/theme/_colors'
import {GLOBAL_ICONS} from '../../../../interface/icon'
import {CategoryDatePicker} from '../../../../common/form/datePicker'
import {PositionBanner} from './component/positionBanner'
import {StatusBanner} from './component/statusBanner'
import {LinkTo} from './component/linkTo'
import {ProductCategory} from './component/_category'
import {ProductBanner} from './component/_product'
import {Textarea} from '../../../../common/form/textarea'
import styled from 'styled-components'

const Index = ({...props}) => {
   const {t} = useTranslation()
   const {pageState, pageDispatch} = useContext(BannerManagementContext)
   const {field_name} = useModal()
   const images = pageState?.create?.image
   const {showAlert} = useAlert()

   const [validates, setValidates] = useState({
      images: {overload: false},
   })

   const handleImageFileChange = e => {
      const files = e.target.files
      let count = 0
      const item = files[0]
      if (
         !['image/jpg', 'image/jpeg', 'image/png'].includes(
            item.type.toLowerCase(),
         )
      ) {
         showAlert({
            type: 'danger',
            content: 'Chỉ hỗ trợ các định dạng: .jpg, .jpeg, .png.',
         })
      }
      if (item?.size <= 3000000) {
         pageDispatch(
            {
               type: bannerManagementActions.UPDATE_FORM_CREATE,
               payload: {
                  image: {
                     file: item,
                     link: window.URL.createObjectURL(item)
                  }
               }
            }
         )
         pageDispatch(
            {
               type: bannerManagementActions.CHANGE_MODAL,
               payload: true
            }
         )
         pageDispatch(
            {
               type: bannerManagementActions.CREATE_BANNER_VALIDATE_UPDATE,
               payload: {
                  image: ''
               }
            }
         )
      } else
         showAlert({
            type: 'danger',
            content: 'Ảnh tải lên không được vượt quá 3MB',
         })
   }

   const handleImageDelete = id => {
      pageDispatch(
         {
            type: bannerManagementActions.UPDATE_FORM_CREATE,
            payload: {
               image: {
                  file: null,
                  link: null
               }
            }
         }
      )
      pageDispatch(
         {
            type: bannerManagementActions.CHANGE_MODAL,
            payload: true
         }
      )
   }

   return (
      <StyledProductGroupContentModal>
         <div className={'product-group-content'}>
            <div className='product-group-content_group flex'>
               <div className="product-group-content_group-items">
                  <Input
                     {...props}
                     label={
                        <>
                           {t('Tên banner')} <Text color={THEME_SEMANTICS.failed}>*</Text>
                        </>
                     }
                     autoComplete={'false'}
                     maxLength={80}
                     value={pageState.create?.title?.value}
                     placeholder={'Vui lòng nhập tên banner'}
                     validateText={pageState.create?.validate?.title}
                     validateType={!pageState.create?.validate?.title ? 'success' : 'danger'}
                     onChange={(e) => field_name.onChangeNameBanner(e)}
                     onBlur={(e) => field_name.onBlurNameBanner(e)}
                  />
               </div>
               <div className="product-group-content_group-items">
                  <StatusBanner/>
               </div>

            </div>
            <div className="product-group-content_group flex">
               <div className="product-group-content_group-items">
                  <label className={'input__label'} style={{margin: '0px 0px 8px', display: 'block'}}><Text>Ngày bắt
                     đầu</Text></label>
                  <CategoryDatePicker
                     datePickerProps={{
                        defaultValue: pageState.create.startedDate.value,
                        cleanable: false,
                     }}
                     onChange={e => field_name.onChangeStartedDate(e)}
                     format={'dd/MM/yyyy'}
                     disabledTime={'isBefore'}
                  />
               </div>
               <div className="product-group-content_group-items">
                  <label className={'input__label'} style={{margin: '0px 0px 8px', display: 'block'}}><Text>Ngày kết
                     thúc</Text></label>
                  <CategoryDatePicker
                     horizontal={'Start'}
                     datePickerProps={{
                        defaultValue: pageState.create.endedDate.value,
                        cleanable: false,
                     }}
                     onChange={e => field_name.onChangeEndedDate(e)}
                     format={'dd/MM/yyyy'}
                     disabledTime={'isBefore'}
                  />
               </div>
            </div>

            <div className='product-group-content_group flex'>
               <div className="product-group-content_group-items">
                  <PositionBanner/>
               </div>
               <div className="product-group-content_group-items">
                  <Input
                     {...props}
                     label={'Thứ tự ưu tiên'}
                     autoComplete={'false'}
                     maxLength={80}
                     value={pageState.create?.ordering}
                     placeholder={'Vui lòng nhập thứ tự ưu tiên hiển thị banner'}
                     onChange={(e) => field_name.onChangeOrderingBanner(e)}
                  />
               </div>
            </div>
            <div className='product-group-content_group flex'>

               <div className="product-group-content_group-items">
                  <StyledInfoProduct>
                     <div className={'product-info-product'}>
                        <Text>{t('Hình ảnh')} <Text color={THEME_SEMANTICS.failed}>*</Text></Text>
                        <div className={'product-info-product__img'}>
                           <StyledFacebookResponseContentScriptDetailDrawer>
                              <Galleries
                                 data={images}
                                 validate={validates?.images}
                                 onChange={handleImageFileChange}
                                 onCreateClick={() =>
                                    setValidates({
                                       ...validates,
                                       images: {...validates.images, overload: false},
                                    })
                                 }
                                 onImageDelete={imageId => handleImageDelete(imageId)}
                              />
                           </StyledFacebookResponseContentScriptDetailDrawer>
                        </div>
                        {!!pageState.create.validate?.image &&
                        <Text color={'#ff424e'} fontSize={12}>{pageState.create.validate?.image}</Text>}
                     </div>
                  </StyledInfoProduct>
               </div>

               <div className="product-group-content_group-items">
                  <Textarea
                     style={{width: '100%'}}
                     value={pageState?.create?.description}
                     label={'Mô tả'}
                     onChange={field_name.onChangeDescription}/>
               </div>
            </div>
            <div className='product-group-content_group flex'>

               <div className="product-group-content_group-items">
                  <Input
                     {...props}
                     label={'Mã màu chính'}
                     autoComplete={'false'}
                     maxLength={7}
                     value={pageState.create?.color}
                     placeholder={'Vui lòng nhập mã màu'}
                     onChange={(e) => field_name.onChangeColor(e)}
                  />
               </div>

               <div className="product-group-content_group-items">
                  <LinkTo/>
               </div>
               <div className="product-group-content_group-items" style={{marginLeft: 16}}>
                  {pageState?.create?.linkTo?.value === 'category' && <ProductCategory/>}
                  {pageState?.create?.linkTo?.value === 'product' && <ProductBanner/>}
               </div>
            </div>
         </div>

      </StyledProductGroupContentModal>

   )
}
export default Index

const Galleries = ({
                      data,
                      validate,
                      onChange,
                      onCreateClick,
                      onImageDelete,
                      ...props
                   }) => {
   const inputFileRef = useRef(null)
   return (
      <div
         {...props}
         className={`facebook-response-content-script-detail-drawer__galleries ${
            props?.className || ''
         }`}
      >

         <div className="facebook-response-content-script-detail-drawer__galleries-list">
            <div
               className="facebook-response-content-script-detail-drawer__galleries-create"
               onDrop={e => {
                  e.preventDefault()
                  onChange(e)
               }}
               onClick={() => {
                  if (onCreateClick) onCreateClick()
                  if (inputFileRef?.current) {
                     inputFileRef.current.value = ''
                     inputFileRef.current.click()
                  }
               }}
            >
               {
                  <svg
                     width="19"
                     height="18"
                     viewBox="0 0 19 18"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M9.5 3.74997V14.25"
                        stroke="#C8CBD4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                     <path
                        d="M4.25 8.99997H14.75"
                        stroke="#C8CBD4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                  </svg>
               }
               <Text color={THEME_COLORS.primary_300} style={{display: 'block'}}>
                  Tải ảnh
               </Text>
            </div>
            <input
               ref={inputFileRef}
               accept="image/png, image/jpeg, image/jpg"
               type="file"
               multiple={false}
               style={{display: 'none'}}
               onChange={onChange}
            />
         </div>

         {!!data?.link &&
         <div
            className="banner-content"
         >
            <img
               className="banner-content__img"
               src={data?.link}
               alt={'kkk'}
            />
            <div
               className="banner-content__icon"
               onClick={e => {
                  e.stopPropagation()
                  if (onImageDelete) onImageDelete()
               }}
            >
               {GLOBAL_ICONS.x2}
            </div>
         </div>
         }
      </div>
   )
}


const StyledFacebookResponseContentScriptDetailDrawer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  
  overflow: auto;
  display: flex;
  .file__galleries{
    &-item{
      display: flex;
      align-items: center;
      &:nth-child(1){
        width: 90%;
        margin-right: 8px;
        .file-content{
           width: 100%;
           a{
            width: 100%;
            
            height: 100%;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
          }
        }
        
      }
      cursor: pointer;
    }
  }
  .facebook-response-content-script-detail-drawer {
    &__header {
      margin-bottom: 32px;
    }

    &__footer {
      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 64px;
      padding: 0 24px;

      display: flex;
      align-items: center;
      justify-content: flex-end;

      background: #fff;
      border-top: 1px solid #ebeef5;
      z-index: 10
    }

    &__input-group {
      width: calc(100% + 8px);
      margin: 0 -4px 8px -4px;

      display: flex;
      flex-wrap: wrap;
    }

    &__input-item {
      width: calc(50% - 8px);
      margin: 0 4px 16px 4px;

      &[data-size='lg'] {
        width: calc(100% - 8px);
      }

      &[data-size='sm'] {
        width: calc(100% / 3 - 8px);
      }
    }
    &__galleries{
      display: flex;
    }
    &__galleries-list {
      margin: 12px -4px 0 -4px;

      display: flex;
      flex-wrap: wrap;
    }

    &__galleries-item {
      position: relative;

      width: 84px;
      height: 84px;
      margin: 0 4px 8px 4px;

      border-radius: 4px;

      cursor: pointer;

      @media screen and (max-width: 1599px) {
        width: 82px;
        height: 82px;
      }
      &:hover {
        &::before {
          opacity: 1;
        }
      }
    }

    &__galleries-background {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 100%;
      height: 100%;

      border-radius: 4px;

      object-fit: cover;
      object-position: center;
    }

    &__galleries-delete {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 3;

      width: 18px;
      height: 18px;

      transform: translate(50%, -50%);

      svg {
        width: 18px;
        height: 18px;
      }
    }

    &__galleries-create {
      width: 84px;
      height: 84px;
      margin: 0 4px 8px 4px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      border: 1px dashed #ebeef5;
      border-radius: 4px;

      cursor: pointer;
    }

    &__loading-modal {
      .modal__body {
        min-height: 150px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
`
