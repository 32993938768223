import { Grid } from '@mui/material'
import React from 'react'
import { Phone } from './_phone'
import { Email } from './_email'
import { NamePerson } from './_name'
import { Address } from './_address'
import { Note } from './_note'
import { Text } from '../../../../../common/text'
import { ProductDetail } from './_productDetaill'
import { useEditOrder } from '../../../hooks/useEditOrder'
import { fDateTimeCustom } from '../../../../../util/formatTime'

export const CustomerInfo = () => {
  const {customerInfo} = useEditOrder()
  return (
    <Grid container spacing={2}>
      <Grid xs={4} sm={4} md={4} lg={4} item>
        <Text fontWeight={500}>User Id: {customerInfo?.order?.zalo_id}</Text>
      </Grid>
      <Grid xs={4} sm={4} md={4} lg={4} item>
        <Text fontWeight={500}>Ngày mua hàng: {!!customerInfo?.order?.created_at ? fDateTimeCustom(customerInfo?.order?.created_at, { format: 'HH:mm dd/MM/yyyy' }) : '---'}</Text>
      </Grid>
      <Grid xs={4} sm={4} md={4} lg={4} item>
        <Text fontWeight={500}>Cập nhật lần cuối: {!!customerInfo?.order?.updated_at ? fDateTimeCustom(customerInfo?.order?.updated_at, { format: 'HH:mm dd/MM/yyyy' }) : '---'}</Text>
      </Grid>
      <Grid xs={4} sm={4} md={4} lg={4} item>
        <NamePerson/>
      </Grid>
      <Grid xs={4} sm={4} md={4} lg={4} item>
        <Phone/>
      </Grid>
      <Grid xs={4} sm={4} md={4} lg={4} item>
        <Email/>
      </Grid>
      <Grid xs={8} sm={8} md={8} lg={8} item>
        <Address/>
      </Grid>
      {/*<Grid xs={4} sm={4} md={4} lg={4} item>*/}
      {/*  <FeeShip/>*/}
      {/*</Grid>*/}
      <Grid xs={8} sm={8} md={8} lg={8} item>
        <Note/>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12} item>
        <ProductDetail/>
      </Grid>
    </Grid>
  )
}