import {useContext, useState} from "react";
import useAlert from "../../../hook/useAlert";
import {sendRequestAuth} from "../../../api/api";
import config from "../../../config";
import {LicenseKey} from "../provider/_context";
import {useLicenseKeyAction} from "../provider/_reducer"
export const useLicenseKeyRow = data =>{
    const { pageState, pageDispatch } = useContext(LicenseKey)
    const { table } = pageState
    const {showAlert} = useAlert()
    // const navigate = useNavigate()


    const detailActive = table.detail.active
    const detailActiveId = table.detail.id
    const detailList = table.detail.list

    const selectedList = table.selected.list

    const isSelected = !!selectedList.find(item => item?.id === data?.id)

    const shouldOpenDetail = data?.id && detailActiveId === data.id

    const handleToggleDetail = _ => {
        if (!data?.id) return
        if (data.id === detailActiveId) {
           pageDispatch({
                type: useLicenseKeyAction.TABLE_DISPLAY_DETAIL_ID_UPDATE,
                payload: { id: null },
            })
           pageDispatch({
                type: useLicenseKeyAction.TABLE_DISPLAY_DETAIL_UPDATE,
                payload: { active: null },
            })
            return
        }

       pageDispatch({
            type: useLicenseKeyAction.TABLE_DISPLAY_DETAIL_ID_UPDATE,
            payload: { id: data.id },
        })

        const findDetail = detailList.find(item => item?.id === data.id)
        if (findDetail) {
           pageDispatch({
                type: useLicenseKeyAction.TABLE_DISPLAY_DETAIL_UPDATE,
                payload: { active: findDetail },
            })
        }

        fetchRowDetail()
    }

    const fetchRowDetail = async _ => {
        const response = await sendRequestAuth('get', `${config.API}/product/license/detail/${data.id}`)

        if (response?.data?.success) {
            const newItem = response?.data?.data
           pageDispatch({
                type: useLicenseKeyAction.TABLE_DISPLAY_DETAIL_UPDATE,
                payload: { active: newItem, list: [...detailList, newItem] },
            })
        } else {
           pageDispatch({
                type: useLicenseKeyAction.TABLE_DISPLAY_DETAIL_UPDATE,
                payload: { active: [], list: [...detailList, []] },
            })
        }
    }

    const handleDisplayDeleteModal = (boo,id='') => {
       pageDispatch({
            type: useLicenseKeyAction.MODAL_DELETE_PRODUCT,
            payload: {
                open: boo,
                id: id
            }
        })
    }

    return {
        detail: {
            id: detailActiveId,
            active: detailActive,
        },
        row: {
            data,
            isSelected,
            shouldOpenDetail,
            onToggleDetail: handleToggleDetail,
            onDisplayDeleteModal: handleDisplayDeleteModal,
        },
    }
}